import { Checkbox, Col, Row } from "antd";
import { FC, useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Card from "components/card/Card";
import {
  checkBoxChecked,
  GrapeAntdForm,
  useGrapeAntdForm,
} from "components/form";
import CustomTitle from "components/typography/title/CustomTitle";
import CustomButton from "components/button/CustomButton";
import { useDispatch, useSelector } from "app/store";

import styles from "./Step5.module.scss";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import BuilderSelected from "features/builder/components/builderList/componenets/builderSelected/BuilderSelected";
import { AlertModalType } from "components/alertModal/AlertModal.store";
import { useNavigate } from "react-router-dom";
import { ASZF_URL, PRIVACY_URL } from "constants/urls";
import enumTranslate from "utils/enumTranslate";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import { phoneNumberDisplayFormat } from "utils/format";
import { useBreakpoint } from "hooks/useBreakpoint";

const Step5: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const navigate = useNavigate();
  const { selectedBuilders, projectCode, wizardData, address } = useSelector(
    (state) => state.builder
  );

  const { loading } = useSelector(
    (state) => state.loading.effects.builder.createBuildOrder
  );

  const formUtils = useGrapeAntdForm();
  const mobileScreen = useBreakpoint({ max: "sm" });

  const handleFinish = useCallback(async () => {
    try {
      await dispatch.builder.createBuildOrder();
      setIsSubmitBtnDisabled(true);

      await dispatch.alertModal.openModal({
        title: t("BUILDERS.BUILDERS_WIZARD.SUCCESS_MODAL.SUCCESS_TITLE"),
        descriptin: t("BUILDERS.BUILDERS_WIZARD.SUCCESS_MODAL.MESSAGE"),
        secondaryButtonLabel: t(
          "BUILDERS.BUILDERS_WIZARD.SUCCESS_MODAL.GOTO_HOMEPAGE_BUTTON"
        ),
        onSecondaryButtonClick: () => {
          if (process.env.NODE_ENV === "development") {
            navigate(`/`);
          } else {
            window.location.href = "https://opticonnect.mvm-optimum.hu/";
          }

          dispatch.alertModal.close();
        },
        type: AlertModalType.Success,
      });
    } catch (e) {
      console.error(e);
      setIsSubmitBtnDisabled(false);
    }
  }, [dispatch.builder, dispatch.alertModal, t, navigate]);

  return (
    <div className={styles.container}>
      <Card className={styles.cardContainer}>
        <CustomTitle
          level={3}
          align="center"
          color="darkBlue"
          className={styles.mainTitle}
        >
          {t("BUILDERS.BUILDERS_WIZARD.STEP_5.CARD_TITLE")}
        </CustomTitle>

        <BuilderSelected
          wizardTitle={t(
            "BUILDERS.BUILDERS_WIZARD.STEP_5.SELECTEDBUILDERS_TITLE"
          )}
          wizardTitlePosition="start"
          selectedBuilders={selectedBuilders}
          hideRemove
        />

        <Row gutter={8}>
          <Col md={24} lg={7}>
            <CustomParagraph
              color="darkBlue"
              className={styles.projectCodeTitle}
            >
              {t("BUILDERS.BUILDERS_WIZARD.STEP_5.DESIGN_TITLE")}
            </CustomParagraph>
          </Col>
          <Col>
            <CustomParagraph className={styles.projectCode}>
              {projectCode}
            </CustomParagraph>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col md={24} lg={7}>
            <CustomParagraph color="darkBlue" className={styles.detailsTitle}>
              {t("BUILDERS.BUILDERS_WIZARD.STEP_5.PLACE_TITLE")}
            </CustomParagraph>
          </Col>
          <Col>
            <CustomParagraph className={styles.details}>
              {address}
            </CustomParagraph>
          </Col>
        </Row>

        <CustomTitle align="left" color="darkBlue" className={styles.title}>
          {t("BUILDERS.BUILDERS_WIZARD.STEP_5.DETAILS_TITLE")}
        </CustomTitle>

        <CustomTitle align="left" color="grey" className={styles.subTitle}>
          {t("BUILDERS.BUILDERS_WIZARD.STEP_5.NOTE_TITLE")}
        </CustomTitle>
        <CustomParagraph className={styles.noteText}>
          {wizardData[1]?.taskDescription}
        </CustomParagraph>

        <CustomTitle align="left" color="grey" className={styles.subTitle}>
          {t("BUILDERS.BUILDERS_WIZARD.STEP_5.START_TITLE")}
        </CustomTitle>
        <CustomParagraph className={styles.noteText}>
          {enumTranslate(
            wizardData[1]?.preferredStart || "",
            EnumContext.PREFERREDSTART
          )}
        </CustomParagraph>

        <CustomTitle
          level={3}
          align="left"
          color="darkBlue"
          className={styles.title}
        >
          {t("BUILDERS.BUILDERS_WIZARD.STEP_5.FILES_TITLE")}
        </CustomTitle>

        <CustomParagraph size="md" strong>
          {wizardData[1]?.files?.map((file) => file.name).join("; ")}
        </CustomParagraph>

        <CustomTitle
          level={3}
          align="left"
          color="darkBlue"
          className={styles.title}
        >
          {t("BUILDERS.BUILDERS_WIZARD.STEP_5.CONTACT_TITLE")}
        </CustomTitle>

        <Row gutter={32}>
          <Col xs={24} md={8}>
            <CustomTitle className={styles.contactDataTitle}>
              {t("BUILDERS.BUILDERS_WIZARD.STEP_5.NAME_LABEL")}
            </CustomTitle>
          </Col>
          <Col xs={24} md={16}>
            <span className={styles.contactData}>{wizardData[2]?.name}</span>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={24} md={8}>
            <CustomTitle className={styles.contactDataTitle}>
              {t("BUILDERS.BUILDERS_WIZARD.STEP_5.EMAIL_LABEL")}
            </CustomTitle>
          </Col>
          <Col xs={24} md={16}>
            <span className={styles.contactData}>
              {wizardData[2]?.emailAddress}
            </span>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={24} md={8}>
            <CustomTitle className={styles.contactDataTitle}>
              {t("BUILDERS.BUILDERS_WIZARD.STEP_5.PHONE_LABEL")}
            </CustomTitle>
          </Col>
          <Col xs={24} md={16}>
            <span className={styles.contactData}>
              {phoneNumberDisplayFormat(wizardData[2]?.phoneNumber)}
            </span>
          </Col>
        </Row>

        <Row className={styles.contactNote}>
          <Col>
            <CustomTitle className={styles.contactDataTitle}>
              {t("BUILDERS.BUILDERS_WIZARD.STEP_5.CONTACT_NOTE")}
            </CustomTitle>
            <span className={styles.contactData}>
              <CustomParagraph size="md" className={styles.noteText}>
                {wizardData[2]?.note}
              </CustomParagraph>
            </span>
          </Col>
        </Row>
      </Card>

      <GrapeAntdForm
        requiredMark={false}
        formUtils={formUtils}
        className={styles.checkBoxGroup}
        onFinish={handleFinish}
      >
        <GrapeAntdForm.Item
          name="aszf"
          rules={[
            checkBoxChecked(
              t("BUILDERS.BUILDERS_WIZARD.STEP_5.ASZF_CHECKBOX_ERROR")
            ),
          ]}
          valuePropName="checked"
        >
          <Checkbox>
            <CustomParagraph className={styles.checkboxText}>
              <Trans i18nKey="BUILDERS.BUILDERS_WIZARD.STEP_5.ASZF">
                <a
                  onClick={() => window.open(ASZF_URL, "_blank")}
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </Trans>
            </CustomParagraph>
          </Checkbox>
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item
          name="dataHandling"
          rules={[
            checkBoxChecked(
              t("BUILDERS.BUILDERS_WIZARD.STEP_5.DATA_CHECKBOX_ERROR")
            ),
          ]}
          valuePropName="checked"
        >
          <Checkbox>
            <CustomParagraph className={styles.checkboxText}>
              <Trans i18nKey="BUILDERS.BUILDERS_WIZARD.STEP_5.DATA_HANDLING">
                <a
                  onClick={() => window.open(PRIVACY_URL, "_blank")}
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </Trans>
            </CustomParagraph>
          </Checkbox>
        </GrapeAntdForm.Item>
      </GrapeAntdForm>

      {!mobileScreen && (
        <Row justify="center">
          <CustomParagraph color="green" className={styles.info}>
            {`${t("BUILDERS.BUILDERS_WIZARD.STEP_5.INFO")}`}
            <br />
            {`${t("BUILDERS.BUILDERS_WIZARD.STEP_5.DESIGNER_CONTACT")}`}
          </CustomParagraph>
        </Row>
      )}

      <Row justify="center">
        <CustomButton
          type="primary"
          onClick={() => formUtils.form.submit()}
          size="large"
          className={styles.submitButton}
          loading={loading}
          disabled={isSubmitBtnDisabled}
        >
          {t("BUILDERS.BUILDERS_WIZARD.STEP_5.SUBMIT")}
        </CustomButton>
      </Row>

      <Row justify="center">
        <CustomButton type="text" onClick={() => dispatch.builder.prevStep()}>
          {t("COMMON.BACK")}
        </CustomButton>
      </Row>
    </div>
  );
};

export default Step5;
