import { useTranslation } from "react-i18next";
import { FC, useMemo } from "react";
import {
  GrapeAntdForm,
  pattern,
  requiredField,
  useGrapeAntdForm,
} from "components/form";
import { Input } from "antd";
import CustomButton from "components/button/CustomButton";
import styles from "./NewPassword.module.scss";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { useDispatch } from "app/store";
import useQuery from "hooks/useQuery";
import { AlertModalType } from "components/alertModal/AlertModal.store";
import { AuthStep } from "../Auth.store";
import { useLocation } from "react-router-dom";

export enum NewPasswordRoutes {
  welcomeProvider = "welcome-provider",
  forgottenPassword = "forgotten-password",
}

const NewPassword: FC = () => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isNewProvider = (): boolean =>
    pathname.includes(NewPasswordRoutes.welcomeProvider);

  const query = useQuery();

  const canLogin = process.env.REACT_APP_LOGIN_IS_ENABLED === "true";

  const token = useMemo(
    () => decodeURIComponent(query.get("token") || ""),
    [query]
  );

  const handleFinish = async ({ password }: any) => {
    try {
      if (isNewProvider()) {
        await dispatch.auth.confirmEmailResetPassword({
          token,
          password,
        });
      } else {
        await dispatch.auth.resetPassword({ token, password });
      }

      await dispatch.auth.resetAuthStep();

      if (canLogin) {
        dispatch.alertModal.openModal({
          buttonLabel: t("AUTH.LOGIN.BUTTON"),
          title: t("AUTH.NEW_PASSWORD.SUCCESS.TITLE"),
          descriptin: t("AUTH.NEW_PASSWORD.SUCCESS.DESC"),
          type: AlertModalType.Success,
          closable: true,
          onButtonClick: () =>
            dispatch.auth.setAuthStep({ authStep: AuthStep.LOGIN }),
        });
      } else {
        dispatch.alertModal.openModal({
          title: t("AUTH.NEW_PASSWORD.SUCCESS.TITLE"),
          descriptin: t("AUTH.NEW_PASSWORD.SUCCESS.DESC"),
          type: AlertModalType.Success,
          closable: true,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <CustomParagraph>{t("AUTH.NEW_PASSWORD.DESC")}</CustomParagraph>

      <CustomParagraph>{t("AUTH.NEW_PASSWORD.CONSTRAIN")}</CustomParagraph>

      <ul className={styles.constrainList}>
        <li>{t("AUTH.NEW_PASSWORD.LENGTH")}</li>
        <li>{t("AUTH.NEW_PASSWORD.UPPERCASE")}</li>
        <li>{t("AUTH.NEW_PASSWORD.LOWERCASE")}</li>
        <li>{t("AUTH.NEW_PASSWORD.NUMERIC")}</li>
      </ul>

      <GrapeAntdForm
        formUtils={formUtils}
        onFinish={handleFinish}
        className={styles.form}
      >
        <GrapeAntdForm.Item
          name="password"
          rules={[
            requiredField(),
            pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/),
          ]}
        >
          <Input.Password
            placeholder={t("AUTH.NEW_PASSWORD.PASSWORD_PLACEHOLDER")}
          />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item
          name="passwordAgain"
          rules={[
            requiredField(),
            ({ getFieldValue }: any) => ({
              validator(_: any, value: string) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error(t("AUTH.NEW_PASSWORD.PASSWORD_NOT_MATCH"))
                );
              },
            }),
          ]}
          dependencies={["password"]}
        >
          <Input.Password
            placeholder={t("AUTH.NEW_PASSWORD.PASSWORD_AGAIN_PLACEHOLDER")}
          />
        </GrapeAntdForm.Item>

        <CustomButton
          type="primary"
          htmlType="submit"
          size="large"
          block
          className={styles.button}
        >
          {t("AUTH.NEW_PASSWORD.BUTTON")}
        </CustomButton>
      </GrapeAntdForm>
    </>
  );
};

export default NewPassword;
