/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DesignOrderNoteType,
    DesignOrderNoteTypeFromJSON,
    DesignOrderNoteTypeFromJSONTyped,
    DesignOrderNoteTypeToJSON,
    DesignRefusalReason,
    DesignRefusalReasonFromJSON,
    DesignRefusalReasonFromJSONTyped,
    DesignRefusalReasonToJSON,
    ProviderInfoDto,
    ProviderInfoDtoFromJSON,
    ProviderInfoDtoFromJSONTyped,
    ProviderInfoDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface LatestNotes
 */
export interface LatestNotes {
    /**
     * 
     * @type {DesignOrderNoteType}
     * @memberof LatestNotes
     */
    type?: DesignOrderNoteType;
    /**
     * 
     * @type {DesignRefusalReason}
     * @memberof LatestNotes
     */
    reason?: DesignRefusalReason;
    /**
     * 
     * @type {string}
     * @memberof LatestNotes
     */
    note?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LatestNotes
     */
    createdAt?: Date;
    /**
     * 
     * @type {ProviderInfoDto}
     * @memberof LatestNotes
     */
    actor?: ProviderInfoDto;
}

export function LatestNotesFromJSON(json: any): LatestNotes {
    return LatestNotesFromJSONTyped(json, false);
}

export function LatestNotesFromJSONTyped(json: any, ignoreDiscriminator: boolean): LatestNotes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : DesignOrderNoteTypeFromJSON(json['type']),
        'reason': !exists(json, 'reason') ? undefined : DesignRefusalReasonFromJSON(json['reason']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'actor': !exists(json, 'actor') ? undefined : ProviderInfoDtoFromJSON(json['actor']),
    };
}

export function LatestNotesToJSON(value?: LatestNotes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': DesignOrderNoteTypeToJSON(value.type),
        'reason': DesignRefusalReasonToJSON(value.reason),
        'note': value.note,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'actor': ProviderInfoDtoToJSON(value.actor),
    };
}


