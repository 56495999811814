import { Col, notification, Row } from "antd";
import {
  BuildMaterialDto,
  BuildOrderDto,
  MaterialCategories,
  ProjectDto,
  UpdateBuildMaterialDto,
} from "api/generated/optimum";
import { useDispatch, useSelector } from "app/store";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isAdmin, isBuilder } from "utils/authUtils";
import CustomButton from "components/button/CustomButton";
import ConfirmModal from "components/confirmModal/ConfirmModal";
import CustomTitle from "components/typography/title/CustomTitle";
import MaterialListModal from "../../components/materialListModal/MaterialListModal";
import useBuild from "../useBuild";
import useBuildMaterialTableProps from "./useBuildMaterialTableProps";
import styles from "../../components/materialListModal/MaterialListModal.module.scss";
import { decimalSeparator } from "utils/format";
import ResetBuildMaterials from "../components/resetBuildMaterials/ResetBuildMaterials";

export const BuildMaterialListModal: React.FC<{
  visible: boolean;
  buildOrder: BuildOrderDto;
  project?: ProjectDto;
  onClose: () => void;
}> = ({ visible, buildOrder, project, onClose }) => {
  const dispatch = useDispatch();
  const { buildMaterial, updatedMaterialList, pricedMaterialList } =
    useSelector((state) => state.buildOrder);
  const { availableMaterials } = useSelector((state) => state.projects);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const tableProps = useBuildMaterialTableProps();
  const {
    isApprovedBuildOrder,
    isNewBuildOrder,
    isBuildOrderRefused,
    getAutomationData,
    getPanelDeliveryData,
    navigateWhenInvalidStatusOperation,
  } = useBuild();

  const { t } = useTranslation();

  const isEditList = !isAdmin() && (isApprovedBuildOrder || isNewBuildOrder);
  const isPricedList = isAdmin() && (isBuildOrderRefused || isNewBuildOrder);

  const getMaterials = useCallback(() => {
    buildOrder?.id &&
      dispatch.buildOrder.getBuildMaterialDetails(buildOrder?.id);
    dispatch.projects.getAvailableMaterials();

    if (isAdmin() && (isBuildOrderRefused || isNewBuildOrder)) {
      dispatch.buildOrder.getPricedMaterialList(Number(project?.designOrderId));
    }
  }, [
    buildOrder?.id,
    dispatch.buildOrder,
    dispatch.projects,
    isBuildOrderRefused,
    isNewBuildOrder,
    project?.designOrderId,
  ]);

  const handleReset = useCallback(() => {
    if (buildOrder?.id) {
      dispatch.buildOrder.resetBuildMaterialDetails(buildOrder?.id).then(() => {
        dispatch.buildOrder.setUpdatedMaterialList([]);
        getMaterials();
        getAutomationData();
        getPanelDeliveryData();
      });
    }
  }, [
    buildOrder?.id,
    dispatch.buildOrder,
    getAutomationData,
    getMaterials,
    getPanelDeliveryData,
  ]);

  useEffect(() => {
    getMaterials();
  }, [getMaterials]);

  const getFilteredData = (
    category: MaterialCategories
  ): BuildMaterialDto[] => {
    return (
      buildMaterial?.materials
        ?.filter((x) => x.category === category)
        .concat(
          availableMaterials
            ?.filter(
              (y) =>
                y.category === category &&
                !buildMaterial?.materials
                  ?.map((z) => z.materialId)
                  .includes(y.id)
            )
            .map((material) => ({ ...material, materialId: material.id }))
        ) || []
    );
  };

  const heatGenerationData = isEditList
    ? getFilteredData(MaterialCategories.HeatGeneration)
    : isPricedList
    ? pricedMaterialList?.filter(
        (y) => y.category === MaterialCategories.HeatGeneration
      ) || []
    : buildMaterial?.materials?.filter(
        (x) => x.category === MaterialCategories.HeatGeneration
      ) || [];

  const heatReleaseData = isEditList
    ? getFilteredData(MaterialCategories.HeatRelease)
    : isPricedList
    ? pricedMaterialList?.filter(
        (y) => y.category === MaterialCategories.HeatRelease
      ) || []
    : buildMaterial?.materials?.filter(
        (x) => x.category === MaterialCategories.HeatRelease
      ) || [];

  const controlData = isEditList
    ? getFilteredData(MaterialCategories.Control)
    : isPricedList
    ? pricedMaterialList?.filter(
        (y) => y.category === MaterialCategories.Control
      ) || []
    : buildMaterial?.materials?.filter(
        (x) => x.category === MaterialCategories.Control
      ) || [];

  const toolsData = isEditList
    ? getFilteredData(MaterialCategories.Tools)
    : isPricedList
    ? pricedMaterialList?.filter(
        (y) => y.category === MaterialCategories.Tools
      ) || []
    : buildMaterial?.materials?.filter(
        (x) => x.category === MaterialCategories.Tools
      ) || [];

  const calculateMaterialTotal = useCallback(
    (data?: BuildMaterialDto[] | null) =>
      data?.reduce((acc: number, current) => {
        const currentAmount =
          updatedMaterialList.find(
            (material) => material.materialId === current.materialId
          )?.amount ?? current.amount;

        if (currentAmount && current.price) {
          return acc + currentAmount * current.price;
        }

        return acc;
      }, 0),
    [updatedMaterialList]
  );

  const netTotal = calculateMaterialTotal(
    buildMaterial?.materials?.concat(
      availableMaterials
        ?.filter(
          (y) =>
            !buildMaterial?.materials?.map((z) => z.materialId).includes(y.id)
        )
        .map((material) => ({ ...material, materialId: material.id }))
    )
  );

  const saveBtnHandler = async () => {
    if (!netTotal) {
      notification.error({
        message: t("PROJECTS.DETAIL.BUILD.MATERIAL_LIST.NET_TOTA_ZERO"),
      });

      return;
    }

    const updatedMaterialsMerged = [
      ...(buildMaterial?.materials
        ?.filter(
          (material) =>
            !updatedMaterialList?.some(
              (updatedMaterial) =>
                material.materialId === updatedMaterial.materialId
            )
        )
        ?.map((filteredMaterial) => ({
          materialId: filteredMaterial.materialId,
          amount: filteredMaterial.amount,
        })) || []),
      ...updatedMaterialList?.filter((material) => material.amount !== 0),
    ];

    try {
      await dispatch.buildOrder.updateBuildOrderMaterials({
        buildOrderId: Number(buildOrder.id),
        materials: updatedMaterialsMerged as UpdateBuildMaterialDto[],
        paymentDueDate: buildOrder.buildMaterialListPaymentDueDate,
      });

      getAutomationData();
      getPanelDeliveryData();

      dispatch.buildOrder.getBuildMaterialDetails(Number(buildOrder.id));

      onClose();
    } catch (error) {
      navigateWhenInvalidStatusOperation(error as Response);
      console.error(error);
    }
  };

  return (
    <MaterialListModal
      visible={visible}
      heatGenerationData={heatGenerationData}
      heatReleaseData={heatReleaseData}
      controlData={controlData}
      toolsData={toolsData}
      tableProps={tableProps}
      afterContent={(data) => {
        const tabTotal = calculateMaterialTotal(data);

        return (
          <Row justify="end">
            <CustomTitle
              defaultFontSize
              extraStrong
              className={styles.tabTotal}
            >
              {t("PROJECTS.DETAIL.BUILD.MATERIAL_LIST.TAB_TOTAL", {
                tabTotal: tabTotal != null ? decimalSeparator(tabTotal) : 0,
              })}
            </CustomTitle>
          </Row>
        );
      }}
    >
      <>
        <Row justify="center" className={styles.buttonContainerRow}>
          <Col span={24}>
            <Row justify="center">
              {t("PROJECTS.DETAIL.BUILD.MATERIAL_LIST.NET_TOTAL")}
            </Row>
            <Row justify="center">
              <CustomTitle level={1} extraStrong>
                {netTotal != null && decimalSeparator(netTotal)}
              </CustomTitle>
            </Row>
          </Col>
          {isBuilder() && (isApprovedBuildOrder || isNewBuildOrder) ? (
            <div className={styles.buttonContainer}>
              <div>
                <CustomButton
                  onClick={() => {
                    if (updatedMaterialList.length) {
                      setIsConfirmModalVisible(true);
                      return;
                    }

                    onClose();
                  }}
                  size="large"
                  className={styles.cancelButton}
                  variant="default"
                >
                  {t("COMMON.CANCEL")}
                </CustomButton>
                <CustomButton
                  size="large"
                  type="primary"
                  onClick={saveBtnHandler}
                  className={styles.saveButton}
                  variant="default"
                >
                  {t("COMMON.SAVE")}
                </CustomButton>
              </div>
              <ResetBuildMaterials handleReset={handleReset} />
            </div>
          ) : (
            <CustomButton
              type="primary"
              onClick={onClose}
              className={styles.closeButton}
              variant="default"
            >
              {t("COMMON.CLOSE")}
            </CustomButton>
          )}
        </Row>
        <ConfirmModal
          isVisible={isConfirmModalVisible}
          onClose={() => setIsConfirmModalVisible(false)}
          onSubmit={async () => {
            await setIsConfirmModalVisible(false);
            onClose();
          }}
          title={t(
            "PROJECTS.DETAIL.DESIGN.MATERIAL.CONFIRM_MODAL.UNSAVED_CHANGES_TITLE"
          )}
          description={t("PROJECTS.DETAIL.DESIGN.MATERIAL.CONFIRM_MODAL.DESC")}
          submitBtnLabel={t(
            "PROJECTS.DETAIL.DESIGN.MATERIAL.CONFIRM_MODAL.REJECT"
          )}
        />
      </>
    </MaterialListModal>
  );
};
export default BuildMaterialListModal;
