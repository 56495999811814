import { isAdmin, isBuilder } from "utils/authUtils";
import {
  BuildOrderStatus,
  BuildOrderDto,
  BuildRefusalReason,
} from "api/generated/optimum";
import { useSelector, useDispatch } from "app/store";
import { AlertModalType } from "components/alertModal/AlertModal.store";
import { SelectedBuilder } from "features/builder/components/builderList/componenets/builderSelected/BuilderSelected";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

const useBuild = () => {
  const { currentProject } = useSelector((state) => state.projects);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getBuildOrderProjectStatus = (): BuildOrderStatus | undefined => {
    if (currentProject?.projectStatus?.includes("BuildOrderStatus")) {
      return BuildOrderStatus[
        currentProject?.projectStatus.split(
          "."
        )[1] as keyof typeof BuildOrderStatus
      ];
    }

    return undefined;
  };

  /**
   * {@link ProjectDto}
   * BuildOrderStatus depends on the user you are logged in with.
   *
   * ADMIN users have access to all 3 buildOrders that attached to this project. Thus the Admin is interested in the status of the PROJECT.
   * Builder users have access only to the specific buildOrder he has access to. He cares about the status of his OWN buildOrder, not the project itself.
   * @returns Admin - BuilderOrderStatus based on ProjectStatus - ProjectDto.projectStatus
   * @returns Builder - BuilderOrderStatus based on the state of his individual order {@link BuilderDto} ProjectDto.buildOrders[0]
   */
  const getBuildOrderStatus = (): BuildOrderStatus | undefined => {
    if (isAdmin()) {
      return getBuildOrderProjectStatus();
    }

    // The builder can only see one element that belongs to him.
    return currentProject?.buildOrders?.[0]?.status;
  };

  const isBuildOrderRefused =
    getBuildOrderStatus() === BuildOrderStatus.Refused ||
    getBuildOrderStatus() === BuildOrderStatus.Archived;

  const isNewBuildOrder = getBuildOrderStatus() === BuildOrderStatus.New;

  const isApprovedBuildOrder =
    getBuildOrderStatus() === BuildOrderStatus.Approved;

  const isCompletedBuildOrder =
    getBuildOrderStatus() === BuildOrderStatus.Completed;

  const isMaterialOrderedBuildOrder =
    getBuildOrderStatus() === BuildOrderStatus.MaterialsOrdered;

  const selectedBuilders = currentProject?.buildOrders?.map<SelectedBuilder>(
    (buildOrder) => {
      return {
        lastName: buildOrder.builder?.lastName,
        firstName: buildOrder.builder?.firstName,
        profilePictureUrl: buildOrder.builder?.profilePictureUrl,
        position: buildOrder.builder?.position,
        companyName: buildOrder.builder?.companyName,
        status: buildOrder.status,
      };
    }
  );

  const refuseOrder = async (note: string, reason: BuildRefusalReason) => {
    const buildOrderId = getBuildOrder()?.id;

    if (buildOrderId == null) {
      return;
    }
    try {
      await dispatch.buildOrder.rejectBuildOrder({
        id: buildOrderId,
        note,
        reason,
      });
    } catch (error) {
      navigateWhenInvalidStatusOperation(error as Response);
      console.log(error);
      return;
    }

    dispatch.alertModal.openModal({
      cardTitle: t("PROJECTS.DETAIL.BUILD.REFUSE.TITLE"),
      title: t("PROJECTS.DETAIL.BUILD.REFUSE.SUCCESS_TITLE"),
      descriptin: t("PROJECTS.DETAIL.BUILD.REFUSE.SUCCESS_DESC"),
      buttonLabel: t("COMMON.ALRIGHT"),
      width: "59rem",
      type: AlertModalType.Success,
      onButtonClick: () => navigate("/projects"),
    });
  };

  const navigateWhenInvalidStatusOperation = async (error: Response) => {
    if (!error) {
      return;
    }

    const invalidOperationErrorCode = "BuildOrder.InvalidStatusOperation";

    const body = await error?.json();
    if (body?.errors?.Error?.includes(invalidOperationErrorCode)) {
      navigate("/projects/refused");
    }
  };

  const getAutomationExportProductNameTransKeyByCode = (value: string) =>
    "AUTOMATION.PRODUCT_NAME." + value.replace(/\s/g, "");

  const getBuildOrder = useCallback((): BuildOrderDto | undefined => {
    // Builder can see only his buildOrder, thus first element of array.
    // Before a builder accepts the order, the admin receives data from the first element of the array.
    if (isBuilder() || isNewBuildOrder) {
      return currentProject?.buildOrders?.[0];
    }

    // After a bulider accepts the order, return the element where the status is NOT Archived.
    if (isAdmin()) {
      return currentProject?.buildOrders?.find(
        (buildOrder) => buildOrder.status !== BuildOrderStatus.Archived
      );
    }
  }, [currentProject?.buildOrders, isNewBuildOrder]);

  const getAutomationData = useCallback(() => {
    if (isAdmin() && isNewBuildOrder && currentProject?.designOrderId != null) {
      dispatch.buildOrder.getAutomationForAdminNewBuildOrder(
        currentProject?.designOrderId
      );

      return;
    }

    const currentBuildOrder = getBuildOrder();

    if (currentBuildOrder?.id != null) {
      dispatch.buildOrder.getAutomation(currentBuildOrder.id);
    }
  }, [
    currentProject?.designOrderId,
    dispatch.buildOrder,
    getBuildOrder,
    isNewBuildOrder,
  ]);

  const getPanelDeliveryData = useCallback(() => {
    if (isAdmin() && isNewBuildOrder && currentProject?.designOrderId != null) {
      dispatch.buildOrder.getDeliveryForAdminNewBuildOrder(
        currentProject?.designOrderId
      );

      return;
    }

    const currentBuildOrder = getBuildOrder();

    if (currentBuildOrder?.id != null) {
      dispatch.buildOrder.getDelivery(currentBuildOrder.id);
    }
  }, [
    currentProject?.designOrderId,
    dispatch.buildOrder,
    getBuildOrder,
    isNewBuildOrder,
  ]);

  return {
    isNewBuildOrder,
    isApprovedBuildOrder,
    isMaterialOrderedBuildOrder,
    isCompletedBuildOrder,
    isBuildOrderRefused,
    buildOrder: getBuildOrder(),
    selectedBuilders,
    getAutomationData,
    getAutomationExportProductNameTransKeyByCode,
    refuseOrder,
    navigateWhenInvalidStatusOperation,
    getPanelDeliveryData,
  };
};

export default useBuild;
