import { Col, Empty, List, message, Row, Tooltip } from "antd";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "app/store";
import ProvidersListItem, {
  SelectMode,
} from "components/providersListItem/ProvidersListItem";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import styles from "./BuildersList.module.scss";
import { useTranslation, Trans } from "react-i18next";
import CustomButton from "components/button/CustomButton";
import Card from "components/card/Card";
import {
  GrapeAntdForm,
  requiredField,
  useGrapeAntdForm,
  zipCodeRule,
} from "components/form";
import CustomInput from "components/form/components/CustomInput";
import BuilderSelected from "./componenets/builderSelected/BuilderSelected";
import BuilderDetailModal from "./componenets/builderDetailsModal/BuilderDetailModal";
import {
  UserAddOutlined,
  UserOutlined,
  InfoCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import CustomCollapse from "components/collapse/CustomCollapse";
import BuilderTimeLine from "./componenets/builderTimeLine/BuilderTimeLine";
import { BuilderDto } from "api/generated/optimum";
import { useBreakpoint } from "hooks/useBreakpoint";
import Loading from "components/loading/Loading";
import clsx from "clsx";
import useQuery from "hooks/useQuery";
import { NavLink } from "react-router-dom";

const BuilderList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formUtils = useGrapeAntdForm();
  const mobileScreen = useBreakpoint({ max: "sm" });
  const { loading } = useSelector(
    (state) => state.loading.effects.builder.getBuilders
  );
  const { zipCode, builders, selectedBuilders } = useSelector(
    (state) => state.builder
  );
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(true);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const zip = useQuery().get("zip");
  const builderListElementRef = useRef<HTMLDivElement>(null);

  const handleModifyBuilderSelection = (builder: BuilderDto): void => {
    if (
      !selectedBuilders.some(
        (selectedBuilder) => selectedBuilder.id === builder.id
      ) &&
      selectedBuilders.length >= 3
    ) {
      message.info(t("BUILDERS.CANT_SELECT_MORE_INFO"));
    } else if (
      !selectedBuilders.some(
        (selectedBuilder) => selectedBuilder.id === builder.id
      )
    ) {
      dispatch.builder.setSelectedBuilders({
        builders: [...selectedBuilders, builder],
      });
    } else {
      let builders = [...selectedBuilders];
      builders = builders.filter((x) => x.id !== builder.id);

      dispatch.builder.setSelectedBuilders({ builders });
    }
  };

  const handleInputOnChange = () => {
    setSubmitDisabled(false);
  };

  const handleGetBuilders = useCallback(
    (code: string) => {
      dispatch.builder.getBuilders(code).then(() => {
        setSubmitDisabled(true);
        setIsCollapseOpen(false);
        setTimeout(scrollToBuilderList, 150);
      });

      dispatch.builder.setZipCode(code);
    },
    [dispatch.builder]
  );

  const handleFinish = (values: any) => {
    handleGetBuilders(String(values.zipCode));

    dispatch.builder.resetSelectedBuilders();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToBuilderList = (): void => {
    builderListElementRef &&
      builderListElementRef.current &&
      window.scrollTo({
        left: 0,
        top: builderListElementRef.current.offsetTop,
        behavior: "smooth",
      });
  };

  useEffect(() => {
    if (zip) {
      handleGetBuilders(zip);
      formUtils.form.validateFields();
    }
  }, [zip, dispatch, formUtils.form, handleGetBuilders]);

  return (
    <>
      <CustomParagraph size="md" strong>
        <Trans i18nKey="BUILDERS.ORDER.DESCRIPTION">
          <a>link</a>
          <NavLink to="/">navlink</NavLink>
        </Trans>
      </CustomParagraph>

      <CustomButton
        type="link"
        className={styles.linkBtn}
        href="https://opticonnect.mvm-optimum.hu/javasolt-ingatlan-parameterek/ "
      >
        {t("BUILDERS.ORDER.LINK1")} <RightOutlined />
      </CustomButton>

      <CustomButton
        type="link"
        className={styles.linkBtn}
        href="https://opticonnect.mvm-optimum.hu/opti-comfort-rendszer/ "
      >
        {t("BUILDERS.ORDER.LINK2")} <RightOutlined />
      </CustomButton>

      <Card className={styles.formContainer}>
        <CustomTitle
          level={3}
          responsiveLevel={{ mobile: 5, tablet: 5 }}
          color="lightBlue"
          className={styles.zipTitle}
        >
          {t("BUILDERS.ORDER.FORM.ZIP_CODE")}
        </CustomTitle>
        <GrapeAntdForm
          formUtils={formUtils}
          requiredMark={false}
          onFinish={handleFinish}
          layout="inline"
          initialValues={{
            zipCode: zip ?? zipCode,
          }}
        >
          <Row gutter={20}>
            <Col xs={24} xl={12}>
              <GrapeAntdForm.Item
                name="zipCode"
                className={clsx(styles.zipFormItem, styles.zipCode)}
                rules={[requiredField(), zipCodeRule()]}
              >
                <CustomInput
                  onChange={handleInputOnChange}
                  placeholder={t("BUILDERS.ORDER.FORM.ZIP_CODE_PLACEHOLDER")}
                />
              </GrapeAntdForm.Item>
            </Col>
            <Col xs={24} xl={12}>
              <GrapeAntdForm.Item className={styles.zipFormItem}>
                <CustomButton
                  disabled={submitDisabled}
                  className={styles.zipSubmitButton}
                  type="primary"
                  htmlType="submit"
                  size="large"
                >
                  {t("BUILDERS.ORDER.LOAD_BUILDERS_BUTTON")}
                </CustomButton>
              </GrapeAntdForm.Item>
            </Col>
          </Row>
        </GrapeAntdForm>
      </Card>

      <CustomCollapse
        title={
          <div className={styles.collapseTitle}>
            <InfoCircleOutlined className={styles.collapseIcon} />
            {t("BUILDERS.BUILDERS_LIST.COLLAPSE_TITLE")}
          </div>
        }
        className={styles.collapse}
        isExpanded={isCollapseOpen}
        onClick={() => setIsCollapseOpen((prevValue: boolean) => !prevValue)}
      >
        <BuilderTimeLine />
      </CustomCollapse>

      {loading && <Loading />}

      {builders ? (
        <>
          <BuilderSelected
            title={t("BUILDERSELECTED.TITLE")}
            selectedBuilders={selectedBuilders}
            handleModifyBuilderSelection={handleModifyBuilderSelection}
          />

          <Row justify="center" className={styles.list}>
            <CustomTitle className={styles.listTitle}>
              {t("BUILDERLIST.LIST_TITLE")}
            </CustomTitle>
          </Row>
          {builders.length ? (
            <div ref={builderListElementRef}>
              <List
                itemLayout={mobileScreen ? "vertical" : "horizontal"}
                dataSource={builders}
                renderItem={(builder: BuilderDto, index) => (
                  <ProvidersListItem
                    provider={builder}
                    key={index}
                    selectMode={SelectMode.MULTI}
                    onViewProfileButton={() =>
                      dispatch.builder.selectBuilder(builder)
                    }
                    actionButton={
                      <Tooltip
                        title={
                          selectedBuilders.some(
                            (selectedBuilder) =>
                              selectedBuilder.id === builder.id
                          )
                            ? t("TOOLTIP.UNSELECT_BUILDER")
                            : t("TOOLTIP.SELECT_BUILDER")
                        }
                      >
                        <CustomButton
                          shape="circle"
                          disabled={
                            selectedBuilders.length >= 3 &&
                            !selectedBuilders.some(
                              (selectedBuilder) =>
                                selectedBuilder.id === builder.id
                            )
                          }
                          icon={
                            selectedBuilders.some(
                              (selectedBuilder) =>
                                selectedBuilder.id === builder.id
                            ) ? (
                              <UserOutlined
                                className={styles.removeBuilderIcon}
                              />
                            ) : (
                              <UserAddOutlined
                                className={styles.addBuilderIcon}
                              />
                            )
                          }
                          className={
                            selectedBuilders.some(
                              (selectedBuilder) =>
                                selectedBuilder.id === builder.id
                            )
                              ? styles.removeBuilderButton
                              : styles.addBuilderButton
                          }
                          onClick={() => handleModifyBuilderSelection(builder)}
                          type={
                            selectedBuilders.some(
                              (selectedBuilder) =>
                                selectedBuilder.id === builder.id
                            )
                              ? "primary"
                              : "ghost"
                          }
                        />
                      </Tooltip>
                    }
                  >
                    <CustomParagraph
                      size="sm"
                      color="grey"
                      className={styles.cardInfo}
                      extraStrong
                    >
                      {t("BUILDERS.BUILDERS_LIST.SPECIALIZATIONS")}
                    </CustomParagraph>
                    <CustomParagraph size="sm" className={styles.cardInfo}>
                      {builder.specializations?.join(", ")}
                    </CustomParagraph>
                  </ProvidersListItem>
                )}
              />
            </div>
          ) : (
            <Empty description={t("COMMON.NO_DATA")} />
          )}
        </>
      ) : (
        mobileScreen && (
          <CustomButton
            type="primary"
            className={styles.startSearchButon}
            block
            size="large"
            onClick={scrollToTop}
          >
            {t("BUILDERS.BUILDERS_LIST.START_SEARCH_BUTTON")}
          </CustomButton>
        )
      )}
      <BuilderDetailModal
        handleModifyBuilderSelection={handleModifyBuilderSelection}
      />
    </>
  );
};

export default BuilderList;
