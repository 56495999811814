export enum LocalStorageKeys {
  AccessToken = "access-token",
  RefreshToken = "refresh-token",
  User = "user",
}

export enum SessionStorageKeys {
  ProjectActiveTab = "project-active-tab",
  AdminProjectActiveStatus = "admin-project-active-status",
  AdminProjectActiveType = "admin-project-active-type",
  AdminProviderActiveStatus = "admin-provider-active-status",
  AdminProviderActiveType = "admin-provider-actice-type",
}

export const getLocalStorageValue = (key: LocalStorageKeys): string | null =>
  localStorage.getItem(key);

export const setLocalStorageValue = (
  key: LocalStorageKeys,
  value: string
): void => localStorage.setItem(key, value);

export const removeLocalStorageValue = (key: LocalStorageKeys): void =>
  localStorage.removeItem(key);

/// SESSION STORAGE

export const getSessionStorageValue = (
  key: SessionStorageKeys
): string | null => sessionStorage.getItem(key);

export const setSessionStorageValue = (
  key: SessionStorageKeys,
  value: string
): void => sessionStorage.setItem(key, value);

export const removeSessionStorageValue = (key: SessionStorageKeys): void =>
  sessionStorage.removeItem(key);
