import clsx from "clsx";
import Card from "components/card/Card";
import styles from "./Jumbotron.module.scss";

const Jumbotron: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return <Card className={clsx(className, styles.jumbotron)}>{children}</Card>;
};

export default Jumbotron;
