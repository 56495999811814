import { Steps } from "antd";
import { useSelector } from "app/store";
import { EmptyContent } from "components/GrapeAntdTable/table-content/EmptyContent";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../../components/typography/title/CustomTitle";
import { INITIAL_CURRENT_STEP } from "../Engineer.store";
import Step2 from "./components/step2/Step2";
import Step3 from "./components/step3/Step3";
import Step4 from "./components/step4/Step4";
import Step5 from "./components/step5/Step5";
import styles from "./EngineerContactWizard.module.scss";

const { Step } = Steps;

const steps = [
  { content: <Step2 /> },
  {
    content: <Step3 />,
  },
  {
    content: <Step4 />,
  },
  {
    content: <Step5 />,
  },
];

const EngineerContactWizard: FC = () => {
  const { t } = useTranslation();
  const { currentStep } = useSelector((state) => state.engineer);

  return currentStep > INITIAL_CURRENT_STEP ? (
    <>
      <CustomTitle
        align="center"
        level={3}
        responsiveLevel={{
          tablet: 4,
        }}
        className={styles.pageTitle}
        extraStrong
        color="grey"
      >
        {t("ENGINEERS.ENGINEER_WIZARD.TITLE")}
      </CustomTitle>
      <Steps
        current={currentStep + 1}
        className={styles.steps}
        responsive={false}
      >
        {steps.map((_, index) => (
          <Step key={index} />
        ))}
      </Steps>
      <div className={styles.content}>{steps[currentStep]?.content}</div>
    </>
  ) : (
    <EmptyContent />
  );
};

export default EngineerContactWizard;
