import i18n from ".";

export enum EnumContext {
  INSULATION = "INSULATION",
  HEATEMITTER = "HEATEMITTER",
  BOILER = "BOILER",
  HOMEPHASE = "HOMEPHASE",
  PROFESSION = "PROFESSION",
  PREFERREDSTART = "PREFERREDSTART",
  DESIGN_ORDER_STATUS = "DESIGN_ORDER_STATUS",
  ORDER_STATUS = "ORDER_STATUS",
  PROJECT_STATUS = "PROJECT_STATUS",
  MATERIALUNIT = "MATERIALUNIT",
  PROVIDER_TYPE = "PROVIDER_TYPE",
  DESIGN_REFUSAL_REASON = "DESIGN_REFUSAL_REASON",
  BUILD_REFUSAL_REASON = "BUILD_REFUSAL_REASON",
}

i18n.t("ENUMS.INSULATION.NONE");
i18n.t("ENUMS.INSULATION.NOINSULATION");
i18n.t("ENUMS.INSULATION.FIVECM");
i18n.t("ENUMS.INSULATION.SIXCM");
i18n.t("ENUMS.INSULATION.TENCM");
i18n.t("ENUMS.INSULATION.FIFTEENCM");
i18n.t("ENUMS.INSULATION.TWENTYCM");
i18n.t("ENUMS.INSULATION.TWENTYCMPLUS");

i18n.t("ENUMS.HEATEMITTER.OTHER");
i18n.t("ENUMS.HEATEMITTER.TRADITIONALRADIATOR");
i18n.t("ENUMS.HEATEMITTER.UNDERFLOORHEATING");
i18n.t("ENUMS.HEATEMITTER.CONVECTIONHEATER");
i18n.t("ENUMS.HEATEMITTER.AIRHEATING");

i18n.t("ENUMS.BOILER.NONE");
i18n.t("ENUMS.BOILER.OTHER");
i18n.t("ENUMS.BOILER.GASBOILER");
i18n.t("ENUMS.BOILER.WOODBURNINGBOILER");
i18n.t("ENUMS.BOILER.COMBIBOILER");
i18n.t("ENUMS.BOILER.ELECTRICBOILER");

i18n.t("ENUMS.HOMEPHASE.NONE");
i18n.t("ENUMS.HOMEPHASE.INQUIRY");
i18n.t("ENUMS.HOMEPHASE.CONSTRUCTIONDESIGN");
i18n.t("ENUMS.HOMEPHASE.UNDERCONSTRUCTION");
i18n.t("ENUMS.HOMEPHASE.CONSTRUCTIONREADY");

i18n.t("ENUMS.PROFESSION.INVALID");
i18n.t("ENUMS.PROFESSION.BUILDER");
i18n.t("ENUMS.PROFESSION.GENERALCONTRACTOR");
i18n.t("ENUMS.PROFESSION.CONSTRUCTIONENGINEER");
i18n.t("ENUMS.PROFESSION.MSCCONSTRUCTIONENGINEER");
i18n.t("ENUMS.PROFESSION.MSCENERGYENGINEER");
i18n.t("ENUMS.PROFESSION.OTHER");

i18n.t("ENUMS.PROVIDER_TYPE.INVALID");
i18n.t("ENUMS.PROVIDER_TYPE.ENGINEER");
i18n.t("ENUMS.PROVIDER_TYPE.BUILDER");

i18n.t("ENUMS.PREFERREDSTART.ASAP");
i18n.t("ENUMS.PREFERREDSTART.WITHIN3MONTHS");
i18n.t("ENUMS.PREFERREDSTART.WITHIN6MONTHS");
i18n.t("ENUMS.PREFERREDSTART.OTHER");

i18n.t("ENUMS.MATERIALUNIT.PIECE");
i18n.t("ENUMS.MATERIALUNIT.METER");
i18n.t("ENUMS.MATERIALUNIT.SQUAREMETER");
i18n.t("ENUMS.MATERIALUNIT.LITER");
i18n.t("ENUMS.MATERIALUNIT.SET");

// Design Order Status
i18n.t("ENUMS.DESIGN_ORDER_STATUS.INVALID");
i18n.t("ENUMS.DESIGN_ORDER_STATUS.NEW");
i18n.t("ENUMS.DESIGN_ORDER_STATUS.REFUSED");
i18n.t("ENUMS.DESIGN_ORDER_STATUS.FILEUPLOAD");
i18n.t("ENUMS.DESIGN_ORDER_STATUS.OPTICOMFORTWIP");
i18n.t("ENUMS.DESIGN_ORDER_STATUS.OPTICOMFORTNEW");
i18n.t("ENUMS.DESIGN_ORDER_STATUS.OPTICOMFORT");
i18n.t("ENUMS.DESIGN_ORDER_STATUS.COMPLETED");

//  order status

i18n.t("ENUMS.ORDER_STATUS.DESIGNORDERSTATUS.INVALID");
i18n.t("ENUMS.ORDER_STATUS.DESIGNORDERSTATUS.NEW");
i18n.t("ENUMS.ORDER_STATUS.DESIGNORDERSTATUS.REFUSED");
i18n.t("ENUMS.ORDER_STATUS.DESIGNORDERSTATUS.FILEUPLOAD");
i18n.t("ENUMS.ORDER_STATUS.DESIGNORDERSTATUS.OPTICOMFORTWIP");
i18n.t("ENUMS.ORDER_STATUS.DESIGNORDERSTATUS.OPTICOMFORTNEW");
i18n.t("ENUMS.ORDER_STATUS.DESIGNORDERSTATUS.OPTICOMFORT");
i18n.t("ENUMS.ORDER_STATUS.DESIGNORDERSTATUS.COMPLETED");

i18n.t("ENUMS.ORDER_STATUS.BUILDORDERSTATUS.APPROVED");
i18n.t("ENUMS.ORDER_STATUS.BUILDORDERSTATUS.MATERIALSORDERED");

// PROJECT STATUSES
i18n.t("ENUMS.PROJECT_STATUS.DESIGNORDERSTATUS.INVALID");
i18n.t("ENUMS.PROJECT_STATUS.DESIGNORDERSTATUS.NEW");
i18n.t("ENUMS.PROJECT_STATUS.DESIGNORDERSTATUS.REFUSED");
i18n.t("ENUMS.PROJECT_STATUS.DESIGNORDERSTATUS.FILEUPLOAD");
i18n.t("ENUMS.PROJECT_STATUS.DESIGNORDERSTATUS.OPTICOMFORTWIP");
i18n.t("ENUMS.PROJECT_STATUS.DESIGNORDERSTATUS.OPTICOMFORTNEW");
i18n.t("ENUMS.PROJECT_STATUS.DESIGNORDERSTATUS.OPTICOMFORT");
i18n.t("ENUMS.PROJECT_STATUS.DESIGNORDERSTATUS.COMPLETED");

i18n.t("ENUMS.PROJECT_STATUS.BUILDORDERSTATUS.NEW");
i18n.t("ENUMS.PROJECT_STATUS.BUILDORDERSTATUS.APPROVED");
i18n.t("ENUMS.PROJECT_STATUS.BUILDORDERSTATUS.MATERIALSORDERED");
i18n.t("ENUMS.PROJECT_STATUS.BUILDORDERSTATUS.COMPLETED");
i18n.t("ENUMS.PROJECT_STATUS.BUILDORDERSTATUS.ARCHIVED");

// DESIGN REFUSAL REASON
i18n.t("ENUMS.DESIGN_REFUSAL_REASON.INVALID");
i18n.t("ENUMS.DESIGN_REFUSAL_REASON.NOTRESIDENTALREQUEST");
i18n.t("ENUMS.DESIGN_REFUSAL_REASON.NOTADEQUATEBUILDINGCONDITION");
i18n.t("ENUMS.DESIGN_REFUSAL_REASON.DUPLICATEDREQUEST");
i18n.t("ENUMS.DESIGN_REFUSAL_REASON.HEATPUMPONLYREQUEST");
i18n.t("ENUMS.DESIGN_REFUSAL_REASON.NOTREQUESTJUSTINQUIRY");
i18n.t("ENUMS.DESIGN_REFUSAL_REASON.PRICEMISMATCH");
i18n.t("ENUMS.DESIGN_REFUSAL_REASON.DEADLINEMISMATCH");
i18n.t("ENUMS.DESIGN_REFUSAL_REASON.TESTREQUEST");
i18n.t("ENUMS.DESIGN_REFUSAL_REASON.OTHERREASON");

// BUILD REFUSAL REASON
i18n.t("ENUMS.BUILD_REFUSAL_REASON.INVALID");
i18n.t("ENUMS.BUILD_REFUSAL_REASON.NOTRESIDENTALREQUEST");
i18n.t("ENUMS.BUILD_REFUSAL_REASON.ANOTHERBUILDORDERWASACCEPTED");
i18n.t("ENUMS.BUILD_REFUSAL_REASON.NOTREQUESTJUSTINQUIRY");
i18n.t("ENUMS.BUILD_REFUSAL_REASON.PRICEOFMATERIALS");
i18n.t("ENUMS.BUILD_REFUSAL_REASON.PRICEOFSERVICE");
i18n.t("ENUMS.BUILD_REFUSAL_REASON.DEADLINEMISMATCH");
i18n.t("ENUMS.BUILD_REFUSAL_REASON.CUSTOMERUNREACHABLE");
i18n.t("ENUMS.BUILD_REFUSAL_REASON.TESTREQUEST");
i18n.t("ENUMS.BUILD_REFUSAL_REASON.OTHERREASON");

// AUTOMATION
i18n.t("AUTOMATION.PRODUCT_NAME.SERV40");
i18n.t("AUTOMATION.PRODUCT_NAME.SERV60");
i18n.t("AUTOMATION.PRODUCT_NAME.SERV60_EXPORT");
i18n.t("AUTOMATION.PRODUCT_NAME.TRA1");
i18n.t("AUTOMATION.PRODUCT_NAME.TR1");

// SERVER SIDE ERROR CODES
i18n.t("error.Authentication.InvalidCredentials");
i18n.t("error.Authentication.InvalidRefreshToken");
i18n.t("error.BuildOrder.AlreadyExists");
i18n.t("error.Common.DbUpdateFailed");
i18n.t("error.Common.EmailSendingFailed");
i18n.t("error.Common.Forbidden");
i18n.t("error.Common.NotFound");
i18n.t("error.Common.SqlError");
i18n.t("error.BuildOrder.AlreadyExists");
i18n.t("error.BuildOrder.InvalidStatusOperation");
i18n.t("error.DesignOrder.RefusedStatus");
i18n.t("error.DesignOrder.InCompleteStatus");
i18n.t("error.DesignOrder.InvalidStatusOperation");
i18n.t("error.FileStorage.ContainerNameInvalid");
i18n.t("error.FileStorage.FileAlreadyExists");
i18n.t("error.FileStorage.FileNotFound");
i18n.t("error.FileStorage.FileStorageError");
i18n.t("error.FileStorage.StorageFileNameInvalid");
i18n.t("error.UserIdentity.AddPasswordFailed");
i18n.t("error.UserIdentity.AnonymizationFailed");
i18n.t("error.UserIdentity.ChangePasswordFailed");
i18n.t("error.UserIdentity.EmailAddressDoesNotExists");
i18n.t("error.UserIdentity.EmailNotConfirmed");
i18n.t("error.UserIdentity.InvalidCurrentPassword");
i18n.t("error.UserIdentity.InvalidEmailConfirmationToken");
i18n.t("error.UserIdentity.EmailAddressAlreadyInUse");
i18n.t("error.UserIdentity.InvalidResetPasswordToken");
i18n.t("error.UserIdentity.InvalidRole");
i18n.t("error.UserIdentity.ResetPasswordFailed");
i18n.t("error.UserIdentity.ResetPasswordFailed");
i18n.t("error.UserIdentity.RoleAssignmentFailed");
i18n.t("error.UserIdentity.UserCannotBePublished");
i18n.t("error.UserIdentity.UserCreationFailed");
i18n.t("error.UserIdentity.UserDoesNotExist");
i18n.t("error.UserIdentity.UserHasActiveOrders");
i18n.t("error.Validation.AddressRequired");
i18n.t("error.Validation.BuilderIdInvalid");
i18n.t("error.Validation.BuildMaterialInvalidStatus");
i18n.t("error.Validation.BuildMaterialsRequired");
i18n.t("error.Validation.BuildOrderAlreadyAccepted");
i18n.t("error.Validation.BuildOrderAlreadyApproved");
i18n.t("error.Validation.BuildOrderIdRequired");
i18n.t("error.Validation.BuildOrderInvalidStatus");
i18n.t("error.Validation.CityRequired");
i18n.t("error.Validation.ConfirmPasswordMismatch");
i18n.t("error.Validation.ConfirmPasswordRequired");
i18n.t("error.Validation.CreateSampleCommandTypeIsCoolToHaveYou");
i18n.t("error.Validation.CreateSampleCommandTypeRequired");
i18n.t("error.Validation.DateInvalid");
i18n.t("error.Validation.DecimalInvalid");
i18n.t("error.Validation.DesignMaterialRequired");
i18n.t("error.Validation.DesignOrderIdInvalid");
i18n.t("error.Validation.DesignOrderIdRequired");
i18n.t("error.Validation.DesignOrderInvalidStatus");
i18n.t("error.Validation.DesignOrderMaterialsRequired");
i18n.t("error.Validation.EmailAddressInvalid");
i18n.t("error.Validation.EmailAddressRequired");
i18n.t("error.Validation.EmailAddressTooLong");
i18n.t("error.Validation.EmailConfirmationTokenInvalid");
i18n.t("error.Validation.EngineerIdInvalid");
i18n.t("error.Validation.FileExtensionInvalid");
i18n.t("error.Validation.FileFinalDesignRequired");
i18n.t("error.Validation.FileInvalid");
i18n.t("error.Validation.FileRequired");
i18n.t("error.Validation.FileTypeInvalid");
i18n.t("error.Validation.IdRequired");
i18n.t("error.Validation.MaterialIdRequired");
i18n.t("error.Validation.MaterialUnitInvalid");
i18n.t("error.Validation.NameRequired");
i18n.t("error.Validation.NewPasswordCannotBeTheActualPassword");
i18n.t("error.Validation.PasswordInvalid");
i18n.t("error.Validation.PasswordRequired");
i18n.t("error.Validation.PhoneNumberInvalid");
i18n.t("error.Validation.PhoneNumberRequired");
i18n.t("error.Validation.PhoneNumberTooLong");
i18n.t("error.Validation.PhoneRequired");
i18n.t("error.Validation.ProjectCodeInvalid");
i18n.t("error.Validation.ProjectCodeInvalidFormat");
i18n.t("error.Validation.ProjectCodeRequired");
i18n.t("error.Validation.RefreshTokenInvalid");
i18n.t("error.Validation.RefreshTokenRequired");
i18n.t("error.Validation.ResetPasswordTokenInvalid");
i18n.t("error.Validation.SpecializationsRequired");
i18n.t("error.Validation.ZipCodeInvalid");
i18n.t("error.Validation.ZipCodeRequired");
i18n.t("error.NotEmptyValidator");
i18n.t("error.Validation.EngineerProfessionRequired");
