import { Children, cloneElement, ReactElement, useContext } from "react";
import { Form, FormItemProps } from "antd";
import { getSchemaRules } from "../utils/schema";
import { GrapeAntdFormContext } from "./GrapeAntdForm";
import { BaseRule, requiredField } from "../utils/formRules";

export type StringKeyof<T> = Extract<keyof T, string>;

// DeepKeyof black magic
// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object

type Cons<H, T> = T extends readonly any[]
  ? ((h: H, ...t: T) => void) extends (...r: infer R) => void
    ? R
    : never
  : never;

type Prev = [never, 0, 1, 2, 3, 4, ...0[]];

export type DeepKeyof<T, DEPTH extends number = never> = [DEPTH] extends [never]
  ? keyof T
  : T extends object
  ? {
      [K in keyof T]-?:
        | [K]
        | (DeepKeyof<T[K], Prev[DEPTH]> extends infer P
            ? P extends []
              ? never
              : Cons<K, P>
            : never);
    }[keyof T]
  : [];

export interface GrapeAntdFormItemProps<T = never, DEPTH extends number = never>
  extends Omit<FormItemProps, "name"> {
  name?: StringKeyof<T> | DeepKeyof<T, DEPTH>;
  rules?: any;
}

function GrapeAntdFormItem<T, DEPTH extends number = never>(
  props: GrapeAntdFormItemProps<T, DEPTH>
): ReactElement {
  // form item props
  const { name, rules, children, label, ...formItemProps } = props;

  const castedName = name as string;

  // props from custom form context
  const { schema, requiredFields, loading } = useContext(GrapeAntdFormContext);

  // get constraints from schema
  const constraints =
    (name && getSchemaRules(schema, castedName, label as string)) || [];

  // handle rules from schema and form item rule prop together
  const mergedRules = [...constraints, ...(rules ?? [])];

  // check if rules already contain required
  const isMergedAlreadyContainsRequired = mergedRules.some(
    ({ required }: BaseRule) => required
  );

  // add additional required if it's a required field and merged constraints doesn't
  const addRequiredRule =
    !isMergedAlreadyContainsRequired &&
    (Array.isArray(name)
      ? requiredFields.includes(name.join("."))
      : requiredFields.includes(name));

  // create the final form field ruleset
  const fieldRules = [
    ...mergedRules,
    ...(addRequiredRule ? [requiredField(label as string)] : []),
  ];

  // clone form item children and override disabled property
  const child = !Array.isArray(children)
    ? cloneElement(children as ReactElement, {
        disabled: loading || (children as ReactElement)?.props?.disabled,
      })
    : Children.map(children, (child) =>
        cloneElement(child as ReactElement, {
          disabled: loading || (child as ReactElement)?.props?.disabled,
        })
      );

  return (
    <Form.Item
      name={castedName}
      rules={fieldRules}
      label={label}
      {...formItemProps}
    >
      {child}
    </Form.Item>
  );
}

export default GrapeAntdFormItem;
