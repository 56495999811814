import { Col, Row } from "antd";
import CustomButton from "components/button/CustomButton";
import CustomModal from "components/customModal/CustomModal";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import { useTranslation } from "react-i18next";
import styles from "./ConfirmModal.module.scss";
import clsx from "clsx";

const ConfirmModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  description: string;
  subDescription?: string;
  submitBtnLabel: string;
  submitBtnClassName?: string;
  width?: string | number;
  className?: string;
}> = ({
  isVisible,
  onClose,
  onSubmit,
  title,
  description,
  submitBtnLabel,
  subDescription,
  submitBtnClassName,
  className,
  width = "67rem",
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      closable={false}
      width={width}
      visible={isVisible}
      className={clsx(styles.modal, className)}
    >
      <Row justify="center" className={styles.modalTitle}>
        <CustomTitle level={3} color="darkBlue" extraStrong>
          {title}
        </CustomTitle>
      </Row>
      <CustomParagraph color="black" className={styles.desc}>
        {description}
      </CustomParagraph>

      <CustomParagraph color="black" className={styles.desc}>
        {subDescription}
      </CustomParagraph>

      <Row
        className={styles.modalButtonContainer}
        justify="space-between"
        gutter={50}
      >
        <Col flex="auto" span={12}>
          <CustomButton
            className={styles.modalCancelButton}
            onClick={onClose}
            variant="customer"
          >
            {t("COMMON.CANCEL")}
          </CustomButton>
        </Col>
        <Col span={12}>
          <CustomButton
            type="primary"
            className={clsx(styles.modalButton, submitBtnClassName)}
            onClick={onSubmit}
            variant="default"
          >
            {submitBtnLabel}
          </CustomButton>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default ConfirmModal;
