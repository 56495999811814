import { SpecializationDto } from "api/generated/optimum";
import styles from "./SpecializationDisplay.module.scss";
import React from "react";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { useTranslation } from "react-i18next";

interface SpecializationDisplayProps {
  specializations?: SpecializationDto[] | null;
}

export const SpecializationDisplay: React.FC<SpecializationDisplayProps> = ({
  specializations,
}) => {
  const { t } = useTranslation();

  if (!specializations || !specializations.length) {
    return (
      <CustomParagraph
        size="xs"
        italic
        className={styles.noSpecializationParag}
      >
        {t("PROJECTS.DETAIL.NO_SPECIALIZATION")}
      </CustomParagraph>
    );
  }

  return (
    <>
      {specializations.map((specialization) => (
        <div className={styles.workingItem} key={specialization.id}>
          {specialization.name}
        </div>
      ))}
    </>
  );
};

export default SpecializationDisplay;
