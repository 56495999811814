/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuilderProfession,
    BuilderProfessionFromJSON,
    BuilderProfessionFromJSONTyped,
    BuilderProfessionToJSON,
    EngineerProfession,
    EngineerProfessionFromJSON,
    EngineerProfessionFromJSONTyped,
    EngineerProfessionToJSON,
    ProviderType,
    ProviderTypeFromJSON,
    ProviderTypeFromJSONTyped,
    ProviderTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateProviderCommand
 */
export interface CreateProviderCommand {
    /**
     * 
     * @type {ProviderType}
     * @memberof CreateProviderCommand
     */
    type: ProviderType;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderCommand
     */
    position: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderCommand
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderCommand
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderCommand
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderCommand
     */
    introduction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderCommand
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderCommand
     */
    emailAddress: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProviderCommand
     */
    specializationIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProviderCommand
     */
    coveredCountyIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProviderCommand
     */
    tagIds?: Array<number> | null;
    /**
     * 
     * @type {EngineerProfession}
     * @memberof CreateProviderCommand
     */
    engineerProfession?: EngineerProfession;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderCommand
     */
    minPriceQuote?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderCommand
     */
    maxPriceQuote?: number | null;
    /**
     * 
     * @type {BuilderProfession}
     * @memberof CreateProviderCommand
     */
    builderProfession?: BuilderProfession;
}

export function CreateProviderCommandFromJSON(json: any): CreateProviderCommand {
    return CreateProviderCommandFromJSONTyped(json, false);
}

export function CreateProviderCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProviderCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': ProviderTypeFromJSON(json['type']),
        'position': json['position'],
        'companyName': json['companyName'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'phoneNumber': json['phoneNumber'],
        'emailAddress': json['emailAddress'],
        'specializationIds': !exists(json, 'specializationIds') ? undefined : json['specializationIds'],
        'coveredCountyIds': !exists(json, 'coveredCountyIds') ? undefined : json['coveredCountyIds'],
        'tagIds': !exists(json, 'tagIds') ? undefined : json['tagIds'],
        'engineerProfession': !exists(json, 'engineerProfession') ? undefined : EngineerProfessionFromJSON(json['engineerProfession']),
        'minPriceQuote': !exists(json, 'minPriceQuote') ? undefined : json['minPriceQuote'],
        'maxPriceQuote': !exists(json, 'maxPriceQuote') ? undefined : json['maxPriceQuote'],
        'builderProfession': !exists(json, 'builderProfession') ? undefined : BuilderProfessionFromJSON(json['builderProfession']),
    };
}

export function CreateProviderCommandToJSON(value?: CreateProviderCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ProviderTypeToJSON(value.type),
        'position': value.position,
        'companyName': value.companyName,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'introduction': value.introduction,
        'phoneNumber': value.phoneNumber,
        'emailAddress': value.emailAddress,
        'specializationIds': value.specializationIds,
        'coveredCountyIds': value.coveredCountyIds,
        'tagIds': value.tagIds,
        'engineerProfession': EngineerProfessionToJSON(value.engineerProfession),
        'minPriceQuote': value.minPriceQuote,
        'maxPriceQuote': value.maxPriceQuote,
        'builderProfession': BuilderProfessionToJSON(value.builderProfession),
    };
}


