import { useGrapeAntdForm } from "components/form";
import { BuildRefusalReason } from "api/generated/optimum";
import enumTranslate from "utils/enumTranslate";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import RefuseOrder, { RefuseOrderProps } from "./RefuseOrder";

const RefuseBuildOrder: React.FC<
  Omit<RefuseOrderProps, "formUtils" | "refuseOptions">
> = (props) => {
  const formUtils = useGrapeAntdForm("RejectBuildOrderCommand");

  const buildRefusalReasonOptions = Object.values(BuildRefusalReason)
    .filter((option) => option !== BuildRefusalReason.Invalid)
    .map((value) => {
      return {
        value,
        label: enumTranslate(value, EnumContext.BUILD_REFUSAL_REASON) ?? value,
      };
    });

  return (
    <RefuseOrder
      formUtils={formUtils}
      refuseOptions={buildRefusalReasonOptions}
      {...props}
    />
  );
};

export default RefuseBuildOrder;
