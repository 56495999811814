import { createModel } from "@rematch/core";
import { api } from "api";
import {
  CreateProviderCommand,
  GetProviderListRequest,
  ProjectsStatisticsRequest,
  ProjectStatusCount,
  ProviderDto,
  SetMyActiveFlagCommand,
  SetProviderActiveFlagCommand,
  SetProviderIsEnabledFlagCommand,
  SetProviderPublishedFlagCommand,
  UpdateProviderCommand,
} from "api/generated/optimum";
import { RootModel } from "app/store";
import { downloadBlobAsCsv } from "utils/fileReader";

interface ProviderPicture {
  imageUrl: string | undefined | null;
  file?: File;
}

interface ProviderState {
  user?: ProviderDto;
  selectedProvider?: ProviderDto;
  providerList?: ProviderDto[];
  providerPicture?: ProviderPicture;
  projectsStatics: ProjectStatusCount[];
}

const initialState: ProviderState = {
  user: undefined,
  providerList: [],
  projectsStatics: [],
};

export const provider = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setUser(state, provider: ProviderDto) {
      state.user = provider;
    },
    setProviderList(state, providerList: ProviderDto[]) {
      state.providerList = providerList;
    },
    setProviderPicture(state, providerPicture?: ProviderPicture) {
      state.providerPicture = providerPicture;
    },
    setProjectsStatics(state, payload: ProjectStatusCount[]) {
      state.projectsStatics = payload;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    return {
      getMyProfile: async (): Promise<void> => {
        const user = await api.accounts.getMyProfile();

        dispatch.provider.setUser(user);
      },
      updateMyProfile: async (
        command: SetMyActiveFlagCommand
      ): Promise<void> => {
        await api.accounts.setMyActiveFlag({ setMyActiveFlagCommand: command });
      },
      // admin effects
      getProvider: async (id: string): Promise<void> => {
        const provider = await api.accounts.getProvider({ id });

        dispatch.provider.setUser(provider);
      },
      getAllProvider: async (
        request: GetProviderListRequest
      ): Promise<void> => {
        const provider = await api.accounts.getProviderList(request);

        dispatch.provider.setProviderList(provider);
      },
      deleteProvider: async (id: string, store): Promise<void> => {
        await api.accounts.updateProvider({
          updateProviderCommand: {
            ...store.provider.user,
            id,
            isEnabled: false,
          } as UpdateProviderCommand,
        });
      },
      updateProvider: async (
        command: UpdateProviderCommand,
        store
      ): Promise<void> => {
        await api.accounts.updateProvider({
          updateProviderCommand: command,
        });

        if (store.provider.providerPicture?.file && store.provider.user?.id) {
          await api.accounts.uploadProfilePicture({
            id: store.provider.user?.id,
            file: store.provider.providerPicture?.file,
          });
        }
      },
      createProvider: async (
        command: CreateProviderCommand,
        store
      ): Promise<void> => {
        const id = await api.accounts.createProvider({
          createProviderCommand: command,
        });

        if (store.provider.providerPicture) {
          api.accounts.uploadProfilePicture({
            id: JSON.parse(id),
            file: store.provider.providerPicture.file,
          });
        }
      },
      exportProviderList: async () => {
        const file = await api.accounts.exportProviders();

        downloadBlobAsCsv(file);
      },
      updateProviderEnabledFlag: async (
        setProviderIsEnabledFlagCommand: SetProviderIsEnabledFlagCommand
      ): Promise<void> => {
        await api.accounts.setEnabledFlag({ setProviderIsEnabledFlagCommand });
      },
      updateProviderPublishedFlag: async (
        setProviderPublishedFlagCommand: SetProviderPublishedFlagCommand
      ): Promise<void> => {
        await api.accounts.setPublishedFlag({
          setProviderPublishedFlagCommand,
        });
      },
      updateProviderActiveFlag: async (
        setProviderActiveFlagCommand: SetProviderActiveFlagCommand
      ): Promise<void> => {
        await api.accounts.setActiveFlag({ setProviderActiveFlagCommand });
      },
      getProjectStatistics: async (
        request: ProjectsStatisticsRequest
      ): Promise<void> => {
        const response = await api.projects.projectsStatistics(request);

        dispatch.provider.setProjectsStatics(response.projectStatuses ?? []);
      },
    };
  },
});
