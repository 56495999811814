import React from "react";
import clsx from "clsx";
import styles from "./CustomParagraph.module.scss";
import { Typography } from "antd";
import { ParagraphProps } from "antd/lib/typography/Paragraph";

const { Paragraph } = Typography;

type CustomParagraphSize = "default" | "xs" | "sm" | "md" | "lg";
type CustomParagraphColor =
  | "default"
  | "lightBlue"
  | "darkBlue"
  | "grey"
  | "green"
  | "black";

interface CustomParagraphProps extends ParagraphProps {
  size?: CustomParagraphSize;
  color?: CustomParagraphColor;
  extraStrong?: boolean;
}

const sizeClassNames: { [size in CustomParagraphSize]: string } = {
  default: "",
  xs: styles.xs,
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
};

const colorClassNames: { [color in CustomParagraphColor]: string } = {
  default: "",
  lightBlue: styles.lightBlueColor,
  darkBlue: styles.darkBlueColor,
  grey: styles.greyColor,
  green: styles.greenColor,
  black: styles.blackColor,
};

const CustomParagraph: React.FC<CustomParagraphProps> = ({
  className,
  size = "default",
  color = "default",
  extraStrong,
  ...rest
}) => {
  return (
    <Paragraph
      {...rest}
      className={clsx(sizeClassNames[size], colorClassNames[color], className, {
        [styles.extraStrong]: extraStrong,
      })}
    />
  );
};

export default CustomParagraph;
