import { ForgotPasswordCommand } from "api/generated/optimum";
import { useDispatch } from "app/store";
import { AlertModalType } from "components/alertModal/AlertModal.store";
import CustomButton from "components/button/CustomButton";
import { email, GrapeAntdForm, useGrapeAntdForm } from "components/form";
import CustomInput from "components/form/components/CustomInput";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AuthStep } from "../Auth.store";
import styles from "./ForgotPassword.module.scss";

const ForgotPassword: FC<{ visible?: boolean }> = ({ visible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formUtils = useGrapeAntdForm<ForgotPasswordCommand>(
    "ForgotPasswordCommand"
  );

  const canLogin = process.env.REACT_APP_LOGIN_IS_ENABLED === "true";

  const handleFinish = async ({ email }: ForgotPasswordCommand) => {
    try {
      await dispatch.auth.forgotPassword(email);
      dispatch.auth.resetAuthStep();

      if (canLogin) {
        dispatch.alertModal.openModal({
          title: t("AUTH.FORGOTTEN_PASSWORD.SUCCES.TITLE"),
          descriptin: t("AUTH.FORGOTTEN_PASSWORD.SUCCES.DESC"),
          buttonLabel: t("COMMON.ALRIGHT"),
          closable: true,
          type: AlertModalType.Success,
        });
      } else {
        dispatch.alertModal.openModal({
          title: t("AUTH.FORGOTTEN_PASSWORD.SUCCES.TITLE"),
          descriptin: t("AUTH.FORGOTTEN_PASSWORD.SUCCES.DESC"),
          closable: true,
          type: AlertModalType.Success,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <CustomParagraph>{t("AUTH.FORGOTTEN_PASSWORD.DESC")}</CustomParagraph>

      <GrapeAntdForm
        formUtils={formUtils}
        onFinish={handleFinish}
        className={styles.form}
      >
        <GrapeAntdForm.Item<ForgotPasswordCommand>
          name="email"
          rules={[email()]}
        >
          <CustomInput placeholder={t("AUTH.LOGIN.EMAIL_PLACEHOLDER")} />
        </GrapeAntdForm.Item>

        <CustomButton
          type="primary"
          htmlType="submit"
          size="large"
          block
          className={styles.nextButton}
        >
          {t("COMMON.NEXT")}
        </CustomButton>

        <CustomButton
          type="text"
          size="large"
          block
          onClick={() =>
            dispatch.auth.setAuthStep({ authStep: AuthStep.LOGIN })
          }
        >
          {t("AUTH.FORGOTTEN_PASSWORD.BACK")}
        </CustomButton>
      </GrapeAntdForm>
    </>
  );
};

export default ForgotPassword;
