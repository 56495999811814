import { Layout } from "antd";
import { BasicProps } from "antd/lib/layout/layout";
import { Role } from "api/generated/optimum";
import { useSelector } from "app/store";
import { FC } from "react";
import PageTitle from "../../pageTitle/PageTitle";
import styles from "./Content.module.scss";
import { ReactComponent as LeftCircle } from "assets/images/Circle.svg";
import { ReactComponent as LeftCircleMobile } from "assets/images/LeftCircleMobile.svg";
import { ReactComponent as RightCircle } from "assets/images/RightCircle.svg";
import { ReactComponent as RightCircleMobile } from "assets/images/RightCircleMobile.svg";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ReactComponent as NgbsPageTitleLogo } from "assets/images/ngbsPageTitleLogo.svg";
import { ReactComponent as EngineerPageTitleLogo } from "assets/images/PageTitle.svg";
import { ReactComponent as BuilderLogo } from "assets/images/builder_logo.svg";
import clsx from "clsx";

export interface ContentProps extends BasicProps {
  title?: string;
  colorVariant?: Role;
  logo?: JSX.Element;
  contentClassName?: string;
}

const Content: FC<ContentProps> = ({
  title,
  colorVariant,
  logo,
  children,
  contentClassName,
  ...rest
}) => {
  const role = useSelector((state) => state.auth.user?.role);
  const mobileScreen = useBreakpoint({ max: "sm" });

  const getRoleBasedLogo = () => {
    if (!role) {
      return undefined;
    }

    const logos = {
      [Role.Engineer]: <EngineerPageTitleLogo />,
      [Role.Ngbs]: <NgbsPageTitleLogo />,
      [Role.Builder]: <BuilderLogo />,
      [Role.Invalid]: undefined,
      [Role.Admin]: undefined,
    };

    return logos[role];
  };

  return (
    <>
      {title && (
        <PageTitle
          title={title}
          colorVariant={colorVariant || role}
          logo={logo || getRoleBasedLogo()}
        />
      )}
      <Layout.Content
        className={clsx(styles.content, contentClassName)}
        {...rest}
      >
        {mobileScreen ? (
          <LeftCircleMobile className={styles.leftCircle} />
        ) : (
          <LeftCircle className={styles.leftCircle} />
        )}
        <div className={styles.container}>{children}</div>

        {mobileScreen ? (
          <RightCircleMobile className={styles.rightCircle} />
        ) : (
          <RightCircle className={styles.rightCircle} />
        )}
      </Layout.Content>
    </>
  );
};

export default Content;
