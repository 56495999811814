import React, {
  createContext,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useState,
} from "react";
import { Form, FormInstance } from "antd";
import { FormProps } from "antd/es/form/Form";
import GrapeAntdFormItem from "./GrapeAntdFormItem";
import GrapeAntdFormSubmit from "./GrapeAntdFormSubmit";
import useFormUtils, { FormUtils } from "../hooks/useGrapeAntdForm";
import { SchemaKey } from "../utils/schema";
import { NamePath } from "antd/lib/form/interface";

export interface GrapeAntdFormProps extends FormProps {
  schema?: SchemaKey;
  formUtils?: FormUtils;
  loading?: boolean;
}

export type GrapeAntdFormComponent = ForwardRefExoticComponent<
  GrapeAntdFormProps & RefAttributes<FormInstance<any>>
> & { Item: typeof GrapeAntdFormItem; Submit: typeof GrapeAntdFormSubmit };

// Form context to get schema props for each field
export const GrapeAntdFormContext = createContext({} as any);

export interface FieldError {
  errors: string[];
  name: NamePath;
}

const GrapeAntdForm = forwardRef(
  (
    { formUtils, schema, loading, onFinish, ...formProps }: GrapeAntdFormProps,
    ref?: React.Ref<any>
  ) => {
    const formUtilsBasedOnSchema = useFormUtils(schema);
    const [backendFieldErrors, setBackendFieldErrors] =
      useState<FieldError[]>();

    const utils = formUtils || formUtilsBasedOnSchema;
    const { form, submittable, handleFieldsChanged } = utils;

    // custom error handler for form field errors
    const handleFinish = async (props: any): Promise<void> => {
      try {
        await onFinish?.(props);

        setBackendFieldErrors(undefined);
        form?.setFields([]);
      } catch (error: any) {
        // convert to FieldData format
        const fieldErrors: FieldError[] = Object.entries(error.errors).map(
          ([key, value]) => ({
            name: key.replace(/[A-Z]/, (value) => value.toLowerCase()),
            errors: [value] as string[],
          })
        );

        setBackendFieldErrors(fieldErrors);
        form?.setFields(fieldErrors);

        throw error;
      }
    };

    return (
      <GrapeAntdFormContext.Provider
        value={{
          schema: utils.schema,
          requiredFields: utils.required,
          loading,
          submittable,
          backendFieldErrors,
        }}
      >
        <Form
          layout="vertical"
          form={form}
          onFieldsChange={handleFieldsChanged}
          onFinish={handleFinish}
          scrollToFirstError
          ref={ref}
          {...formProps}
          validateTrigger="onBlur"
        />
      </GrapeAntdFormContext.Provider>
    );
  }
) as GrapeAntdFormComponent;

GrapeAntdForm.Item = GrapeAntdFormItem;
GrapeAntdForm.Submit = GrapeAntdFormSubmit;

export default GrapeAntdForm;
