import { Input, InputNumber, InputNumberProps, InputProps } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { FC, forwardRef, Ref } from "react";

interface CustomInputProps extends InputProps {
  className?: string;
}

interface CustomInputTextAreaProps extends TextAreaProps {
  className?: string;
}

const CustomInput: FC<CustomInputProps> = ({ className, ...rest }) => (
  <Input {...rest} className={className} maxLength={255} />
);

export const CustomInputNumber: FC<
  InputNumberProps & {
    className?: string;
  }
> = ({ className, ...rest }) => {
  return <InputNumber {...rest} className={className} />;
};

const supportedCurrencies: { [key: string]: string } = {
  forint: "Ft",
  // euro: "€",
};
export const CustomCurrencyInput: FC<
  InputNumberProps & {
    className?: string;
    currency?: "forint" | "euro";
  }
> = ({ className, currency, ...rest }) => {
  return (
    <InputNumber
      {...rest}
      formatter={(value: any) =>
        `${value} ${supportedCurrencies[currency ?? "forint"]}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )
      }
      parser={(value: string | undefined) =>
        value?.replace(/\$\s?|(,*)/g, "") ?? ""
      }
      className={className}
      min={0}
      step={10000}
    />
  );
};

export const CustomTextArea = forwardRef(
  ({ className, ...rest }: CustomInputTextAreaProps, ref?: Ref<any>) => (
    <Input.TextArea
      {...rest}
      ref={ref}
      className={className}
      maxLength={2000}
    />
  )
);

export default CustomInput;
