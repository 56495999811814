import { Col, Row } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Card from "components/card/Card";
import {
  email,
  GrapeAntdForm,
  phoneNumber,
  useGrapeAntdForm,
} from "components/form";
import CustomTitle from "components/typography/title/CustomTitle";
import StepNavigator from "components/stepNavigator/StepNavigator";
import CustomButton from "components/button/CustomButton";
import { useDispatch, useSelector } from "app/store";

import styles from "./Step4.module.scss";
import { CreateDesignOrderCommand } from "api/generated/optimum";
import CustomInput, {
  CustomTextArea,
} from "components/form/components/CustomInput";
import useEngineerContactWizard from "../../useEngineerContactWizard";
import { useNavigate } from "react-router-dom";

const Step4: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { prevRoute, nextRoute } = useEngineerContactWizard();
  const navigate = useNavigate();

  const { wizardData, currentStep } = useSelector((state) => state.engineer);
  const formUtils = useGrapeAntdForm<CreateDesignOrderCommand>(
    "CreateDesignOrderCommand"
  );

  const handleFinish = (values: CreateDesignOrderCommand) => {
    dispatch.engineer.setWizardData(values);
    dispatch.engineer.nextStep();

    if (nextRoute) {
      navigate(nextRoute);
    }
  };

  const handleBackButton = () => {
    dispatch.engineer.setWizardData(formUtils.form.getFieldsValue());
    dispatch.engineer.prevStep();

    if (prevRoute) {
      navigate(prevRoute);
    }
  };

  return (
    <>
      <Card className={styles.cardContainer}>
        <CustomTitle
          level={3}
          align="center"
          color="darkBlue"
          responsiveLevel={{ mobile: 5, tablet: 5 }}
          extraStrong
        >
          {t("ENGINEERS.ENGINEER_WIZARD.STEP_4.CARD_TITLE")}
        </CustomTitle>

        <GrapeAntdForm
          formUtils={formUtils}
          onFinish={handleFinish}
          className={styles.form}
          initialValues={wizardData[currentStep]}
        >
          <Row>
            <Col xs={24}>
              <GrapeAntdForm.Item<CreateDesignOrderCommand>
                label={t("ENGINEERS.ENGINEER_WIZARD.STEP_4.NAME_LABEL")}
                name="name"
              >
                <CustomInput placeholder={t("COMMON.FORM.INPUT_PLACEHOLDER")} />
              </GrapeAntdForm.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <GrapeAntdForm.Item<CreateDesignOrderCommand>
                label={t("ENGINEERS.ENGINEER_WIZARD.STEP_4.EMAIL_LABEL")}
                name="emailAddress"
                rules={[email()]}
              >
                <CustomInput placeholder={t("COMMON.FORM.INPUT_PLACEHOLDER")} />
              </GrapeAntdForm.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <GrapeAntdForm.Item<CreateDesignOrderCommand>
                label={t("ENGINEERS.ENGINEER_WIZARD.STEP_4.PHONE_LABEL")}
                name="phoneNumber"
                rules={[phoneNumber(t("error.common.phoneNumber"))]}
              >
                <CustomInput
                  placeholder={t(
                    "ENGINEERS.ENGINEER_WIZARD.STEP_4.PHONE_PLACEHOLDER"
                  )}
                />
              </GrapeAntdForm.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <GrapeAntdForm.Item<CreateDesignOrderCommand>
                label={t("ENGINEERS.ENGINEER_WIZARD.STEP_4.NOTE_LABEL")}
                name="note"
              >
                <CustomTextArea
                  placeholder={t(
                    "ENGINEERS.ENGINEER_WIZARD.STEP_4.NOTE_PLACEHOLDER"
                  )}
                  className={styles.textArea}
                />
              </GrapeAntdForm.Item>
            </Col>
          </Row>
        </GrapeAntdForm>
      </Card>

      <StepNavigator
        firstButton={
          <CustomButton onClick={handleBackButton} className={styles.button}>
            {t("COMMON.BACK")}
          </CustomButton>
        }
        secondButton={
          <CustomButton
            type="primary"
            className={styles.button}
            onClick={() => formUtils.form.submit()}
          >
            {t("COMMON.NEXT")}
          </CustomButton>
        }
      />
    </>
  );
};

export default Step4;
