/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectStatusCount,
    ProjectStatusCountFromJSON,
    ProjectStatusCountFromJSONTyped,
    ProjectStatusCountToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectsStatisticsDto
 */
export interface ProjectsStatisticsDto {
    /**
     * 
     * @type {Array<ProjectStatusCount>}
     * @memberof ProjectsStatisticsDto
     */
    projectStatuses?: Array<ProjectStatusCount> | null;
}

export function ProjectsStatisticsDtoFromJSON(json: any): ProjectsStatisticsDto {
    return ProjectsStatisticsDtoFromJSONTyped(json, false);
}

export function ProjectsStatisticsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsStatisticsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectStatuses': !exists(json, 'projectStatuses') ? undefined : (json['projectStatuses'] === null ? null : (json['projectStatuses'] as Array<any>).map(ProjectStatusCountFromJSON)),
    };
}

export function ProjectsStatisticsDtoToJSON(value?: ProjectsStatisticsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectStatuses': value.projectStatuses === undefined ? undefined : (value.projectStatuses === null ? null : (value.projectStatuses as Array<any>).map(ProjectStatusCountToJSON)),
    };
}


