import { Col, Row } from "antd";
import { valueType } from "antd/lib/statistic/utils";
import {
  CreateProviderCommand,
  EngineerProfession,
  ProviderType,
  SpecializationCategory,
  UpdateProviderCommand,
} from "api/generated/optimum";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import { useDispatch, useSelector } from "app/store";
import clsx from "clsx";
import CustomButton from "components/button/CustomButton";
import CustomModal from "components/customModal/CustomModal";
import {
  email,
  GrapeAntdForm,
  pattern,
  phoneNumber,
  useGrapeAntdForm,
} from "components/form";
import CustomInput, {
  CustomInputNumber,
  CustomTextArea,
} from "components/form/components/CustomInput";
import { CustomSelect } from "components/form/components/CustomSelect";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import RadioButtonGroup, {
  RadioButton,
} from "features/admin/components/radioButton/RadioButtonGroup";
import { ProviderPictureUpload } from "features/provider/components/form/provider/ProviderPictureUpload";
import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import enumTranslate from "utils/enumTranslate";
import styles from "./ProviderModal.module.scss";

interface ProviderModalProps {
  isCreateMode: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isVisible: boolean;
  id?: string;
  getProviders?: (wildcard?: string | undefined) => Promise<void>;
  setRejectModalVisible?: (value: React.SetStateAction<boolean>) => void;
}

const ALL_COUNTY_KEY = -1;

const ProviderModal: React.FC<ProviderModalProps> = ({
  isCreateMode,
  onClose,
  onSubmit,
  isVisible,
  id,
  getProviders,
  setRejectModalVisible,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formUtils = useGrapeAntdForm(
    isCreateMode ? "CreateProviderCommand" : "UpdateProviderCommand"
  );
  const { user } = useSelector((state) => state.provider);
  const { specializations, counties } = useSelector(
    (state) => state.referenceData
  );

  const [typeRadioValue, setTypeRadioValue] = useState<ProviderType>(
    ProviderType.Engineer
  );

  const [areAllCountiesSelected, setAreAllCountiesSelected] = useState(false);

  const engineerProfessionOptions = Object.values(EngineerProfession)
    .filter((option) => option !== EngineerProfession.Invalid)
    .map((value) => {
      return {
        value: value,
        label: enumTranslate(value, EnumContext.PROFESSION),
      };
    });

  const modalOnFinish = async (
    values: CreateProviderCommand | UpdateProviderCommand
  ) => {
    const fullName = values.firstName.split(" ");

    const command = {
      ...values,
      lastName: fullName?.shift(),
      firstName: fullName?.join(" "),
      type: typeRadioValue,
      coveredCountyIds: values.coveredCountyIds?.includes(ALL_COUNTY_KEY)
        ? counties.map((county) => county.id)
        : values.coveredCountyIds,
    };

    try {
      if (isCreateMode) {
        await dispatch.provider.createProvider(
          command as CreateProviderCommand
        );

        getProviders && getProviders();
      } else {
        await dispatch.provider.updateProvider({
          ...command,
          id: user?.id,
        } as UpdateProviderCommand);

        await dispatch.provider.reset();

        await dispatch.provider.getProvider(String(user?.id));
      }

      onSubmit();
    } catch (error) {
      console.error(error);
    }
  };

  const typeRadioButtons = useMemo<RadioButton<ProviderType>[]>(
    () => [
      {
        label: t("ADMIN.PROVIDERS.MODAL.PROVIDER_TYPE.ENGINEER"),
        value: ProviderType.Engineer,
      },
      {
        label: t("ADMIN.PROVIDERS.MODAL.PROVIDER_TYPE.BUILDER"),
        value: ProviderType.Builder,
      },
    ],
    [t]
  );

  const checkIfChanged = () => {
    if (!isCreateMode) {
      const values = formUtils.form.getFieldsValue();

      const userData = {
        firstName: `${user?.lastName} ${user?.firstName}`,
        position: user?.position,
        companyName: user?.companyName,
        phoneNumber: user?.phoneNumber,
        emailAddress: user?.emailAddress,
        introduction: user?.introduction,
        coveredCountyIds: user?.coveredCounties?.map((x) => x.id),
        specializationIds: user?.specializations?.map((x) => x.id),
      };

      const engineer = {
        ...userData,
        engineerProfession: user?.engineerProfession,
        maxPriceQuote: user?.maxPriceQuote,
        minPriceQuote: user?.minPriceQuote,
      };

      if (
        !isEqual(
          typeRadioValue === ProviderType.Engineer ? engineer : userData,
          values
        )
      ) {
        setRejectModalVisible && setRejectModalVisible(true);
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  };

  useEffect(() => {
    dispatch.referenceData.getAll();
  }, [dispatch.referenceData]);

  useEffect(() => {
    if (isVisible && id && user?.type) {
      formUtils.form.setFieldsValue({
        ...user,
        firstName: `${user?.lastName} ${user?.firstName}`,
        specializationIds: user?.specializations?.map((x) => x.id),
        coveredCountyIds: user?.coveredCounties?.map((x) => x.id),
      } as UpdateProviderCommand);

      setTypeRadioValue(user?.type);
    }
  }, [formUtils.form, id, user, isVisible]);

  const priceFormatter = (value?: valueType) =>
    ` ${value}`
      .replace(/\./, ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      .replace(",", "");

  const priceParser = (value?: valueType) =>
    parseFloat(
      `${value}`.replace(/,/, "#").replace(/\./g, "").replace(/#/, ".")
    );

  const getSpecializationLabel = () => {
    const userType = user?.type ?? typeRadioValue;

    return userType === ProviderType.Engineer
      ? t("PROFILE.SPECIALIZATION")
      : t("PROFILE.BUILDER_SPECIALIZATION");
  };

  return (
    <CustomModal
      closable={false}
      width={"80rem"}
      visible={isVisible}
      className={styles.modal}
      afterClose={() => {
        dispatch.provider.setProviderPicture({
          imageUrl: user?.profilePictureUrl,
          file: undefined,
        });
        formUtils.form.resetFields();
      }}
    >
      <Row justify="center">
        <CustomTitle level={3} color="darkBlue" extraStrong>
          {isCreateMode
            ? t("ADMIN.PROVIDERS.MODAL.ADD_PROVIDER_TITLE")
            : t("ADMIN.PROVIDERS.MODAL.PROFILE_TITLE")}
        </CustomTitle>
      </Row>
      <GrapeAntdForm
        formUtils={formUtils}
        layout="horizontal"
        className={styles.form}
        onFinish={modalOnFinish}
      >
        {isCreateMode && (
          <Row align="middle" className={styles.radioGroup}>
            <Col>
              <RadioButtonGroup
                onChange={setTypeRadioValue}
                value={typeRadioValue}
                radioButtons={typeRadioButtons}
                className={styles.radioGroupContent}
                size="large"
              />
            </Col>
          </Row>
        )}
        <Row gutter={32}>
          <Col xs={8}>
            <ProviderPictureUpload
              className={styles.providerPictureUpload}
              providerId={user?.id || ""}
              url={user?.profilePictureUrl}
            />
          </Col>
          <Col xs={16}>
            <GrapeAntdForm.Item
              className={styles.formItemLabel}
              label={t("ADMIN.PROVIDERS.MODAL.NAME")}
              name="firstName"
              rules={[pattern(/^(([\p{L}.'-]+)\s+)+([\p{L}.'-]+)$/u)]}
            >
              <CustomInput
                placeholder={t("ADMIN.PROVIDERS.MODAL.NAME_PLACEHOLDER")}
                className={styles.customInputBackground}
              />
            </GrapeAntdForm.Item>

            <GrapeAntdForm.Item
              className={styles.formItemLabel}
              label={t("ADMIN.PROVIDERS.MODAL.WORK")}
              name="position"
            >
              <CustomInput
                placeholder={t("ADMIN.PROVIDERS.MODAL.POSITION_PLACEHOLDER")}
                className={styles.customInputBackground}
              />
            </GrapeAntdForm.Item>

            <GrapeAntdForm.Item
              className={styles.formItemLabel}
              label={t("ADMIN.PROVIDERS.MODAL.COMPANY")}
              name="companyName"
            >
              <CustomInput
                placeholder={t("ADMIN.PROVIDERS.MODAL.COMPANY_PLACEHOLDER")}
                className={styles.customInputBackground}
              />
            </GrapeAntdForm.Item>

            <GrapeAntdForm.Item
              className={styles.formItemLabel}
              label={t("ADMIN.PROVIDERS.MODAL.PHONE_NUMBER")}
              rules={[phoneNumber(t("error.common.phoneNumber"))]}
              name="phoneNumber"
            >
              <CustomInput
                placeholder={t(
                  "ADMIN.PROVIDERS.MODAL.PHONE_NUMBER_PLACEHOLDER"
                )}
                className={styles.customInputBackground}
              />
            </GrapeAntdForm.Item>

            <GrapeAntdForm.Item
              className={styles.formItemLabel}
              label={t("ADMIN.PROVIDERS.MODAL.EMAIL")}
              name="emailAddress"
              rules={[email()]}
            >
              <CustomInput
                placeholder={t("ADMIN.PROVIDERS.MODAL.EMAIL_PLACEHOLDER")}
                className={styles.customInputBackground}
                disabled={!isCreateMode}
              />
            </GrapeAntdForm.Item>
          </Col>
        </Row>

        {typeRadioValue === ProviderType.Engineer && (
          <Row>
            <Col>
              <CustomParagraph
                className={styles.dataLabel}
                color="grey"
                extraStrong
              >
                {t("ADMIN.PROVIDERS.MODAL.DESIGNERPLAN_PRICE")}
              </CustomParagraph>
              <Row gutter={10} align="middle">
                <Col>
                  <GrapeAntdForm.Item name="minPriceQuote">
                    <CustomInputNumber
                      onChange={() =>
                        formUtils.form.validateFields(["maxPriceQuote"])
                      }
                      min="0"
                      className={clsx(
                        styles.pricingEditor,
                        styles.customNumberInputBackground
                      )}
                      formatter={priceFormatter}
                      parser={priceParser}
                      placeholder={t(
                        "ADMIN.PROVIDERS.MODAL.PRICE_FROM_PLACEHOLDER"
                      )}
                    />
                  </GrapeAntdForm.Item>
                </Col>
                <Col>{t("ADMIN.PROVIDERS.MODAL.PRICE_FROM")}</Col>
                <Col>
                  <GrapeAntdForm.Item
                    name="maxPriceQuote"
                    rules={[
                      ({ getFieldValue }: { getFieldValue: any }) => ({
                        validator(_: any, value: number) {
                          const minPriceQuote = getFieldValue("minPriceQuote");
                          if (+minPriceQuote > value) {
                            return Promise.reject(
                              new Error(
                                t(
                                  "ADMIN.PROVIDERS.MODAL.PRICE_TO_SMALLER_THAN_FROM",
                                  {
                                    minPriceQuote,
                                  }
                                )
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <CustomInputNumber
                      min="1"
                      className={clsx(
                        styles.pricingEditor,
                        styles.customNumberInputBackground
                      )}
                      placeholder={t(
                        "ADMIN.PROVIDERS.MODAL.PRICE_FROM_PLACEHOLDER_TO"
                      )}
                      formatter={priceFormatter}
                      parser={priceParser}
                    />
                  </GrapeAntdForm.Item>
                </Col>
                {t("ADMIN.PROVIDERS.MODAL.PRICE_TO")}
                <Col />
              </Row>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <CustomParagraph
              className={styles.dataLabel}
              color="grey"
              extraStrong
            >
              {getSpecializationLabel()}
            </CustomParagraph>
            <GrapeAntdForm.Item
              className={styles.formItemLabel}
              name="specializationIds"
            >
              <CustomSelect
                placeholder={t("COMMON.FORM.SELECT_PLACEHOLDER")}
                className={styles.customSelectBackground}
                mode="multiple"
                optionFilterProp="label"
                options={
                  typeRadioValue === ProviderType.Engineer
                    ? specializations
                        .filter(
                          (x) => x.category === SpecializationCategory.Engineer
                        )
                        .map((spec) => ({ value: spec.id, label: spec.name }))
                    : specializations
                        .filter(
                          (x) => x.category === SpecializationCategory.Builder
                        )
                        .map((spec) => ({ value: spec.id, label: spec.name }))
                }
                showArrow
              />
            </GrapeAntdForm.Item>
            {typeRadioValue === ProviderType.Engineer && (
              <>
                <CustomParagraph
                  className={styles.dataLabel}
                  color="grey"
                  extraStrong
                >
                  {t("ADMIN.PROVIDERS.MODAL.EDUCATION")}
                </CustomParagraph>
                <GrapeAntdForm.Item
                  className={styles.formItemLabel}
                  name="engineerProfession"
                >
                  <CustomSelect
                    placeholder={t("COMMON.FORM.SELECT_PLACEHOLDER")}
                    className={styles.customSelectBackground}
                    options={engineerProfessionOptions}
                  />
                </GrapeAntdForm.Item>
              </>
            )}

            <CustomParagraph
              className={styles.dataLabel}
              color="grey"
              extraStrong
            >
              {typeRadioValue === ProviderType.Engineer
                ? t("ADMIN.PROVIDERS.MODAL.COVERED_COUNTIES")
                : t("ADMIN.PROVIDERS.MODAL.COVERED_COUNTIES_BUILDER")}
            </CustomParagraph>

            <GrapeAntdForm.Item
              className={styles.formItemLabel}
              name="coveredCountyIds"
            >
              <CustomSelect
                placeholder={t("COMMON.FORM.SELECT_PLACEHOLDER")}
                className={styles.customSelectBackground}
                mode="multiple"
                optionFilterProp="children"
                showArrow
                onChange={(values) => {
                  if (values.includes(ALL_COUNTY_KEY)) {
                    formUtils.form.setFieldsValue({
                      coveredCountyIds: [ALL_COUNTY_KEY],
                    });
                    setAreAllCountiesSelected(true);
                  } else {
                    formUtils.form.setFieldsValue({
                      coveredCountyIds: values,
                    });

                    setAreAllCountiesSelected(false);
                  }
                }}
              >
                <CustomSelect.Option value={ALL_COUNTY_KEY}>
                  {t("ADMIN.PROVIDERS.MODAL.ALL_COUNTIES")}
                </CustomSelect.Option>
                {counties.map((county) => (
                  <CustomSelect.Option
                    disabled={areAllCountiesSelected}
                    key={county.id}
                    value={county.id}
                  >
                    {county.name}
                  </CustomSelect.Option>
                ))}
              </CustomSelect>
            </GrapeAntdForm.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <CustomParagraph
              className={styles.dataLabel}
              color="grey"
              extraStrong
            >
              {t("ADMIN.PROVIDERS.MODAL.INTRODUCTION")}
            </CustomParagraph>
            <GrapeAntdForm.Item
              className={styles.formItemLabel}
              name="introduction"
            >
              <CustomTextArea
                className={styles.customInputBackground}
                placeholder={t(
                  "ADMIN.PROVIDERS.MODAL.INTRODUCTION_PLACEHOLDER"
                )}
                autoSize
              />
            </GrapeAntdForm.Item>
          </Col>
        </Row>
      </GrapeAntdForm>

      <Row className={styles.modalButtonContainer} gutter={50} justify="center">
        <Col>
          <CustomButton
            className={styles.modalCancelButton}
            onClick={checkIfChanged}
            variant="default"
            size="large"
          >
            {t("COMMON.CANCEL")}
          </CustomButton>
        </Col>
        <Col>
          <CustomButton
            type="primary"
            className={styles.modalButton}
            onClick={formUtils.form.submit}
            variant="default"
            size="large"
          >
            {isCreateMode
              ? t("ADMIN.PROVIDERS.MODAL.CREATE")
              : t("COMMON.SAVE")}
          </CustomButton>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default ProviderModal;
