import clsx from "clsx";
import { FC } from "react";
import styles from "./Card.module.scss";

export interface CardProps {
  className?: string;
}

const Card: FC<CardProps> = ({ children, className }) => {
  return (
    <div className={clsx(className, styles.cardContainer)}>{children}</div>
  );
};

export default Card;
