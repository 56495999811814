import { Checkbox, Col, Radio, Row, Upload } from "antd";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import Card from "components/card/Card";
import {
  GrapeAntdForm,
  positiveNumber,
  requiredField,
  useGrapeAntdForm,
  zipCodeRule,
} from "components/form";
import CustomInput, {
  CustomInputNumber,
} from "components/form/components/CustomInput";
import CustomTitle from "components/typography/title/CustomTitle";
import styles from "./Step3.module.scss";
import CustomButton from "components/button/CustomButton";
import { useDispatch, useSelector } from "app/store";
import { InboxOutlined } from "@ant-design/icons";
import StepNavigator from "components/stepNavigator/StepNavigator";
import {
  BoilerOptions,
  CreateDesignOrderCommand,
  HeatEmitterOptions,
  HomeBuildingPhaseOptions,
  InsulationOptions,
} from "api/generated/optimum";
import enumTranslate from "utils/enumTranslate";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import { CreateDesign } from "models/CreateDesign";
import { RealEstateType } from "models/RealEstateType";
import clsx from "clsx";
import useFileInput, { FileExtensions } from "hooks/useFileInput";
import { CustomSelect } from "components/form/components/CustomSelect";
import useEngineerContactWizard from "../../useEngineerContactWizard";
import { useNavigate } from "react-router-dom";

const Step3: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { prevRoute, nextRoute } = useEngineerContactWizard();
  const navigate = useNavigate();

  const formUtils = useGrapeAntdForm<CreateDesign>("CreateDesignOrderCommand");
  const { wizardData, currentStep, realEstateType } = useSelector(
    (state) => state.engineer
  );

  const { acceptedExtensions, normFile } = useFileInput({
    validExtensions: [
      FileExtensions.JPG,
      FileExtensions.JPEG,
      FileExtensions.PDF,
      FileExtensions.PNG,
    ],
  });

  const isNewlyBuilt = realEstateType === RealEstateType.NewlyBuilt;

  const homeBuildingPhaseOptions = Object.values(HomeBuildingPhaseOptions)
    .filter((option) => option !== HomeBuildingPhaseOptions.None)
    .map((value, i) => {
      return {
        value: value,
        label: enumTranslate(value, EnumContext.HOMEPHASE),
      };
    });

  const newlyBuiltInsulationOptions = Object.values(InsulationOptions)
    .filter(
      (option) =>
        option !== InsulationOptions.None &&
        option !== InsulationOptions.NoInsulation
    )
    .map((value, i) => {
      return {
        value: value,
        label: enumTranslate(value, EnumContext.INSULATION),
      };
    });

  const insulationOptions = Object.values(InsulationOptions)
    .filter(
      (option) =>
        option !== InsulationOptions.None &&
        option !== InsulationOptions.TwentyCmPlus
    )
    .map((value, i) => {
      return {
        value: value,
        label: enumTranslate(value, EnumContext.INSULATION),
      };
    });

  const heatEmitterOptions = [
    ...Object.values(HeatEmitterOptions).filter(
      (heatEmitterOption) => heatEmitterOption !== HeatEmitterOptions.Other
    ),
    HeatEmitterOptions.Other,
  ]
    .filter((option) => option !== HeatEmitterOptions.None)
    .map((value, i) => {
      return {
        value: value,
        label: enumTranslate(value, EnumContext.HEATEMITTER),
      };
    });

  const boilerOptions = [
    ...Object.values(BoilerOptions).filter(
      (boilerOption) => boilerOption !== BoilerOptions.Other
    ),
    BoilerOptions.Other,
  ]
    .filter((option) => option !== BoilerOptions.None)
    .map((value, i) => {
      return {
        value: value,
        label: enumTranslate(value, EnumContext.BOILER),
      };
    });

  const handleFinish = (values: any) => {
    dispatch.engineer.setWizardData(values);
    dispatch.engineer.nextStep();

    if (nextRoute) {
      navigate(nextRoute);
    }
  };

  const handleBackButton = () => {
    dispatch.engineer.setWizardData(formUtils.form.getFieldsValue());
    dispatch.engineer.prevStep();

    if (prevRoute) {
      navigate(prevRoute);
    }
  };

  return (
    <>
      <Card className={styles.cardContainer}>
        <CustomTitle
          level={3}
          align="center"
          color="darkBlue"
          extraStrong
          responsiveLevel={{ mobile: 5, tablet: 5 }}
        >
          {t("ENGINEERS.ENGINEER_WIZARD.STEP_3.CARD_TITLE")}
        </CustomTitle>

        <GrapeAntdForm
          formUtils={formUtils}
          onFinish={handleFinish}
          initialValues={wizardData[currentStep]}
        >
          {isNewlyBuilt && (
            <Row>
              <Col span={24} className={styles.homePhase}>
                <GrapeAntdForm.Item<CreateDesign>
                  label={t("ENGINEERS.ENGINEER_WIZARD.STEP_3.HOME_PHASE_LABEL")}
                  name="homeBuildingPhaseOptions"
                  className={styles.radio}
                  rules={[requiredField()]}
                >
                  <Radio.Group options={homeBuildingPhaseOptions} />
                </GrapeAntdForm.Item>
              </Col>
            </Row>
          )}

          <div
            className={clsx(styles.form, {
              [styles.awaitingRenovationForm]: !isNewlyBuilt,
            })}
          >
            <Row gutter={60}>
              <Col
                {...(realEstateType === RealEstateType.NewlyBuilt
                  ? { lg: 12 }
                  : { md: 12 })}
                xs={24}
              >
                <GrapeAntdForm.Item<CreateDesign>
                  className={styles.label}
                  label={
                    <Trans i18nKey="ENGINEERS.ENGINEER_WIZARD.STEP_3.FLOOR_AREA_LABEL">
                      <sup>2 </sup>
                    </Trans>
                  }
                  name="heatedSquareMs"
                  rules={[positiveNumber()]}
                >
                  <CustomInputNumber
                    className={styles.realEstateSpecInput}
                    placeholder={t("COMMON.FORM.INPUT_PLACEHOLDER")}
                  />
                </GrapeAntdForm.Item>
              </Col>

              <Col
                {...(realEstateType === RealEstateType.NewlyBuilt
                  ? { lg: 12 }
                  : { md: 12 })}
                xs={24}
              >
                <GrapeAntdForm.Item
                  label={t("ENGINEERS.ENGINEER_WIZARD.STEP_3.INSULATION_LABEL")}
                  name="insulationOptions"
                >
                  <CustomSelect
                    placeholder={t("COMMON.FORM.SELECT_PLACEHOLDER")}
                    size="large"
                    className={styles.realEstateSpecInput}
                    options={
                      isNewlyBuilt
                        ? newlyBuiltInsulationOptions
                        : insulationOptions
                    }
                  />
                </GrapeAntdForm.Item>
              </Col>
            </Row>

            <Row>
              <Col md={13} xs={24}>
                <GrapeAntdForm.Item<CreateDesign>
                  label={t(
                    "ENGINEERS.ENGINEER_WIZARD.STEP_3.PEOPLE_COUNT_LABEL"
                  )}
                  name="headCount"
                  rules={[positiveNumber()]}
                >
                  <CustomInputNumber
                    placeholder={t("COMMON.FORM.INPUT_PLACEHOLDER")}
                    className={styles.realEstateSpecInput}
                  />
                </GrapeAntdForm.Item>
              </Col>

              {!isNewlyBuilt && (
                <Col md={11} xs={24} className={styles.peopleCountRow}>
                  <GrapeAntdForm.Item
                    label={t("ENGINEERS.ENGINEER_WIZARD.STEP_3.BOILER_LABEL")}
                    name="boilerOptions"
                    rules={[requiredField()]}
                  >
                    <CustomSelect
                      placeholder={t("COMMON.FORM.SELECT_PLACEHOLDER")}
                      size="large"
                      options={boilerOptions}
                      className={styles.realEstateSpecInput}
                    />
                  </GrapeAntdForm.Item>
                </Col>
              )}
            </Row>
            {!isNewlyBuilt && (
              <GrapeAntdForm.Item
                label={t("ENGINEERS.ENGINEER_WIZARD.STEP_3.HEAT_EMITTER_LABEL")}
                name="heatEmitterOptions"
                className={styles.checkBox}
                rules={[requiredField()]}
              >
                <Checkbox.Group options={heatEmitterOptions} />
              </GrapeAntdForm.Item>
            )}

            <Row gutter={28}>
              <Col xs={24} md={8}>
                <GrapeAntdForm.Item<CreateDesignOrderCommand>
                  label={t("REAL_ESTATE.ZIP_CODE")}
                  name="zipCode"
                  rules={[zipCodeRule()]}
                >
                  <CustomInput
                    placeholder={t("COMMON.FORM.INPUT_PLACEHOLDER")}
                  />
                </GrapeAntdForm.Item>
              </Col>

              <Col xs={24} md={14}>
                <GrapeAntdForm.Item<CreateDesignOrderCommand>
                  label={t("REAL_ESTATE.CITY")}
                  name="city"
                >
                  <CustomInput
                    placeholder={t("COMMON.FORM.INPUT_PLACEHOLDER")}
                  />
                </GrapeAntdForm.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={22}>
                <GrapeAntdForm.Item<CreateDesignOrderCommand>
                  label={t("REAL_ESTATE.ADDRESS")}
                  name="address"
                >
                  <CustomInput
                    placeholder={t("COMMON.FORM.INPUT_PLACEHOLDER")}
                  />
                </GrapeAntdForm.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} md={22}>
                <GrapeAntdForm.Item
                  name="files"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload.Dragger
                    name="files"
                    multiple={true}
                    accept={acceptedExtensions}
                    className={styles.dragger}
                    beforeUpload={() => false}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {t("ENGINEERS.ENGINEER_WIZARD.STEP_3.UPLOAD_TITLE")}
                    </p>
                    <p className="ant-upload-hint">
                      {t("ENGINEERS.ENGINEER_WIZARD.STEP_3.CARD_HINT")}
                    </p>
                    <p className="ant-upload-hint">
                      {t("ENGINEERS.ENGINEER_WIZARD.STEP_3.CARD_FORMATS")}
                    </p>
                  </Upload.Dragger>
                </GrapeAntdForm.Item>
              </Col>
            </Row>
          </div>
        </GrapeAntdForm>
      </Card>

      <StepNavigator
        firstButton={
          <CustomButton onClick={handleBackButton} className={styles.button}>
            {t("COMMON.BACK")}
          </CustomButton>
        }
        secondButton={
          <CustomButton
            type="primary"
            onClick={() => formUtils.form.submit()}
            className={styles.button}
          >
            {t("COMMON.NEXT")}
          </CustomButton>
        }
      />
    </>
  );
};

export default Step3;
