/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateResetPasswordCommand
 */
export interface ValidateResetPasswordCommand {
    /**
     * 
     * @type {string}
     * @memberof ValidateResetPasswordCommand
     */
    token: string;
}

export function ValidateResetPasswordCommandFromJSON(json: any): ValidateResetPasswordCommand {
    return ValidateResetPasswordCommandFromJSONTyped(json, false);
}

export function ValidateResetPasswordCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateResetPasswordCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function ValidateResetPasswordCommandToJSON(value?: ValidateResetPasswordCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
    };
}


