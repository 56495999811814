import { createModel } from "@rematch/core";
import { api } from "api";
import {
  DeleteDesignFileRequest,
  ManageOptiComfortPlanCommand,
  UpdateDesignOrderCommand,
  UploadDesignFileRequest,
  AcceptDesignOrderRequest,
  GetProjectByCodeRequest,
  MaterialDto,
  ProjectDto,
  ProjectListDto,
  RefuseDesignOrderRequest,
  UpdateDesignOrderMaterialsCommand,
  WildcardSearchType,
  DesignMaterialDto,
  DesignOrderStatus,
  BuildOrderStatus,
} from "api/generated/optimum";
import { RootModel } from "app/store";
import { downloadBlobAsCsv } from "utils/fileReader";
interface ProjectsState {
  projects: ProjectListDto[];
  currentProject?: ProjectDto;
  materialList: DesignMaterialDto[];
  availableMaterials: MaterialDto[];
  ngbsUpdatedOrder: boolean;
  isMaterialUpdated: boolean;
}

const initialState: ProjectsState = {
  projects: [],
  currentProject: undefined,
  materialList: [],
  availableMaterials: [],
  isMaterialUpdated: false,
  ngbsUpdatedOrder: false,
};

export const projects = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setProjectsList(state, projects: ProjectListDto[]) {
      state.projects = projects;
    },
    setCurrentProject(state, project: ProjectDto) {
      state.currentProject = project;
    },
    setMaterialList(
      state,
      {
        materialList,
        isMaterialUpdated,
      }: { materialList: DesignMaterialDto[]; isMaterialUpdated: boolean }
    ) {
      state.materialList = materialList;
      state.isMaterialUpdated = isMaterialUpdated;
    },
    setAvailableMaterials(state, availableMaterials: MaterialDto[]) {
      state.availableMaterials = availableMaterials;
    },
    setNgbsUpdatedorder(state, isUpdated: boolean) {
      state.ngbsUpdatedOrder = isUpdated;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    return {
      getProjects: async ({
        wildcardSearchTerm,
        wildcardSearchTypes,
        projectStatuses,
        designOrderStatuses,
        buildOrderStatuses,
        builderId,
        engineerId,
      }: {
        wildcardSearchTerm?: string;
        wildcardSearchTypes?: WildcardSearchType[];
        projectStatuses?: string[];
        designOrderStatuses?: DesignOrderStatus[];
        buildOrderStatuses?: BuildOrderStatus[];
        builderId?: string;
        engineerId?: string;
      }) => {
        const projectsList = await api.projects.searchProjects({
          wildcardSearchTerm: wildcardSearchTerm || undefined,
          ...(wildcardSearchTerm ? { wildcardSearchTypes } : {}),
          projectStatuses,
          buildOrderStatuses,
          designOrderStatuses,
          builderId,
          engineerId,
        });

        dispatch.projects.setProjectsList(projectsList as ProjectListDto[]);
      },

      getProject: async (requests: GetProjectByCodeRequest) => {
        const project = await api.projects.getProjectByCode(requests);

        dispatch.projects.setCurrentProject(project);
      },
      uploadDesignFile: async (file: UploadDesignFileRequest) => {
        await api.designOrders.uploadDesignFile(file);
      },
      removeDesignFile: async (deleteDesign: DeleteDesignFileRequest) => {
        await api.designOrders.deleteDesignFile(deleteDesign);
      },
      manageOptiComfortPlan: async (params: ManageOptiComfortPlanCommand) => {
        await api.designOrders.manageOptiComfortPlan({
          manageOptiComfortPlanCommand: params,
        });
      },
      updateDesign: async (params: UpdateDesignOrderCommand) => {
        await api.designOrders.updateDesignOrder({
          updateDesignOrderCommand: params,
        });
      },
      updateMaterials: async (params: UpdateDesignOrderMaterialsCommand) => {
        await api.designOrders.updateDesignOrderMaterial({
          updateDesignOrderMaterialsCommand: params,
        });
      },
      finalizeDesignOrder: async (id: number) => {
        await api.designOrders.completeDesignOrder({
          completeDesignOrderCommand: {
            id,
          },
        });
      },
      exportMaterial: async (id: number) => {
        const file = await api.designOrders.exportDesignOrderMaterials({
          id,
        });

        downloadBlobAsCsv(file);
      },

      getMaterials: async (id: number) => {
        const materialList = await api.designOrders.getDesignOrderMaterials({
          id,
        });

        dispatch.projects.setMaterialList({
          materialList,
          isMaterialUpdated: false,
        });
      },
      getAvailableMaterials: async () => {
        const materialList = await api.materials.getMaterials();

        dispatch.projects.setAvailableMaterials(materialList);
      },
      acceptProject: async (request: AcceptDesignOrderRequest) => {
        await api.designOrders.acceptDesignOrder(request);
      },
      refuseProject: async (request: RefuseDesignOrderRequest) => {
        await api.designOrders.refuseDesignOrder(request);
      },
    };
  },
});
