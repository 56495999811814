import { Col, Row } from "antd";
import { ProviderDto, ProviderType } from "api/generated/optimum";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import CustomButton from "components/button/CustomButton";
import Card from "components/card/Card";
import CustomModal from "components/customModal/CustomModal";
import ProviderAvatar from "components/providerAvatar/ProviderAvatar";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import numeral from "numeral";
import { useTranslation } from "react-i18next";
import enumTranslate from "utils/enumTranslate";
import { phoneNumberDisplayFormat } from "utils/format";
import styles from "./ProviderDetailsModal.module.scss";

interface ProviderDetailsModalProps {
  isVisible: boolean;
  onClose: () => void;
  provider?: ProviderDto;
}

const ProviderDetailsModal: React.FC<ProviderDetailsModalProps> = ({
  isVisible,
  onClose,
  provider,
}) => {
  const { t } = useTranslation();

  const profession =
    provider?.builderProfession ?? provider?.engineerProfession;

  return (
    <CustomModal
      width={"80rem"}
      visible={isVisible}
      className={styles.modal}
      closable={false}
    >
      <Card className={styles.userDetails}>
        <Row gutter={[32, 32]}>
          <Col>
            <ProviderAvatar url={provider?.profilePictureUrl} />
          </Col>
          <Col xs={16}>
            <CustomParagraph color="grey" size="lg" strong>
              {`${provider?.lastName} ${provider?.firstName}`}
            </CustomParagraph>

            <Row gutter={32}>
              <Col xs={8}>
                <CustomParagraph className={styles.dataTitleLabel}>
                  {t("PROFILE.WORK")}
                </CustomParagraph>
              </Col>
              <Col xs={16}>
                <CustomParagraph color="grey" className={styles.dataLabel}>
                  {provider?.position || ""}
                </CustomParagraph>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col xs={8}>
                <CustomParagraph className={styles.dataTitleLabel}>
                  {t("PROFILE.COMPANY")}
                </CustomParagraph>
              </Col>
              <Col xs={16}>
                <CustomParagraph color="grey" className={styles.dataLabel}>
                  {provider?.companyName || ""}
                </CustomParagraph>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col xs={8}>
                <CustomParagraph className={styles.dataTitleLabel}>
                  {t("PROFILE.PHONENUMBER")}
                </CustomParagraph>
              </Col>
              <Col xs={16}>
                <CustomParagraph color="grey" className={styles.dataLabel}>
                  {(provider?.phoneNumber &&
                    phoneNumberDisplayFormat(provider?.phoneNumber)) ||
                    ""}
                </CustomParagraph>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col xs={8}>
                <CustomParagraph className={styles.dataTitleLabel}>
                  {t("PROFILE.EMAIL")}
                </CustomParagraph>
              </Col>
              <Col xs={16}>
                <CustomParagraph color="grey" className={styles.dataLabel}>
                  {provider?.emailAddress || ""}
                </CustomParagraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      {provider?.type === ProviderType.Engineer && (
        <Card className={styles.card}>
          <CustomParagraph
            color="grey"
            size="sm"
            className={styles.dataLabel}
            strong
          >
            {t("PROFILE.DESIGNERPLAN_PRICE")}
          </CustomParagraph>

          <CustomParagraph color="grey" className={styles.dataLabel}>
            {t("PROFILE.FULLPRICE", {
              minPriceQuote: String(
                numeral(provider?.minPriceQuote)
                  .format("0,0")
                  .replaceAll(",", ".")
              ),
              maxPriceQuote: String(
                numeral(provider?.maxPriceQuote)
                  .format("0,0")
                  .replaceAll(",", ".")
              ),
            })}
          </CustomParagraph>
        </Card>
      )}

      <Card className={styles.card}>
        <CustomParagraph
          color="grey"
          size="sm"
          className={styles.dataLabel}
          strong
        >
          {provider?.type === ProviderType.Engineer
            ? t("PROFILE.SPECIALIZATION")
            : t("PROFILE.BUILDER_SPECIALIZATION")}
        </CustomParagraph>

        <CustomParagraph color="grey" className={styles.dataLabel}>
          {provider?.specializations?.map((x) => x.name).join(",") || ""}
        </CustomParagraph>
      </Card>
      {provider?.type === ProviderType.Engineer && (
        <Card className={styles.card}>
          <CustomParagraph
            color="grey"
            size="sm"
            className={styles.dataLabel}
            strong
          >
            {t("PROFILE.EDUCATION")}
          </CustomParagraph>

          <CustomParagraph color="grey" className={styles.dataLabel}>
            {profession && enumTranslate(profession, EnumContext.PROFESSION)}
          </CustomParagraph>
        </Card>
      )}

      <Card className={styles.card}>
        <CustomParagraph
          color="grey"
          size="sm"
          className={styles.dataLabel}
          strong
        >
          {t("PROFILE.INTORDUCTION")}
        </CustomParagraph>

        <CustomParagraph color="grey" className={styles.introductionLabel}>
          {provider?.introduction || ""}
        </CustomParagraph>
      </Card>

      <Row justify="center">
        <CustomButton
          type="primary"
          onClick={onClose}
          className={styles.closeButton}
          variant="default"
        >
          {t("COMMON.CLOSE")}
        </CustomButton>
      </Row>
    </CustomModal>
  );
};

export default ProviderDetailsModal;
