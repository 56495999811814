import { Grid } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";

type BreakpointParams = {
  min?: Breakpoint;
  max?: Breakpoint;
};

export const useGetBreakpoint = (): string => {
  const screens = Grid.useBreakpoint();
  const size = Object.entries(screens)
    .filter(([, value]) => value)
    .map(([size]) => size)
    .reverse()
    .concat(["xs"]);

  return size[0];
};

export const useBreakpoint = (params?: BreakpointParams): boolean => {
  const { min, max } = params || { max: "xs" };
  const screens = Grid.useBreakpoint();
  const size = Object.entries(screens)
    .filter(([, value]) => value)
    .map(([size]) => size)
    .reverse()
    .concat(["xs"]);

  if (min) {
    return size.includes(min);
  } else if (max) {
    return !size.includes(max) || size[0] === max;
  }

  return false;
};
