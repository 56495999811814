import CustomModal from "components/customModal/CustomModal";
import { GrapeAntdForm, useGrapeAntdForm } from "components/form";
import GrapeAntdFormItem from "components/form/components/GrapeAntdFormItem";
import { CustomTextArea } from "components/form/components/CustomInput";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useDispatch, useSelector } from "app/store";
import { AlertModalType } from "components/alertModal/AlertModal.store";
import { OptiComfortPlanActions } from "api/generated/optimum";
import { Col, Row } from "antd";
import CustomTitle from "components/typography/title/CustomTitle";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomButton from "components/button/CustomButton";
import styles from "./SendDemandModal.module.scss";

interface SendDemandModalProps {
  isVisible: boolean;
  closeModal: () => void;
}

const SendDemandModal: React.FC<SendDemandModalProps> = ({
  isVisible,
  closeModal,
}) => {
  const formUtils = useGrapeAntdForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentProject } = useSelector((state) => state.projects);
  const { loading } = useSelector(
    (state) => state.loading.effects.projects.manageOptiComfortPlan
  );

  const designDemandHandler = useCallback(
    async (values: any) => {
      if (currentProject?.designOrderId) {
        try {
          await dispatch.projects.manageOptiComfortPlan({
            designOrderId: currentProject?.designOrderId,
            note: values.note,
            action: OptiComfortPlanActions.Start,
          });
          closeModal();

          await dispatch.alertModal.openModal({
            title: t("PROJECTS.DETAIL.DESIGN.MODAL.SUCCESS_TITLE"),
            cardTitle: t("PROJECTS.DETAIL.DESIGN.MODAL.TITLE"),
            descriptin: t("PROJECTS.DETAIL.DESIGN.MODAL.DESCRIPTION_SUCCESS"),
            buttonLabel: t("COMMON.ALRIGHT"),
            width: "59rem",
            type: AlertModalType.Success,
          });

          currentProject?.projectCode &&
            dispatch.projects.getProject({
              projectCode: currentProject?.projectCode,
            });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [
      t,
      dispatch.alertModal,
      dispatch.projects,
      closeModal,
      currentProject?.designOrderId,
      currentProject?.projectCode,
    ]
  );

  return (
    <CustomModal
      closable={false}
      width={"58rem"}
      visible={isVisible}
      className={styles.modal}
    >
      <Row justify="center">
        <CustomTitle
          level={3}
          color="darkBlue"
          extraStrong
          className={styles.modalTitle}
        >
          {t("PROJECTS.DETAIL.DESIGN.MODAL.TITLE")}
        </CustomTitle>
      </Row>
      <div className={styles.modalDescription}>
        <CustomParagraph color="black">
          {t("PROJECTS.DETAIL.DESIGN.MODAL.DESC")}
        </CustomParagraph>
        <CustomParagraph color="black">
          {t("PROJECTS.DETAIL.DESIGN.MODAL.COMMENT")}
        </CustomParagraph>
      </div>
      <GrapeAntdForm onFinish={designDemandHandler} formUtils={formUtils}>
        <GrapeAntdFormItem name="note">
          <CustomTextArea
            className={styles.textArea}
            placeholder={t("COMMON.COMMENT")}
          />
        </GrapeAntdFormItem>
      </GrapeAntdForm>

      <Row justify="center">
        <Col>
          <CustomButton
            variant="default"
            className={styles.cancelBtn}
            onClick={closeModal}
            size="large"
          >
            {t("COMMON.CANCEL")}
          </CustomButton>
        </Col>
        <Col>
          <CustomButton
            variant="default"
            type="primary"
            onClick={() => formUtils.form.submit()}
            size="large"
            loading={loading}
          >
            {t("PROJECTS.DETAIL.DESIGN.MODAL.SEND_DEMAND")}
          </CustomButton>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default SendDemandModal;
