import { FC } from "react";
import { useRoutes } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Engineer from "../features/engineer/Engineer";
import BuilderPage from "../features/builder/BuilderPage";
import NotFound from "../features/notFound/NotFound";
import ProjectsList from "features/projects/list/ProjectsList";
import PrivateRoute from "./PrivateRoute";
import { ScrollToTop } from "components/ScrollToTop";
import AcceptOrder from "features/projects/details/design/acceptOrder/AcceptOrder";
import MyProfile from "features/provider/MyProfile";
import AdminProjectList from "features/admin/projects/AdminProjectList";
import AdminProviderList from "features/admin/providers/AdminProviderList";
import AdminProviderProfile from "features/admin/providers/components/adminProviderProfile/AdminProviderProfile";
import HomePage from "features/home/HomePage";
import { Role } from "api/generated/optimum";
import ProjectDetail from "features/projects/details/ProjectDetail";
import Reports from "features/admin/reports/Reports";
import BuildNotAvailable from "features/projects/details/build/buildNotAvailable/BuildNotAvailable";

const Router: FC = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "", element: <HomePage /> },
        { path: "welcome-provider", element: <Engineer /> },
        {
          path: "/forgot-password/email-validation/reset-password",
          element: <Engineer />,
        },
        {
          path: "admin",
          children: [
            {
              path: "projects",
              children: [
                {
                  path: "",
                  element: (
                    <PrivateRoute roles={[Role.Admin]}>
                      <AdminProjectList />
                    </PrivateRoute>
                  ),
                },
                {
                  path: ":projectCode",
                  element: (
                    <PrivateRoute shouldStayOnRoute>
                      <ProjectDetail />
                    </PrivateRoute>
                  ),
                },
              ],
            },
            {
              path: "providers",
              children: [
                {
                  path: "",
                  element: (
                    <PrivateRoute roles={[Role.Admin]}>
                      <AdminProviderList />
                    </PrivateRoute>
                  ),
                },
                {
                  path: ":id",
                  element: (
                    <PrivateRoute roles={[Role.Admin]} shouldStayOnRoute>
                      <AdminProviderProfile />
                    </PrivateRoute>
                  ),
                },
              ],
            },
            {
              path: "reports",
              element: (
                <PrivateRoute roles={[Role.Admin]} shouldStayOnRoute>
                  <Reports />
                </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: "projects",
          children: [
            {
              path: "",
              element: (
                <PrivateRoute>
                  <ProjectsList />
                </PrivateRoute>
              ),
            },
            {
              path: ":projectCode/design",
              element: (
                <PrivateRoute shouldStayOnRoute>
                  <AcceptOrder />
                </PrivateRoute>
              ),
            },
            {
              path: ":projectCode",
              element: (
                <PrivateRoute shouldStayOnRoute>
                  <ProjectDetail />
                </PrivateRoute>
              ),
            },
            {
              path: "refused",
              element: <BuildNotAvailable />,
            },
          ],
        },
        { path: "builder", element: <BuilderPage /> },
        {
          path: "my-profile",
          element: (
            <PrivateRoute>
              <MyProfile />
            </PrivateRoute>
          ),
        },
        // {
        //   path: "/forgot-password/email-validation/reset-password",
        //   element: <UserProfile />,
        // },
        {
          path: "design",
          element: <Engineer />,
          children: [
            { path: "real-estate", element: <Engineer /> },
            {
              path: "new",
              element: <Engineer />,
              children: [
                {
                  path: "details",
                  element: <Engineer />,
                },
                {
                  path: "contact",
                  element: <Engineer />,
                },
                {
                  path: "confirmation",
                  element: <Engineer />,
                },
                {
                  path: "confirmed",
                  element: <Engineer />,
                },
              ],
            },
            {
              path: "renewal",
              element: <Engineer />,
              children: [
                {
                  path: "details",
                  element: <Engineer />,
                },
                {
                  path: "contact",
                  element: <Engineer />,
                },
                {
                  path: "confirmation",
                  element: <Engineer />,
                },
                {
                  path: "confirmed",
                  element: <Engineer />,
                },
              ],
            },
          ],
        },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  return <ScrollToTop>{routes}</ScrollToTop>;
};

export default Router;
