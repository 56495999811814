export const ASZF_URL =
  "https://opticonnect.mvm-optimum.hu/altalanos-szerzodesi-feltetelek/";
export const PRIVACY_URL =
  "https://opticonnect.mvm-optimum.hu/adatkezelesi-tajekoztato/";

export const KNOWLEDGE_BASE_URL =
  "https://mvmoptimumzrt.zendesk.com/hc/hu/categories/10720280931858-Opti-Comfort-f%C5%B1t%C3%A9s-kisokos";

export const BUILDER_SEARCH_URL =
  "https://opticonnect.mvm-optimum.hu/kivitelezok-keresese/";
