import { Image } from "antd";
import { FC } from "react";
import { ImageProps } from "rc-image";
import { StopOutlined } from "@ant-design/icons";
import noProfilePicture from "../../assets/images/noprofile.png";
import styles from "./ProviderAvatar.module.scss";
import clsx from "clsx";

export interface ProviderAvatarProps extends ImageProps {
  className?: string;
  url?: string | null;
  size?: number | string;
  preview?: boolean;
  isActive?: boolean;
}
const ProviderAvatar: FC<ProviderAvatarProps> = ({
  url,
  size = "18rem",
  preview = false,
  className,
  isActive = true,
  ...rest
}) => {
  return (
    <>
      <div
        className={clsx(styles.imgContainer, className)}
        style={{
          width: size,
        }}
      >
        <Image
          {...rest}
          width={size}
          height={size}
          src={url || noProfilePicture}
          className={clsx(className, styles.avatar)}
          preview={preview}
        />
        {!isActive && (
          <>
            <div className={styles.inActiveOverlay} />
            <div className={styles.inActiveLogo}>
              <StopOutlined className={styles.icon} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProviderAvatar;
