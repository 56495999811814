import { Col, Divider, Row, Upload } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Card from "components/card/Card";
import { GrapeAntdForm, useGrapeAntdForm } from "components/form";
import CustomTitle from "components/typography/title/CustomTitle";
import styles from "./Step3.module.scss";
import CustomButton from "components/button/CustomButton";
import { InboxOutlined } from "@ant-design/icons";
import StepNavigator from "components/stepNavigator/StepNavigator";
import { useDispatch, useSelector } from "app/store";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { CustomTextArea } from "components/form/components/CustomInput";
import { PreferredStart } from "api/generated/optimum";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import enumTranslate from "utils/enumTranslate";
import { CreateBuildOrder } from "models/CreateBuildOrder";
import useFileInput, { FileExtensions } from "hooks/useFileInput";
import { CustomSelect } from "components/form/components/CustomSelect";

const Step3: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formUtils = useGrapeAntdForm<CreateBuildOrder>(
    "CreateBuildOrderCommand"
  );
  const { projectCode, wizardData, address, currentStep } = useSelector(
    (state) => state.builder
  );

  const preferredStartPhaseOptions = Object.values(PreferredStart)
    .filter((option) => option !== PreferredStart.Invalid)
    .map((value, i) => {
      return {
        value: value,
        label: enumTranslate(value, EnumContext.PREFERREDSTART),
      };
    });

  const handleBackButton = () => {
    dispatch.builder.setWizardData(formUtils.form.getFieldsValue());
    dispatch.builder.prevStep();
  };

  const handleFinish = (values: any) => {
    dispatch.builder.setWizardData(values);

    dispatch.builder.nextStep();
  };

  const { acceptedExtensions, normFile } = useFileInput({
    validExtensions: [
      FileExtensions.JPG,
      FileExtensions.JPEG,
      FileExtensions.PDF,
      FileExtensions.PNG,
    ],
  });

  return (
    <>
      <Card className={styles.cardContainer}>
        <CustomTitle
          level={3}
          align="center"
          color="darkBlue"
          className={styles.title}
        >
          {t("BUILDERS.BUILDERS_WIZARD.STEP_3.CARD_TITLE")}
        </CustomTitle>
        <Row gutter={8}>
          <Col md={24} lg={10}>
            <CustomParagraph color="lightBlue" className={styles.detailsTitle}>
              {t("BUILDERS.BUILDERS_WIZARD.STEP_3.DESIGN_TITLE")}
            </CustomParagraph>
          </Col>
          <Col>
            <CustomParagraph className={styles.details}>
              {projectCode}
            </CustomParagraph>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col md={24} lg={9}>
            <CustomParagraph color="lightBlue" className={styles.detailsTitle}>
              {t("BUILDERS.BUILDERS_WIZARD.STEP_3.PLACE_TITLE")}
            </CustomParagraph>
          </Col>
          <Col>
            <CustomParagraph className={styles.details}>
              {address}
            </CustomParagraph>
          </Col>
        </Row>

        <Divider className={styles.divider} />
        <GrapeAntdForm
          formUtils={formUtils}
          requiredMark={false}
          onFinish={handleFinish}
          initialValues={wizardData[currentStep]}
        >
          <GrapeAntdForm.Item<CreateBuildOrder>
            label={t("BUILDERS.BUILDERS_WIZARD.STEP_3.NOTE_TITLE")}
            name="taskDescription"
          >
            <CustomTextArea
              className={styles.textArea}
              placeholder={t(
                "BUILDERS.BUILDERS_WIZARD.STEP_3.NOTE_PALCEHOLDER"
              )}
            />
          </GrapeAntdForm.Item>

          <GrapeAntdForm.Item<CreateBuildOrder>
            label={t("BUILDERS.BUILDERS_WIZARD.STEP_3.START_TITLE")}
            name="preferredStart"
          >
            <CustomSelect
              placeholder={t("COMMON.FORM.SELECT_PLACEHOLDER")}
              className={styles.select}
              options={preferredStartPhaseOptions}
            />
          </GrapeAntdForm.Item>

          <Row className={styles.fileUploadContainer}>
            <Col xs={24} md={22}>
              <GrapeAntdForm.Item
                name="files"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload.Dragger
                  name="files"
                  multiple={true}
                  beforeUpload={() => false}
                  accept={acceptedExtensions}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {t("BUILDERS.BUILDERS_WIZARD.STEP_3.UPLOAD_TITLE")}
                  </p>
                  <p className="ant-upload-hint">
                    {t("BUILDERS.BUILDERS_WIZARD.STEP_3.CARD_HINT")}
                  </p>
                  <p className="ant-upload-hint">
                    {t("BUILDERS.BUILDERS_WIZARD.STEP_3.CARD_FORMATS")}
                  </p>
                </Upload.Dragger>
              </GrapeAntdForm.Item>
            </Col>
          </Row>
        </GrapeAntdForm>
      </Card>

      <StepNavigator
        firstButton={
          <CustomButton
            onClick={handleBackButton}
            className={styles.navigatorButton}
          >
            {t("COMMON.BACK")}
          </CustomButton>
        }
        secondButton={
          <CustomButton
            type="primary"
            block
            className={styles.navigatorButton}
            onClick={() => formUtils.form.submit()}
          >
            {t("COMMON.NEXT")}
          </CustomButton>
        }
      />
    </>
  );
};

export default Step3;
