import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Content from "components/layout/content/Content";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { MaterialIcon } from "components/GrapeAntdTable/components/MaterialIcon";
import { mdiChevronLeft } from "@mdi/js";
import GrapeAntdForm from "components/form/components/GrapeAntdForm";
import useGrapeAntdForm from "components/form/hooks/useGrapeAntdForm";
import Card from "components/card/Card";
import { Col, Row } from "antd";
import styles from "./Provider.module.scss";
import CustomButton from "components/button/CustomButton";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import Checkbox from "antd/lib/checkbox/Checkbox";
import CustomInput, {
  CustomTextArea,
} from "components/form/components/CustomInput";
import ProviderAvatar from "components/providerAvatar/ProviderAvatar";
import { useDispatch, useSelector } from "app/store";
import enumTranslate from "utils/enumTranslate";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import { phoneNumberDisplayFormat } from "utils/format";
import ConfirmModal from "components/confirmModal/ConfirmModal";
import numeral from "numeral";
import { ProviderDto, ProviderType } from "api/generated/optimum";
import { isEngineer } from "utils/authUtils";

const MyProfile: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, selectedProvider } = useSelector((state) => state.provider);
  const { id } = useParams();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState<ProviderDto>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

  const formUtils = useGrapeAntdForm();

  const profession =
    currentUser?.builderProfession ?? currentUser?.engineerProfession;

  const handleFormFinish = (values: any) => {
    if (values.isActive) {
      dispatch.provider.updateMyProfile({
        isActive: values.isActive,
      });
    } else if (values?.isActive === false) {
      setModalIsVisible(true);
    }
  };

  useEffect(() => {
    if (id) {
      dispatch.provider.getProvider(id);
    } else {
      dispatch.provider.getMyProfile();
    }

    setIsEditMode(location.pathname.includes("edit"));
  }, [dispatch.provider, id, location]);

  useEffect(() => {
    if (selectedProvider && id) {
      setCurrentUser(selectedProvider);
    }

    if (user) {
      setCurrentUser(user);
    }
  }, [user, selectedProvider, id]);

  useEffect(() => {
    if (user) {
      formUtils.form.setFieldsValue({ isActive: user.isActive });
    }
  }, [formUtils.form, user]);

  return (
    <Content title={t("PROFILE.TITLE")}>
      <NavLink to="/projects" className={styles.navigationLink}>
        <MaterialIcon path={mdiChevronLeft} />
        {t("PROFILE.BACK")}
      </NavLink>
      <GrapeAntdForm
        formUtils={formUtils}
        requiredMark={false}
        layout="horizontal"
        onFinish={handleFormFinish}
      >
        <Card className={styles.userDetails}>
          <Row gutter={[32, 32]}>
            <Col>
              <ProviderAvatar url={currentUser?.profilePictureUrl} />
            </Col>
            <Col>
              <CustomParagraph color="grey" size="lg" strong>
                {`${currentUser?.lastName} ${currentUser?.firstName}`}
              </CustomParagraph>
              <GrapeAntdForm.Item
                className={styles.formItemLabel}
                label={t("PROFILE.WORK")}
              >
                {isEditMode ? (
                  <CustomInput
                    placeholder={t("PROFILE.WORK_PLACEHOLDER")}
                    className={styles.customInputBackground}
                    value={currentUser?.position || ""}
                  />
                ) : (
                  <CustomParagraph color="grey" className={styles.dataLabel}>
                    {currentUser?.position || ""}
                  </CustomParagraph>
                )}
              </GrapeAntdForm.Item>

              <GrapeAntdForm.Item
                className={styles.formItemLabel}
                label={t("PROFILE.COMPANY")}
              >
                {isEditMode ? (
                  <CustomInput
                    placeholder={t("PROFILE.COMPANY_PLACEHOLDER")}
                    value={currentUser?.companyName || ""}
                    className={styles.customInputBackground}
                  />
                ) : (
                  <CustomParagraph color="grey" className={styles.dataLabel}>
                    {currentUser?.companyName || ""}
                  </CustomParagraph>
                )}
              </GrapeAntdForm.Item>
              <GrapeAntdForm.Item
                className={styles.formItemLabel}
                label={t("PROFILE.PHONENUMBER")}
              >
                {isEditMode ? (
                  <CustomInput
                    placeholder={t("PROFILE.PHONENUMBER_PLACEHOLDER")}
                    value={currentUser?.phoneNumber || ""}
                    className={styles.customInputBackground}
                  />
                ) : (
                  <CustomParagraph color="grey" className={styles.dataLabel}>
                    {(currentUser?.phoneNumber &&
                      phoneNumberDisplayFormat(currentUser?.phoneNumber)) ||
                      ""}
                  </CustomParagraph>
                )}
              </GrapeAntdForm.Item>
              <GrapeAntdForm.Item
                className={styles.formItemLabel}
                label={t("PROFILE.EMAIL")}
              >
                {isEditMode ? (
                  <CustomInput
                    placeholder={t("PROFILE.EMAIL_PLACEHOLDER")}
                    value={currentUser?.emailAddress || ""}
                    className={styles.customInputBackground}
                  />
                ) : (
                  <CustomParagraph color="grey" className={styles.dataLabel}>
                    {currentUser?.emailAddress || ""}
                  </CustomParagraph>
                )}
              </GrapeAntdForm.Item>
            </Col>
          </Row>
        </Card>
        {isEngineer() && (
          <Card className={styles.card}>
            <CustomParagraph
              color="grey"
              size="sm"
              className={styles.dataLabel}
              strong
            >
              {t("PROFILE.DESIGNERPLAN_PRICE")}
            </CustomParagraph>

            {isEditMode ? (
              <Row gutter={10} align="middle">
                <Col>
                  <CustomInput
                    placeholder={t("PROFILE.PRICE_FROM_PLACEHOLDER")}
                    value={currentUser?.minPriceQuote || ""}
                    className={styles.customInputBackground}
                  />
                </Col>
                <Col>{t("PROFILE.PRICE_FROM")}</Col>
                <Col>
                  <CustomInput
                    placeholder={t("PROFILE.PRICE_TO_PLACEHOLDER")}
                    value={currentUser?.maxPriceQuote || ""}
                    className={styles.customInputBackground}
                  />
                </Col>
                {t("PROFILE.PRICE_TO")}
                <Col />
              </Row>
            ) : (
              <CustomParagraph color="grey" className={styles.dataLabel}>
                {t("PROFILE.FULLPRICE", {
                  minPriceQuote: String(
                    numeral(currentUser?.minPriceQuote)
                      .format("0,0")
                      .replaceAll(",", ".")
                  ),
                  maxPriceQuote: String(
                    numeral(currentUser?.maxPriceQuote)
                      .format("0,0")
                      .replaceAll(",", ".")
                  ),
                })}
              </CustomParagraph>
            )}
          </Card>
        )}

        <Card className={styles.card}>
          <CustomParagraph
            color="grey"
            size="sm"
            className={styles.dataLabel}
            strong
          >
            {currentUser?.type === ProviderType.Engineer
              ? t("PROFILE.SPECIALIZATION")
              : t("PROFILE.BUILDER_SPECIALIZATION")}
          </CustomParagraph>
          {isEditMode ? (
            <CustomInput
              className={styles.input}
              placeholder={t("PROFILE.PLACEHOLDER")}
              value={
                currentUser?.specializations?.map((x) => x.name).join(", ") ||
                ""
              }
            />
          ) : (
            <CustomParagraph color="grey" className={styles.dataLabel}>
              {currentUser?.specializations?.map((x) => x.name).join(", ") ||
                ""}
            </CustomParagraph>
          )}
        </Card>
        {isEngineer() && (
          <Card className={styles.card}>
            <CustomParagraph
              color="grey"
              size="sm"
              className={styles.dataLabel}
              strong
            >
              {t("PROFILE.EDUCATION")}
            </CustomParagraph>
            {isEditMode ? (
              <CustomInput
                className={styles.customInputBackground}
                placeholder={t("PROFILE.EDUCATION_PLACEHOLDER")}
                value={
                  profession &&
                  enumTranslate(profession, EnumContext.PROFESSION)
                }
              />
            ) : (
              <CustomParagraph color="grey" className={styles.dataLabel}>
                {profession &&
                  enumTranslate(profession, EnumContext.PROFESSION)}
              </CustomParagraph>
            )}
          </Card>
        )}

        <Card className={styles.card}>
          <CustomParagraph
            color="grey"
            size="sm"
            className={styles.dataLabel}
            strong
          >
            {t("PROFILE.INTORDUCTION")}
          </CustomParagraph>
          {isEditMode ? (
            <CustomTextArea
              className={styles.input}
              placeholder={t("PROFILE.INTORDUCTION_PLACEHOLDER")}
              value={currentUser?.introduction || ""}
              autoSize
            />
          ) : (
            <CustomParagraph color="grey" className={styles.introductionLabel}>
              {currentUser?.introduction || ""}
            </CustomParagraph>
          )}
        </Card>
        <Card className={styles.card}>
          <CustomParagraph
            color="grey"
            size="sm"
            className={styles.dataLabel}
            strong
          >
            {t("PROFILE.CONTACT")}
          </CustomParagraph>
          <GrapeAntdForm.Item
            name="isActive"
            valuePropName="checked"
            rules={[{ required: true }]}
          >
            <Checkbox>{t("PROFILE.CHECKBOX")}</Checkbox>
          </GrapeAntdForm.Item>
        </Card>

        <Row justify="center">
          <GrapeAntdForm.Item>
            <CustomButton
              disabled={!formUtils.submittable}
              type="primary"
              htmlType="submit"
              size="large"
            >
              {t("PROFILE.SAVE")}
            </CustomButton>
          </GrapeAntdForm.Item>
        </Row>
      </GrapeAntdForm>
      <ConfirmModal
        onSubmit={() => {
          dispatch.provider.updateMyProfile({
            isActive: false,
          });

          setModalIsVisible(false);
        }}
        isVisible={modalIsVisible}
        onClose={() => setModalIsVisible(false)}
        title={t("PROFILE.MODAL_TITLE")}
        description={t("PROFILE.MODAL_DESCRIPTION")}
        subDescription={t("PROFILE.MODAL_DESCRIPTION_2")}
        submitBtnLabel={t("PROFILE.MODAL_OK")}
      />
    </Content>
  );
};

export default MyProfile;
