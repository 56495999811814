/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcceptBuildOrderCommand,
    AcceptBuildOrderCommandFromJSON,
    AcceptBuildOrderCommandToJSON,
    BuildFileType,
    BuildFileTypeFromJSON,
    BuildFileTypeToJSON,
    BuildServiceChargeSummaryDto,
    BuildServiceChargeSummaryDtoFromJSON,
    BuildServiceChargeSummaryDtoToJSON,
    CompleteBuildOrderCommand,
    CompleteBuildOrderCommandFromJSON,
    CompleteBuildOrderCommandToJSON,
    CreateBuildOrderCommand,
    CreateBuildOrderCommandFromJSON,
    CreateBuildOrderCommandToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RejectBuildOrderCommand,
    RejectBuildOrderCommandFromJSON,
    RejectBuildOrderCommandToJSON,
} from '../models';

export interface AcceptBuildOrderRequest {
    acceptBuildOrderCommand?: AcceptBuildOrderCommand;
}

export interface CompleteBuildOrderRequest {
    completeBuildOrderCommand?: CompleteBuildOrderCommand;
}

export interface CreateBuildOrderRequest {
    createBuildOrderCommand?: CreateBuildOrderCommand;
}

export interface GetBuildAutomationChargesRequest {
    buildOrderId: number;
}

export interface GetBuildPanelDeliveryChargesRequest {
    buildOrderId: number;
}

export interface GetPrefilledProposalTemplateRequest {
    buildOrderId: number;
}

export interface RejectBuildOrderRequest {
    rejectBuildOrderCommand?: RejectBuildOrderCommand;
}

export interface UploadBuildFileRequest {
    id: number;
    type?: BuildFileType;
    file?: Blob;
}

/**
 * 
 */
export class BuildOrdersApi extends runtime.BaseAPI {

    /**
     * Accept build order
     */
    async acceptBuildOrderRaw(requestParameters: AcceptBuildOrderRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/Accept`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptBuildOrderCommandToJSON(requestParameters.acceptBuildOrderCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept build order
     */
    async acceptBuildOrder(requestParameters: AcceptBuildOrderRequest): Promise<void> {
        await this.acceptBuildOrderRaw(requestParameters);
    }

    /**
     * Complete the build order
     */
    async completeBuildOrderRaw(requestParameters: CompleteBuildOrderRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/Complete`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteBuildOrderCommandToJSON(requestParameters.completeBuildOrderCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Complete the build order
     */
    async completeBuildOrder(requestParameters: CompleteBuildOrderRequest): Promise<void> {
        await this.completeBuildOrderRaw(requestParameters);
    }

    /**
     * [Anonymous] Creates new build order
     */
    async createBuildOrderRaw(requestParameters: CreateBuildOrderRequest): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBuildOrderCommandToJSON(requestParameters.createBuildOrderCommand),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * [Anonymous] Creates new build order
     */
    async createBuildOrder(requestParameters: CreateBuildOrderRequest): Promise<Array<number>> {
        const response = await this.createBuildOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the build order\'s automation charges
     */
    async getBuildAutomationChargesRaw(requestParameters: GetBuildAutomationChargesRequest): Promise<runtime.ApiResponse<BuildServiceChargeSummaryDto>> {
        if (requestParameters.buildOrderId === null || requestParameters.buildOrderId === undefined) {
            throw new runtime.RequiredError('buildOrderId','Required parameter requestParameters.buildOrderId was null or undefined when calling getBuildAutomationCharges.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/{buildOrderId}/Automation`.replace(`{${"buildOrderId"}}`, encodeURIComponent(String(requestParameters.buildOrderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildServiceChargeSummaryDtoFromJSON(jsonValue));
    }

    /**
     * Returns the build order\'s automation charges
     */
    async getBuildAutomationCharges(requestParameters: GetBuildAutomationChargesRequest): Promise<BuildServiceChargeSummaryDto> {
        const response = await this.getBuildAutomationChargesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the build order\'s panel delivery charges
     */
    async getBuildPanelDeliveryChargesRaw(requestParameters: GetBuildPanelDeliveryChargesRequest): Promise<runtime.ApiResponse<BuildServiceChargeSummaryDto>> {
        if (requestParameters.buildOrderId === null || requestParameters.buildOrderId === undefined) {
            throw new runtime.RequiredError('buildOrderId','Required parameter requestParameters.buildOrderId was null or undefined when calling getBuildPanelDeliveryCharges.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/{buildOrderId}/PanelDelivery`.replace(`{${"buildOrderId"}}`, encodeURIComponent(String(requestParameters.buildOrderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildServiceChargeSummaryDtoFromJSON(jsonValue));
    }

    /**
     * Returns the build order\'s panel delivery charges
     */
    async getBuildPanelDeliveryCharges(requestParameters: GetBuildPanelDeliveryChargesRequest): Promise<BuildServiceChargeSummaryDto> {
        const response = await this.getBuildPanelDeliveryChargesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns prefilled proposal template
     */
    async getPrefilledProposalTemplateRaw(requestParameters: GetPrefilledProposalTemplateRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.buildOrderId === null || requestParameters.buildOrderId === undefined) {
            throw new runtime.RequiredError('buildOrderId','Required parameter requestParameters.buildOrderId was null or undefined when calling getPrefilledProposalTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/{buildOrderId}/PrefilledProposalTemplate`.replace(`{${"buildOrderId"}}`, encodeURIComponent(String(requestParameters.buildOrderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Returns prefilled proposal template
     */
    async getPrefilledProposalTemplate(requestParameters: GetPrefilledProposalTemplateRequest): Promise<Blob> {
        const response = await this.getPrefilledProposalTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Reject build order
     */
    async rejectBuildOrderRaw(requestParameters: RejectBuildOrderRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/Reject`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RejectBuildOrderCommandToJSON(requestParameters.rejectBuildOrderCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject build order
     */
    async rejectBuildOrder(requestParameters: RejectBuildOrderRequest): Promise<void> {
        await this.rejectBuildOrderRaw(requestParameters);
    }

    /**
     * Upload build order files
     */
    async uploadBuildFileRaw(requestParameters: UploadBuildFileRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadBuildFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.type !== undefined) {
            formParams.append('type', requestParameters.type as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/BuildOrders/{id}/BuildFiles`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Upload build order files
     */
    async uploadBuildFile(requestParameters: UploadBuildFileRequest): Promise<string> {
        const response = await this.uploadBuildFileRaw(requestParameters);
        return await response.value();
    }

}
