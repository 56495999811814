// returns true if all elements of an array are contained in an other
export const arrayElementsContainedInAnother = (a: any, b: any): boolean => {
  if (
    !Array.isArray(a) ||
    !Array.isArray(b) ||
    (a.length === 0 && b.length === 0)
  )
    return false;

  return a.every((val) => b.includes(val));
};

// returns true if some elements of an array are contained in an another
export const someArrayElementsContainedInAnother = (
  a: any,
  b: any
): boolean => {
  if (
    !Array.isArray(a) ||
    !Array.isArray(b) ||
    (a.length === 0 && b.length === 0)
  )
    return false;

  return a.some((val) => b.includes(val));
};

export const compareStrings = (a?: string | null, b?: string | null) => {
  if (a == null || a === "") return 1;
  if (b == null || b === "") return -1;

  return a.localeCompare(b);
};
