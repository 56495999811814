import { useDispatch, useSelector } from "app/store";
import CustomButton from "components/button/CustomButton";
import Card from "components/card/Card";
import CustomTitle from "components/typography/title/CustomTitle";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Step2.module.scss";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { ReactComponent as HouseComponent } from "assets/images/House.svg";
import { ReactComponent as RenovationHouseComponent } from "assets/images/RenovationPlan.svg";
import clsx from "clsx";
import { RealEstateType } from "models/RealEstateType";
import ProviderAvatar from "components/providerAvatar/ProviderAvatar";
import StepNavigator from "components/stepNavigator/StepNavigator";
import enumTranslate from "utils/enumTranslate";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import { useNavigate } from "react-router-dom";
import useEngineerContactWizard from "../../useEngineerContactWizard";

const Step2: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { nextRouteForNew, nextRouteForRenewal } = useEngineerContactWizard();

  const { selectedEngineer, realEstateType, wizardData } = useSelector(
    (state) => state.engineer
  );

  const handleChangeRealEstateType = (value: RealEstateType) => {
    if (value !== realEstateType) {
      const newData = {
        ...wizardData[1],
        insulationOptions: undefined,
      };
      dispatch.engineer.setWizardData(newData, 1);
    }

    dispatch.engineer.setRealEstateType(value);

    if (value === RealEstateType.AwaitingRenovation && nextRouteForRenewal) {
      navigate(nextRouteForRenewal);
    }

    if (value === RealEstateType.NewlyBuilt && nextRouteForNew) {
      navigate(nextRouteForNew);
    }
  };

  return (
    <>
      <Card className={styles.cardContainer}>
        <CustomTitle
          level={3}
          responsiveLevel={{ mobile: 3, tablet: 5 }}
          align="center"
          color="lightBlue"
          extraStrong
        >
          {t("ENGINEERS.ENGINEER_WIZARD.STEP_2.CARD_TITLE")}
        </CustomTitle>

        <div className={styles.selectedEngineer}>
          <ProviderAvatar size={60} url={selectedEngineer?.profilePictureUrl} />
          <div className={styles.selectedEngineerData}>
            <CustomTitle extraStrong level={5}>
              {`${selectedEngineer?.lastName} ${selectedEngineer?.firstName}`}
            </CustomTitle>

            <CustomParagraph size="xs" strong>
              {enumTranslate(
                selectedEngineer?.profession || "",
                EnumContext.PROFESSION
              )}
            </CustomParagraph>
            <CustomParagraph size="xs" strong>
              {selectedEngineer?.companyName}
            </CustomParagraph>
          </div>
        </div>

        <div className={styles.content}>
          <CustomTitle
            level={3}
            align="center"
            color="darkBlue"
            extraStrong
            className={styles.selectionTitle}
          >
            {t("ENGINEERS.ENGINEER_WIZARD.STEP_2.TYPE_SELECTION_TITLE")}
          </CustomTitle>

          <div className={styles.iconsContainer}>
            <div
              className={clsx(styles.icon, styles.leftIcon)}
              onClick={() =>
                handleChangeRealEstateType(RealEstateType.AwaitingRenovation)
              }
            >
              <HouseComponent />

              <CustomTitle
                level={4}
                align="center"
                className={styles.buttonLabel}
              >
                {t("ENGINEERS.ENGINEER_WIZARD.STEP_2.AWAITING_RENOVATION")}
              </CustomTitle>
            </div>

            <div
              className={clsx(styles.icon)}
              onClick={() =>
                handleChangeRealEstateType(RealEstateType.NewlyBuilt)
              }
            >
              <RenovationHouseComponent className={styles.icon} />

              <CustomTitle level={4} className={styles.buttonLabel}>
                {t("ENGINEERS.ENGINEER_WIZARD.STEP_2.NEWLY_BUILT")}
              </CustomTitle>
            </div>
          </div>
        </div>
      </Card>

      <StepNavigator
        firstButton={
          <CustomButton
            onClick={() => dispatch.engineer.resetWizardData()}
            size="large"
            className={styles.backButton}
          >
            {t("COMMON.BACK")}
          </CustomButton>
        }
      />
    </>
  );
};

export default Step2;
