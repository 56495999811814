import { useDispatch, useSelector } from "app/store";
import CustomButton from "components/button/CustomButton";
import Card from "components/card/Card";
import CustomTitle from "components/typography/title/CustomTitle";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import StepNavigator from "components/stepNavigator/StepNavigator";
import styles from "./Step2.module.scss";
import BuilderSelected from "features/builder/components/builderList/componenets/builderSelected/BuilderSelected";
import { Divider, notification, Row } from "antd";
import CustomInput from "components/form/components/CustomInput";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import useGrapeAntdForm from "components/form/hooks/useGrapeAntdForm";
import GrapeAntdForm from "components/form/components/GrapeAntdForm";
import { requiredField, pattern } from "components/form/utils/formRules";
import { BuilderDto } from "api/generated/optimum";
import { NavLink } from "react-router-dom";

const Step2: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formUtils = useGrapeAntdForm();
  const { selectedBuilders, projectCode } = useSelector(
    (state) => state.builder
  );

  const handleModifyBuilderSelection = (builder: BuilderDto): void => {
    if (!selectedBuilders.includes(builder) && selectedBuilders.length !== 3) {
      dispatch.builder.setSelectedBuilders({
        builders: [...selectedBuilders, builder],
      });
    } else {
      let builders = [...selectedBuilders];
      builders = builders.filter((x) => x.id !== builder.id);

      dispatch.builder.setSelectedBuilders({ builders });
    }
  };

  const handleFinish = () => {
    const code = formUtils.form.getFieldValue("projectCode");
    dispatch.builder.setProjectCode(code);
    dispatch.builder
      .getBuildOrder(code)
      .then(() => {
        dispatch.builder.nextStep();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = () => {
    dispatch.builder.setProjectCode(
      formUtils.form.getFieldValue("projectCode")
    );
    dispatch.builder.prevStep();
  };

  useEffect(() => {
    if (!selectedBuilders.length) {
      dispatch.builder.prevStep();
    }
  }, [dispatch, selectedBuilders]);

  useEffect(() => {
    if (projectCode) {
      formUtils.form.setFieldsValue({ projectCode });
      formUtils.form.validateFields();
    }
  }, [formUtils.form, projectCode]);

  return (
    <>
      <Card className={styles.cardContainer}>
        <CustomTitle
          level={3}
          align="center"
          color="darkBlue"
          className={styles.title}
        >
          {t("BUILDERS.BUILDERS_WIZARD.STEP_2.CARD_TITLE")}
        </CustomTitle>
        <BuilderSelected
          wizardTitle={t(
            "BUILDERS.BUILDERS_WIZARD.STEP_2.SELECTEDBUILDERS_TITLE"
          )}
          wizardTitlePosition="center"
          selectedBuilders={selectedBuilders}
          hideRemove
          handleModifyBuilderSelection={handleModifyBuilderSelection}
        />

        <Divider className={styles.divider} />

        <Row justify="center">
          <CustomTitle
            level={4}
            align="left"
            color="lightBlue"
            className={styles.formInputTitle}
          >
            {t("BUILDERS.BUILDERS_WIZARD.STEP.FORM_TITLE")}
          </CustomTitle>
        </Row>

        <Row justify="center">
          <GrapeAntdForm
            formUtils={formUtils}
            requiredMark={false}
            className={styles.input}
          >
            <GrapeAntdForm.Item
              name="projectCode"
              rules={[requiredField(), pattern(/^OC-[0-9A-Z]{6}-[0-9]{4}$/)]}
            >
              <CustomInput
                placeholder={t(
                  "BUILDERS.BUILDERS_WIZARD.STEP_2.FORM_INPUT_PLACEHOLDER"
                )}
              />
            </GrapeAntdForm.Item>
          </GrapeAntdForm>
        </Row>

        <Row justify="center" className={styles.inputInfo}>
          <CustomParagraph size="xs">
            {t("BUILDERS.BUILDERS_WIZARD.STEP.FORM_INPUT_INFO")}
          </CustomParagraph>
        </Row>
      </Card>

      <StepNavigator
        firstButton={
          <CustomButton onClick={handleBack} className={styles.button}>
            {t("COMMON.BACK")}
          </CustomButton>
        }
        secondButton={
          <CustomButton
            type="primary"
            onClick={handleFinish}
            className={styles.button}
            disabled={!formUtils.submittable}
          >
            {t("COMMON.NEXT")}
          </CustomButton>
        }
      />
      <Row justify="center">
        <CustomParagraph size="xs" className={styles.bottomInfo} strong>
          {t("BUILDERS.BUILDERS_WIZARD.STEP_2.BOTTOM_INFO")}
          <NavLink to="/">
            {t("BUILDERS.BUILDERS_WIZARD.STEP_2.BOTTOM_INFO_LINK")}
          </NavLink>
          {t("BUILDERS.BUILDERS_WIZARD.STEP_2.BOTTOM_INFO_2")}
        </CustomParagraph>
      </Row>
    </>
  );
};

export default Step2;
