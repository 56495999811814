/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateEmailConfirmationCommand
 */
export interface ValidateEmailConfirmationCommand {
    /**
     * 
     * @type {string}
     * @memberof ValidateEmailConfirmationCommand
     */
    token: string;
}

export function ValidateEmailConfirmationCommandFromJSON(json: any): ValidateEmailConfirmationCommand {
    return ValidateEmailConfirmationCommandFromJSONTyped(json, false);
}

export function ValidateEmailConfirmationCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateEmailConfirmationCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function ValidateEmailConfirmationCommandToJSON(value?: ValidateEmailConfirmationCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
    };
}


