import { FC } from "react";
import { Icon } from "@mdi/react";
import { IconProps } from "@mdi/react/dist/IconProps";
import clsx from "clsx";

export const MaterialIcon: FC<IconProps> = ({
  className,
  size = "1em",
  ...props
}) => {
  return (
    <span className={clsx("anticon", className)}>
      <Icon size={size} {...props} />
    </span>
  );
};
