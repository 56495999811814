import { createModel } from "@rematch/core";
import { RootModel } from "app/store";

export enum AlertModalType {
  // Error,
  // Warning,
  // Info,
  Success,
}

interface AlertModalProps {
  title?: string;
  cardTitle?: string;
  type?: AlertModalType;
  width?: string | number;
  descriptin?: string;
  buttonLabel?: string;
  secondaryButtonLabel?: string;
  closable?: boolean;
  onButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
}

interface AlertModalState {
  isOpen: boolean;
  modalProps: AlertModalProps;
}

const initialState: AlertModalState = {
  isOpen: false,
  modalProps: { type: AlertModalType.Success, closable: false },
};

export const alertModal = createModel<RootModel>()({
  state: initialState,
  reducers: {
    openModal: (state, action: AlertModalProps) => {
      state.isOpen = true;
      state.modalProps = action;
    },
    close: () => initialState,
  },
});
