import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ProviderDto } from "api/generated/optimum";
import { useNavigate } from "react-router-dom";
import styles from "./ProviderTable.module.scss";

const ProviderTable: React.FC<{
  providers?: ProviderDto[];
  loading?: boolean;
  columns: ColumnsType<ProviderDto>;
}> = ({ providers, loading, columns }) => {
  const navigate = useNavigate();

  const openProfile = (id: string) => {
    navigate(id);
  };

  return (
    <Table
      className={styles.table}
      rowKey="id"
      columns={columns}
      loading={loading}
      dataSource={providers}
      onRow={(provider: ProviderDto, rowIndex) => ({
        onClick: () => {
          provider.id && openProfile(provider.id);
        },
      })}
    />
  );
};

export default ProviderTable;
