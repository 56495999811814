import { FC } from "react";
import { Spin } from "antd";
import { mdiLoading } from "@mdi/js";
import classNames from "classnames";
import styles from "./Loading.module.scss";
import Icon from "@mdi/react";

interface LoadingProps {
  absolute?: boolean;
  size?: number;
  className?: string;
}

const Loading: FC<LoadingProps> = ({
  absolute = false,
  size = 3,
  className,
}) => {
  return (
    <div
      className={classNames(styles.loading, className, {
        [styles.absolute]: absolute,
      })}
    >
      <Spin indicator={<Icon path={mdiLoading} size={size} spin />} />
    </div>
  );
};

export default Loading;
