import CustomButton from "components/button/CustomButton";
import Content from "components/layout/content/Content";
import CustomTitle from "components/typography/title/CustomTitle";
import styles from "./Reports.module.scss";
import { useTranslation } from "react-i18next";
import { api } from "api";
import { downloadBlobAsCsv } from "utils/fileReader";

const Reports: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Content>
      <CustomTitle extraStrong level={1}>
        {t("ADMIN.REPORTS.PAGE_TITLE")}
      </CustomTitle>

      <div className={styles.buttonContainer}>
        <CustomButton
          type="link"
          onClick={async () => {
            const file = await api.projects.exportProjectActivities();
            downloadBlobAsCsv(file);
          }}
        >
          {t("ADMIN.REPORTS.ACTIVITY_EXPORT_BTN")}
        </CustomButton>

        <CustomButton
          type="link"
          onClick={async () => {
            const file = await api.stats.exportProviderSearchActivity();
            downloadBlobAsCsv(file);
          }}
        >
          {t("ADMIN.REPORTS.PROVIDER_EXPORT_BTN")}
        </CustomButton>
      </div>
    </Content>
  );
};

export default Reports;
