import { Col, Row } from "antd";
import Content from "components/layout/content/Content";
import CustomTitle from "components/typography/title/CustomTitle";
import { useTranslation } from "react-i18next";
import styles from "./AdminProjectList.module.scss";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { useDispatch, useSelector } from "app/store";
import { useCallback, useMemo, useState } from "react";
import { WildcardSearchType } from "api/generated/optimum";
import ProjectTable from "../components/projectTable/ProjectTable";
import SearchInput from "../components/searchInput/SearchInput";
import {
  completedColumns,
  onGoingColumns,
  refusedColumns,
} from "../components/projectTable/projectTableColumns";
import RadioButtonGroup, {
  RadioButton,
} from "../components/radioButton/RadioButtonGroup";
import {
  getSessionStorageValue,
  SessionStorageKeys,
  setSessionStorageValue,
} from "utils/storageHandler";
import { AdminProjectStatus } from "models/AdminProjectStatus";

enum ProjectType {
  Design = "Design",
  Build = "Build",
  All = "All",
}

const AdminProjectList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.projects);
  const { loading } = useSelector(
    (state) => state.loading.effects.projects.getProjects
  );

  const currentAdmintProjectStatus = getSessionStorageValue(
    SessionStorageKeys.AdminProjectActiveStatus
  );

  const currentAdmintProjectType = getSessionStorageValue(
    SessionStorageKeys.AdminProjectActiveType
  );

  const [statusRadioValue, setStatusRadioValue] = useState<AdminProjectStatus>(
    AdminProjectStatus[
      (currentAdmintProjectStatus as keyof typeof AdminProjectStatus) ??
        "onGoing"
    ]
  );

  const [typeRadioValue, setTypeRadioValue] = useState<ProjectType>(
    ProjectType[
      (currentAdmintProjectType as keyof typeof ProjectType) ?? "Design"
    ]
  );

  const ProjectStatusMatrix = useMemo(
    () => ({
      [AdminProjectStatus.onGoing]: {
        [ProjectType.Design]: [
          "DesignOrderStatus.New",
          "DesignOrderStatus.FileUpload",
          "DesignOrderStatus.OptiComfortNew",
          "DesignOrderStatus.OptiComfortWip",
          "DesignOrderStatus.OptiComfort",
        ],
        [ProjectType.Build]: [
          "BuildOrderStatus.New",
          "BuildOrderStatus.Approved",
          "BuildOrderStatus.MaterialsOrdered",
        ],
        [ProjectType.All]: [
          "DesignOrderStatus.New",
          "DesignOrderStatus.FileUpload",
          "DesignOrderStatus.OptiComfortNew",
          "DesignOrderStatus.OptiComfortWip",
          "DesignOrderStatus.OptiComfort",
          "BuildOrderStatus.New",
          "BuildOrderStatus.Approved",
          "BuildOrderStatus.MaterialsOrdered",
        ],
      },

      [AdminProjectStatus.Refused]: {
        [ProjectType.Design]: ["DesignOrderStatus.Refused"],
        [ProjectType.Build]: ["BuildOrderStatus.Refused"],
        [ProjectType.All]: [
          "DesignOrderStatus.Refused",
          "BuildOrderStatus.Refused",
        ],
      },

      [AdminProjectStatus.Completed]: {
        [ProjectType.Design]: ["DesignOrderStatus.Completed"],
        [ProjectType.Build]: ["BuildOrderStatus.Completed"],
        [ProjectType.All]: [
          "DesignOrderStatus.Completed",
          "BuildOrderStatus.Completed",
        ],
      },
    }),
    []
  );

  const getProjects = useCallback(
    (wildcard?: string) =>
      dispatch.projects.getProjects({
        wildcardSearchTerm: wildcard,
        wildcardSearchTypes: [
          WildcardSearchType.Customer,
          WildcardSearchType.Design,
          WildcardSearchType.Build,
        ],
        projectStatuses: ProjectStatusMatrix[statusRadioValue][typeRadioValue],
      }),
    [dispatch.projects, ProjectStatusMatrix, statusRadioValue, typeRadioValue]
  );

  const statusRadioButtons = useMemo<RadioButton<AdminProjectStatus>[]>(
    () => [
      {
        label: t("ADMIN.PROJECTS.STATUS.ONGOING"),
        value: AdminProjectStatus.onGoing,
      },
      {
        label: t("ADMIN.PROJECTS.STATUS.REFUSED"),
        value: AdminProjectStatus.Refused,
      },
      {
        label: t("ADMIN.PROJECTS.STATUS.COMPLETED"),
        value: AdminProjectStatus.Completed,
      },
    ],
    [t]
  );

  const typeRadioButtons = useMemo<RadioButton<ProjectType>[]>(
    () => [
      {
        label: t("ADMIN.PROJECTS.TYPE.DESIGN"),
        value: ProjectType.Design,
      },
      {
        label: t("ADMIN.PROJECTS.TYPE.BUILD"),
        value: ProjectType.Build,
      },
      {
        label: t("ADMIN.PROJECTS.TYPE.ALL"),
        value: ProjectType.All,
      },
    ],
    [t]
  );

  const getProjectTableColumns = useCallback(() => {
    let columns = {
      [AdminProjectStatus.onGoing]: onGoingColumns,
      [AdminProjectStatus.Refused]: refusedColumns,
      [AdminProjectStatus.Completed]: completedColumns,
    }[statusRadioValue];

    // these for Engeener and NGBS users only
    columns = columns.filter(
      (column) =>
        column.dataIndex !== "designOrderStatus" &&
        column.dataIndex !== "designOrderCreationDate" &&
        column.dataIndex !== "designOrderCompletionDate"
    );

    if (
      typeRadioValue !== ProjectType.Design &&
      statusRadioValue !== AdminProjectStatus.onGoing
    ) {
      columns = columns.filter(
        (column) => column.dataIndex !== "lastOrderRequestDate"
      );
    }

    if (
      typeRadioValue !== ProjectType.Design &&
      statusRadioValue === AdminProjectStatus.Refused
    ) {
      return columns.filter(
        (column) => column.dataIndex !== "designRefusalReason"
      );
    }

    return columns;
  }, [statusRadioValue, typeRadioValue]);

  const emptyMessage = (() => {
    switch (statusRadioValue) {
      case AdminProjectStatus.onGoing:
        return t("table.empty_note.projects.no_ongoing_projects_admin");
      case AdminProjectStatus.Refused:
        return t("table.empty_note.projects.no_refused_projects_admin");
      case AdminProjectStatus.Completed:
        return t("table.empty_note.projects.no_completed_projects_admin");
      default:
        return "";
    }
  })();

  return (
    <Content>
      <CustomTitle extraStrong level={1} className={styles.pageTitle}>
        {t("ADMIN.PROJECTS.PAGE_TITLE")}
      </CustomTitle>

      <Row align="middle">
        <Col>
          <RadioButtonGroup
            onChange={(e) => {
              setStatusRadioValue(e);
              setSessionStorageValue(
                SessionStorageKeys.AdminProjectActiveStatus,
                e
              );
            }}
            value={statusRadioValue}
            radioButtons={statusRadioButtons}
            size="large"
          />
        </Col>

        <Col>
          <SearchInput getResource={getProjects} />
        </Col>
      </Row>

      <Row>
        <RadioButtonGroup
          onChange={(e) => {
            setTypeRadioValue(e);
            setSessionStorageValue(
              SessionStorageKeys.AdminProjectActiveType,
              e
            );
          }}
          value={typeRadioValue}
          className={styles.typeRadioGroup}
          radioButtons={typeRadioButtons}
        />
      </Row>

      <CustomParagraph italic size="xs" className={styles.searchCount}>
        {t("ADMIN.PROJECTS.SEARCH_COUNT", { recordCount: projects.length })}
      </CustomParagraph>

      <ProjectTable
        projects={projects}
        loading={loading}
        columns={getProjectTableColumns()}
        emptyMessage={emptyMessage}
      />
    </Content>
  );
};

export default AdminProjectList;
