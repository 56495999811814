import { Row } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { ProviderDto, ProviderType } from "api/generated/optimum";
import i18n from "app/i18n";
import clsx from "clsx";
import { compareStrings } from "components/GrapeAntdTable/utils";
import moment from "moment";
import { formatDate, phoneNumberDisplayFormat } from "utils/format";
import styles from "./ProviderTable.module.scss";

export const onGoingColumns = [
  {
    title: i18n.t("ADMIN.PROVIDERS.TABLE.NAME"),
    dataIndex: "profession",
    sorter: {
      compare: (a: ProviderDto, b: ProviderDto) =>
        compareStrings(a.lastName, b.lastName),
    },
    render: (value: string, row: ProviderDto) => {
      return (
        <Row
          className={clsx([
            {
              [styles.engineer]: row.type === ProviderType.Engineer,
            },
            {
              [styles.builder]: row.type === ProviderType.Builder,
            },
          ])}
        >
          <div className={styles.name}>
            {row.lastName} {row.firstName}
          </div>
        </Row>
      );
    },
    width: "30%",
  },
  {
    title: i18n.t("ADMIN.PROVIDERS.TABLE.CONTACT"),
    dataIndex: "emailAddress",
    sorter: {
      compare: (a: ProviderDto, b: ProviderDto) =>
        compareStrings(a.emailAddress, b.emailAddress),
    },
    render: (value: string, row: ProviderDto) => {
      return (
        <>
          <div>{row?.emailAddress}</div>
          <div>
            {(row?.phoneNumber && phoneNumberDisplayFormat(row?.phoneNumber)) ||
              ""}
          </div>
        </>
      );
    },
    width: "25%",
  },
  {
    title: i18n.t("ADMIN.PROVIDERS.TABLE.LAST_SEEN"),
    dataIndex: "lastLoginDate",
    defaultSortOrder: "descend" as SortOrder,
    sorter: {
      compare: (a: ProviderDto, b: ProviderDto) => {
        return (
          (a.lastLoginDate === undefined
            ? -1
            : moment(a.lastLoginDate).unix()) -
            (b.lastLoginDate === undefined
              ? -1
              : moment(b.lastLoginDate).unix()) ||
          +(moment(a.lastLoginDate).unix() > moment(b.lastLoginDate).unix()) ||
          -(moment(a.lastLoginDate).unix() < moment(b.lastLoginDate).unix())
        );
      },
    },
    render: (value: Date) => formatDate.dateTime(value),
    width: "25%",
  },
  {
    title: i18n.t("ADMIN.PROVIDERS.TABLE.IS_ENABLED"),
    dataIndex: "isActive",
    sorter: {
      compare: (a: ProviderDto, b: ProviderDto) =>
        a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1,
    },
    render: (value: boolean) =>
      value
        ? i18n.t("ADMIN.PROVIDERS.TABLE.IS_ENABLED_YES")
        : i18n.t("ADMIN.PROVIDERS.TABLE.IS_ENABLED_NO"),
    width: "20%",
  },
];
