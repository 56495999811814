/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineerProfession,
    EngineerProfessionFromJSON,
    EngineerProfessionFromJSONTyped,
    EngineerProfessionToJSON,
} from './';

/**
 * 
 * @export
 * @interface EngineerDto
 */
export interface EngineerDto {
    /**
     * 
     * @type {string}
     * @memberof EngineerDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EngineerDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EngineerDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EngineerDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EngineerDto
     */
    minPriceQuote?: number;
    /**
     * 
     * @type {number}
     * @memberof EngineerDto
     */
    maxPriceQuote?: number;
    /**
     * 
     * @type {string}
     * @memberof EngineerDto
     */
    introduction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EngineerDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EngineerDto
     */
    position?: string | null;
    /**
     * 
     * @type {EngineerProfession}
     * @memberof EngineerDto
     */
    profession?: EngineerProfession;
    /**
     * 
     * @type {Array<string>}
     * @memberof EngineerDto
     */
    specializations?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EngineerDto
     */
    profilePictureUrl?: string | null;
}

export function EngineerDtoFromJSON(json: any): EngineerDto {
    return EngineerDtoFromJSONTyped(json, false);
}

export function EngineerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'minPriceQuote': !exists(json, 'minPriceQuote') ? undefined : json['minPriceQuote'],
        'maxPriceQuote': !exists(json, 'maxPriceQuote') ? undefined : json['maxPriceQuote'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'profession': !exists(json, 'profession') ? undefined : EngineerProfessionFromJSON(json['profession']),
        'specializations': !exists(json, 'specializations') ? undefined : json['specializations'],
        'profilePictureUrl': !exists(json, 'profilePictureUrl') ? undefined : json['profilePictureUrl'],
    };
}

export function EngineerDtoToJSON(value?: EngineerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'isActive': value.isActive,
        'minPriceQuote': value.minPriceQuote,
        'maxPriceQuote': value.maxPriceQuote,
        'introduction': value.introduction,
        'companyName': value.companyName,
        'position': value.position,
        'profession': EngineerProfessionToJSON(value.profession),
        'specializations': value.specializations,
        'profilePictureUrl': value.profilePictureUrl,
    };
}


