import { FC } from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, useGrapeAntdForm } from "components/form";
import CustomInput from "components/form/components/CustomInput";
import { Checkbox, Input } from "antd";
import CustomButton from "components/button/CustomButton";
import { LoginCommand } from "api/generated/optimum";
import styles from "./Login.module.scss";
import { useDispatch, useSelector } from "app/store";
import { AuthStep } from "../Auth.store";
import { LoginCredentials } from "models/LoginCredentials";
import { useNavigate } from "react-router-dom";
import { isAdmin } from "utils/authUtils";

const Login: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formUtils = useGrapeAntdForm<LoginCredentials>("LoginCommand");
  const navgiate = useNavigate();
  const { navigate } = useSelector((state) => state.auth);

  const handleFinish = async (values: LoginCredentials) => {
    try {
      await dispatch.auth.login(values);

      if (navigate) {
        const route = isAdmin() ? "admin/projects" : navigate;

        navgiate(route);
      }

      dispatch.auth.resetAuthStep();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <GrapeAntdForm formUtils={formUtils} onFinish={handleFinish}>
        <GrapeAntdForm.Item<LoginCommand> name="email">
          <CustomInput placeholder={t("AUTH.LOGIN.EMAIL_PLACEHOLDER")} />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item<LoginCommand> name="password">
          <Input.Password placeholder={t("AUTH.LOGIN.PASSWORD_PLACEHOLDER")} />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item name="remember" valuePropName="checked">
          <Checkbox>{t("AUTH.LOGIN.REMEMBER_ME")}</Checkbox>
        </GrapeAntdForm.Item>

        <CustomButton
          type="primary"
          htmlType="submit"
          size="large"
          block
          className={styles.loginButton}
        >
          {t("AUTH.LOGIN.BUTTON")}
        </CustomButton>

        <CustomButton
          type="text"
          size="large"
          block
          onClick={() =>
            dispatch.auth.setAuthStep({ authStep: AuthStep.FORGOTTENPASS })
          }
        >
          {t("AUTH.LOGIN.FORGOTTEN_PASSWORD")}
        </CustomButton>
      </GrapeAntdForm>
    </>
  );
};

export default Login;
