/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetProviderPublishedFlagCommand
 */
export interface SetProviderPublishedFlagCommand {
    /**
     * 
     * @type {string}
     * @memberof SetProviderPublishedFlagCommand
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof SetProviderPublishedFlagCommand
     */
    isPublished: boolean;
}

export function SetProviderPublishedFlagCommandFromJSON(json: any): SetProviderPublishedFlagCommand {
    return SetProviderPublishedFlagCommandFromJSONTyped(json, false);
}

export function SetProviderPublishedFlagCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetProviderPublishedFlagCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isPublished': json['isPublished'],
    };
}

export function SetProviderPublishedFlagCommandToJSON(value?: SetProviderPublishedFlagCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isPublished': value.isPublished,
    };
}


