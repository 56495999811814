import { Layout } from "antd";
import { FC } from "react";
import Auth from "features/auth/Auth";
import { useSelector } from "app/store";
import PublicNavBar from "./public/PublicNavBar";
import PrivateNavBar from "./private/PrivateNavBar";

const NavBar: FC = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Layout.Header>
      {user ? <PrivateNavBar /> : <PublicNavBar />}

      <Auth />
    </Layout.Header>
  );
};

export default NavBar;
