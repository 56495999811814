/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RealEstateFileType,
    RealEstateFileTypeFromJSON,
    RealEstateFileTypeToJSON,
} from '../models';

export interface UploadRealEstateFileRequest {
    designOrderId?: number;
    file?: Blob;
    type?: RealEstateFileType;
}

/**
 * 
 */
export class RealEstateFilesApi extends runtime.BaseAPI {

    /**
     * Uploads designOrder / buildOrder files
     */
    async uploadRealEstateFileRaw(requestParameters: UploadRealEstateFileRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.designOrderId !== undefined) {
            formParams.append('designOrderId', requestParameters.designOrderId as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.type !== undefined) {
            formParams.append('type', requestParameters.type as any);
        }

        const response = await this.request({
            path: `/api/RealEstateFiles/uploadRealEstateFiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Uploads designOrder / buildOrder files
     */
    async uploadRealEstateFile(requestParameters: UploadRealEstateFileRequest): Promise<string> {
        const response = await this.uploadRealEstateFileRaw(requestParameters);
        return await response.value();
    }

}
