import { Menu, Popover } from "antd";
import { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "../NavBar.module.scss";
import { useDispatch, useSelector } from "app/store";
import { ReactComponent as Logo } from "assets/images/LOGO_WHITE.svg";
import { ReactComponent as LogoBlue } from "assets/images/Logo.svg";

import clsx from "clsx";
import ProviderAvatar from "components/providerAvatar/ProviderAvatar";
import CustomTitle from "components/typography/title/CustomTitle";
import CustomButton from "components/button/CustomButton";
import { MenuItemProps } from "models/MenuItemProps";
import {
  isAdmin,
  isBuilder,
  isEngineer,
  isNgbs,
  isProvider,
} from "utils/authUtils";

const PrivateNavBar: FC = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logout = () => {
    dispatch.auth.logout();

    if (process.env.NODE_ENV === "production") {
      window.location.href = "https://opticonnect.mvm-optimum.hu/";
    } else {
      navigate("/projects");
    }
  };

  const path = location.pathname;

  const profileMenuPopupContent = useMemo(
    (): ReactNode => (
      <div className={styles.profileMenuPopupButtons}>
        {(isEngineer() || isBuilder()) && (
          <CustomButton
            type="link"
            htmlType="button"
            onClick={() => navigate("/my-profile")}
            className={styles.editProfileButton}
          >
            {t("top-menu.view-my-profile")}
          </CustomButton>
        )}

        <CustomButton
          type="link"
          htmlType="button"
          onClick={logout}
          className={styles.logoutButton}
        >
          {t("topMenu.logout")}
        </CustomButton>
      </div>
    ),
    [t, navigate, logout]
  );

  const menuItems = useMemo<MenuItemProps[]>(
    () => [
      /* {
        label: t("NAVBAR.ADMIN.OVERVIEW"),
        link: "/admin/overview",
        key: "overview",
      }, */
      {
        label: t("NAVBAR.ADMIN.PROJECTS"),
        link: "/admin/projects",
        key: "projects",
      },
      {
        label: t("NAVBAR.ADMIN.PROVIDERS"),
        link: "/admin/providers",
        key: "providers",
      },
      {
        label: t("NAVBAR.ADMIN.REPORTS"),
        link: "/admin/reports",
        key: "reports",
      },
    ],

    [t]
  );

  return (
    <nav
      className={clsx(styles.navBar, {
        [styles.engineerNavBar]: isEngineer(),
        [styles.ngbsNavBar]: isNgbs(),
        [styles.adminNavBar]: isAdmin(),
        [styles.builderNavBar]: isBuilder(),
      })}
    >
      <a className={styles.logo} href="https://opticonnect.mvm-optimum.hu/">
        {isAdmin() ? <LogoBlue /> : <Logo />}
      </a>
      <Menu
        mode="horizontal"
        className={styles.menu}
        selectedKeys={menuItems
          .filter((item) => item.key && path.includes(item.key))
          .map((selectedItem) => selectedItem.key || "")}
      >
        {isAdmin() &&
          menuItems.map((menuItem, index) => {
            return menuItem.external ? (
              <Menu.Item key={index} className={clsx(menuItem.className)}>
                <a
                  href={menuItem.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {menuItem.label}
                </a>
              </Menu.Item>
            ) : (
              <Menu.Item
                key={menuItem.key}
                className={clsx(menuItem.className)}
              >
                <Link to={menuItem.link || ""} onClick={menuItem.onClick}>
                  {menuItem.label}
                </Link>
              </Menu.Item>
            );
          })}
        <Menu.Item key={"user_menu"}>
          <Popover
            trigger="hover"
            mouseLeaveDelay={0.5}
            content={profileMenuPopupContent}
            placement="bottom"
            className={styles.profileMenu}
            align={{ offset: [0, -30] }}
          >
            <ProviderAvatar
              size={35}
              preview={false}
              url={user?.profilePictureUrl}
            />
            <CustomTitle
              level={5}
              className={clsx(styles.userName, {
                [styles.providerUserName]: isProvider(),
              })}
            >
              {user?.displayName}
            </CustomTitle>
          </Popover>
        </Menu.Item>
      </Menu>
    </nav>
  );
};

export default PrivateNavBar;
