import { Col, Popover, Row, Tag } from "antd";
import Card from "components/card/Card";
import Jumbotron from "components/jumbotron/Jumbotron";
import CustomTitle from "components/typography/title/CustomTitle";
import { useTranslation } from "react-i18next";
import styles from "./Design.module.scss";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import CustomButton from "components/button/CustomButton";
import { ReactComponent as ArrowIcon } from "assets/images/Arrow_1.svg";
import { ReactComponent as NgbsArrowIcon } from "assets/images/ngbsArrow.svg";
import { ReactComponent as BlueAarrowIcon } from "assets/images/blueArrow.svg";
import { useDispatch, useSelector } from "app/store";

import UploadButton from "components/button/UploadButton";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { useCallback, useEffect, useState } from "react";

import {
  DesignFileType,
  DesignOrderStatus,
  SpecializationDto,
} from "api/generated/optimum";
import DesignFileInput from "./components/designFileInput/DesignFileInput";
import useDesign from "./useDesign";
import OptiComfortPlan from "./components/optiComfortPlan/OptiComfortPlan";
import useFileInput, { FileExtensions } from "hooks/useFileInput";
import SendDemandModal from "./components/sendDemandModal/SendDemandModal";
import FinalizeDesignModal from "./components/finalizeDesignModal/FinalizeDesignModal";
import { isAdmin, isEngineer, isNgbs } from "utils/authUtils";
import RejectionDetails from "../components/admin/rejectionDetails/RejectionDetails";
import EditDesignDemandModal from "./components/EditDesignDemandModal";
import OrderDetails from "./components/OrderDetails";
import RefuseDesignOrder from "../components/refuseOrder/RefuseDesignOrder";
import { CustomSelect } from "components/form/components/CustomSelect";
import AdministrationCard from "../components/admin/administrationCard/AdministrationCard";
import SpecializationDisplay from "../components/specializationDisplay/SpecializationDisplay";

const Design: React.FC = () => {
  const { currentProject } = useSelector((state) => state.projects);

  const { t } = useTranslation();

  const {
    thermalCalculationFiles,
    floorPlanFiles,
    otherFilesAsUploadFile,
    designOrderId,
    removeOtherFileHandler,
    beforeUploadHandler,
    refuseOrder,
  } = useDesign();

  const { isFileValid, acceptedExtensions } = useFileInput({
    validExtensions: [
      FileExtensions.JPG,
      FileExtensions.JPEG,
      FileExtensions.PDF,
      FileExtensions.PNG,
      FileExtensions.XLS,
      FileExtensions.XLSX,
      FileExtensions.DOC,
      FileExtensions.DOCX,
      FileExtensions.DWG,
    ],
  });

  const { specializations } = useSelector((state) => state.referenceData);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedItems(
      currentProject?.requiredSpecializations?.map((spec) => spec.id!) || []
    );
  }, [currentProject]);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isSpecializationChanged, setIsSpecializationChanged] =
    useState<boolean>(false);

  const [isSendDemandModalVisible, setIsSendDemandModalVisible] =
    useState<boolean>(false);

  const [isFinalizeModalVisible, setIsFinalizeModalVisible] =
    useState<boolean>(false);

  const [isEditDesignDemandModalVisible, setIsEditDesignDemandModalVisible] =
    useState<boolean>(false);

  const [
    isSpecializationSelectDropdownOpen,
    setIsSpecializationSelectDropdownOpen,
  ] = useState(false);

  const removeFileHandler = useCallback(
    async (fileName: string) => {
      if (currentProject?.designOrderId) {
        try {
          await dispatch.projects.removeDesignFile({
            id: currentProject?.designOrderId,
            fileName: fileName,
          });

          currentProject?.projectCode &&
            dispatch.projects.getProject({
              projectCode: currentProject.projectCode,
            });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [
      dispatch.projects,
      currentProject?.projectCode,
      currentProject?.designOrderId,
    ]
  );

  return (
    <>
      {isAdmin() && (
        <AdministrationCard
          displayName={`${currentProject?.engineer?.lastName} ${currentProject?.engineer?.firstName}`}
          providerUrl={`/admin/providers/${currentProject?.engineer?.id}`}
          phoneNumber={currentProject?.engineer?.phoneNumber}
          email={currentProject?.engineer?.emailAddress}
        />
      )}
      {(isAdmin() || isEngineer()) &&
      currentProject?.projectStatus === "DesignOrderStatus.Refused" ? (
        <RejectionDetails />
      ) : (
        <Card>
          <CustomTitle
            level={3}
            extraStrong
            color="darkBlue"
            align="center"
            className={styles.cardTitle}
          >
            {t("PROJECTS.DETAIL.DESIGN.TITLE")}
          </CustomTitle>
          <Jumbotron>
            <CustomTitle level={4} extraStrong className={styles.subTitle}>
              {t("PROJECTS.DETAIL.DESIGN.SUB_TITLE")}
            </CustomTitle>

            <Row justify="center">
              <Card className={styles.fileInputCard}>
                <CustomTitle
                  level={4}
                  extraStrong
                  align="center"
                  className={styles.fileInputTitle}
                >
                  {t("PROJECTS.DETAIL.DESIGN.FILE_INPUT_TITLE")}
                  {!isNgbs() && (
                    <Popover
                      title={
                        <CustomTitle
                          extraStrong
                          className={styles.fileInputTooltipTitle}
                        >
                          {t("PROJECTS.DETAIL.DESIGN.FILE_INPUT_TITLE")}
                        </CustomTitle>
                      }
                      content={
                        <div className={styles.fileInputTooltipContent}>
                          {t("PROJECTS.DETAIL.DESIGN.FILE_INPUT_TOOLTIP")}
                        </div>
                      }
                      placement="right"
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  )}
                </CustomTitle>

                {currentProject?.designFiles && (
                  <DesignFileInput
                    layoutFiles={floorPlanFiles}
                    thermalCalculationFiles={thermalCalculationFiles}
                    beforeUploadHandler={beforeUploadHandler}
                    removeFileHandler={removeFileHandler}
                    designFiles={currentProject?.designFiles}
                    disabled={
                      isAdmin() ||
                      currentProject?.designOrderStatus !==
                        DesignOrderStatus.FileUpload
                    }
                    isNgbs={isNgbs()}
                  />
                )}

                {currentProject?.designOrderStatus !==
                  DesignOrderStatus.Completed &&
                  isEngineer() && (
                    <Row justify="center">
                      {currentProject?.designOrderStatus ===
                      DesignOrderStatus.FileUpload ? (
                        <CustomButton
                          className={styles.sendDemandBtn}
                          type="primary"
                          size="large"
                          variant="provider"
                          disabled={
                            !floorPlanFiles?.length ||
                            !thermalCalculationFiles?.length
                          }
                          onClick={() => setIsSendDemandModalVisible(true)}
                        >
                          {t("PROJECTS.DETAIL.DESIGN.SEND_DEMAND")}
                        </CustomButton>
                      ) : (
                        <CustomButton
                          className={styles.modifyDemandBtn}
                          onClick={() =>
                            setIsEditDesignDemandModalVisible(true)
                          }
                          type="primary"
                          size="large"
                        >
                          {t("PROJECTS.DETAIL.DESIGN.MODIFY_DEMAND")}
                        </CustomButton>
                      )}
                    </Row>
                  )}
              </Card>
            </Row>

            <Row justify="center">
              {isNgbs() ? (
                <NgbsArrowIcon />
              ) : currentProject?.designOrderStatus ===
                DesignOrderStatus.FileUpload ? (
                <ArrowIcon />
              ) : (
                <BlueAarrowIcon />
              )}
            </Row>

            <Row justify="center">
              <OptiComfortPlan designOrderId={designOrderId} />
            </Row>
          </Jumbotron>
          {!isNgbs() && (
            <>
              <Jumbotron>
                <CustomTitle level={4} extraStrong>
                  {t("PROJECTS.DETAIL.DESIGN.OTHER_FILE_TITLE")}
                </CustomTitle>
                <Row gutter={90} align="middle">
                  <Col>
                    <UploadButton
                      disabled={
                        currentProject?.designOrderStatus ===
                          DesignOrderStatus.Completed || isAdmin()
                      }
                      multiple
                      accept={acceptedExtensions}
                      fileList={otherFilesAsUploadFile}
                      onRemove={removeOtherFileHandler}
                      className={styles.otherFileUpload}
                      buttonClassName={styles.uploadButton}
                      buttonVisible={isEngineer()}
                      beforeUpload={(file: File) => {
                        if (isFileValid(file, currentProject?.designFiles)) {
                          beforeUploadHandler(file, DesignFileType.Design);
                        }
                        return false;
                      }}
                    />
                  </Col>

                  {!otherFilesAsUploadFile?.length && (
                    <Col>
                      <CustomParagraph
                        size="xs"
                        italic
                        className={styles.noFileParag}
                      >
                        {t("PROJECTS.DETAIL.DESIGN.NO_FILE")}
                      </CustomParagraph>
                    </Col>
                  )}
                </Row>
              </Jumbotron>
              <Jumbotron>
                <CustomTitle level={4} extraStrong>
                  {t("PROJECTS.DETAIL.DESIGN.WORKING_TYPE_TITLE")}
                </CustomTitle>
                <Row gutter={90} align="middle">
                  <Col span={16}>
                    {isAdmin() ? (
                      <SpecializationDisplay
                        specializations={
                          currentProject?.requiredSpecializations
                        }
                      />
                    ) : (
                      <CustomSelect
                        optionFilterProp="children"
                        mode="multiple"
                        showArrow
                        open={isSpecializationSelectDropdownOpen}
                        onDropdownVisibleChange={async (open: boolean) => {
                          setIsSpecializationSelectDropdownOpen(open);

                          if (open || !isSpecializationChanged) {
                            return;
                          }

                          try {
                            designOrderId &&
                              (await dispatch.projects.updateDesign({
                                id: designOrderId,
                                specializations: selectedItems,
                              }));

                            setIsSpecializationChanged(false);
                          } catch (error) {
                            console.error(error);
                          }
                        }}
                        placeholder={t("COMMON.FORM.SELECT_PLACEHOLDER")}
                        value={selectedItems}
                        onChange={(value) => {
                          setSelectedItems(value);
                          setIsSpecializationChanged(true);
                        }}
                        className={styles.workingTypeSelect}
                        disabled={
                          currentProject?.designOrderStatus ===
                            DesignOrderStatus.Completed || !isEngineer()
                        }
                        tagRender={(props) => {
                          const { label, value } = props;

                          return (
                            <Tag>
                              {label}
                              {isEngineer() &&
                                currentProject?.designOrderStatus !==
                                  DesignOrderStatus.Completed && (
                                  <CloseOutlined
                                    onMouseDown={(e) => {
                                      e.stopPropagation();
                                      return false;
                                    }}
                                    onClick={async (e) => {
                                      const modifiedSpecialization =
                                        selectedItems.filter(
                                          (item) => item !== value
                                        );

                                      try {
                                        designOrderId &&
                                          (await dispatch.projects.updateDesign(
                                            {
                                              id: designOrderId,
                                              specializations:
                                                modifiedSpecialization,
                                            }
                                          ));

                                        setSelectedItems(
                                          modifiedSpecialization
                                        );
                                      } catch (error) {
                                        console.error(error);
                                      }
                                    }}
                                    className="ant-tag-close-icon"
                                  />
                                )}
                            </Tag>
                          );
                        }}
                      >
                        {specializations.map((item: SpecializationDto) => (
                          <CustomSelect.Option key={item.id} value={item.id}>
                            {item.name}
                          </CustomSelect.Option>
                        ))}
                      </CustomSelect>
                    )}
                  </Col>
                </Row>
              </Jumbotron>
            </>
          )}
          <hr className={styles.divider} />
          {currentProject?.designOrderStatus !== DesignOrderStatus.Completed &&
            isEngineer() && (
              <Row justify="center">
                <CustomButton
                  className={styles.finalieDesignBtn}
                  type="primary"
                  size="large"
                  variant="default"
                  disabled={
                    currentProject?.designOrderStatus !==
                    DesignOrderStatus.OptiComfort
                  }
                  onClick={() => setIsFinalizeModalVisible(true)}
                >
                  {t("PROJECTS.DETAIL.DESIGN.FINALIZE_DESIGN")}
                </CustomButton>
              </Row>
            )}
          <SendDemandModal
            isVisible={isSendDemandModalVisible}
            closeModal={() => setIsSendDemandModalVisible(false)}
          />
          <FinalizeDesignModal
            isVisible={isFinalizeModalVisible}
            closeModal={() => setIsFinalizeModalVisible(false)}
            designOrderId={designOrderId}
            projectCode={currentProject?.projectCode}
          />
          <EditDesignDemandModal
            isVisible={isEditDesignDemandModalVisible}
            onClose={() => setIsEditDesignDemandModalVisible(false)}
          />
        </Card>
      )}

      <OrderDetails project={currentProject} />

      {isEngineer() &&
        currentProject?.designOrderStatus === DesignOrderStatus.FileUpload && (
          <RefuseDesignOrder
            buttonLabel={t("PROJECT_DETAILS.DECLINE")}
            modalTitle={t("PROJECT_DETAILS.MODAL.TITLE")}
            textAreaLable={t("PROJECT_DETAILS.MODAL.TEXTAREA_LABEL")}
            description={t("PROJECT_DETAILS.MODAL.DESCRIPTION")}
            refuseOrder={refuseOrder}
          />
        )}
    </>
  );
};

export default Design;
