import { Row, Tabs } from "antd";
import clsx from "clsx";
import CustomModal from "components/customModal/CustomModal";
import CustomTitle from "components/typography/title/CustomTitle";
import MaterialTable from "features/projects/details/components/materialListModal/MaterialTable";
import { MaterialListModalProps } from "models/MaterialListModalProps";
import { useTranslation } from "react-i18next";
import { isAdmin, isBuilder, isEngineer, isNgbs } from "utils/authUtils";
import styles from "./MaterialListModal.module.scss";

export const MaterialListModal: React.FC<MaterialListModalProps> = ({
  visible,
  children,
  heatGenerationData,
  heatReleaseData,
  controlData,
  toolsData,
  tableProps,
  afterContent,
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      closable={false}
      visible={visible}
      className={styles.modal}
      width={"90%"}
    >
      <Row justify="center">
        <CustomTitle level={3} color="darkBlue" extraStrong>
          {t("MATERIAL_LIST_MODAL.TITLE")}
        </CustomTitle>
      </Row>
      <div className={styles.content}>
        <Tabs
          defaultActiveKey="1"
          className={clsx(styles.tabs, {
            [styles.ngbsTab]: isNgbs(),
            [styles.engineerTab]: isEngineer(),
            [styles.builderTab]: isBuilder(),
            [styles.adminTab]: isAdmin(),
          })}
          destroyInactiveTabPane
          onChange={() =>
            document
              .querySelector(".ant-tabs-content-holder")
              ?.scrollTo({ top: 0 })
          }
        >
          <Tabs.TabPane
            tab={t("MATERIAL_LIST_MODAL.HEAT_GENERATION_TITLE")}
            key="1"
          >
            <MaterialTable
              data={heatGenerationData}
              tableProps={tableProps}
              afterContent={afterContent}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t("MATERIAL_LIST_MODAL.HEAT_RELEASE_TITLE")}
            key="2"
          >
            <MaterialTable
              data={heatReleaseData}
              tableProps={tableProps}
              afterContent={afterContent}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("MATERIAL_LIST_MODAL.CONTROL_TITLE")} key="3">
            <MaterialTable
              data={controlData}
              tableProps={tableProps}
              afterContent={afterContent}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("MATERIAL_LIST_MODAL.TOOLS_TITLE")} key="4">
            <MaterialTable
              data={toolsData}
              tableProps={tableProps}
              afterContent={afterContent}
            />
          </Tabs.TabPane>
        </Tabs>
        {children}
      </div>
    </CustomModal>
  );
};
export default MaterialListModal;
