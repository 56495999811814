/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DesignFileType {
    Invalid = 'Invalid',
    Design = 'Design',
    FloorPlan = 'FloorPlan',
    ThermalCalculation = 'ThermalCalculation',
    RoomLayout = 'RoomLayout',
    FinalPlan = 'FinalPlan'
}

export function DesignFileTypeFromJSON(json: any): DesignFileType {
    return DesignFileTypeFromJSONTyped(json, false);
}

export function DesignFileTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DesignFileType {
    return json as DesignFileType;
}

export function DesignFileTypeToJSON(value?: DesignFileType | null): any {
    return value as any;
}

