import { notification } from "antd";
import { Middleware, ResponseContext } from "api/generated/optimum/runtime";
import i18n from "app/i18n";
import { store } from "app/store";
import { fetchApiRefreshTokenHandler } from "utils/fetchApiRefreshTokenHandler";

export const errorHandlingMiddleware: Middleware[] = [
  {
    post: async (ctx: ResponseContext): Promise<void> => {
      if (ctx.response.status < 400) {
        return Promise.resolve();
      }

      const body = await ctx.response?.json();
      const errorCode = body?.errors?.Error[0];

      if (
        ctx.response.status === 401 &&
        !ctx.response.url.endsWith("Auth/RefreshToken")
      ) {
        return fetchApiRefreshTokenHandler({
          ctx,
          asyncTokenRefresher: () => store.dispatch.auth.refreshAccessToken(),
        }).catch((error) => console.error(error));
      }

      if (ctx.response.status === 404) {
        notification.error({
          message: i18n.t("error.status_code.not_found"),
          description: errorCode ? i18n.t("error." + errorCode) : "",
        });

        return Promise.resolve();
      }

      if (!errorCode) {
        notification.error({
          message: i18n.t("error.error.error", {
            statusCode: ctx.response.status,
          }),
          description: i18n.t("common.error.unknown"),
        });

        return Promise.resolve();
      }

      if (ctx.response.status === 503) {
        notification.error({
          message: i18n.t("error.status_code.server_unavailable"),
        });
      } else if (ctx.response.status >= 500) {
        notification.error({
          message: i18n.t("error.status_code.server_error", {
            statusCode: ctx.response.status,
          }),
          description: i18n.t("error." + errorCode),
        });
      } else if (ctx.response.status >= 400) {
        notification.error({
          message: i18n.t("error.status_code.default_error", {
            statusCode: ctx.response.status,
          }),
          description: i18n.t("error." + errorCode),
        });
      }

      return Promise.resolve();
    },
  },
];
