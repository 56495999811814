/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildFileType,
    BuildFileTypeFromJSON,
    BuildFileTypeFromJSONTyped,
    BuildFileTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface BuildFileDto
 */
export interface BuildFileDto {
    /**
     * 
     * @type {string}
     * @memberof BuildFileDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {BuildFileType}
     * @memberof BuildFileDto
     */
    type?: BuildFileType;
    /**
     * 
     * @type {string}
     * @memberof BuildFileDto
     */
    downloadUrl?: string | null;
}

export function BuildFileDtoFromJSON(json: any): BuildFileDto {
    return BuildFileDtoFromJSONTyped(json, false);
}

export function BuildFileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildFileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'type': !exists(json, 'type') ? undefined : BuildFileTypeFromJSON(json['type']),
        'downloadUrl': !exists(json, 'downloadUrl') ? undefined : json['downloadUrl'],
    };
}

export function BuildFileDtoToJSON(value?: BuildFileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileName': value.fileName,
        'type': BuildFileTypeToJSON(value.type),
        'downloadUrl': value.downloadUrl,
    };
}


