import {
  BuildOrderStatus,
  DesignOrderStatus,
  Role,
  WildcardSearchType,
} from "api/generated/optimum";
import { useDispatch } from "app/store";
import useSearchInput from "hooks/useSearchInput";
import { useCallback } from "react";
import { getUserData, isBuilder } from "utils/authUtils";
import TabPaneList from "../components/tabPaneList/TabPaneList";

type DesignOrderRoles = Exclude<Role, Role.Admin | Role.Invalid | Role.Builder>;

const ActiveTab: React.FC = () => {
  const dispatch = useDispatch();

  const getDesignOrderStatusByRole = () => {
    const currentRole = getUserData()?.role;

    if (!currentRole) {
      return undefined;
    }

    const statuses = {
      [Role.Engineer]: [
        DesignOrderStatus.New,
        DesignOrderStatus.OptiComfortNew,
        DesignOrderStatus.FileUpload,
        DesignOrderStatus.OptiComfort,
        DesignOrderStatus.OptiComfortWip,
      ],

      [Role.Ngbs]: [
        DesignOrderStatus.OptiComfortNew,
        DesignOrderStatus.OptiComfort,
        DesignOrderStatus.OptiComfortWip,
      ],
    };

    return statuses[currentRole as DesignOrderRoles];
  };

  const getProjects = useCallback(
    (wildcard?: string) =>
      dispatch.projects.getProjects({
        wildcardSearchTerm: wildcard,
        wildcardSearchTypes: [WildcardSearchType.Customer],
        buildOrderStatuses: isBuilder()
          ? [BuildOrderStatus.Approved, BuildOrderStatus.MaterialsOrdered]
          : undefined,
        designOrderStatuses: getDesignOrderStatusByRole(),
      }),
    [dispatch.projects]
  );

  const setSearchTerm = useSearchInput(getProjects);

  return <TabPaneList setSearchTerm={setSearchTerm} />;
};

export default ActiveTab;
