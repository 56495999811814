import { FC, useCallback, useEffect } from "react";
import Upload from "antd/lib/upload";
import { Popover, Row, Spin } from "antd";
import ProviderAvatar from "components/providerAvatar/ProviderAvatar";
import useFileInput, {
  FileExtensions,
  MAX_PROFILE_PICTURE_FILE_SIZE_IN_MB,
} from "hooks/useFileInput";
import CustomButton from "components/button/CustomButton";
import { UploadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import styles from "../../../Provider.module.scss";
import CustomTitle from "components/typography/title/CustomTitle";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "app/store";

interface ProviderPictureUploadProps {
  className?: string;
  providerId?: string;
  url?: string | null;
  disabled?: boolean;
}

export const ProviderPictureUpload: FC<ProviderPictureUploadProps> = ({
  className,
  url,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { providerPicture } = useSelector((state) => state.provider);

  const { loading: isCreateProviderLoading } = useSelector(
    (state) => state.loading.effects.provider.createProvider
  );

  const { loading: isUpdateProviderLoading } = useSelector(
    (state) => state.loading.effects.provider.updateProvider
  );

  const {
    acceptedExtensions,
    isFileExtensionValid,
    isFileSizeValid,
    isImgWidthHeightValid,
  } = useFileInput({
    validExtensions: [
      FileExtensions.JPG,
      FileExtensions.JPEG,
      FileExtensions.PNG,
    ],
  });

  useEffect(() => {
    if (url) {
      dispatch.provider.setProviderPicture({
        imageUrl: url,
      });
    }
  }, [url, dispatch.provider]);

  const beforeUpload = useCallback(
    async (file: File): Promise<boolean> => {
      if (
        isFileExtensionValid(file) &&
        isFileSizeValid(file, MAX_PROFILE_PICTURE_FILE_SIZE_IN_MB) &&
        (await isImgWidthHeightValid({ file, width: 400, height: 400 }))
      ) {
        dispatch.provider.setProviderPicture({
          imageUrl: URL.createObjectURL(file),
          file: file,
        });
      }

      return false;
    },
    [
      isFileExtensionValid,
      isFileSizeValid,
      dispatch.provider,
      isImgWidthHeightValid,
    ]
  );

  return (
    <>
      <Row>
        <Upload
          id="upload"
          name="file" // name of the input should match PUT body field name
          accept={acceptedExtensions}
          className={clsx(`avatar-uploader ${className}`, styles.upload)}
          showUploadList={false}
          disabled={disabled}
          beforeUpload={beforeUpload}
        >
          <Spin spinning={isUpdateProviderLoading || isCreateProviderLoading}>
            <ProviderAvatar
              title={t("COMMON.CHANGE")}
              url={providerPicture?.imageUrl}
            />
            <div className={styles.buttonContainer}>
              <CustomButton
                icon={<UploadOutlined />}
                className={styles.selectButton}
              >
                {t("PROVIDER_PICTURE.UPLOAD")}
              </CustomButton>
              <Popover
                title={
                  <CustomTitle
                    extraStrong
                    className={styles.fileInputTooltipTitle}
                  >
                    {t("PROVIDER_PICTURE.FILE_INPUT_TITLE")}
                  </CustomTitle>
                }
                content={
                  <div className={styles.fileInputTooltipContent}>
                    {t("PROVIDER_PICTURE.FILE_INPUT_TOOLTIP")}
                  </div>
                }
                placement="right"
              >
                <InfoCircleOutlined className={styles.infoIcon} />
              </Popover>
            </div>
          </Spin>
        </Upload>
      </Row>
    </>
  );
};
