/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangePasswordCommand
 */
export interface ChangePasswordCommand {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordCommand
     */
    currentPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordCommand
     */
    newPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordCommand
     */
    confirmPassword?: string | null;
}

export function ChangePasswordCommandFromJSON(json: any): ChangePasswordCommand {
    return ChangePasswordCommandFromJSONTyped(json, false);
}

export function ChangePasswordCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePasswordCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentPassword': !exists(json, 'currentPassword') ? undefined : json['currentPassword'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
        'confirmPassword': !exists(json, 'confirmPassword') ? undefined : json['confirmPassword'],
    };
}

export function ChangePasswordCommandToJSON(value?: ChangePasswordCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentPassword': value.currentPassword,
        'newPassword': value.newPassword,
        'confirmPassword': value.confirmPassword,
    };
}


