import { RejectBuildOrderCommand } from "./../../../../api/generated/optimum/models/RejectBuildOrderCommand";
import { createModel } from "@rematch/core";
import { api } from "api";
import {
  BuildMaterialListDto,
  CompleteBuildOrderCommand,
  PricedDesignMaterialDto,
  UpdateBuildMaterialDto,
  UpdateBuildOrderMaterialsCommand,
  UploadBuildFileRequest,
} from "api/generated/optimum";
import { RootModel } from "app/store";
import {
  downloadBlob,
  downloadBlobAsCsv,
  getContentDispositionFileName,
} from "utils/fileReader";
import { ServiceChargeSummaryDto } from "models/ServiceChargeSummaryDto";
interface BuildOrderState {
  buildMaterial?: BuildMaterialListDto;
  updatedMaterialList: UpdateBuildMaterialDto[];
  automation?: ServiceChargeSummaryDto;
  pricedMaterialList: PricedDesignMaterialDto[];
  delivery?: ServiceChargeSummaryDto;
}

const initialState: BuildOrderState = {
  buildMaterial: undefined,
  updatedMaterialList: [],
  automation: undefined,
  pricedMaterialList: [],
  delivery: undefined,
};

export const buildOrder = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setBuildOrderMaterials(state, materials: BuildMaterialListDto) {
      state.buildMaterial = materials;
    },
    setUpdatedMaterialList(state, materialList: UpdateBuildMaterialDto[]) {
      state.updatedMaterialList = materialList;
    },
    setAutomation(state, automation: ServiceChargeSummaryDto) {
      state.automation = automation;
    },
    setPricedMaterialList(
      state,
      pricedMaterialList: PricedDesignMaterialDto[]
    ) {
      state.pricedMaterialList = pricedMaterialList;
    },
    setDelivery(state, delivery: ServiceChargeSummaryDto) {
      state.delivery = delivery;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    return {
      acceptBuildOrder: async (id: number) => {
        await api.buildOrders.acceptBuildOrder({
          acceptBuildOrderCommand: {
            id,
          },
        });
      },
      rejectBuildOrder: async (rejectOrder: RejectBuildOrderCommand) => {
        await api.buildOrders.rejectBuildOrder({
          rejectBuildOrderCommand: rejectOrder,
        });
      },
      getBuildMaterialDetails: async (buildOrderId: number) => {
        const materials = await api.buildOrderMaterials.getBuildOrderMaterials({
          buildOrderId,
        });

        dispatch.buildOrder.setBuildOrderMaterials(materials);
      },
      resetBuildMaterialDetails: async (buildOrderId: number) => {
        await api.buildOrderMaterials.resetBuildOrderMaterials({
          resetBuildOrderMaterialsCommand: {
            buildOrderId,
          },
        });
      },
      orderBuildOrderMaterials: async (buildOrderId: number) => {
        await api.buildOrderMaterials.orderBuildOrderMaterials({
          orderBuildMaterialListCommand: {
            buildOrderId,
          },
        });
      },
      updateBuildOrderMaterials: async (
        buildOrderMaterialRequest: UpdateBuildOrderMaterialsCommand
      ) => {
        await api.buildOrderMaterials.updateBuildOrderMaterials({
          updateBuildOrderMaterialsCommand: buildOrderMaterialRequest,
        });
      },
      exportMaterialList: async (buildOrderId: number) => {
        const file = await api.buildOrderMaterials.exportBuildOrderMaterials({
          buildOrderId,
        });

        downloadBlobAsCsv(file);
      },
      completeBuildOrder: async (buildOrder: CompleteBuildOrderCommand) => {
        await api.buildOrders.completeBuildOrder({
          completeBuildOrderCommand: buildOrder,
        });
      },
      uploadBuildFiles: async (uploadFile: UploadBuildFileRequest) => {
        await api.buildOrders.uploadBuildFile(uploadFile);
      },
      getAutomation: async (buildOrderId: number) => {
        const response = await api.buildOrders.getBuildAutomationCharges({
          buildOrderId,
        });

        dispatch.buildOrder.setAutomation(response);
      },
      getAutomationForAdminNewBuildOrder: async (designOrderId: number) => {
        // For admin in NewBuildOrderStatus(Ajanlatadas fazis) when there are still 3 builders for 1 project. The admin has to see the original automation
        // designOrders.getDesignAutomationCharges returns the data independently from  changes made by any builder.
        const response = await api.designOrders.getDesignAutomationCharges({
          designOrderId,
        });

        dispatch.buildOrder.setAutomation(response);
      },
      getPricedMaterialList: async (designOrderId: number) => {
        const materials = await api.designOrders.getDesignOrderPricedMaterials({
          id: designOrderId,
        });

        dispatch.buildOrder.setPricedMaterialList(materials);
      },
      exportPricedMaterialList: async (designOrderId: number) => {
        const file = await api.designOrders.exportDesignOrderPricedMaterials({
          id: designOrderId,
        });

        downloadBlobAsCsv(file);
      },
      getDelivery: async (buildOrderId: number) => {
        const response = await api.buildOrders.getBuildPanelDeliveryCharges({
          buildOrderId,
        });

        dispatch.buildOrder.setDelivery(response);
      },
      getDeliveryForAdminNewBuildOrder: async (designOrderId: number) => {
        // For admin in NewBuildOrderStatus(Ajanlatadas fazis) when there are still 3 builders for 1 project. The admin has to see the original delivery
        // designOrders.getDesignPanelDeliveryCharges returns the data independently from  changes made by any builder.
        const response = await api.designOrders.getDesignPanelDeliveryCharges({
          designOrderId,
        });

        dispatch.buildOrder.setDelivery(response);
      },
      downloadProposalTemplate: async (buildOrderId: number) => {
        const response = await api.buildOrders.getPrefilledProposalTemplateRaw({
          buildOrderId,
        });

        const fileName = getContentDispositionFileName(response.raw);
        const file = await response.raw.blob();

        downloadBlob(file, fileName);
      },
    };
  },
});
