/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildFileDto,
    BuildFileDtoFromJSON,
    BuildFileDtoFromJSONTyped,
    BuildFileDtoToJSON,
    BuildOrderStatus,
    BuildOrderStatusFromJSON,
    BuildOrderStatusFromJSONTyped,
    BuildOrderStatusToJSON,
    BuildRefusalReason,
    BuildRefusalReasonFromJSON,
    BuildRefusalReasonFromJSONTyped,
    BuildRefusalReasonToJSON,
    PreferredStart,
    PreferredStartFromJSON,
    PreferredStartFromJSONTyped,
    PreferredStartToJSON,
    ProviderDto,
    ProviderDtoFromJSON,
    ProviderDtoFromJSONTyped,
    ProviderDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface BuildOrderDto
 */
export interface BuildOrderDto {
    /**
     * 
     * @type {number}
     * @memberof BuildOrderDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildOrderDto
     */
    builderId?: string | null;
    /**
     * 
     * @type {BuildOrderStatus}
     * @memberof BuildOrderDto
     */
    status?: BuildOrderStatus;
    /**
     * 
     * @type {BuildRefusalReason}
     * @memberof BuildOrderDto
     */
    refusalReason?: BuildRefusalReason;
    /**
     * 
     * @type {PreferredStart}
     * @memberof BuildOrderDto
     */
    preferredStart?: PreferredStart;
    /**
     * 
     * @type {string}
     * @memberof BuildOrderDto
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuildOrderDto
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuildOrderDto
     */
    taskDescription?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BuildOrderDto
     */
    installationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof BuildOrderDto
     */
    completionDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BuildOrderDto
     */
    heatPumpIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuildOrderDto
     */
    heatPumpSerialNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BuildOrderDto
     */
    buildMaterialListOrderDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof BuildOrderDto
     */
    buildMaterialListPaymentDueDate?: Date | null;
    /**
     * 
     * @type {Array<BuildFileDto>}
     * @memberof BuildOrderDto
     */
    buildFiles?: Array<BuildFileDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof BuildOrderDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {ProviderDto}
     * @memberof BuildOrderDto
     */
    builder?: ProviderDto;
}

export function BuildOrderDtoFromJSON(json: any): BuildOrderDto {
    return BuildOrderDtoFromJSONTyped(json, false);
}

export function BuildOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildOrderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'builderId': !exists(json, 'builderId') ? undefined : json['builderId'],
        'status': !exists(json, 'status') ? undefined : BuildOrderStatusFromJSON(json['status']),
        'refusalReason': !exists(json, 'refusalReason') ? undefined : BuildRefusalReasonFromJSON(json['refusalReason']),
        'preferredStart': !exists(json, 'preferredStart') ? undefined : PreferredStartFromJSON(json['preferredStart']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'taskDescription': !exists(json, 'taskDescription') ? undefined : json['taskDescription'],
        'installationDate': !exists(json, 'installationDate') ? undefined : (json['installationDate'] === null ? null : new Date(json['installationDate'])),
        'completionDate': !exists(json, 'completionDate') ? undefined : (json['completionDate'] === null ? null : new Date(json['completionDate'])),
        'heatPumpIdentifier': !exists(json, 'heatPumpIdentifier') ? undefined : json['heatPumpIdentifier'],
        'heatPumpSerialNumber': !exists(json, 'heatPumpSerialNumber') ? undefined : json['heatPumpSerialNumber'],
        'buildMaterialListOrderDate': !exists(json, 'buildMaterialListOrderDate') ? undefined : (json['buildMaterialListOrderDate'] === null ? null : new Date(json['buildMaterialListOrderDate'])),
        'buildMaterialListPaymentDueDate': !exists(json, 'buildMaterialListPaymentDueDate') ? undefined : (json['buildMaterialListPaymentDueDate'] === null ? null : new Date(json['buildMaterialListPaymentDueDate'])),
        'buildFiles': !exists(json, 'buildFiles') ? undefined : (json['buildFiles'] === null ? null : (json['buildFiles'] as Array<any>).map(BuildFileDtoFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'builder': !exists(json, 'builder') ? undefined : ProviderDtoFromJSON(json['builder']),
    };
}

export function BuildOrderDtoToJSON(value?: BuildOrderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'builderId': value.builderId,
        'status': BuildOrderStatusToJSON(value.status),
        'refusalReason': BuildRefusalReasonToJSON(value.refusalReason),
        'preferredStart': PreferredStartToJSON(value.preferredStart),
        'note': value.note,
        'message': value.message,
        'taskDescription': value.taskDescription,
        'installationDate': value.installationDate === undefined ? undefined : (value.installationDate === null ? null : value.installationDate.toISOString()),
        'completionDate': value.completionDate === undefined ? undefined : (value.completionDate === null ? null : value.completionDate.toISOString()),
        'heatPumpIdentifier': value.heatPumpIdentifier,
        'heatPumpSerialNumber': value.heatPumpSerialNumber,
        'buildMaterialListOrderDate': value.buildMaterialListOrderDate === undefined ? undefined : (value.buildMaterialListOrderDate === null ? null : value.buildMaterialListOrderDate.toISOString()),
        'buildMaterialListPaymentDueDate': value.buildMaterialListPaymentDueDate === undefined ? undefined : (value.buildMaterialListPaymentDueDate === null ? null : value.buildMaterialListPaymentDueDate.toISOString()),
        'buildFiles': value.buildFiles === undefined ? undefined : (value.buildFiles === null ? null : (value.buildFiles as Array<any>).map(BuildFileDtoToJSON)),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'builder': ProviderDtoToJSON(value.builder),
    };
}


