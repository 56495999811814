import { ServiceChargeSummaryDto } from "models/ServiceChargeSummaryDto";
import React, { useMemo } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/format";
import useBuild from "../../useBuild";

interface MaterialListCSVLinkProps {
  data?: ServiceChargeSummaryDto;
}

const MaterialListCSVLink: React.FC<MaterialListCSVLinkProps> = ({ data }) => {
  const { t } = useTranslation();
  const { getAutomationExportProductNameTransKeyByCode } = useBuild();

  const exportData = useMemo(
    () =>
      data?.serviceCharges?.map((charge) => ({
        ...charge,
        productName: t(
          getAutomationExportProductNameTransKeyByCode(
            charge.code === "SERV 60" ? "SERV60_EXPORT" : charge.code ?? ""
          )
        ),
      })),
    [data?.serviceCharges, getAutomationExportProductNameTransKeyByCode, t]
  );

  return (
    <CSVLink
      data={exportData ?? []}
      filename={`${formatDate.long(new Date())}.csv`}
      headers={[
        {
          label: t("MATERIAL_LIST_MODAL.LISTS.PRODUCT_CODE"),
          key: "code",
        },
        {
          label: t("MATERIAL_LIST_MODAL.LISTS.PRODUCT_NAME"),
          key: "productName",
        },
        {
          label: t("MATERIAL_LIST_MODAL.LISTS.UNIT_PRICE"),
          key: "price",
        },
        {
          label: t("MATERIAL_LIST_MODAL.LISTS.QUANTITY"),
          key: "amount",
        },
        {
          label: t("MATERIAL_LIST_MODAL.LISTS.TOTAL_PRICE"),
          key: "totalPrice",
        },
      ]}
    >
      {t("COMMON.EXPORT_CSV")}
    </CSVLink>
  );
};

export default MaterialListCSVLink;
