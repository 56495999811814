/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PreferredStart {
    Invalid = 'Invalid',
    Asap = 'Asap',
    Within3Months = 'Within3Months',
    Within6Months = 'Within6Months',
    Other = 'Other'
}

export function PreferredStartFromJSON(json: any): PreferredStart {
    return PreferredStartFromJSONTyped(json, false);
}

export function PreferredStartFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreferredStart {
    return json as PreferredStart;
}

export function PreferredStartToJSON(value?: PreferredStart | null): any {
    return value as any;
}

