import { BuildServiceChargeDto, MaterialDto } from "api/generated/optimum";
import CustomTable, {
  CustomTableProps,
} from "components/GrapeAntdTable/CustomTable";
import { FC } from "react";
import styles from "./MaterialListModal.module.scss";

export interface ListsProps {
  data: MaterialDto[] | BuildServiceChargeDto[];
  tableProps: CustomTableProps;
  afterContent?: (data: MaterialDto[]) => JSX.Element;
}

const MaterialTable: FC<ListsProps> = ({ data, tableProps, afterContent }) => {
  return (
    <>
      <CustomTable
        pagination={false}
        selectable={false}
        className={styles.table}
        dataSource={data}
        {...tableProps}
        hideHeader
      />
      {afterContent && afterContent(data)}
    </>
  );
};

export default MaterialTable;
