import { useTranslation } from "react-i18next";
import { UploadFile } from "antd/lib/upload/interface";
import {
  DesignFileDto,
  DesignFileType,
  DesignRefusalReason,
} from "api/generated/optimum";
import { useDispatch, useSelector } from "app/store";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AlertModalType } from "components/alertModal/AlertModal.store";

const useDesign = () => {
  const { projectCode } = useParams();
  const { currentProject } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const thermalCalculationFiles = useMemo(
    () =>
      currentProject?.designFiles?.filter(
        (file: DesignFileDto) => file.type === DesignFileType.ThermalCalculation
      ),
    [currentProject?.designFiles]
  );

  const floorPlanFiles = useMemo(
    () =>
      currentProject?.designFiles?.filter(
        (file: DesignFileDto) => file.type === DesignFileType.FloorPlan
      ),
    [currentProject?.designFiles]
  );

  const finalPlanFile = useMemo(
    () =>
      currentProject?.designFiles?.filter(
        (file: DesignFileDto) => file.type === DesignFileType.FinalPlan
      ),
    [currentProject?.designFiles]
  );

  const roomLayoutFiles = useMemo(
    () =>
      currentProject?.designFiles?.filter(
        (file: DesignFileDto) => file.type === DesignFileType.RoomLayout
      ),
    [currentProject?.designFiles]
  );

  const otherFiles = useMemo(
    () =>
      currentProject?.designFiles?.filter(
        (file: DesignFileDto) => file.type === DesignFileType.Design
      ),
    [currentProject?.designFiles]
  );

  const otherFilesAsUploadFile: UploadFile[] = useMemo(
    () =>
      otherFiles?.map<UploadFile>((filteredFile) => ({
        uid: filteredFile.fileName || "",
        name: filteredFile.fileName || "",
        url: filteredFile.downloadUrl || "",
      })) || [],
    [otherFiles]
  );

  const removeOtherFileHandler = useCallback(
    async (file: UploadFile) => {
      if (currentProject?.designOrderId) {
        try {
          await dispatch.projects.removeDesignFile({
            id: currentProject?.designOrderId,
            fileName: file.uid,
          });
          projectCode &&
            dispatch.projects.getProject({
              projectCode: projectCode,
            });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [projectCode, dispatch.projects, currentProject?.designOrderId]
  );

  const beforeUploadHandler = useCallback(
    async (file: File, designFileType: DesignFileType) => {
      if (currentProject?.designOrderId) {
        try {
          await dispatch.projects.uploadDesignFile({
            id: currentProject?.designOrderId,
            file: file,
            type: designFileType,
          });

          dispatch.projects.setNgbsUpdatedorder(true);

          projectCode &&
            dispatch.projects.getProject({
              projectCode: projectCode,
            });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [dispatch.projects, currentProject?.designOrderId, projectCode]
  );

  const refuseOrder = (note: string, reason: DesignRefusalReason) => {
    if (!currentProject?.designOrderId) {
      console.log("Missing designOrderId.");
      return;
    }

    dispatch.projects
      .refuseProject({
        refuseDesignOrderCommand: {
          id: currentProject?.designOrderId,
          reason,
          note,
        },
      })
      .then(() => {
        dispatch.alertModal.openModal({
          cardTitle: t("PROJECT_DETAILS.MODAL.TITLE"),
          title: t("PROJECT_DETAILS.MODAL.DECLINE_SUCCESS"),
          buttonLabel: t("COMMON.ALRIGHT"),
          width: "59rem",
          type: AlertModalType.Success,
          onButtonClick: () => navigate("/projects"),
        });
      });
  };

  return {
    thermalCalculationFiles,
    floorPlanFiles,
    finalPlanFile,
    otherFiles,
    otherFilesAsUploadFile,
    roomLayoutFiles,
    designOrderId: currentProject?.designOrderId,
    currentProject,
    removeOtherFileHandler,
    beforeUploadHandler,
    refuseOrder,
  };
};

export default useDesign;
