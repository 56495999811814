/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BuildOrderStatus,
    BuildOrderStatusFromJSON,
    BuildOrderStatusToJSON,
    DesignOrderDto,
    DesignOrderDtoFromJSON,
    DesignOrderDtoToJSON,
    DesignOrderStatus,
    DesignOrderStatusFromJSON,
    DesignOrderStatusToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ProjectDto,
    ProjectDtoFromJSON,
    ProjectDtoToJSON,
    ProjectListDto,
    ProjectListDtoFromJSON,
    ProjectListDtoToJSON,
    ProjectsStatisticsDto,
    ProjectsStatisticsDtoFromJSON,
    ProjectsStatisticsDtoToJSON,
    WildcardSearchType,
    WildcardSearchTypeFromJSON,
    WildcardSearchTypeToJSON,
} from '../models';

export interface GetProjectByCodeRequest {
    projectCode: string;
}

export interface GetProjectDesignByCodeRequest {
    projectCode: string;
}

export interface ProjectsStatisticsRequest {
    engineerId?: string;
    builderId?: string;
}

export interface SearchProjectsRequest {
    projectStatuses?: Array<string> | null;
    designOrderStatuses?: Array<DesignOrderStatus> | null;
    buildOrderStatuses?: Array<BuildOrderStatus> | null;
    engineerId?: string | null;
    builderId?: string | null;
    wildcardSearchTypes?: Array<WildcardSearchType>;
    wildcardSearchTerm?: string;
}

/**
 * 
 */
export class ProjectsApi extends runtime.BaseAPI {

    /**
     * Export projects activity log.
     */
    async exportProjectActivitiesRaw(): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Projects/Export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export projects activity log.
     */
    async exportProjectActivities(): Promise<Blob> {
        const response = await this.exportProjectActivitiesRaw();
        return await response.value();
    }

    /**
     * Get project details by project code
     */
    async getProjectByCodeRaw(requestParameters: GetProjectByCodeRequest): Promise<runtime.ApiResponse<ProjectDto>> {
        if (requestParameters.projectCode === null || requestParameters.projectCode === undefined) {
            throw new runtime.RequiredError('projectCode','Required parameter requestParameters.projectCode was null or undefined when calling getProjectByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Projects/{projectCode}`.replace(`{${"projectCode"}}`, encodeURIComponent(String(requestParameters.projectCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectDtoFromJSON(jsonValue));
    }

    /**
     * Get project details by project code
     */
    async getProjectByCode(requestParameters: GetProjectByCodeRequest): Promise<ProjectDto> {
        const response = await this.getProjectByCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Allows anonymous access for a Customer to a Design.  Get a Design by the project code.
     */
    async getProjectDesignByCodeRaw(requestParameters: GetProjectDesignByCodeRequest): Promise<runtime.ApiResponse<DesignOrderDto>> {
        if (requestParameters.projectCode === null || requestParameters.projectCode === undefined) {
            throw new runtime.RequiredError('projectCode','Required parameter requestParameters.projectCode was null or undefined when calling getProjectDesignByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Projects/{projectCode}/Design`.replace(`{${"projectCode"}}`, encodeURIComponent(String(requestParameters.projectCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DesignOrderDtoFromJSON(jsonValue));
    }

    /**
     * Allows anonymous access for a Customer to a Design.  Get a Design by the project code.
     */
    async getProjectDesignByCode(requestParameters: GetProjectDesignByCodeRequest): Promise<DesignOrderDto> {
        const response = await this.getProjectDesignByCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get projects statistics for Engineer and Builder.
     */
    async projectsStatisticsRaw(requestParameters: ProjectsStatisticsRequest): Promise<runtime.ApiResponse<ProjectsStatisticsDto>> {
        const queryParameters: any = {};

        if (requestParameters.engineerId !== undefined) {
            queryParameters['engineerId'] = requestParameters.engineerId;
        }

        if (requestParameters.builderId !== undefined) {
            queryParameters['builderId'] = requestParameters.builderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Projects/Statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectsStatisticsDtoFromJSON(jsonValue));
    }

    /**
     * Get projects statistics for Engineer and Builder.
     */
    async projectsStatistics(requestParameters: ProjectsStatisticsRequest): Promise<ProjectsStatisticsDto> {
        const response = await this.projectsStatisticsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get projects
     */
    async searchProjectsRaw(requestParameters: SearchProjectsRequest): Promise<runtime.ApiResponse<Array<ProjectListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.projectStatuses) {
            queryParameters['projectStatuses'] = requestParameters.projectStatuses;
        }

        if (requestParameters.designOrderStatuses) {
            queryParameters['designOrderStatuses'] = requestParameters.designOrderStatuses;
        }

        if (requestParameters.buildOrderStatuses) {
            queryParameters['buildOrderStatuses'] = requestParameters.buildOrderStatuses;
        }

        if (requestParameters.engineerId !== undefined) {
            queryParameters['engineerId'] = requestParameters.engineerId;
        }

        if (requestParameters.builderId !== undefined) {
            queryParameters['builderId'] = requestParameters.builderId;
        }

        if (requestParameters.wildcardSearchTypes) {
            queryParameters['wildcardSearch.types'] = requestParameters.wildcardSearchTypes;
        }

        if (requestParameters.wildcardSearchTerm !== undefined) {
            queryParameters['wildcardSearch.term'] = requestParameters.wildcardSearchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectListDtoFromJSON));
    }

    /**
     * Get projects
     */
    async searchProjects(requestParameters: SearchProjectsRequest): Promise<Array<ProjectListDto>> {
        const response = await this.searchProjectsRaw(requestParameters);
        return await response.value();
    }

}
