/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildServiceChargeDto,
    BuildServiceChargeDtoFromJSON,
    BuildServiceChargeDtoFromJSONTyped,
    BuildServiceChargeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface BuildServiceChargeSummaryDto
 */
export interface BuildServiceChargeSummaryDto {
    /**
     * 
     * @type {Array<BuildServiceChargeDto>}
     * @memberof BuildServiceChargeSummaryDto
     */
    serviceCharges?: Array<BuildServiceChargeDto> | null;
    /**
     * 
     * @type {number}
     * @memberof BuildServiceChargeSummaryDto
     */
    readonly totalPrice?: number;
}

export function BuildServiceChargeSummaryDtoFromJSON(json: any): BuildServiceChargeSummaryDto {
    return BuildServiceChargeSummaryDtoFromJSONTyped(json, false);
}

export function BuildServiceChargeSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildServiceChargeSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceCharges': !exists(json, 'serviceCharges') ? undefined : (json['serviceCharges'] === null ? null : (json['serviceCharges'] as Array<any>).map(BuildServiceChargeDtoFromJSON)),
        'totalPrice': !exists(json, 'totalPrice') ? undefined : json['totalPrice'],
    };
}

export function BuildServiceChargeSummaryDtoToJSON(value?: BuildServiceChargeSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceCharges': value.serviceCharges === undefined ? undefined : (value.serviceCharges === null ? null : (value.serviceCharges as Array<any>).map(BuildServiceChargeDtoToJSON)),
    };
}


