/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticationDto,
    AuthenticationDtoFromJSON,
    AuthenticationDtoToJSON,
    ChangePasswordCommand,
    ChangePasswordCommandFromJSON,
    ChangePasswordCommandToJSON,
    ConfirmEmailAndSetPasswordCommand,
    ConfirmEmailAndSetPasswordCommandFromJSON,
    ConfirmEmailAndSetPasswordCommandToJSON,
    CreateProviderCommand,
    CreateProviderCommandFromJSON,
    CreateProviderCommandToJSON,
    ForgotPasswordCommand,
    ForgotPasswordCommandFromJSON,
    ForgotPasswordCommandToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ProviderDto,
    ProviderDtoFromJSON,
    ProviderDtoToJSON,
    ProviderType,
    ProviderTypeFromJSON,
    ProviderTypeToJSON,
    ResetPasswordCommand,
    ResetPasswordCommandFromJSON,
    ResetPasswordCommandToJSON,
    SetMyActiveFlagCommand,
    SetMyActiveFlagCommandFromJSON,
    SetMyActiveFlagCommandToJSON,
    SetProviderActiveFlagCommand,
    SetProviderActiveFlagCommandFromJSON,
    SetProviderActiveFlagCommandToJSON,
    SetProviderIsEnabledFlagCommand,
    SetProviderIsEnabledFlagCommandFromJSON,
    SetProviderIsEnabledFlagCommandToJSON,
    SetProviderPublishedFlagCommand,
    SetProviderPublishedFlagCommandFromJSON,
    SetProviderPublishedFlagCommandToJSON,
    UpdateProviderCommand,
    UpdateProviderCommandFromJSON,
    UpdateProviderCommandToJSON,
    ValidateEmailConfirmationCommand,
    ValidateEmailConfirmationCommandFromJSON,
    ValidateEmailConfirmationCommandToJSON,
    ValidateResetPasswordCommand,
    ValidateResetPasswordCommandFromJSON,
    ValidateResetPasswordCommandToJSON,
} from '../models';

export interface ChangePasswordRequest {
    changePasswordCommand?: ChangePasswordCommand;
}

export interface ConfirmEmailAndSetPasswordRequest {
    confirmEmailAndSetPasswordCommand?: ConfirmEmailAndSetPasswordCommand;
}

export interface CreateProviderRequest {
    createProviderCommand?: CreateProviderCommand;
}

export interface ForgotPasswordRequest {
    forgotPasswordCommand?: ForgotPasswordCommand;
}

export interface GetProviderRequest {
    id: string;
}

export interface GetProviderListRequest {
    isPublished: boolean;
    isEnabled: boolean;
    type?: ProviderType;
    wildcard?: string | null;
}

export interface ResendWelcomeEmailRequest {
    id: string;
}

export interface ResetPasswordRequest {
    resetPasswordCommand?: ResetPasswordCommand;
}

export interface SetActiveFlagRequest {
    setProviderActiveFlagCommand?: SetProviderActiveFlagCommand;
}

export interface SetEnabledFlagRequest {
    setProviderIsEnabledFlagCommand?: SetProviderIsEnabledFlagCommand;
}

export interface SetMyActiveFlagRequest {
    setMyActiveFlagCommand?: SetMyActiveFlagCommand;
}

export interface SetPublishedFlagRequest {
    setProviderPublishedFlagCommand?: SetProviderPublishedFlagCommand;
}

export interface UpdateProviderRequest {
    updateProviderCommand?: UpdateProviderCommand;
}

export interface UploadProfilePictureRequest {
    id: string;
    file?: Blob;
}

export interface ValidateEmailConfirmationRequest {
    validateEmailConfirmationCommand?: ValidateEmailConfirmationCommand;
}

export interface ValidateResetPasswordRequest {
    validateResetPasswordCommand?: ValidateResetPasswordCommand;
}

/**
 * 
 */
export class AccountsApi extends runtime.BaseAPI {

    /**
     * Sets the user\'s password to the desired new password.
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest): Promise<runtime.ApiResponse<AuthenticationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/ChangePassword`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordCommandToJSON(requestParameters.changePasswordCommand),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationDtoFromJSON(jsonValue));
    }

    /**
     * Sets the user\'s password to the desired new password.
     */
    async changePassword(requestParameters: ChangePasswordRequest): Promise<AuthenticationDto> {
        const response = await this.changePasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     * Confirms the email address and sets the initial password.
     */
    async confirmEmailAndSetPasswordRaw(requestParameters: ConfirmEmailAndSetPasswordRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/ConfirmEmailAndSetPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmEmailAndSetPasswordCommandToJSON(requestParameters.confirmEmailAndSetPasswordCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirms the email address and sets the initial password.
     */
    async confirmEmailAndSetPassword(requestParameters: ConfirmEmailAndSetPasswordRequest): Promise<void> {
        await this.confirmEmailAndSetPasswordRaw(requestParameters);
    }

    /**
     * Create provider profile.
     */
    async createProviderRaw(requestParameters: CreateProviderRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/Provider`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProviderCommandToJSON(requestParameters.createProviderCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create provider profile.
     */
    async createProvider(requestParameters: CreateProviderRequest): Promise<string> {
        const response = await this.createProviderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Export providers
     */
    async exportProvidersRaw(): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/Providers/Export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export providers
     */
    async exportProviders(): Promise<Blob> {
        const response = await this.exportProvidersRaw();
        return await response.value();
    }

    /**
     * Sends a password reset email to the provided email address.
     */
    async forgotPasswordRaw(requestParameters: ForgotPasswordRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/ForgotPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordCommandToJSON(requestParameters.forgotPasswordCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends a password reset email to the provided email address.
     */
    async forgotPassword(requestParameters: ForgotPasswordRequest): Promise<void> {
        await this.forgotPasswordRaw(requestParameters);
    }

    /**
     * Get the current user\'s provider profile
     */
    async getMyProfileRaw(): Promise<runtime.ApiResponse<ProviderDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/MyProfile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderDtoFromJSON(jsonValue));
    }

    /**
     * Get the current user\'s provider profile
     */
    async getMyProfile(): Promise<ProviderDto> {
        const response = await this.getMyProfileRaw();
        return await response.value();
    }

    /**
     * Get provider
     */
    async getProviderRaw(requestParameters: GetProviderRequest): Promise<runtime.ApiResponse<ProviderDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/Provider/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderDtoFromJSON(jsonValue));
    }

    /**
     * Get provider
     */
    async getProvider(requestParameters: GetProviderRequest): Promise<ProviderDto> {
        const response = await this.getProviderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of providers
     */
    async getProviderListRaw(requestParameters: GetProviderListRequest): Promise<runtime.ApiResponse<Array<ProviderDto>>> {
        if (requestParameters.isPublished === null || requestParameters.isPublished === undefined) {
            throw new runtime.RequiredError('isPublished','Required parameter requestParameters.isPublished was null or undefined when calling getProviderList.');
        }

        if (requestParameters.isEnabled === null || requestParameters.isEnabled === undefined) {
            throw new runtime.RequiredError('isEnabled','Required parameter requestParameters.isEnabled was null or undefined when calling getProviderList.');
        }

        const queryParameters: any = {};

        if (requestParameters.isPublished !== undefined) {
            queryParameters['isPublished'] = requestParameters.isPublished;
        }

        if (requestParameters.isEnabled !== undefined) {
            queryParameters['isEnabled'] = requestParameters.isEnabled;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.wildcard !== undefined) {
            queryParameters['wildcard'] = requestParameters.wildcard;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/Provider`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProviderDtoFromJSON));
    }

    /**
     * Get list of providers
     */
    async getProviderList(requestParameters: GetProviderListRequest): Promise<Array<ProviderDto>> {
        const response = await this.getProviderListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Resend welcome email.
     */
    async resendWelcomeEmailRaw(requestParameters: ResendWelcomeEmailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resendWelcomeEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/{id}/ResendWelcomeEmail`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend welcome email.
     */
    async resendWelcomeEmail(requestParameters: ResendWelcomeEmailRequest): Promise<void> {
        await this.resendWelcomeEmailRaw(requestParameters);
    }

    /**
     * Resets the password.
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/ResetPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordCommandToJSON(requestParameters.resetPasswordCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resets the password.
     */
    async resetPassword(requestParameters: ResetPasswordRequest): Promise<void> {
        await this.resetPasswordRaw(requestParameters);
    }

    /**
     * Set provider IsActive flag.
     */
    async setActiveFlagRaw(requestParameters: SetActiveFlagRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/Provider/ActiveFlag`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetProviderActiveFlagCommandToJSON(requestParameters.setProviderActiveFlagCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set provider IsActive flag.
     */
    async setActiveFlag(requestParameters: SetActiveFlagRequest): Promise<void> {
        await this.setActiveFlagRaw(requestParameters);
    }

    /**
     * Set provider IsEnabled flag.
     */
    async setEnabledFlagRaw(requestParameters: SetEnabledFlagRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/Provider/EnabledFlag`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetProviderIsEnabledFlagCommandToJSON(requestParameters.setProviderIsEnabledFlagCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set provider IsEnabled flag.
     */
    async setEnabledFlag(requestParameters: SetEnabledFlagRequest): Promise<void> {
        await this.setEnabledFlagRaw(requestParameters);
    }

    /**
     * Sets the IsActive flag of the current user
     */
    async setMyActiveFlagRaw(requestParameters: SetMyActiveFlagRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/MyActiveFlag`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetMyActiveFlagCommandToJSON(requestParameters.setMyActiveFlagCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sets the IsActive flag of the current user
     */
    async setMyActiveFlag(requestParameters: SetMyActiveFlagRequest): Promise<void> {
        await this.setMyActiveFlagRaw(requestParameters);
    }

    /**
     * Set provider IsPublished flag.
     */
    async setPublishedFlagRaw(requestParameters: SetPublishedFlagRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/Provider/PublishedFlag`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetProviderPublishedFlagCommandToJSON(requestParameters.setProviderPublishedFlagCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set provider IsPublished flag.
     */
    async setPublishedFlag(requestParameters: SetPublishedFlagRequest): Promise<void> {
        await this.setPublishedFlagRaw(requestParameters);
    }

    /**
     * Update provider profile.
     */
    async updateProviderRaw(requestParameters: UpdateProviderRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/Provider`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProviderCommandToJSON(requestParameters.updateProviderCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update provider profile.
     */
    async updateProvider(requestParameters: UpdateProviderRequest): Promise<void> {
        await this.updateProviderRaw(requestParameters);
    }

    /**
     * Upload profile picture.
     */
    async uploadProfilePictureRaw(requestParameters: UploadProfilePictureRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadProfilePicture.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/Accounts/{id}/ProfilePicture`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Upload profile picture.
     */
    async uploadProfilePicture(requestParameters: UploadProfilePictureRequest): Promise<string> {
        const response = await this.uploadProfilePictureRaw(requestParameters);
        return await response.value();
    }

    /**
     * Validates an email confirmation token.
     */
    async validateEmailConfirmationRaw(requestParameters: ValidateEmailConfirmationRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/ValidateEmailConfirmation`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateEmailConfirmationCommandToJSON(requestParameters.validateEmailConfirmationCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validates an email confirmation token.
     */
    async validateEmailConfirmation(requestParameters: ValidateEmailConfirmationRequest): Promise<void> {
        await this.validateEmailConfirmationRaw(requestParameters);
    }

    /**
     * Validates a password reset token.
     */
    async validateResetPasswordRaw(requestParameters: ValidateResetPasswordRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Accounts/ValidateResetPassword`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateResetPasswordCommandToJSON(requestParameters.validateResetPasswordCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validates a password reset token.
     */
    async validateResetPassword(requestParameters: ValidateResetPasswordRequest): Promise<void> {
        await this.validateResetPasswordRaw(requestParameters);
    }

}
