import { createModel } from "@rematch/core";
import { api } from "api";
import {
  CountyDto,
  SpecializationCategory,
  SpecializationDto,
  TagDto,
} from "api/generated/optimum";
import { RootModel } from "app/store";

interface ReferenceDataState {
  counties: CountyDto[];
  specializations: SpecializationDto[];
  tags: TagDto[];
  heatPumpIdentifiers: string[];
}

const initialState: ReferenceDataState = {
  counties: [],
  specializations: [],
  tags: [],
  heatPumpIdentifiers: [],
};

export const referenceData = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setCounties(state, counties: CountyDto[]) {
      state.counties = counties;
    },
    setSpecializations(state, specializations: SpecializationDto[]) {
      state.specializations = specializations;
    },
    setTags(state, tags: TagDto[]) {
      state.tags = tags;
    },
    setHeatPumpIdentifiers(state, identifiers: string[]) {
      state.heatPumpIdentifiers = identifiers;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    return {
      getCounties: async (): Promise<void> => {
        const response = await api.referenceData.getCounties();
        dispatch.referenceData.setCounties(response);
      },
      getSpecializations: async (
        categories?: SpecializationCategory[]
      ): Promise<void> => {
        const response = await api.referenceData.getSpecializations({
          categories,
        });
        dispatch.referenceData.setSpecializations(response);
      },
      getTags: async (): Promise<void> => {
        const response = await api.referenceData.getTags();
        dispatch.referenceData.setTags(response);
      },
      getAll: async (categories?: SpecializationCategory[]): Promise<void> => {
        dispatch.referenceData.getCounties();
        dispatch.referenceData.getSpecializations(categories);
        dispatch.referenceData.getTags();
      },
      getHeatPumpIdentifiers: async (): Promise<void> => {
        const response = await api.referenceData.getHeatPumpIdentifiers();
        dispatch.referenceData.setHeatPumpIdentifiers(response);
      },
    };
  },
});
