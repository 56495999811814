import { useTranslation } from "react-i18next";
import styles from "./OptiComfortPlan.module.scss";

import { Col, Row } from "antd";
import CustomTitle from "components/typography/title/CustomTitle";
import { ClockCircleOutlined, PaperClipOutlined } from "@ant-design/icons";
import CustomButton from "components/button/CustomButton";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { useCallback, useState } from "react";
import Card from "components/card/Card";
import clsx from "clsx";
import { useDispatch, useSelector } from "app/store";
import {
  DesignFileDto,
  DesignFileType,
  DesignOrderStatus,
  OptiComfortPlanActions,
} from "api/generated/optimum";
import UploadButton from "components/button/UploadButton";
import useFileInput, { FileExtensions } from "hooks/useFileInput";
import useDesign from "../../useDesign";
import SendPlanModal from "../sendPlanModal/SendPlanModal";
import { AlertModalType } from "components/alertModal/AlertModal.store";
import { formatDate } from "utils/format";
import { isNgbs } from "utils/authUtils";
import DesignMaterialListModal from "../../designMaterialListModal/DesignMaterialListModal";

const OptiComfortPlan: React.FC<{
  designOrderId?: number | null;
}> = ({ designOrderId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentProject, materialList, ngbsUpdatedOrder } = useSelector(
    (state) => state.projects
  );

  const [isMaterialListModalVisible, setIsMaterialListModalVisible] =
    useState<boolean>(false);

  const [isNotifyEngineerModalVisible, setIsNotifyEngineerModalVisible] =
    useState<boolean>(false);

  const { beforeUploadHandler, finalPlanFile, roomLayoutFiles } = useDesign();

  const {
    acceptedExtensions: roomLayoutAcceptedExtension,
    isFileValid: roomlayoutIsFileValid,
  } = useFileInput({
    validExtensions: [FileExtensions.XLS, FileExtensions.XLSX],
  });

  const {
    acceptedExtensions: finalPlanAcceptedExtension,
    isFileValid: finalPlanIsValid,
  } = useFileInput({
    validExtensions: [FileExtensions.DWG],
  });

  const sendPlanHandler = async (note: string) => {
    if (designOrderId != null) {
      await dispatch.projects.manageOptiComfortPlan({
        action:
          currentProject?.designOrderStatus === DesignOrderStatus.OptiComfortWip
            ? OptiComfortPlanActions.Finalize
            : OptiComfortPlanActions.Modify,
        designOrderId: designOrderId,
        note,
      });

      dispatch.projects.setNgbsUpdatedorder(false);
      setIsMaterialListModalVisible(false);
      setIsNotifyEngineerModalVisible(false);

      currentProject?.projectCode &&
        dispatch.projects.getProject({
          projectCode: currentProject?.projectCode,
        });

      dispatch.alertModal.openModal({
        title: t("PROJECTS.DETAIL.DESIGN.MATERIAL.NOTIFY_MODAL.NOTIFY_SENT"),
        buttonLabel: t("COMMON.ALRIGHT"),
        cardTitle: t("PROJECTS.DETAIL.DESIGN.MATERIAL.NOTIFY_MODAL.TITLE"),
        type: AlertModalType.Success,
      });
    }
  };

  const MaterialListActions = useCallback(
    () => (
      <Row className={styles.materialListActionBtnContainer}>
        <CustomButton
          variant="provider"
          onClick={() => setIsMaterialListModalVisible(true)}
        >
          {t("PROJECTS.DETAIL.DESIGN.BTN_VIEW")}
        </CustomButton>
        <CustomButton
          variant="provider"
          onClick={() =>
            designOrderId && dispatch.projects.exportMaterial(designOrderId)
          }
        >
          {t("PROJECTS.DETAIL.DESIGN.BTN_EXPORT")}
        </CustomButton>
      </Row>
    ),
    [t, designOrderId, dispatch.projects]
  );

  const fileList = useCallback(
    (fileList?: DesignFileDto[], noFileText?: string) => {
      return (
        <>
          {fileList?.length ? (
            fileList.map((file: DesignFileDto, i: number) => (
              <a
                key={i}
                className={styles.fileListElement}
                href={file.downloadUrl || ""}
              >
                <PaperClipOutlined /> {file.fileName}
              </a>
            ))
          ) : (
            <CustomParagraph italic>{noFileText}</CustomParagraph>
          )}
        </>
      );
    },
    []
  );

  const MaterialList = useCallback(
    () => (
      <div className={styles.materialList}>
        {currentProject?.latestOptiComfortModificationDate && (
          <Row
            justify="center"
            className={clsx(
              styles.lastModifiedDate,
              styles.engineerModifiedDate
            )}
          >
            {t("COMMON.LAST_MODIFIED_DATE", {
              date: formatDate.dateTime(
                currentProject?.latestOptiComfortModificationDate
              ),
            })}
          </Row>
        )}
        <Row>
          <Col span={8}>
            <CustomTitle defaultFontSize extraStrong>
              {t("PROJECTS.DETAIL.DESIGN.BLUE_PRINT")}
            </CustomTitle>
          </Col>
          <Col span={16}>
            {fileList(finalPlanFile, t("PROJECTS.DETAIL.DESIGN.NO_ALLOCATION"))}
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <CustomTitle defaultFontSize extraStrong>
              {t("PROJECTS.DETAIL.DESIGN.ROOM_ALLOCATION")}
            </CustomTitle>
          </Col>
          <Col span={16}>
            {fileList(
              roomLayoutFiles,
              t("PROJECTS.DETAIL.DESIGN.NO_ALLOCATION")
            )}
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <CustomTitle defaultFontSize extraStrong>
              {t("PROJECTS.DETAIL.DESIGN.MATERIALS")}
            </CustomTitle>
          </Col>
          <Col span={16}>
            {materialList.length ? (
              <MaterialListActions />
            ) : (
              <CustomParagraph italic>
                {t("PROJECTS.DETAIL.DESIGN.NO_MATERIALS")}
              </CustomParagraph>
            )}
          </Col>
        </Row>
      </div>
    ),
    [
      t,
      MaterialListActions,
      materialList.length,
      fileList,
      roomLayoutFiles,
      currentProject?.latestOptiComfortModificationDate,
      finalPlanFile,
    ]
  );

  const DesignWip = useCallback(() => {
    if (!isNgbs()) {
      return (
        <div className={styles.designWip}>
          {currentProject?.latestOptiComfortModificationDate && (
            <Row
              justify="center"
              className={clsx(
                styles.lastModifiedDate,
                styles.engineerModifiedDate
              )}
            >
              {t("COMMON.LAST_MODIFIED_DATE", {
                date: formatDate.dateTime(
                  currentProject?.latestOptiComfortModificationDate
                ),
              })}
            </Row>
          )}
          <div
            className={clsx(styles.clock, {
              [styles.clockWithModification]:
                currentProject?.latestOptiComfortModificationDate,
            })}
          >
            <ClockCircleOutlined className={styles.clockIcon} />
            <CustomTitle extraStrong defaultFontSize color="lightBlue">
              {t("PROJECTS.DETAIL.DESIGN.DESIGN_WIP")}
            </CustomTitle>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.materialList}>
        {currentProject?.latestOptiComfortModificationDate && (
          <Row
            justify="center"
            className={clsx(styles.lastModifiedDate, styles.ngbsModifiedDate)}
          >
            {t("COMMON.LAST_MODIFIED_DATE", {
              date: formatDate.dateTime(
                currentProject?.latestOptiComfortModificationDate
              ),
            })}
          </Row>
        )}
        <Row justify="space-between">
          <Col>
            <CustomTitle defaultFontSize extraStrong>
              {t("PROJECTS.DETAIL.DESIGN.ROOM_ALLOCATION")}
            </CustomTitle>
          </Col>
          {currentProject?.designOrderStatus !==
            DesignOrderStatus.Completed && (
            <Col>
              <UploadButton
                accept={roomLayoutAcceptedExtension}
                beforeUpload={async (file: File) => {
                  if (
                    roomlayoutIsFileValid(
                      file,
                      currentProject?.designFiles?.filter(
                        (file: DesignFileDto) =>
                          file.type !== DesignFileType.RoomLayout
                      )
                    )
                  ) {
                    beforeUploadHandler(file, DesignFileType.RoomLayout);
                  }

                  return false;
                }}
                showUploadList={false}
                multiple={false}
                children={
                  roomLayoutFiles?.length
                    ? t("COMMON.FILE_SWAP")
                    : t("COMMON.FILE_UPLOAD")
                }
              />
            </Col>
          )}
        </Row>
        <Row>
          {fileList(roomLayoutFiles, t("PROJECTS.DETAIL.DESIGN.NO_FILE"))}
        </Row>

        <Row justify="space-between">
          <Col>
            <CustomTitle defaultFontSize extraStrong>
              {t("PROJECTS.DETAIL.DESIGN.BLUE_PRINT")}
            </CustomTitle>
          </Col>

          {currentProject?.designOrderStatus !==
            DesignOrderStatus.Completed && (
            <Col>
              <UploadButton
                accept={finalPlanAcceptedExtension}
                beforeUpload={(file: File) => {
                  if (
                    finalPlanIsValid(
                      file,
                      currentProject?.designFiles?.filter(
                        (file: DesignFileDto) =>
                          file.type !== DesignFileType.FinalPlan
                      ),
                      true
                    )
                  ) {
                    beforeUploadHandler(file, DesignFileType.FinalPlan);
                  }
                  return false;
                }}
                showUploadList={false}
                multiple={false}
                children={
                  finalPlanFile?.length
                    ? t("COMMON.FILE_SWAP")
                    : t("COMMON.FILE_UPLOAD")
                }
              />
            </Col>
          )}
        </Row>
        <Row>
          {fileList(finalPlanFile, t("PROJECTS.DETAIL.DESIGN.NO_FILE"))}
        </Row>

        <Row justify="space-between">
          <Col>
            <CustomTitle defaultFontSize extraStrong>
              {t("PROJECTS.DETAIL.DESIGN.MATERIALS")}
            </CustomTitle>
          </Col>
          <Col>
            {currentProject?.designOrderStatus !==
            DesignOrderStatus.Completed ? (
              <CustomButton onClick={() => setIsMaterialListModalVisible(true)}>
                {t("COMMON.MODIFY")}
              </CustomButton>
            ) : (
              <CustomButton
                onClick={() => setIsMaterialListModalVisible(true)}
                className={styles.viewBtn}
              >
                {t("PROJECTS.DETAIL.DESIGN.BTN_VIEW")}
              </CustomButton>
            )}
          </Col>
        </Row>

        {currentProject?.designOrderStatus !== DesignOrderStatus.Completed && (
          <Row justify="center">
            <CustomButton
              size="large"
              type="primary"
              disabled={
                (currentProject?.designOrderStatus ===
                  DesignOrderStatus.OptiComfort &&
                  !ngbsUpdatedOrder) ||
                !roomLayoutFiles?.length ||
                !finalPlanFile?.length ||
                !materialList.length
              }
              className={styles.notifyEngineerBtn}
              onClick={() => setIsNotifyEngineerModalVisible(true)}
            >
              {t("PROJECTS.DETAIL.DESIGN.NOTIFY_ENGINEER")}
            </CustomButton>
          </Row>
        )}
      </div>
    );
  }, [
    t,
    roomLayoutFiles,
    fileList,
    beforeUploadHandler,
    materialList.length,
    finalPlanFile,
    currentProject?.latestOptiComfortModificationDate,
    finalPlanAcceptedExtension,
    finalPlanIsValid,
    roomLayoutAcceptedExtension,
    roomlayoutIsFileValid,
    currentProject?.designOrderStatus,
    currentProject?.designFiles,
    ngbsUpdatedOrder,
  ]);

  const getOptiComfortPlanContent = () => {
    if (!currentProject?.designOrderStatus) {
      return null;
    }

    return {
      [DesignOrderStatus.New]: <MaterialList />,
      [DesignOrderStatus.FileUpload]: <MaterialList />,
      [DesignOrderStatus.OptiComfortNew]: !isNgbs() ? (
        <DesignWip />
      ) : (
        <MaterialList />
      ),
      [DesignOrderStatus.OptiComfortWip]: <DesignWip />,
      [DesignOrderStatus.OptiComfort]: isNgbs() ? (
        <DesignWip />
      ) : (
        <MaterialList />
      ),

      [DesignOrderStatus.Completed]: isNgbs() ? (
        <DesignWip />
      ) : (
        <MaterialList />
      ),
      [DesignOrderStatus.Refused]: null,
      [DesignOrderStatus.Invalid]: null,
    }[currentProject?.designOrderStatus];
  };

  return (
    <>
      <Card
        className={clsx(styles.optiComfortCard, {
          [styles.fileUploadStatus]:
            currentProject?.designOrderStatus === DesignOrderStatus.FileUpload,
        })}
      >
        <CustomTitle level={4} extraStrong align="center">
          {t("PROJECTS.DETAIL.DESIGN.OPTI_COMFORT_TITLE")}
        </CustomTitle>

        {getOptiComfortPlanContent()}
      </Card>
      {currentProject?.designOrderId && (
        <DesignMaterialListModal
          visible={isMaterialListModalVisible}
          orderId={currentProject?.designOrderId}
          onClose={() => {
            designOrderId != null &&
              dispatch.projects.getMaterials(designOrderId);

            setIsMaterialListModalVisible(false);
          }}
        />
      )}

      <SendPlanModal
        isVisible={isNotifyEngineerModalVisible}
        onClose={() => setIsNotifyEngineerModalVisible(false)}
        onSubmit={sendPlanHandler}
        title={t("PROJECTS.DETAIL.DESIGN.MATERIAL.NOTIFY_MODAL.TITLE")}
        description={t("PROJECTS.DETAIL.DESIGN.MATERIAL.NOTIFY_MODAL.DESC")}
        submitBtnLabel={t(
          "PROJECTS.DETAIL.DESIGN.MATERIAL.NOTIFY_MODAL.SEND_BTN"
        )}
        note={t("PROJECTS.DETAIL.DESIGN.MATERIAL.NOTIFY_MODAL.NOTE")}
      />
    </>
  );
};

export default OptiComfortPlan;
