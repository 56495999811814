import { Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { TitleProps } from "antd/lib/typography/Title";
import styles from "./CustomTitle.module.scss";
import clsx from "clsx";
import { useBreakpoint } from "hooks/useBreakpoint";

const { Title } = Typography;

type CustomTitleColor = "default" | "lightBlue" | "darkBlue" | "grey" | "green";
type TextAlign = "left" | "center" | "right";

declare const TITLE_ELE_LIST: [1, 2, 3, 4, 5];
type Level = typeof TITLE_ELE_LIST[number] | undefined;

const colorClassNames: { [color in CustomTitleColor]: string } = {
  default: "",
  lightBlue: styles.lightBlueColor,
  darkBlue: styles.darkBlueColor,
  grey: styles.greyColor,
  green: styles.greenColor,
};

const alignClassNames: { [color in TextAlign]: string } = {
  left: "",
  center: styles.textAlignCenter,
  right: styles.textAlignRight,
};
export interface CustomTitleProps extends TitleProps {
  className?: string;
  color?: CustomTitleColor;
  align?: TextAlign;
  responsiveLevel?: {
    mobile?: Level;
    tablet?: Level;
  };
  extraStrong?: boolean;
  defaultFontSize?: boolean;
}

const CustomTitle: FC<CustomTitleProps> = ({
  className,
  color = "default",
  align = "left",
  level,
  responsiveLevel,
  extraStrong,
  defaultFontSize,
  ...rest
}) => {
  const [currentLevel, setCurrentLevel] = useState<Level>(level);
  const mobileScreen = useBreakpoint({ max: "sm" });
  const tabletScreen = useBreakpoint({ max: "lg" });

  useEffect(() => {
    if (!responsiveLevel) return;

    const { mobile, tablet } = responsiveLevel;

    if (mobile && mobileScreen) {
      setCurrentLevel(mobile);
      return;
    }

    if (tablet && tabletScreen) {
      setCurrentLevel(tablet);
      return;
    }

    setCurrentLevel(level);
  }, [responsiveLevel, mobileScreen, tabletScreen, level]);

  return (
    <Title
      {...rest}
      level={currentLevel}
      className={clsx(
        colorClassNames[color],
        alignClassNames[align],
        styles.title,
        className,
        {
          [styles.extraStrong]: extraStrong,
          [styles.defaultFontSize]: defaultFontSize,
        }
      )}
    />
  );
};

export default CustomTitle;
