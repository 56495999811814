import { Layout as ReactLayout } from "antd";
import AlertModal from "components/alertModal/AlertModal";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";
import styles from "./Layout.module.scss";
import NavBar from "./navBar/NavBar";

const Layout: FC = () => (
  <ReactLayout className={styles.layout}>
    <NavBar />
    <Outlet />
    <AlertModal />
    <Footer />
  </ReactLayout>
);

export default Layout;
