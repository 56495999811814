import { Col, DatePicker, Row } from "antd";
import {
  BuildFileType,
  CompleteBuildOrderCommand,
} from "api/generated/optimum";
import { useDispatch, useSelector } from "app/store";
import CustomButton from "components/button/CustomButton";
import UploadButton from "components/button/UploadButton";
import CustomModal from "components/customModal/CustomModal";
import {
  GrapeAntdForm,
  requiredField,
  useGrapeAntdForm,
} from "components/form";
import CustomInput from "components/form/components/CustomInput";
import { CustomSelect } from "components/form/components/CustomSelect";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import useFileInput, { FileExtensions } from "hooks/useFileInput";
import { omit } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FileList from "../../components/fileList/FileList";
import useBuild from "../../useBuild";
import styles from "./ReadyReportModal.module.scss";

enum SelectedFileKeys {
  DaikinProtocol = "daikinProtocol",
  StatementFile = "statementFile",
  PurchaseProtocolFile = "purchaseProtocolFile",
  EducationProtocolFile = "educationProtocolFile",
}

export const ReadyReportModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
}> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { heatPumpIdentifiers } = useSelector((state) => state.referenceData);
  const { currentProject } = useSelector((state) => state.projects);
  const { buildOrder } = useBuild();
  const { loading } = useSelector(
    (state) => state.loading.effects.buildOrder.uploadBuildFiles
  );

  const [selectedFiles, setSelectedFiles] = useState<{
    daikinProtocol: any[];
    statementFile: File[];
    purchaseProtocolFile: File[];
    educationProtocolFile: File[];
  }>({
    daikinProtocol: [],
    statementFile: [],
    purchaseProtocolFile: [],
    educationProtocolFile: [],
  });

  const formUtils = useGrapeAntdForm<CompleteBuildOrderCommand>(
    "CompleteBuildOrderCommand"
  );
  const {
    acceptedExtensions,
    isFileExtensionValid,
    isFileSizeValid,
    isFileAlreadyExist,
  } = useFileInput({
    validExtensions: [FileExtensions.PDF],
  });

  useEffect(() => {
    dispatch.referenceData.getHeatPumpIdentifiers();
  }, [dispatch.referenceData]);

  const formFinishHandler = useCallback(
    async (values) => {
      const { heatPumpIdentifier, installationDate, heatPumpSerialNumber } =
        values;

      if (buildOrder?.id == null) {
        return;
      }

      try {
        await Promise.all([
          ...(selectedFiles.daikinProtocol.length
            ? [
                dispatch.buildOrder.uploadBuildFiles({
                  id: buildOrder?.id,
                  type: BuildFileType.RecordOfDaikinInstallation,
                  file: selectedFiles.daikinProtocol[0],
                }),
              ]
            : []),
          dispatch.buildOrder.uploadBuildFiles({
            id: buildOrder?.id,
            type: BuildFileType.StatementOfBuilder,
            file: selectedFiles.statementFile[0],
          }),
          dispatch.buildOrder.uploadBuildFiles({
            id: buildOrder?.id,
            type: BuildFileType.RecordOfDelivery,
            file: selectedFiles.purchaseProtocolFile[0],
          }),
          dispatch.buildOrder.uploadBuildFiles({
            id: buildOrder?.id,
            type: BuildFileType.RecordOfTraining,
            file: selectedFiles.educationProtocolFile[0],
          }),
        ]);

        await dispatch.buildOrder.completeBuildOrder({
          id: buildOrder?.id,
          heatPumpIdentifier: heatPumpIdentifier,
          installationDate: installationDate,
          heatPumpSerialNumber: heatPumpSerialNumber,
        });

        currentProject?.projectCode &&
          (await dispatch.projects.getProject({
            projectCode: currentProject?.projectCode,
          }));

        onClose();
      } catch (error) {
        console.error(error);
      }
    },
    [
      dispatch.buildOrder,
      buildOrder?.id,
      selectedFiles,
      currentProject?.projectCode,
      onClose,
      dispatch.projects,
    ]
  );

  const getUploadButtonLabel = useCallback(
    (fileListLength?: number) =>
      fileListLength ? t("COMMON.FILE_SWAP") : t("COMMON.FILE_UPLOAD"),
    [t]
  );

  const normalizeUploadedFile = useCallback(
    (e, selectedFileKey: SelectedFileKeys) => {
      const object = omit(selectedFiles, [selectedFileKey]);

      if (
        isFileAlreadyExist(e.file, Object.values(object).flat(), "name") ||
        !isFileSizeValid(e.file) ||
        !isFileExtensionValid(e.file)
      ) {
        return;
      }

      if (e.file) {
        setSelectedFiles({
          ...selectedFiles,
          [selectedFileKey]: [e.file],
        });
      }

      return [e.file];
    },
    [isFileAlreadyExist, isFileExtensionValid, isFileSizeValid, selectedFiles]
  );

  return (
    <CustomModal
      closable={false}
      width={"58rem"}
      visible={isVisible}
      className={styles.modal}
      loading={loading}
      afterClose={() => {
        formUtils.form.resetFields();
        setSelectedFiles({
          daikinProtocol: [],
          statementFile: [],
          purchaseProtocolFile: [],
          educationProtocolFile: [],
        });
      }}
    >
      <CustomTitle level={3} color="darkBlue" extraStrong align="center">
        {t("PROJECTS.DETAIL.BUILD.READY.TITLE")}
      </CustomTitle>

      <CustomParagraph color="black">
        {t("PROJECTS.DETAIL.BUILD.READY.DESC")}
      </CustomParagraph>

      <GrapeAntdForm formUtils={formUtils} onFinish={formFinishHandler}>
        <GrapeAntdForm.Item
          label={t("PROJECTS.DETAIL.BUILD.READY.DATE_LABLE")}
          name="installationDate"
          className={styles.inputField}
        >
          <DatePicker className={styles.input} />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item
          label={t("PROJECTS.DETAIL.BUILD.READY.CODE_LABLE")}
          name="heatPumpIdentifier"
          className={styles.inputField}
        >
          <CustomSelect
            className={styles.input}
            options={heatPumpIdentifiers.map((identifier) => ({
              label: identifier,
              value: identifier,
            }))}
            placeholder={t("PROJECTS.DETAIL.BUILD.READY.CODE_PLACEHOLDER")}
          />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item<CompleteBuildOrderCommand>
          className={styles.inputField}
          label={t("PROJECTS.DETAIL.BUILD.READY.PUMP_SN_LABLE")}
          name="heatPumpSerialNumber"
        >
          <CustomInput
            placeholder={t("PROJECTS.DETAIL.BUILD.READY.PUMP_SN_PLACEHOLDER")}
            className={styles.input}
          />
        </GrapeAntdForm.Item>

        <div className={styles.fileUploadContainer}>
          <Row justify="space-between">
            <Col className={styles.fileLabelContainer}>
              <CustomTitle defaultFontSize extraStrong>
                {t("PROJECTS.DETAIL.BUILD.READY.DAIKIN_PROTOCOL_LABEL")}
              </CustomTitle>
            </Col>

            <Col>
              <GrapeAntdForm.Item
                name="daikinProtocol"
                valuePropName="fileList"
                getValueFromEvent={(e) =>
                  normalizeUploadedFile(e, SelectedFileKeys.DaikinProtocol)
                }
              >
                <UploadButton
                  accept={acceptedExtensions}
                  name="daikinProtocol"
                  showUploadList={false}
                  beforeUpload={() => false}
                  children={getUploadButtonLabel(
                    selectedFiles.daikinProtocol.length
                  )}
                />
              </GrapeAntdForm.Item>
            </Col>
          </Row>

          <Row className={styles.fileListContainer}>
            <FileList fileList={selectedFiles.daikinProtocol} />
          </Row>
        </div>

        <div className={styles.fileUploadContainer}>
          <Row justify="space-between">
            <Col className={styles.fileLabelContainer}>
              <CustomTitle defaultFontSize extraStrong>
                {t("PROJECTS.DETAIL.BUILD.READY.STATEMENT_LABEL")}
              </CustomTitle>
            </Col>

            <Col>
              <GrapeAntdForm.Item
                name="statementFile"
                valuePropName="fileList"
                getValueFromEvent={(e) =>
                  normalizeUploadedFile(e, SelectedFileKeys.StatementFile)
                }
                rules={[requiredField()]}
              >
                <UploadButton
                  accept={acceptedExtensions}
                  name="statementFile"
                  showUploadList={false}
                  beforeUpload={() => false}
                  children={getUploadButtonLabel(
                    selectedFiles.statementFile.length
                  )}
                />
              </GrapeAntdForm.Item>
            </Col>
          </Row>

          <Row className={styles.fileListContainer}>
            <FileList fileList={selectedFiles.statementFile} />
          </Row>
        </div>

        <div className={styles.fileUploadContainer}>
          <Row justify="space-between">
            <Col className={styles.fileLabelContainer}>
              <CustomTitle defaultFontSize extraStrong>
                {t("PROJECTS.DETAIL.BUILD.READY.PURCHASE_PROTOCOL_LABEL")}
              </CustomTitle>
            </Col>

            <Col>
              <GrapeAntdForm.Item
                name="purchaseProtocolFile"
                valuePropName="fileList"
                getValueFromEvent={(e) =>
                  normalizeUploadedFile(
                    e,
                    SelectedFileKeys.PurchaseProtocolFile
                  )
                }
                rules={[requiredField()]}
              >
                <UploadButton
                  accept={acceptedExtensions}
                  name="purchaseProtocolFile"
                  showUploadList={false}
                  beforeUpload={() => false}
                  children={getUploadButtonLabel(
                    selectedFiles.purchaseProtocolFile.length
                  )}
                />
              </GrapeAntdForm.Item>
            </Col>
          </Row>

          <Row className={styles.fileListContainer}>
            <FileList fileList={selectedFiles.purchaseProtocolFile} />
          </Row>
        </div>

        <div className={styles.fileUploadContainer}>
          <Row justify="space-between">
            <Col className={styles.fileLabelContainer}>
              <CustomTitle defaultFontSize extraStrong>
                {t("PROJECTS.DETAIL.BUILD.READY.EDUCATION_PROTOCOL_LABEL")}
              </CustomTitle>
            </Col>

            <Col>
              <GrapeAntdForm.Item
                name="educationProtocolFile"
                valuePropName="fileList"
                getValueFromEvent={(e) =>
                  normalizeUploadedFile(
                    e,
                    SelectedFileKeys.EducationProtocolFile
                  )
                }
                rules={[requiredField()]}
              >
                <UploadButton
                  accept={acceptedExtensions}
                  name="educationProtocolFile"
                  showUploadList={false}
                  beforeUpload={() => false}
                  children={getUploadButtonLabel(
                    selectedFiles.educationProtocolFile.length
                  )}
                />
              </GrapeAntdForm.Item>
            </Col>
          </Row>

          <Row className={styles.fileListContainer}>
            <FileList fileList={selectedFiles.educationProtocolFile} />
          </Row>
        </div>
      </GrapeAntdForm>

      <Row justify="center" className={styles.buttonContainer}>
        <Col>
          <CustomButton
            size="large"
            variant="default"
            onClick={onClose}
            className={styles.cancelBtn}
          >
            {t("COMMON.CANCEL")}
          </CustomButton>
        </Col>

        <Col>
          <CustomButton
            size="large"
            type="primary"
            variant="default"
            onClick={formUtils.form.submit}
            className={styles.saveBtn}
            loading={loading}
          >
            {t("PROJECTS.DETAIL.BUILD.READY.SAVE_BTN")}
          </CustomButton>
        </Col>
      </Row>
    </CustomModal>
  );
};
export default ReadyReportModal;
