/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticationDto,
    AuthenticationDtoFromJSON,
    AuthenticationDtoToJSON,
    LoginCommand,
    LoginCommandFromJSON,
    LoginCommandToJSON,
    LogoutCommand,
    LogoutCommandFromJSON,
    LogoutCommandToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RefreshTokenCommand,
    RefreshTokenCommandFromJSON,
    RefreshTokenCommandToJSON,
} from '../models';

export interface LoginRequest {
    loginCommand?: LoginCommand;
}

export interface LogoutRequest {
    logoutCommand?: LogoutCommand;
}

export interface RefreshTokenRequest {
    refreshTokenCommand?: RefreshTokenCommand;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Authenticates the user with the supplied email and password.
     */
    async loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<AuthenticationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Auth/Login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginCommandToJSON(requestParameters.loginCommand),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationDtoFromJSON(jsonValue));
    }

    /**
     * Authenticates the user with the supplied email and password.
     */
    async login(requestParameters: LoginRequest): Promise<AuthenticationDto> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }

    /**
     * Removes the refresh token.
     */
    async logoutRaw(requestParameters: LogoutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Auth/Logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogoutCommandToJSON(requestParameters.logoutCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes the refresh token.
     */
    async logout(requestParameters: LogoutRequest): Promise<void> {
        await this.logoutRaw(requestParameters);
    }

    /**
     * Refresh the token for a logged in user.
     */
    async refreshTokenRaw(requestParameters: RefreshTokenRequest): Promise<runtime.ApiResponse<AuthenticationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Auth/RefreshToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenCommandToJSON(requestParameters.refreshTokenCommand),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationDtoFromJSON(jsonValue));
    }

    /**
     * Refresh the token for a logged in user.
     */
    async refreshToken(requestParameters: RefreshTokenRequest): Promise<AuthenticationDto> {
        const response = await this.refreshTokenRaw(requestParameters);
        return await response.value();
    }

}
