import { useDispatch, useSelector } from "app/store";
import { FC, useCallback } from "react";
import CustomModal from "components/customModal/CustomModal";
import styles from "./EngineerDetailModal.module.scss";
import Avatar from "components/providerAvatar/ProviderAvatar";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import CustomButton from "components/button/CustomButton";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";

const EngineerDetailModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const selectedEngineer = useSelector(
    (state) => state.engineer.selectedEngineer
  );

  const handleContactClick = useCallback(() => {
    dispatch.engineer.nextStep();
    navigate("/design/real-estate");
  }, [dispatch.engineer, navigate]);

  return (
    <CustomModal
      visible={!!selectedEngineer}
      onCancel={() => dispatch.engineer.unSelectEngineer()}
      className={styles.modal}
    >
      <div className={styles.selectedEngineer}>
        <Avatar
          isActive={selectedEngineer?.isActive}
          className={styles.avatar}
          url={selectedEngineer?.profilePictureUrl}
        />
        <div className={styles.selectedEngineerData}>
          <CustomTitle
            level={3}
            responsiveLevel={{
              mobile: 3,
              tablet: 4,
            }}
            extraStrong
            className={styles.nameTitle}
          >{`${selectedEngineer?.lastName} ${selectedEngineer?.firstName}`}</CustomTitle>

          <CustomTitle
            level={5}
            className={clsx(styles.engineerDataTitle, styles.professionData)}
            color="grey"
          >
            {selectedEngineer?.position}
          </CustomTitle>

          <CustomTitle
            level={5}
            className={clsx(styles.engineerDataTitle, styles.professionData)}
            color="grey"
          >
            {selectedEngineer?.companyName}
          </CustomTitle>
        </div>
      </div>

      <section className={styles.engineerDetailSection}>
        <CustomTitle
          extraStrong
          level={5}
          color="lightBlue"
          className={clsx(
            styles.engineerDataTitle,
            styles.engineerDetailData,
            styles.priceRangeText
          )}
        >
          {t("ENGINEERS.PRICE_RANGE", {
            minPrice: numeral(selectedEngineer?.minPriceQuote)
              .format("0,0")
              .replaceAll(",", "."),
            maxPrice: numeral(selectedEngineer?.maxPriceQuote)
              .format("0,0")
              .replaceAll(",", "."),
          })}
        </CustomTitle>

        <CustomTitle
          extraStrong
          level={5}
          className={clsx(styles.engineerDataTitle, styles.engineerDetailData)}
          color="grey"
        >
          {t("ENGINEERS.SPECIALIZATIONS")}
        </CustomTitle>

        <CustomTitle
          level={5}
          className={clsx(
            styles.engineerDataTitle,
            styles.engineerDetailData,
            styles.specList
          )}
          color="grey"
        >
          {selectedEngineer?.specializations?.join(", ")}
        </CustomTitle>

        <CustomParagraph
          size="sm"
          color="grey"
          className={styles.introductionText}
        >
          {selectedEngineer?.introduction}
        </CustomParagraph>
      </section>

      <Row justify="center">
        <CustomButton
          type="primary"
          disabled={!selectedEngineer?.isActive}
          onClick={handleContactClick}
          size="large"
          className={styles.cta}
        >
          {t("ENGINEERS.CONTACT_BUTTON_LABEL")}
        </CustomButton>

        <CustomButton
          onClick={() => dispatch.engineer.unSelectEngineer()}
          block
          size="large"
          className={styles.backBtn}
        >
          {t("COMMON.BACK")}
        </CustomButton>
      </Row>
    </CustomModal>
  );
};

export default EngineerDetailModal;
