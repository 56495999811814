import { useDispatch, useSelector } from "app/store";
import CustomButton from "components/button/CustomButton";
import CustomModal from "components/customModal/CustomModal";
import { CheckCircleOutlined } from "@ant-design/icons";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { FC } from "react";
import styles from "./AlertModal.module.scss";
import { AlertModalType } from "./AlertModal.store";
import CustomTitle from "components/typography/title/CustomTitle";
import LinkButton from "components/button/linkButton/LinkButton";

const AlertModal: FC = () => {
  const dispatch = useDispatch();
  const { isOpen, modalProps } = useSelector((state) => state.alertModal);
  const {
    buttonLabel,
    descriptin,
    onButtonClick,
    onSecondaryButtonClick,
    secondaryButtonLabel,
    title,
    type,
    cardTitle,
    width,
    closable,
  } = modalProps;

  const handlePrimaryButtonClick = () => {
    onButtonClick?.();

    closeAlertModal();
  };

  const closeAlertModal = () => dispatch.alertModal.close();

  const Icon = () => {
    if (type === undefined) return <></>;

    return {
      [AlertModalType.Success]: <CheckCircleOutlined className={styles.icon} />,
    }[type];
  };

  return isOpen ? (
    <CustomModal
      closable={closable ?? false}
      width={width ?? "74rem"}
      visible
      onCancel={closeAlertModal}
      className={styles.alertModal}
    >
      {cardTitle && (
        <CustomTitle
          level={3}
          color="darkBlue"
          extraStrong
          className={styles.cardTitle}
          align="center"
        >
          {cardTitle}
        </CustomTitle>
      )}

      <div className={styles.modal}>
        <Icon />

        <CustomTitle
          level={4}
          color="green"
          extraStrong
          className={styles.title}
          align="center"
        >
          {title}
        </CustomTitle>
        <CustomParagraph className={styles.description}>
          {descriptin}
        </CustomParagraph>

        {buttonLabel && (
          <CustomButton
            onClick={handlePrimaryButtonClick}
            className={styles.homeButton}
            type="primary"
            variant="default"
            size="large"
          >
            {buttonLabel}
          </CustomButton>
        )}

        {onSecondaryButtonClick && (
          <LinkButton
            onClick={onSecondaryButtonClick}
            className={styles.secondaryButton}
          >
            {secondaryButtonLabel}
          </LinkButton>
        )}
      </div>
    </CustomModal>
  ) : (
    <></>
  );
};

export default AlertModal;
