import Content from "components/layout/content/Content";
import {
  getUserData,
  isAdmin,
  isBuilder,
  isEngineer,
  isNgbs,
} from "utils/authUtils";
import { ReactComponent as PageTitleLogo } from "assets/images/PageTitle.svg";
import { ReactComponent as NgbsPageTitleLogo } from "assets/images/ngbsPageTitleLogo.svg";
import { ReactComponent as BuilderLogo } from "assets/images/builder_logo.svg";
import { useDispatch, useSelector } from "app/store";
import clsx from "clsx";
import styles from "./ProjectDetail.module.scss";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Role, SpecializationCategory } from "api/generated/optimum";
import Design from "./design/Design";
import Build from "./build/Build";
import ProjectDetailsHeader from "./components/projectDetailsHeader/ProjectDetailsHeader";
import { Skeleton } from "antd";

const ProjectDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { projectCode } = useParams();
  const { currentProject } = useSelector((state) => state.projects);
  const { loading } = useSelector(
    (state) => state.loading.effects.projects.getProject
  );

  const isBuildOrder =
    currentProject?.projectStatus?.includes("BuildOrderStatus");

  const getLogo = useCallback(
    () =>
      ({
        [Role.Ngbs]: (
          <NgbsPageTitleLogo className={clsx(styles.logo, styles.ngbsLogo)} />
        ),
        [Role.Engineer]: (
          <PageTitleLogo className={clsx(styles.logo, styles.engineerLogo)} />
        ),
        [Role.Admin]: isBuildOrder ? (
          <BuilderLogo className={clsx(styles.logo, styles.builderLogo)} />
        ) : (
          <PageTitleLogo className={clsx(styles.logo, styles.engineerLogo)} />
        ),
        [Role.Builder]: (
          <BuilderLogo className={clsx(styles.logo, styles.builderLogo)} />
        ),

        [Role.Invalid]: <></>,
      }[getUserData()?.role || Role.Invalid]),
    [isBuildOrder]
  );

  const getOrderDetailPage = () => {
    if (isAdmin()) {
      return isBuildOrder ? <Build /> : <Design />;
    }

    if (isBuilder()) {
      return <Build />;
    }

    if (isEngineer() || isNgbs()) {
      return <Design />;
    }
  };

  useEffect(() => {
    if (projectCode) {
      dispatch.projects.getProject({ projectCode });
      dispatch.referenceData.getSpecializations([SpecializationCategory.Build]);
    }

    return () => {
      dispatch.projects.reset();
    };
  }, [dispatch.projects, projectCode, dispatch.referenceData]);

  return (
    <Content contentClassName={styles.content}>
      {loading && <Skeleton />}
      {currentProject && (
        <>
          <ProjectDetailsHeader logo={getLogo()} project={currentProject} />

          {getOrderDetailPage()}
        </>
      )}
    </Content>
  );
};

export default ProjectDetail;
