/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RealEstateFileType {
    Invalid = 'Invalid',
    Design = 'Design',
    Build = 'Build'
}

export function RealEstateFileTypeFromJSON(json: any): RealEstateFileType {
    return RealEstateFileTypeFromJSONTyped(json, false);
}

export function RealEstateFileTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RealEstateFileType {
    return json as RealEstateFileType;
}

export function RealEstateFileTypeToJSON(value?: RealEstateFileType | null): any {
    return value as any;
}

