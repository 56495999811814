import { Collapse, CollapseProps } from "antd";
import clsx from "clsx";
import { FC, ReactNode, useMemo } from "react";
import CustomTitle from "../typography/title/CustomTitle";
import styles from "./CustomCollapse.module.scss";

const { Panel } = Collapse;

export interface CustomCollapseProps
  extends Omit<CollapseProps, "header" | "showArrow" | "extra" | "key"> {
  className?: string;
  headerClassName?: string;
  title: ReactNode;
  isExpanded?: boolean;
  onClick?: () => void;
}

const CustomCollapse: FC<CustomCollapseProps> = ({
  title,
  children,
  className,
  headerClassName,
  isExpanded,
  onClick,
  ...rest
}) => {
  const header = useMemo((): ReactNode => {
    return (
      <div className={clsx(headerClassName, styles.header)}>
        <CustomTitle className={styles.title} level={3}>
          {title}
        </CustomTitle>
      </div>
    );
  }, [title, headerClassName]);

  return (
    <Collapse
      {...rest}
      expandIconPosition="right"
      ghost
      className={clsx(className)}
      {...(isExpanded === undefined
        ? {}
        : isExpanded
        ? { activeKey: 1 }
        : { activeKey: 0 })}
      onChange={onClick}
    >
      <Panel header={header} key={1} className={styles.panel}>
        <div className={styles.content}>{children}</div>
      </Panel>
    </Collapse>
  );
};

export default CustomCollapse;
