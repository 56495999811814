/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuilderProfession,
    BuilderProfessionFromJSON,
    BuilderProfessionFromJSONTyped,
    BuilderProfessionToJSON,
    CountyDto,
    CountyDtoFromJSON,
    CountyDtoFromJSONTyped,
    CountyDtoToJSON,
    EngineerProfession,
    EngineerProfessionFromJSON,
    EngineerProfessionFromJSONTyped,
    EngineerProfessionToJSON,
    ProviderType,
    ProviderTypeFromJSON,
    ProviderTypeFromJSONTyped,
    ProviderTypeToJSON,
    SpecializationDto,
    SpecializationDtoFromJSON,
    SpecializationDtoFromJSONTyped,
    SpecializationDtoToJSON,
    TagDto,
    TagDtoFromJSON,
    TagDtoFromJSONTyped,
    TagDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProviderDto
 */
export interface ProviderDto {
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    id?: string | null;
    /**
     * 
     * @type {ProviderType}
     * @memberof ProviderDto
     */
    type?: ProviderType;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    introduction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDto
     */
    isEmailAddressConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDto
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDto
     */
    isPublished?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProviderDto
     */
    firstLoginDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProviderDto
     */
    lastLoginDate?: Date | null;
    /**
     * 
     * @type {Array<SpecializationDto>}
     * @memberof ProviderDto
     */
    specializations?: Array<SpecializationDto> | null;
    /**
     * 
     * @type {Array<CountyDto>}
     * @memberof ProviderDto
     */
    coveredCounties?: Array<CountyDto> | null;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof ProviderDto
     */
    tags?: Array<TagDto> | null;
    /**
     * 
     * @type {EngineerProfession}
     * @memberof ProviderDto
     */
    engineerProfession?: EngineerProfession;
    /**
     * 
     * @type {number}
     * @memberof ProviderDto
     */
    minPriceQuote?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProviderDto
     */
    maxPriceQuote?: number | null;
    /**
     * 
     * @type {BuilderProfession}
     * @memberof ProviderDto
     */
    builderProfession?: BuilderProfession;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    profilePictureUrl?: string | null;
}

export function ProviderDtoFromJSON(json: any): ProviderDto {
    return ProviderDtoFromJSONTyped(json, false);
}

export function ProviderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : ProviderTypeFromJSON(json['type']),
        'position': !exists(json, 'position') ? undefined : json['position'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'isEmailAddressConfirmed': !exists(json, 'isEmailAddressConfirmed') ? undefined : json['isEmailAddressConfirmed'],
        'isEnabled': !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
        'isPublished': !exists(json, 'isPublished') ? undefined : json['isPublished'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'firstLoginDate': !exists(json, 'firstLoginDate') ? undefined : (json['firstLoginDate'] === null ? null : new Date(json['firstLoginDate'])),
        'lastLoginDate': !exists(json, 'lastLoginDate') ? undefined : (json['lastLoginDate'] === null ? null : new Date(json['lastLoginDate'])),
        'specializations': !exists(json, 'specializations') ? undefined : (json['specializations'] === null ? null : (json['specializations'] as Array<any>).map(SpecializationDtoFromJSON)),
        'coveredCounties': !exists(json, 'coveredCounties') ? undefined : (json['coveredCounties'] === null ? null : (json['coveredCounties'] as Array<any>).map(CountyDtoFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : (json['tags'] === null ? null : (json['tags'] as Array<any>).map(TagDtoFromJSON)),
        'engineerProfession': !exists(json, 'engineerProfession') ? undefined : EngineerProfessionFromJSON(json['engineerProfession']),
        'minPriceQuote': !exists(json, 'minPriceQuote') ? undefined : json['minPriceQuote'],
        'maxPriceQuote': !exists(json, 'maxPriceQuote') ? undefined : json['maxPriceQuote'],
        'builderProfession': !exists(json, 'builderProfession') ? undefined : BuilderProfessionFromJSON(json['builderProfession']),
        'profilePictureUrl': !exists(json, 'profilePictureUrl') ? undefined : json['profilePictureUrl'],
    };
}

export function ProviderDtoToJSON(value?: ProviderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': ProviderTypeToJSON(value.type),
        'position': value.position,
        'companyName': value.companyName,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'introduction': value.introduction,
        'phoneNumber': value.phoneNumber,
        'emailAddress': value.emailAddress,
        'isEmailAddressConfirmed': value.isEmailAddressConfirmed,
        'isEnabled': value.isEnabled,
        'isPublished': value.isPublished,
        'isActive': value.isActive,
        'firstLoginDate': value.firstLoginDate === undefined ? undefined : (value.firstLoginDate === null ? null : value.firstLoginDate.toISOString()),
        'lastLoginDate': value.lastLoginDate === undefined ? undefined : (value.lastLoginDate === null ? null : value.lastLoginDate.toISOString()),
        'specializations': value.specializations === undefined ? undefined : (value.specializations === null ? null : (value.specializations as Array<any>).map(SpecializationDtoToJSON)),
        'coveredCounties': value.coveredCounties === undefined ? undefined : (value.coveredCounties === null ? null : (value.coveredCounties as Array<any>).map(CountyDtoToJSON)),
        'tags': value.tags === undefined ? undefined : (value.tags === null ? null : (value.tags as Array<any>).map(TagDtoToJSON)),
        'engineerProfession': EngineerProfessionToJSON(value.engineerProfession),
        'minPriceQuote': value.minPriceQuote,
        'maxPriceQuote': value.maxPriceQuote,
        'builderProfession': BuilderProfessionToJSON(value.builderProfession),
        'profilePictureUrl': value.profilePictureUrl,
    };
}


