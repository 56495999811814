import { AuthenticationDto, Role } from "api/generated/optimum";
import { getLocalStorageValue, LocalStorageKeys } from "./storageHandler";

export const getUserData = (): AuthenticationDto | undefined => {
  const userValueInStorage = getLocalStorageValue(LocalStorageKeys.User);

  return userValueInStorage && userValueInStorage !== ""
    ? (JSON.parse(userValueInStorage) as AuthenticationDto)
    : undefined;
};

export const isLoggedIn = (): boolean => !!getUserData();

export const isNgbs = () => getUserData()?.role === Role.Ngbs;
export const isEngineer = () => getUserData()?.role === Role.Engineer;
export const isBuilder = () => getUserData()?.role === Role.Builder;
export const isProvider = () => isNgbs() || isEngineer() || isBuilder();
export const isAdmin = () => getUserData()?.role === Role.Admin;
