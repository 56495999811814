import { Row, Skeleton } from "antd";
import Card from "components/card/Card";
import CustomTitle from "components/typography/title/CustomTitle";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./AcceptOrder.module.scss";
import { useDispatch, useSelector } from "app/store";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as PageTitleLogo } from "assets/images/PageTitle.svg";
import CustomButton from "components/button/CustomButton";
import { DesignOrderStatus } from "api/generated/optimum";
import OrderDetails from "../components/OrderDetails";
import RefuseDesignOrder from "../../components/refuseOrder/RefuseDesignOrder";
import ProjectDetailsHeader from "../../components/projectDetailsHeader/ProjectDetailsHeader";
import useDesign from "../useDesign";

const AcceptOrder: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentProject } = useSelector((state) => state.projects);
  const { projectCode } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { loading } = useSelector(
    (state) => state.loading.effects.projects.acceptProject
  );
  const { refuseOrder } = useDesign();

  const navigate = useNavigate();

  const acceptOrder = () => {
    if (!currentProject?.designOrderId) {
      console.log("Missing designOrderId.");
      return;
    }

    dispatch.projects
      .acceptProject({
        acceptDesignOrderCommand: { id: currentProject?.designOrderId },
      })
      .then(() => {
        navigate(`/projects/${projectCode}`);
      });
  };

  useEffect(() => {
    if (!projectCode) {
      console.log("Missing project code");
      return;
    }

    if (user) {
      dispatch.projects.getProject({ projectCode });
    }
  }, [dispatch.auth, dispatch.projects, projectCode, user]);

  useEffect(() => {
    if (
      currentProject &&
      currentProject.designOrderStatus !== DesignOrderStatus.New
    ) {
      navigate(`/projects/${projectCode}`);
    }
  }, [currentProject, navigate, projectCode]);

  return !currentProject ? (
    <Skeleton className={styles.skeleton} />
  ) : (
    <div className={styles.container}>
      <ProjectDetailsHeader
        logo={<PageTitleLogo className={styles.logo} />}
        project={currentProject}
      />

      <OrderDetails project={currentProject} />

      <Card className={styles.buttonContainer}>
        <Row justify="center">
          <CustomTitle level={3} color="darkBlue" extraStrong>
            {t("PROJECT_DETAILS.TITLE")}
          </CustomTitle>
        </Row>

        <Row justify="center" className={styles.buttonRow}>
          <CustomButton
            type="primary"
            variant="default"
            size="large"
            onClick={acceptOrder}
            loading={loading}
          >
            {t("PROJECT_DETAILS.ACCEPT")}
          </CustomButton>
        </Row>
      </Card>

      <RefuseDesignOrder
        buttonLabel={t("PROJECT_DETAILS.DECLINE")}
        modalTitle={t("PROJECT_DETAILS.MODAL.TITLE")}
        textAreaLable={t("PROJECT_DETAILS.MODAL.TEXTAREA_LABEL")}
        description={t("PROJECT_DETAILS.MODAL.DESCRIPTION")}
        refuseOrder={refuseOrder}
      />
    </div>
  );
};

export default AcceptOrder;
