import { Col, Row } from "antd";
import { ProviderDto } from "api/generated/optimum";
import CustomButton from "components/button/CustomButton";
import Jumbotron from "components/jumbotron/Jumbotron";
import ProviderAvatar from "components/providerAvatar/ProviderAvatar";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { phoneNumberDisplayFormat } from "utils/format";
import ProviderDetailsModal from "./ProviderDetailsModal";
import styles from "./ProviderInfo.module.scss";

export const ProviderInfo: React.FC<{
  title: string;
  provider?: ProviderDto;
}> = ({ title, provider }) => {
  const { t } = useTranslation();

  const [providerDetailsModalIsVisible, setProviderDetailsModalIsVisible] =
    useState<boolean>(false);

  return (
    <Jumbotron className={styles.jumbotron}>
      <CustomTitle
        level={4}
        extraStrong
        align="left"
        className={styles.jumbotronTitle}
      >
        {title}
      </CustomTitle>

      <Row gutter={20}>
        <Col>
          <ProviderAvatar size="7rem" url={provider?.profilePictureUrl} />
        </Col>
        <Col flex="auto">
          <Row align="middle">
            <Col>
              <CustomTitle
                extraStrong
                defaultFontSize
                className={styles.dataLabel}
              >
                {`${provider?.lastName} ${provider?.firstName}`}
              </CustomTitle>
            </Col>
          </Row>

          <CustomParagraph color="grey" className={styles.dataLabel}>
            {`${provider?.position}, ${provider?.companyName}`}
          </CustomParagraph>

          <CustomParagraph color="grey" className={styles.dataLabel}>
            <span>{provider?.emailAddress} </span>
            {(provider?.phoneNumber &&
              phoneNumberDisplayFormat(provider?.phoneNumber)) ||
              ""}
          </CustomParagraph>
          <CustomButton
            className={styles.detailsButton}
            onClick={() => setProviderDetailsModalIsVisible(true)}
          >
            {t("ADMIN.PROVIDERS.PROFILE.DETAILS")}
          </CustomButton>
        </Col>
      </Row>
      <ProviderDetailsModal
        isVisible={providerDetailsModalIsVisible}
        onClose={() => setProviderDetailsModalIsVisible(false)}
        provider={provider}
      />
    </Jumbotron>
  );
};
export default ProviderInfo;
