import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { DesignOrderStatus, ProjectListDto } from "api/generated/optimum";
import clsx from "clsx";
import { CustomEmptyNote } from "components/GrapeAntdTable/table-content/CustomEmptyNote";
import { useNavigate } from "react-router-dom";
import { isNgbs } from "utils/authUtils";
import styles from "./ProjectTable.module.scss";

const ProjectTable: React.FC<{
  projects: ProjectListDto[];
  loading?: boolean;
  columns: ColumnsType<ProjectListDto>;
  isAdmin?: boolean;
  emptyMessage?: string;
}> = ({ projects, loading, columns, isAdmin = true, emptyMessage }) => {
  const navigate = useNavigate();

  return projects.length ? (
    <Table
      className={clsx(styles.table, { [styles.ngbs]: isNgbs() })}
      rowKey="projectCode"
      columns={columns}
      loading={loading}
      dataSource={projects}
      onRow={(project) => ({
        onClick: () => {
          if (project.projectCode) {
            if (isAdmin) {
              navigate(`/admin/projects/${project.projectCode}`);
            } else {
              navigate(
                project.designOrderStatus === DesignOrderStatus.New
                  ? `${project.projectCode}/design`
                  : project.projectCode
              );
            }
          }
        },
      })}
    />
  ) : (
    <CustomEmptyNote message={emptyMessage} />
  );
};

export default ProjectTable;
