import { useEffect, useRef, useState } from "react";

const useSearchInput = (apiRequest: (payload?: string) => Promise<void>) => {
  const [searchTerm, setSearchTerm] = useState("");
  const API_REQUEST_TIMEOUT = 1000;
  const firstUpdate = useRef(true);

  useEffect(() => {
    // No need to delay apiRequest on first render.
    if (firstUpdate.current) {
      firstUpdate.current = false;
      apiRequest(searchTerm);
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      apiRequest(searchTerm);
    }, API_REQUEST_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, apiRequest]);

  return setSearchTerm;
};

export default useSearchInput;
