import { Col, Row } from "antd";
import { useDispatch, useSelector } from "app/store";
import CustomButton from "components/button/CustomButton";
import Card from "components/card/Card";
import Jumbotron from "components/jumbotron/Jumbotron";
import CustomTitle from "components/typography/title/CustomTitle";
import { useCallback, useEffect, useState } from "react";
import styles from "./Build.module.scss";
import useDesign from "../design/useDesign";
import OfferDetails from "./offerDetails/OfferDetails";
import { useTranslation } from "react-i18next";
import BuildImplementation from "./buildImplementation/BuildImplementation";
import ConfirmModal from "components/confirmModal/ConfirmModal";
import FileList from "./components/fileList/FileList";
import useBuild from "./useBuild";
import { PaperClipOutlined } from "@ant-design/icons";
import { isAdmin, isBuilder } from "utils/authUtils";
import BuilderSelected from "features/builder/components/builderList/componenets/builderSelected/BuilderSelected";
import clsx from "clsx";
import ProviderInfo from "./components/fileList/providerInfo/ProviderInfo";
import AdministrationCard from "../components/admin/administrationCard/AdministrationCard";
import SpecializationDisplay from "../components/specializationDisplay/SpecializationDisplay";
import { useNavigate } from "react-router-dom";
import RefuseBuildOrder from "../components/refuseOrder/RefuseBuildOrder";

const Build: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    thermalCalculationFiles,
    floorPlanFiles,
    finalPlanFile,
    roomLayoutFiles,
    otherFiles,
  } = useDesign();

  const dispatch = useDispatch();
  const [isConfrimModalVisible, setIsConfrimModalVisible] = useState(false);
  const { currentProject } = useSelector((state) => state.projects);
  const {
    isBuildOrderRefused,
    isNewBuildOrder,
    isApprovedBuildOrder,
    isMaterialOrderedBuildOrder,
    buildOrder,
    selectedBuilders,
    navigateWhenInvalidStatusOperation,
    refuseOrder,
  } = useBuild();

  useEffect(() => {
    if (isBuildOrderRefused && isBuilder()) {
      navigate("/projects/refused");
    }
  }, [isBuildOrderRefused, navigate]);

  const MechanicalPlan = () => (
    <Card className={styles.card}>
      <CustomTitle
        level={3}
        extraStrong
        align="center"
        color="darkBlue"
        className={styles.cardTitle}
      >
        {t("PROJECTS.DETAIL.BUILD.MECHANICAL_PLAN.TITLE")}
      </CustomTitle>

      <ProviderInfo
        title={t("PROJECTS.DETAIL.BUILD.MECHANICAL_PLAN.ENGINEER")}
        provider={currentProject?.engineer}
      />

      <Jumbotron className={styles.jumbotron}>
        <CustomTitle
          level={4}
          extraStrong
          align="left"
          className={clsx(styles.jumbotronTitle, styles.optiComfortPlanTitle)}
        >
          {t("PROJECTS.DETAIL.BUILD.MECHANICAL_PLAN.OPTI_COMFORT_PLAN")}
        </CustomTitle>

        <Row>
          <Col span={12}>
            <FileList
              title={t("PROJECTS.DETAIL.DESIGN.LAYOUT")}
              fileList={floorPlanFiles}
            />

            <FileList
              title={t("PROJECTS.DETAIL.DESIGN.THERMAL_CALCULATION")}
              fileList={thermalCalculationFiles}
            />
          </Col>

          <Col span={12}>
            <FileList
              title={t("PROJECTS.DETAIL.DESIGN.BLUE_PRINT")}
              fileList={finalPlanFile}
            />

            <FileList
              title={t("PROJECTS.DETAIL.DESIGN.ROOM_ALLOCATION")}
              fileList={roomLayoutFiles}
            />

            <CustomTitle level={3} extraStrong align="left" defaultFontSize>
              {t("PROJECTS.DETAIL.DESIGN.MATERIALS")}
            </CustomTitle>
            <a
              href={undefined}
              onClick={() =>
                currentProject?.designOrderId &&
                dispatch.projects.exportMaterial(currentProject?.designOrderId)
              }
            >
              <PaperClipOutlined /> {t("COMMON.MATERIAL_LIST_CSV")}
            </a>
          </Col>
        </Row>
      </Jumbotron>

      <Jumbotron className={styles.jumbotron}>
        <CustomTitle
          level={4}
          extraStrong
          align="left"
          className={styles.jumbotronTitle}
        >
          {t("PROJECTS.DETAIL.DESIGN.OTHER_FILE_TITLE")}
        </CustomTitle>

        <FileList fileList={otherFiles} />
      </Jumbotron>

      <Jumbotron className={styles.jumbotron}>
        <CustomTitle
          level={4}
          extraStrong
          align="left"
          className={styles.jumbotronTitle}
        >
          {t("PROJECTS.DETAIL.DESIGN.WORKING_TYPE_TITLE")}
        </CustomTitle>

        <SpecializationDisplay
          specializations={currentProject?.requiredSpecializations}
        />
      </Jumbotron>
    </Card>
  );

  const ConfirmStartModal = useCallback(
    () => (
      <ConfirmModal
        width={"58rem"}
        isVisible={isConfrimModalVisible}
        onClose={() => setIsConfrimModalVisible(false)}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={async () => {
          try {
            buildOrder?.id &&
              (await dispatch.buildOrder.acceptBuildOrder(buildOrder.id));
            setIsConfrimModalVisible(false);

            currentProject?.projectCode &&
              dispatch.projects.getProject({
                projectCode: currentProject?.projectCode,
              });

            window.scrollTo({ top: 0 });
          } catch (error) {
            console.error(error);
            navigateWhenInvalidStatusOperation(error as Response);
          }
        }}
        title={t("PROJECTS.DETAIL.BUILD.CONFIRM_MODAL.TITLE")}
        description={t("PROJECTS.DETAIL.BUILD.CONFIRM_MODAL.DESCRIPTION")}
        subDescription={t(
          "PROJECTS.DETAIL.BUILD.CONFIRM_MODAL.SUB_DESCRIPTION"
        )}
        submitBtnLabel={t("PROJECTS.DETAIL.BUILD.CONFIRM_MODAL.SUBMIT")}
      />
    ),
    [
      t,
      isConfrimModalVisible,
      dispatch.buildOrder,
      buildOrder?.id,
      dispatch.projects,
      currentProject?.projectCode,
      navigateWhenInvalidStatusOperation,
    ]
  );

  return (
    <>
      {isAdmin() && (isApprovedBuildOrder || isMaterialOrderedBuildOrder) && (
        <AdministrationCard
          displayName={`${buildOrder?.builder?.lastName} ${buildOrder?.builder?.firstName}`}
          providerUrl={`/admin/providers/${buildOrder?.builder?.id}`}
          email={buildOrder?.builder?.emailAddress}
          phoneNumber={buildOrder?.builder?.phoneNumber}
        />
      )}

      {isAdmin() && (isNewBuildOrder || isBuildOrderRefused) && (
        <Card className={clsx(styles.card, styles.selectedBuilderCard)}>
          <CustomTitle
            level={3}
            extraStrong
            align="center"
            color="darkBlue"
            className={styles.cardTitle}
          >
            {t("PROJECTS.DETAIL.BUILD.SELECTED_BUILDER.TITLE")}
          </CustomTitle>

          {selectedBuilders && (
            <BuilderSelected selectedBuilders={selectedBuilders} hideRemove />
          )}
        </Card>
      )}
      <BuildImplementation />

      <MechanicalPlan />

      <OfferDetails />

      {isNewBuildOrder && isBuilder() && (
        <>
          <RefuseBuildOrder
            buttonLabel={t("PROJECTS.DETAIL.BUILD.REFUSE.BUTTON_LABEL")}
            modalTitle={t("PROJECTS.DETAIL.BUILD.REFUSE.TITLE")}
            textAreaLable={t("PROJECTS.DETAIL.BUILD.REFUSE.TEXT_AREA")}
            description={t("PROJECTS.DETAIL.BUILD.REFUSE.DESC")}
            refuseOrder={refuseOrder}
          />

          <Row justify="center">
            <CustomButton
              type="primary"
              variant="default"
              onClick={() => setIsConfrimModalVisible(true)}
              className={styles.startBtn}
              size="large"
            >
              {t("PROJECTS.DETAIL.BUILD.START_BTN")}
            </CustomButton>
          </Row>
        </>
      )}

      <ConfirmStartModal />
    </>
  );
};

export default Build;
