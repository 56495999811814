import { FC } from "react";
import { Row, Col } from "antd";
import styles from "./StepNavigator.module.scss";
import { useBreakpoint } from "hooks/useBreakpoint";

interface StepNavigatorProps {
  firstButton?: React.ReactNode;
  secondButton?: React.ReactNode;
}

const StepNavigator: FC<StepNavigatorProps> = ({
  firstButton,
  secondButton,
}) => {
  const mobileScreen = useBreakpoint({ max: "sm" });

  return !mobileScreen ? (
    <Row className={styles.container} justify="center" gutter={20}>
      <Col>
        <div className={styles.firstButton}>{firstButton}</div>
      </Col>
      {secondButton && (
        <Col>
          <div>{secondButton}</div>
        </Col>
      )}
    </Row>
  ) : (
    <Row className={styles.container} justify="center" gutter={20}>
      {secondButton && (
        <Col>
          <div className={styles.firstButton}>{secondButton}</div>
        </Col>
      )}
      <Col>
        <div>{firstButton}</div>
      </Col>
    </Row>
  );
};

export default StepNavigator;
