/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RealEstateFileType,
    RealEstateFileTypeFromJSON,
    RealEstateFileTypeFromJSONTyped,
    RealEstateFileTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface RealEstateFileDto
 */
export interface RealEstateFileDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateFileDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {RealEstateFileType}
     * @memberof RealEstateFileDto
     */
    type?: RealEstateFileType;
    /**
     * 
     * @type {string}
     * @memberof RealEstateFileDto
     */
    downloadUrl?: string | null;
}

export function RealEstateFileDtoFromJSON(json: any): RealEstateFileDto {
    return RealEstateFileDtoFromJSONTyped(json, false);
}

export function RealEstateFileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RealEstateFileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'type': !exists(json, 'type') ? undefined : RealEstateFileTypeFromJSON(json['type']),
        'downloadUrl': !exists(json, 'downloadUrl') ? undefined : json['downloadUrl'],
    };
}

export function RealEstateFileDtoToJSON(value?: RealEstateFileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileName': value.fileName,
        'type': RealEstateFileTypeToJSON(value.type),
        'downloadUrl': value.downloadUrl,
    };
}


