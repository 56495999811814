import { Col, Row } from "antd";
import { BuildRefusalReason, DesignRefusalReason } from "api/generated/optimum";
import CustomButton from "components/button/CustomButton";
import CustomModal from "components/customModal/CustomModal";
import { FormUtils, GrapeAntdForm } from "components/form";
import { CustomTextArea } from "components/form/components/CustomInput";
import { CustomSelect } from "components/form/components/CustomSelect";
import GrapeAntdFormItem from "components/form/components/GrapeAntdFormItem";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isBuilder, isEngineer } from "utils/authUtils";
import styles from "./RefuseOrder.module.scss";

export interface RefuseOrderProps {
  buttonLabel: string;
  modalTitle: string;
  textAreaLable: string;
  description: string;
  refuseOrder: (
    note: string,
    reason: any // DesignRefusalReason | BuildRefusalReason
  ) => void;

  formUtils: FormUtils;
  refuseOptions: {
    value: DesignRefusalReason | BuildRefusalReason;
    label: string;
  }[];
}

const RefuseOrder: React.FC<RefuseOrderProps> = ({
  formUtils,
  refuseOptions,
  refuseOrder,
  buttonLabel,
  modalTitle,
  textAreaLable,
  description,
}) => {
  const { t } = useTranslation();
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

  const finishHandler = async ({ note, reason }: any) => {
    try {
      await refuseOrder(note, reason);
      setModalIsVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Row justify="center" className={styles.cancelButtonContainer}>
      <CustomButton
        className={styles.cancelButton}
        type="link"
        size="large"
        onClick={() => setModalIsVisible(true)}
      >
        {buttonLabel}
      </CustomButton>

      <CustomModal
        closable={false}
        width={"58rem"}
        visible={modalIsVisible}
        className={styles.modal}
      >
        <Row justify="center" className={styles.modalTitle}>
          <CustomTitle level={3} color="darkBlue" extraStrong>
            {modalTitle}
          </CustomTitle>
        </Row>

        <CustomParagraph color="black">{textAreaLable}</CustomParagraph>

        <GrapeAntdForm formUtils={formUtils} onFinish={finishHandler}>
          {(isEngineer() || isBuilder()) && (
            <GrapeAntdFormItem name="reason">
              <CustomSelect
                className={styles.select}
                placeholder={t("PROJECT_DETAILS.MODAL.REASON_PLACEHOLDER")}
                size="large"
                options={refuseOptions}
              />
            </GrapeAntdFormItem>
          )}

          <GrapeAntdFormItem name="note">
            <CustomTextArea
              className={styles.textArea}
              placeholder={t("PROJECT_DETAILS.MODAL.TEXTAREA_PLACEHOLDER")}
            />
          </GrapeAntdFormItem>
        </GrapeAntdForm>

        <CustomParagraph color="black" className={styles.modalDescription}>
          {description}
        </CustomParagraph>

        <Row className={styles.modalButtonContainer} justify="space-between">
          <Col>
            <CustomButton
              className={styles.declineButton}
              variant="customer"
              onClick={() => setModalIsVisible(false)}
            >
              {t("PROJECT_DETAILS.MODAL.CANCEL")}
            </CustomButton>
          </Col>

          <Col>
            <CustomButton
              type="primary"
              className={styles.declineButton}
              onClick={formUtils.form.submit}
              disabled={!formUtils.submittable}
            >
              {t("PROJECT_DETAILS.MODAL.DECLINE")}
            </CustomButton>
          </Col>
        </Row>
      </CustomModal>
    </Row>
  );
};

export default RefuseOrder;
