import { FC } from "react";
import styles from "./PageTitle.module.scss";
import { TitleProps } from "antd/lib/typography/Title";
import { ReactComponent as PageTitleLogo } from "assets/images/PageTitle.svg";

import { useTranslation } from "react-i18next";
import clsx from "clsx";
import CustomTitle from "components/typography/title/CustomTitle";
import { Role } from "api/generated/optimum";

export interface PageTitleProps extends TitleProps {
  title?: string;
  colorVariant?: Role;
  logo?: JSX.Element;
}

const PageTitle: FC<PageTitleProps> = ({
  title,
  colorVariant = Role.Engineer,
  logo = <PageTitleLogo />,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(styles.container, {
        [styles.builderColor]: colorVariant === Role.Builder,
        [styles.ngbsColor]: colorVariant === Role.Ngbs,
        [styles.adminColor]: colorVariant === Role.Admin,
      })}
    >
      <div>
        <CustomTitle
          className={styles.title}
          level={1}
          responsiveLevel={{
            mobile: 4,
          }}
          extraStrong
          {...rest}
        >
          {title}
        </CustomTitle>
        <CustomTitle className={clsx(styles.title, styles.subTitle)} level={4}>
          {t("PAGE_TITLE.SUB_TITLE")}
        </CustomTitle>
      </div>
      <div className={styles.pageTitleLogo}>{logo}</div>
    </div>
  );
};

export default PageTitle;
