import { Card, Button, Space } from "antd";
import { useTranslation } from "react-i18next";
import TableSettingsDragAndDrop from "./TableSettingsDragAndDrop";
import { TableSettingsUtils } from "./useTableSettingsUtils";

function TableSettingsLayout<T>({
  ...utilProps
}: TableSettingsUtils<T>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Card size="small">
      <Space direction="vertical">
        <TableSettingsDragAndDrop<T> {...utilProps} />
        <Space>
          <Button
            onClick={utilProps.handleResetToDefault}
            disabled={!utilProps.areColumnsChanged}
          >
            <span>{t("table.reset")}</span>
          </Button>
          <Button
            type="primary"
            disabled={utilProps.applyChangesNotAllowed}
            onClick={utilProps.handleApplyChanges}
          >
            {t("common.save")}
          </Button>
        </Space>
      </Space>
    </Card>
  );
}

export default TableSettingsLayout;
