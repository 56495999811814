/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildMaterialDto,
    BuildMaterialDtoFromJSON,
    BuildMaterialDtoFromJSONTyped,
    BuildMaterialDtoToJSON,
    BuildMaterialListStatus,
    BuildMaterialListStatusFromJSON,
    BuildMaterialListStatusFromJSONTyped,
    BuildMaterialListStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface BuildMaterialListDto
 */
export interface BuildMaterialListDto {
    /**
     * 
     * @type {number}
     * @memberof BuildMaterialListDto
     */
    buildOrderId?: number;
    /**
     * 
     * @type {BuildMaterialListStatus}
     * @memberof BuildMaterialListDto
     */
    status?: BuildMaterialListStatus;
    /**
     * 
     * @type {Date}
     * @memberof BuildMaterialListDto
     */
    paymentDueDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof BuildMaterialListDto
     */
    orderDate?: Date | null;
    /**
     * 
     * @type {Array<BuildMaterialDto>}
     * @memberof BuildMaterialListDto
     */
    materials?: Array<BuildMaterialDto> | null;
}

export function BuildMaterialListDtoFromJSON(json: any): BuildMaterialListDto {
    return BuildMaterialListDtoFromJSONTyped(json, false);
}

export function BuildMaterialListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildMaterialListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buildOrderId': !exists(json, 'buildOrderId') ? undefined : json['buildOrderId'],
        'status': !exists(json, 'status') ? undefined : BuildMaterialListStatusFromJSON(json['status']),
        'paymentDueDate': !exists(json, 'paymentDueDate') ? undefined : (json['paymentDueDate'] === null ? null : new Date(json['paymentDueDate'])),
        'orderDate': !exists(json, 'orderDate') ? undefined : (json['orderDate'] === null ? null : new Date(json['orderDate'])),
        'materials': !exists(json, 'materials') ? undefined : (json['materials'] === null ? null : (json['materials'] as Array<any>).map(BuildMaterialDtoFromJSON)),
    };
}

export function BuildMaterialListDtoToJSON(value?: BuildMaterialListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buildOrderId': value.buildOrderId,
        'status': BuildMaterialListStatusToJSON(value.status),
        'paymentDueDate': value.paymentDueDate === undefined ? undefined : (value.paymentDueDate === null ? null : value.paymentDueDate.toISOString()),
        'orderDate': value.orderDate === undefined ? undefined : (value.orderDate === null ? null : value.orderDate.toISOString()),
        'materials': value.materials === undefined ? undefined : (value.materials === null ? null : (value.materials as Array<any>).map(BuildMaterialDtoToJSON)),
    };
}


