import { List } from "antd";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../button/CustomButton";
import CustomParagraph from "../typography/paragraph/CustomParagraph";
import styles from "./ProvidersListItem.module.scss";
import CustomTitle from "../../components/typography/title/CustomTitle";
import ProviderAvatar from "components/providerAvatar/ProviderAvatar";
import Card from "components/card/Card";
import { EngineerDto, BuilderDto } from "api/generated/optimum";
import clsx from "clsx";
import { useBreakpoint } from "hooks/useBreakpoint";

export enum SelectMode {
  SINGLE,
  MULTI,
}
export interface ProvidersListProps {
  selectMode?: SelectMode;
  provider: EngineerDto | BuilderDto;
  actionButton?: JSX.Element;
  onViewProfileButton?: () => void;
}

const ProvidersListItem: FC<ProvidersListProps> = ({
  children,
  selectMode,
  provider,
  actionButton,
  onViewProfileButton,
}) => {
  const { t } = useTranslation();
  const { firstName, lastName, position, companyName, isActive } = provider;
  const belowDesktopScreen = useBreakpoint({ max: "lg" });

  const actions = useMemo(
    () => [
      <>
        {!isActive && (
          <CustomParagraph className={styles.inActiveText}>
            {t("PROVIDERS_LIST.INACTIVE")}
          </CustomParagraph>
        )}
        <CustomButton onClick={onViewProfileButton} variant="customer">
          {t("PROVIDERS_LIST.VIEW_PROFILE_BUTTON")}
        </CustomButton>
      </>,
    ],
    [isActive, t, onViewProfileButton]
  );

  return (
    <Card>
      <div className={styles.actionButton}>
        {selectMode === SelectMode.MULTI &&
          actionButton &&
          isActive &&
          actionButton}
      </div>

      <List.Item className={styles.item} actions={actions}>
        <List.Item.Meta
          description={
            <section className={styles.description}>
              <div className={styles.providerMetaData}>
                <ProviderAvatar
                  size={belowDesktopScreen ? 100 : 180}
                  isActive={isActive}
                  url={provider.profilePictureUrl}
                />
                <div className={styles.providerBasicDetail}>
                  <CustomTitle
                    level={3}
                    extraStrong
                    responsiveLevel={{
                      tablet: 4,
                      mobile: 3,
                    }}
                  >
                    {`${lastName} ${firstName}`}
                  </CustomTitle>

                  <CustomParagraph
                    size="sm"
                    color="grey"
                    className={styles.workText}
                  >
                    {position}
                  </CustomParagraph>

                  <CustomParagraph
                    size="sm"
                    color="grey"
                    className={styles.workText}
                  >
                    {companyName}
                  </CustomParagraph>

                  <section
                    className={clsx(styles.details, styles.desktopDetail)}
                  >
                    {children}
                  </section>
                </div>
              </div>
              <section className={clsx(styles.details, styles.tabletDetail)}>
                {children}
              </section>
            </section>
          }
        />
      </List.Item>
    </Card>
  );
};

export default ProvidersListItem;
