import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TableSettingsUtils } from "./useTableSettingsUtils";
import { useTranslation } from "react-i18next";
import { Checkbox, Typography } from "antd";
import { ColumnState, ExtendedColumnType } from "./ExtendedColumnType";
import styles from "./TableSettingsDragAndDrop.module.scss";
import { mdiDrag, mdiLock } from "@mdi/js";
import { MaterialIcon } from "../components/MaterialIcon";

interface DraggableItemProps<T> {
  column: ExtendedColumnType<any>;
  index: number;
  handleChangeColumnVisibility: (
    visible: boolean,
    column: ExtendedColumnType<T>
  ) => void;
}

function DraggableItem<T = any>({
  column,
  index,
  handleChangeColumnVisibility,
}: DraggableItemProps<T>): JSX.Element {
  const alwaysVisible = column.currentState === ColumnState.AlwaysVisible;

  return (
    <Draggable
      key={String(column.key)}
      draggableId={String(column.key)}
      index={index}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={styles.draggableItem}
        >
          <MaterialIcon path={mdiDrag} className={styles.grabIcon} />
          {alwaysVisible ? (
            <MaterialIcon
              path={mdiLock}
              className={`${styles.actionElement} locked`}
            />
          ) : (
            <Checkbox
              onChange={(e) =>
                handleChangeColumnVisibility(e.target.checked, column)
              }
              checked={column.currentState === ColumnState.Visible}
            />
          )}
          <Typography.Text
            ellipsis
            className={`${styles.itemText} ${alwaysVisible ? "locked" : ""}`}
          >
            {column.altTitle ?? column.title}
          </Typography.Text>
        </div>
      )}
    </Draggable>
  );
}

function TableSettingsDragAndDrop<T>(
  props: TableSettingsUtils<T>
): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.title}>{t("table.header")}</div>
      <DragDropContext onDragEnd={props.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className={styles.draggableArea}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {props.tempColumns
                .filter(
                  (column) =>
                    column.title && column.key && !column.columnOrderFixed
                )
                .map((column, index) => (
                  <DraggableItem
                    column={column}
                    index={index}
                    handleChangeColumnVisibility={
                      props.handleChangeColumnVisibility
                    }
                    key={index}
                  />
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default TableSettingsDragAndDrop;
