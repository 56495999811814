import { Col, Image, Layout, Row, Space } from "antd";
import { FC } from "react";
import CustomTitle from "../../typography/title/CustomTitle";
import logo from "assets/images/mvm_logo.png";
import styles from "./Footer.module.scss";
import { useTranslation } from "react-i18next";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ASZF_URL, KNOWLEDGE_BASE_URL, PRIVACY_URL } from "constants/urls";

const Footer: FC = () => {
  const { t } = useTranslation();
  const mobileScreen = useBreakpoint({ max: "sm" });

  const GyikLink = () => (
    <a
      href={KNOWLEDGE_BASE_URL}
      target="_blank"
      className={styles.kbLink}
      rel="noreferrer"
    >
      {t("FOOTER.GYIK")}
    </a>
  );

  const AszfLink = () => (
    <a
      onClick={() => window.open(ASZF_URL, "_blank")}
      target="_blank"
      rel="noreferrer"
      className={styles.navLink}
    >
      {t("FOOTER.ASZF")}
    </a>
  );

  const PrivacyLink = () => (
    <a
      onClick={() => window.open(PRIVACY_URL, "_blank")}
      target="_blank"
      rel="noreferrer"
      className={styles.navLink}
    >
      {t("FOOTER.PRIVACY")}
    </a>
  );

  const TermsLink = () => (
    <a
      href="https://mvmoptimum.hu/jogi-nyilatkozat/"
      target="_blank"
      className={styles.navLink}
      rel="noreferrer"
    >
      {t("FOOTER.TERMS")}
    </a>
  );

  const ImpressumLink = () => (
    <a
      href="https://mvmoptimum.hu/impresszum/"
      target="_blank"
      className={styles.navLink}
      rel="noreferrer"
    >
      {t("FOOTER.IMPRESSIUM")}
    </a>
  );

  return (
    <Layout.Footer className={styles.footer}>
      {!mobileScreen ? (
        <>
          <Row>
            <Col xs={24} md={12}>
              <CustomTitle className={styles.contact}>
                {t("FOOTER.CONTACT")}
              </CustomTitle>
              <Space direction="vertical">
                {/* {t("FOOTER.PHONE")} */}
                <span className={styles.email}>{t("FOOTER.EMAIL")}</span>
                <CustomParagraph className={styles.spaceItem} strong underline>
                  <GyikLink />
                </CustomParagraph>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <CustomTitle level={5} className={styles.footerTitle}>
                {t("FOOTER.TITLE")}
              </CustomTitle>
              <p className={styles.description}>
                {t("FOOTER.DESCRIPTION")}
                <a
                  href="https://mvmoptimum.hu/mvm-optimum/rolunk/mvm-optimum-zrt-bemutatkozas/"
                  target="_blank"
                  className={styles.nextButton}
                  rel="noreferrer"
                >
                  {t("FOOTER.NEXT")}
                </a>
              </p>
            </Col>
          </Row>

          <Row
            className={styles.bottomSection}
            align="middle"
            justify="space-between"
          >
            <Col sm={6}>
              <Image src={logo} className={styles.logo} preview={false} />
            </Col>
            <Col sm={10} className={styles.statementSection}>
              <div>
                <span className={styles.footerItem}>
                  <AszfLink />
                </span>
                <span className={styles.footerItem}>
                  <PrivacyLink />
                </span>
                <span className={styles.footerItem}>
                  <TermsLink />
                </span>
                <span className={styles.footerItem}>
                  <ImpressumLink />
                </span>
              </div>
            </Col>
            <Col sm={8} className={styles.copyRightDesktop}>
              <span className={styles.copyright}>{t("FOOTER.COPYRIGHT")}</span>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col xs={24} md={12}>
              <CustomTitle
                level={5}
                className={styles.mobileContatctTitle}
                extraStrong
              >
                {t("FOOTER.CONTACT")}
              </CustomTitle>
              <Space size={0} direction="vertical">
                <span className={styles.mobileContatct}>
                  {t("FOOTER.PHONE")}
                </span>
                <span className={styles.mobileContatct}>
                  {t("FOOTER.EMAIL")}
                </span>
                <CustomParagraph className={styles.spaceItem} strong underline>
                  <GyikLink />
                </CustomParagraph>
              </Space>
            </Col>
          </Row>

          <Row align="middle" className={styles.bottomSection}>
            <Col xs={6}>
              <span className={styles.footerItem}>
                <AszfLink />
              </span>
            </Col>
            <Col>
              <span className={styles.footerItem}>
                <TermsLink />
              </span>
            </Col>
          </Row>

          <Row align="middle">
            <Col xs={6}>
              <span className={styles.footerItem}>
                <PrivacyLink />
              </span>
            </Col>
            <Col>
              <span className={styles.footerItem}>
                <ImpressumLink />
              </span>
            </Col>
          </Row>

          <Row align="bottom">
            <Col xs={12} flex="auto">
              <Image src={logo} className={styles.logo} preview={false} />
            </Col>
            <Col>
              <span className={styles.copyright}>{t("FOOTER.COPYRIGHT")}</span>
            </Col>
          </Row>
        </>
      )}
    </Layout.Footer>
  );
};

export default Footer;
