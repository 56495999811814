import { useDispatch, useSelector } from "app/store";
import { FC } from "react";
import CustomModal from "components/customModal/CustomModal";
import styles from "./BuilderDetailModal.module.scss";
import Avatar from "components/providerAvatar/ProviderAvatar";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import CustomButton from "components/button/CustomButton";
import { UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import { t } from "i18next";
import { BuilderDto } from "api/generated/optimum";
import clsx from "clsx";

export interface BuilderDetailModalProps {
  handleModifyBuilderSelection: (builder: BuilderDto) => void;
}

const BuilderDetailModal: FC<BuilderDetailModalProps> = ({
  handleModifyBuilderSelection,
}) => {
  const { selectedBuilder, selectedBuilders } = useSelector(
    (state) => state.builder
  );
  const dispatch = useDispatch();

  const handleOnRemoveBuilder = (): void => {
    if (!selectedBuilder) {
      console.log("There is no currently selected builder.");
      return;
    }

    handleModifyBuilderSelection(selectedBuilder);
  };

  return (
    <CustomModal
      visible={!!selectedBuilder}
      onCancel={() => dispatch.builder.unSelectBuilder()}
      className={styles.modal}
      closable={false}
      width={"120rem"}
    >
      <Row>
        <Col xs={21} flex="auto">
          <div className={styles.selectedBuilder}>
            <Avatar
              url={selectedBuilder?.profilePictureUrl}
              size={"18rem"}
              className={styles.avatar}
              isActive={selectedBuilder?.isActive}
            />
            <div className={styles.selectedBuilderData}>
              <CustomTitle
                level={3}
                responsiveLevel={{
                  mobile: 3,
                  tablet: 4,
                }}
                extraStrong
                className={styles.nameTitle}
              >{`${selectedBuilder?.lastName} ${selectedBuilder?.firstName}`}</CustomTitle>

              <CustomTitle
                level={5}
                className={clsx(styles.builderDataTitle, styles.professionData)}
                color="grey"
              >
                {selectedBuilder?.position}
              </CustomTitle>

              <CustomTitle
                level={5}
                className={clsx(styles.builderDataTitle, styles.professionData)}
                color="grey"
              >
                {selectedBuilder?.companyName}
              </CustomTitle>
            </div>
          </div>
        </Col>
        {selectedBuilder?.isActive && (
          <Col>
            <Tooltip
              title={
                selectedBuilders.includes(selectedBuilder!)
                  ? t("TOOLTIP.UNSELECT_BUILDER")
                  : t("TOOLTIP.SELECT_BUILDER")
              }
            >
              <CustomButton
                shape="circle"
                disabled={
                  selectedBuilders.length >= 3 &&
                  !selectedBuilders.includes(selectedBuilder!)
                }
                icon={
                  selectedBuilders.includes(selectedBuilder!) ? (
                    <UserOutlined className={styles.removeBuilderIcon} />
                  ) : (
                    <UserAddOutlined className={styles.addBuilderIcon} />
                  )
                }
                className={
                  selectedBuilders.includes(selectedBuilder!)
                    ? styles.removeBuilderButton
                    : styles.addBuilderButton
                }
                onClick={() => handleOnRemoveBuilder()}
                type={
                  selectedBuilders.includes(selectedBuilder!)
                    ? "primary"
                    : "ghost"
                }
              />
            </Tooltip>
          </Col>
        )}
      </Row>

      <section className={styles.builderDetailSection}>
        <CustomParagraph
          className={styles.specTitle}
          size="sm"
          color="grey"
          strong
        >
          {t("BUILDER.DETAILS.SPECIALIZATION")}
        </CustomParagraph>

        <CustomParagraph size="sm" color="grey">
          {selectedBuilder?.specializations?.join(", ")}
        </CustomParagraph>

        <CustomParagraph
          size="sm"
          color="grey"
          className={styles.introductionText}
        >
          {selectedBuilder?.introduction}
        </CustomParagraph>
      </section>

      <Row justify="center">
        <CustomButton
          type="primary"
          onClick={() => dispatch.builder.unSelectBuilder()}
        >
          {t("BUILDER.DETAILS.CLOSE")}
        </CustomButton>
      </Row>
    </CustomModal>
  );
};

export default BuilderDetailModal;
