import { FC } from "react";
import { useTranslation } from "react-i18next";
import Content from "../../components/layout/content/Content";
import BuilderList from "./components/builderList/BuildersList";
import { INITIAL_CURRENT_STEP } from "features/builder/Builder.store";
import { useSelector } from "app/store";
import BuilderContactWizard from "./components/builderContactWizard/BuilderContactWizard";
import { ReactComponent as BuilderLogo } from "assets/images/builder_logo.svg";
import styles from "./BuilderPage.module.scss";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Role } from "api/generated/optimum";
import { ScrollToTop } from "components/ScrollToTop";

const BuilderPage: FC = () => {
  const { t } = useTranslation();
  const currentStep = useSelector((state) => state.builder.currentStep);
  const mobileScreen = useBreakpoint({ max: "sm" });

  return (
    <ScrollToTop scrollIndicator={currentStep}>
      <Content
        title={
          mobileScreen
            ? t("BUILDERS.PAGE_TITLE_MOBILE")
            : t("BUILDERS.PAGE_TITLE")
        }
        colorVariant={Role.Builder}
        logo={<BuilderLogo className={styles.pageTitleLogo} />}
      >
        {currentStep > INITIAL_CURRENT_STEP ? (
          <BuilderContactWizard />
        ) : (
          <BuilderList />
        )}
      </Content>
    </ScrollToTop>
  );
};

export default BuilderPage;
