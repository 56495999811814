import { BuildOrderStatus, WildcardSearchType } from "api/generated/optimum";
import { useDispatch } from "app/store";
import useSearchInput from "hooks/useSearchInput";
import { useCallback } from "react";
import TabPaneList from "../components/tabPaneList/TabPaneList";

export const OffersTab: React.FC = () => {
  const dispatch = useDispatch();

  const getProjects = useCallback(
    (wildcard?: string) =>
      dispatch.projects.getProjects({
        wildcardSearchTerm: wildcard,
        wildcardSearchTypes: [WildcardSearchType.Customer],
        buildOrderStatuses: [BuildOrderStatus.New],
      }),
    [dispatch.projects]
  );

  const setSearchTerm = useSearchInput(getProjects);

  return <TabPaneList setSearchTerm={setSearchTerm} />;
};
export default OffersTab;
