import i18n from "app/i18n";
import { EnumContext } from "app/i18n/manualLocaleKeys";

const enumTranslate = (key: string, context: EnumContext) => {
  if (!key) return;

  const i18nKey = `ENUMS.${context}.${key}`.toUpperCase();

  return i18n.t([i18nKey, key.toUpperCase()]);
};

export default enumTranslate;
