/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DesignOrderStatus {
    Invalid = 'Invalid',
    New = 'New',
    Refused = 'Refused',
    FileUpload = 'FileUpload',
    OptiComfortNew = 'OptiComfortNew',
    OptiComfortWip = 'OptiComfortWip',
    OptiComfort = 'OptiComfort',
    Completed = 'Completed'
}

export function DesignOrderStatusFromJSON(json: any): DesignOrderStatus {
    return DesignOrderStatusFromJSONTyped(json, false);
}

export function DesignOrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DesignOrderStatus {
    return json as DesignOrderStatus;
}

export function DesignOrderStatusToJSON(value?: DesignOrderStatus | null): any {
    return value as any;
}

