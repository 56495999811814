import { Descriptions, Divider, Row } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./OrderDetails.module.scss";
import Card from "components/card/Card";
import CustomTitle from "components/typography/title/CustomTitle";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import {
  DesignOrderStatus,
  ProjectDto,
  RealEstateFileDto,
  RealEstateFileType,
} from "api/generated/optimum";
import { phoneNumberDisplayFormat } from "utils/format";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import enumTranslate from "utils/enumTranslate";
import { isEngineer, isNgbs } from "utils/authUtils";
import { is } from "immer/dist/internal";

export interface OrderDetailsProps {
  project?: ProjectDto;
}

const OrderDetails: FC<OrderDetailsProps> = ({ project }) => {
  const { t } = useTranslation();

  return (
    <Card className={styles.dataCard}>
      <Row justify="center" className={styles.cardTitle}>
        <CustomTitle level={3} color="darkBlue" extraStrong>
          {t("ORDER_DETAILS.TITLE")}
        </CustomTitle>
      </Row>

      <Descriptions className={styles.descriptions}>
        <Descriptions.Item
          label={
            <CustomParagraph
              className={styles.descriptionLabel}
              color="grey"
              extraStrong
            >
              {t("ORDER_DETAILS.CUSTOMER")}
            </CustomParagraph>
          }
          span={24}
        >
          {project?.customerName}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <CustomParagraph
              className={styles.descriptionLabel}
              color="grey"
              extraStrong
            >
              {t("ORDER_DETAILS.PHONENUMBER")}
            </CustomParagraph>
          }
          span={24}
        >
          {phoneNumberDisplayFormat(project?.customerPhoneNumber || "")}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <CustomParagraph
              className={styles.descriptionLabel}
              color="grey"
              extraStrong
            >
              {t("ORDER_DETAILS.EMAIL")}
            </CustomParagraph>
          }
          span={24}
        >
          {project?.customerEmailAddress}
        </Descriptions.Item>
      </Descriptions>

      <Divider className={styles.divider} />

      <Descriptions className={styles.descriptions}>
        <Descriptions.Item
          label={
            <CustomParagraph
              className={styles.descriptionLabel}
              color="grey"
              extraStrong
            >
              {t("ORDER_DETAILS.ADDRESS")}
            </CustomParagraph>
          }
          span={24}
        >
          {`${project?.realEstateZipCode} ${project?.realEstateCity}, ${project?.realEstateAddress}`}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <CustomParagraph
              className={styles.descriptionLabel}
              color="grey"
              extraStrong
            >
              {t("ORDER_DETAILS.PROJECT_TYPE")}
            </CustomParagraph>
          }
          span={24}
        >
          {project?.realEstateIsNew
            ? t("PROJECT_DETAILS.HEADER.NEWLY_BUILT")
            : t("PROJECT_DETAILS.HEADER.AWAITING_RENOVATION")}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <CustomParagraph
              className={styles.descriptionLabel}
              color="grey"
              extraStrong
            >
              {t("ORDER_DETAILS.FLOOR_ARE")}
            </CustomParagraph>
          }
          span={24}
        >
          {project?.realEstateHeatedSquareMs} m<sup>2</sup>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <CustomParagraph
              className={styles.descriptionLabel}
              color="grey"
              extraStrong
            >
              {t("ORDER_DETAILS.INSULATION")}
            </CustomParagraph>
          }
          span={24}
        >
          {enumTranslate(
            project?.realEstateInsulationOptions?.toString() || "",
            EnumContext.INSULATION
          )?.toLocaleLowerCase()}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <CustomParagraph
              className={styles.descriptionLabel}
              color="grey"
              extraStrong
            >
              {t("ORDER_DETAILS.PEOPLE_COUNT")}
            </CustomParagraph>
          }
          span={24}
        >
          {project?.realEstateHeadCount}
          {t("ORDER_DETAILS.PEOPLE_COUNT_POSTFIX")}
        </Descriptions.Item>

        {!project?.realEstateIsNew && (
          <Descriptions.Item
            label={
              <CustomParagraph
                className={styles.descriptionLabel}
                color="grey"
                extraStrong
              >
                {t("ORDER_DETAILS.BOILER")}
              </CustomParagraph>
            }
            span={24}
          >
            {enumTranslate(
              project?.realEstateBoilerOptions?.toString() || "",
              EnumContext.BOILER
            )?.toLocaleLowerCase()}
          </Descriptions.Item>
        )}

        {project?.realEstateHeatEmitterOptions?.length && (
          <Descriptions.Item
            label={
              <CustomParagraph
                className={styles.descriptionLabel}
                color="grey"
                extraStrong
              >
                {t("ORDER_DETAILS.HEAT_EMITTER")}
              </CustomParagraph>
            }
            span={24}
          >
            {project?.realEstateHeatEmitterOptions
              ?.map((x) =>
                enumTranslate(
                  x.toString(),
                  EnumContext.HEATEMITTER
                )?.toLowerCase()
              )
              .join(", ")}
          </Descriptions.Item>
        )}

        {project?.realEstateIsNew && (
          <Descriptions.Item
            label={
              <CustomParagraph
                className={styles.descriptionLabel}
                color="grey"
                extraStrong
              >
                {t("ORDER_DETAILS.PROJECT_PHASE")}
              </CustomParagraph>
            }
            span={24}
          >
            {enumTranslate(
              project?.realEstateHomeBuildingPhaseOptions?.toString() || "",
              EnumContext.HOMEPHASE
            )?.toLocaleLowerCase()}
          </Descriptions.Item>
        )}
      </Descriptions>

      <Divider className={styles.divider} />

      <CustomParagraph className={styles.descriptionLabel} extraStrong>
        {t("ORDER_DETAILS.MESSAGE")}
      </CustomParagraph>
      <CustomParagraph className={styles.message}>
        {project?.customerDesignOrderMessage}
      </CustomParagraph>

      <Divider className={styles.divider} />

      <CustomParagraph className={styles.descriptionLabel} extraStrong>
        {t("ORDER_DETAILS.FILES")}
      </CustomParagraph>
      {project?.designOrderStatus === DesignOrderStatus.Completed &&
      (isNgbs() || isEngineer())
        ? project?.realEstateFiles
            ?.filter((x) => x.type !== RealEstateFileType.Build)
            .map((file: RealEstateFileDto, i: number) => (
              <a key={i} href={file.downloadUrl || ""} className={styles.files}>
                {(i ? ", " : "") + file.fileName}
              </a>
            ))
        : project?.realEstateFiles?.map(
            (file: RealEstateFileDto, i: number) => (
              <a key={i} href={file.downloadUrl || ""} className={styles.files}>
                {(i ? ", " : "") + file.fileName}
              </a>
            )
          )}
    </Card>
  );
};

export default OrderDetails;
