import Engineer from "features/engineer/Engineer";
import { Navigate } from "react-router-dom";
import { isAdmin, isProvider } from "utils/authUtils";

const HomePage: React.FC = () => {
  if (isProvider()) {
    return <Navigate to={"/projects"} replace />;
  }

  if (isAdmin()) {
    return <Navigate to={"/admin/projects"} replace />;
  }

  return <Engineer />;
};

export default HomePage;
