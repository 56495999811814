/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DesignOrderStatus,
    DesignOrderStatusFromJSON,
    DesignOrderStatusFromJSONTyped,
    DesignOrderStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface DesignOrderDto
 */
export interface DesignOrderDto {
    /**
     * 
     * @type {string}
     * @memberof DesignOrderDto
     */
    projectCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesignOrderDto
     */
    realEstateZipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesignOrderDto
     */
    realEstateCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesignOrderDto
     */
    realEstateAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesignOrderDto
     */
    customerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesignOrderDto
     */
    customerEmailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesignOrderDto
     */
    customerPhoneNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DesignOrderDto
     */
    designOrderId?: number;
    /**
     * 
     * @type {DesignOrderStatus}
     * @memberof DesignOrderDto
     */
    designOrderStatus?: DesignOrderStatus;
}

export function DesignOrderDtoFromJSON(json: any): DesignOrderDto {
    return DesignOrderDtoFromJSONTyped(json, false);
}

export function DesignOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DesignOrderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectCode': !exists(json, 'projectCode') ? undefined : json['projectCode'],
        'realEstateZipCode': !exists(json, 'realEstateZipCode') ? undefined : json['realEstateZipCode'],
        'realEstateCity': !exists(json, 'realEstateCity') ? undefined : json['realEstateCity'],
        'realEstateAddress': !exists(json, 'realEstateAddress') ? undefined : json['realEstateAddress'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerEmailAddress': !exists(json, 'customerEmailAddress') ? undefined : json['customerEmailAddress'],
        'customerPhoneNumber': !exists(json, 'customerPhoneNumber') ? undefined : json['customerPhoneNumber'],
        'designOrderId': !exists(json, 'designOrderId') ? undefined : json['designOrderId'],
        'designOrderStatus': !exists(json, 'designOrderStatus') ? undefined : DesignOrderStatusFromJSON(json['designOrderStatus']),
    };
}

export function DesignOrderDtoToJSON(value?: DesignOrderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectCode': value.projectCode,
        'realEstateZipCode': value.realEstateZipCode,
        'realEstateCity': value.realEstateCity,
        'realEstateAddress': value.realEstateAddress,
        'customerName': value.customerName,
        'customerEmailAddress': value.customerEmailAddress,
        'customerPhoneNumber': value.customerPhoneNumber,
        'designOrderId': value.designOrderId,
        'designOrderStatus': DesignOrderStatusToJSON(value.designOrderStatus),
    };
}


