/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DesignServiceChargeDto
 */
export interface DesignServiceChargeDto {
    /**
     * 
     * @type {string}
     * @memberof DesignServiceChargeDto
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DesignServiceChargeDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignServiceChargeDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignServiceChargeDto
     */
    readonly totalPrice?: number;
}

export function DesignServiceChargeDtoFromJSON(json: any): DesignServiceChargeDto {
    return DesignServiceChargeDtoFromJSONTyped(json, false);
}

export function DesignServiceChargeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DesignServiceChargeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'totalPrice': !exists(json, 'totalPrice') ? undefined : json['totalPrice'],
    };
}

export function DesignServiceChargeDtoToJSON(value?: DesignServiceChargeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'price': value.price,
        'amount': value.amount,
    };
}


