/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderBuildMaterialListCommand
 */
export interface OrderBuildMaterialListCommand {
    /**
     * 
     * @type {number}
     * @memberof OrderBuildMaterialListCommand
     */
    buildOrderId: number;
}

export function OrderBuildMaterialListCommandFromJSON(json: any): OrderBuildMaterialListCommand {
    return OrderBuildMaterialListCommandFromJSONTyped(json, false);
}

export function OrderBuildMaterialListCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderBuildMaterialListCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buildOrderId': json['buildOrderId'],
    };
}

export function OrderBuildMaterialListCommandToJSON(value?: OrderBuildMaterialListCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buildOrderId': value.buildOrderId,
    };
}


