/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProviderType {
    Invalid = 'Invalid',
    Engineer = 'Engineer',
    Builder = 'Builder'
}

export function ProviderTypeFromJSON(json: any): ProviderType {
    return ProviderTypeFromJSONTyped(json, false);
}

export function ProviderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderType {
    return json as ProviderType;
}

export function ProviderTypeToJSON(value?: ProviderType | null): any {
    return value as any;
}

