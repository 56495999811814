/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MaterialUnit {
    Invalid = 'Invalid',
    Piece = 'Piece',
    Meter = 'Meter',
    SquareMeter = 'SquareMeter',
    Liter = 'Liter',
    Set = 'Set'
}

export function MaterialUnitFromJSON(json: any): MaterialUnit {
    return MaterialUnitFromJSONTyped(json, false);
}

export function MaterialUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaterialUnit {
    return json as MaterialUnit;
}

export function MaterialUnitToJSON(value?: MaterialUnit | null): any {
    return value as any;
}

