/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BoilerOptions {
    None = 'None',
    Other = 'Other',
    GasBoiler = 'GasBoiler',
    WoodBurningBoiler = 'WoodBurningBoiler',
    CombiBoiler = 'CombiBoiler',
    ElectricBoiler = 'ElectricBoiler'
}

export function BoilerOptionsFromJSON(json: any): BoilerOptions {
    return BoilerOptionsFromJSONTyped(json, false);
}

export function BoilerOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoilerOptions {
    return json as BoilerOptions;
}

export function BoilerOptionsToJSON(value?: BoilerOptions | null): any {
    return value as any;
}

