import { Row } from "antd";
import {
  BuildServiceChargeSummaryDto,
  DesignServiceChargeSummaryDto,
} from "api/generated/optimum";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import CustomButton from "components/button/CustomButton";
import CustomModal from "components/customModal/CustomModal";
import { CustomTableProps } from "components/GrapeAntdTable/CustomTable";
import CustomTitle from "components/typography/title/CustomTitle";
import MaterialTable from "features/projects/details/components/materialListModal/MaterialTable";
import { FC, ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import enumTranslate from "utils/enumTranslate";
import { decimalSeparator } from "utils/format";
import useBulid from "../../useBuild";
import styles from "./BuildMaterialTable.module.scss";

const BuildMaterialTable: FC<{
  visible: boolean;
  onClose: () => void;
  title: ReactNode;
  data:
    | BuildServiceChargeSummaryDto
    | DesignServiceChargeSummaryDto
    | undefined;
}> = ({ visible, onClose, data, title }) => {
  const { t } = useTranslation();
  const { getAutomationExportProductNameTransKeyByCode } = useBulid();

  const tableProps = {
    // table config
    rowKey: "id",
    columns: [
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.PRODUCT_CODE"),
        dataIndex: "code",
        className: styles.codeCell,
        width: "18rem",
      },
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.PRODUCT_NAME"),
        dataIndex: "code",
        className: styles.nameCell,
        render: (value) => (
          <Trans i18nKey={getAutomationExportProductNameTransKeyByCode(value)}>
            <strong />
          </Trans>
        ),
      },
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.UNIT_PRICE"),
        dataIndex: "price",
        width: "14rem",
        className: styles.priceCell,
        align: "center",
        render: (value) => decimalSeparator(value),
      },
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.QUANTITY"),
        dataIndex: "amount",
        width: "14rem",
        align: "center",
        className: styles.amountCell,
        render: (value) =>
          `${value} ${enumTranslate("piece", EnumContext.MATERIALUNIT)}`,
      },
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.TOTAL_PRICE"),
        dataIndex: "totalPrice",
        width: "14rem",
        align: "center",
        className: styles.priceCell,
        render: (value) => decimalSeparator(value),
      },
    ],
  } as CustomTableProps;

  return (
    <CustomModal
      closable={false}
      visible={visible}
      className={styles.modal}
      width={"90%"}
    >
      <Row justify="center" className={styles.title}>
        <CustomTitle level={3} color="darkBlue" extraStrong>
          {title}
        </CustomTitle>
      </Row>
      <div className={styles.content}>
        {data?.serviceCharges && (
          <MaterialTable
            data={data.serviceCharges}
            tableProps={tableProps}
            afterContent={() => {
              const tabTotal = data?.totalPrice;

              return (
                <Row justify="end">
                  <CustomTitle
                    defaultFontSize
                    extraStrong
                    className={styles.tabTotal}
                  >
                    {t("PROJECTS.DETAIL.BUILD.MATERIAL_LIST.TAB_TOTAL", {
                      tabTotal:
                        tabTotal != null ? decimalSeparator(tabTotal) : 0,
                    })}
                  </CustomTitle>
                </Row>
              );
            }}
          />
        )}
        <Row justify="center" className={styles.buttonContainerRow}>
          <CustomButton
            className={styles.closeButton}
            size="large"
            onClick={() => onClose()}
            variant="default"
            type="primary"
          >
            {t("COMMON.CLOSE")}
          </CustomButton>
        </Row>
      </div>
    </CustomModal>
  );
};

export default BuildMaterialTable;
