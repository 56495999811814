/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuilderProfession,
    BuilderProfessionFromJSON,
    BuilderProfessionFromJSONTyped,
    BuilderProfessionToJSON,
} from './';

/**
 * 
 * @export
 * @interface BuilderDto
 */
export interface BuilderDto {
    /**
     * 
     * @type {string}
     * @memberof BuilderDto
     */
    id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BuilderDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BuilderDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuilderDto
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuilderDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuilderDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuilderDto
     */
    introduction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuilderDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuilderDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {BuilderProfession}
     * @memberof BuilderDto
     */
    profession?: BuilderProfession;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuilderDto
     */
    specializations?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof BuilderDto
     */
    profilePictureUrl?: string | null;
}

export function BuilderDtoFromJSON(json: any): BuilderDto {
    return BuilderDtoFromJSONTyped(json, false);
}

export function BuilderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuilderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'profession': !exists(json, 'profession') ? undefined : BuilderProfessionFromJSON(json['profession']),
        'specializations': !exists(json, 'specializations') ? undefined : json['specializations'],
        'profilePictureUrl': !exists(json, 'profilePictureUrl') ? undefined : json['profilePictureUrl'],
    };
}

export function BuilderDtoToJSON(value?: BuilderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'companyName': value.companyName,
        'position': value.position,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'introduction': value.introduction,
        'phoneNumber': value.phoneNumber,
        'emailAddress': value.emailAddress,
        'profession': BuilderProfessionToJSON(value.profession),
        'specializations': value.specializations,
        'profilePictureUrl': value.profilePictureUrl,
    };
}


