/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagDto
 */
export interface TagDto {
    /**
     * 
     * @type {number}
     * @memberof TagDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TagDto
     */
    name?: string | null;
}

export function TagDtoFromJSON(json: any): TagDto {
    return TagDtoFromJSONTyped(json, false);
}

export function TagDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function TagDtoToJSON(value?: TagDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}


