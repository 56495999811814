/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MaterialCategories {
    Invalid = 'Invalid',
    HeatGeneration = 'HeatGeneration',
    HeatRelease = 'HeatRelease',
    Control = 'Control',
    Tools = 'Tools'
}

export function MaterialCategoriesFromJSON(json: any): MaterialCategories {
    return MaterialCategoriesFromJSONTyped(json, false);
}

export function MaterialCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaterialCategories {
    return json as MaterialCategories;
}

export function MaterialCategoriesToJSON(value?: MaterialCategories | null): any {
    return value as any;
}

