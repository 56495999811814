/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InsulationOptions {
    None = 'None',
    NoInsulation = 'NoInsulation',
    FiveCm = 'FiveCm',
    SixCm = 'SixCm',
    TenCm = 'TenCm',
    FifteenCm = 'FifteenCm',
    TwentyCm = 'TwentyCm',
    TwentyCmPlus = 'TwentyCmPlus'
}

export function InsulationOptionsFromJSON(json: any): InsulationOptions {
    return InsulationOptionsFromJSONTyped(json, false);
}

export function InsulationOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsulationOptions {
    return json as InsulationOptions;
}

export function InsulationOptionsToJSON(value?: InsulationOptions | null): any {
    return value as any;
}

