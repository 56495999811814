import { buildOrder } from "./../../features/projects/details/build/BuildOrder.store";
import { init, Models, RematchDispatch, RematchRootState } from "@rematch/core";
import loadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading";
import immerPlugin from "@rematch/immer";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { builder } from "../../features/builder/Builder.store";
import { engineer } from "features/engineer/Engineer.store";
import { auth } from "features/auth/Auth.store";
import { alertModal } from "components/alertModal/AlertModal.store";
import { projects } from "features/projects/Projects.store";
import { provider } from "features/provider/Provider.store";
import { referenceData } from "features/provider/ReferenceData.store";

export interface RootModel extends Models<RootModel> {
  engineer: typeof engineer;
  builder: typeof builder;
  auth: typeof auth;
  alertModal: typeof alertModal;
  projects: typeof projects;
  provider: typeof provider;
  referenceData: typeof referenceData;
  buildOrder: typeof buildOrder;
}

const models: RootModel = {
  engineer,
  builder,
  auth,
  alertModal,
  projects,
  provider,
  referenceData,
  buildOrder,
};

type FullModel = ExtraModelsFromLoading<RootModel, { type: "full" }>;

export const store = init<RootModel, FullModel>({
  models,
  plugins: [
    immerPlugin({ blacklist: ["loading"] }),
    loadingPlugin({ type: "full" }),
  ],
  redux: {
    rootReducers: {
      RESET: () => {
        return undefined;
      },
    },
  },
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useDispatch = (): Dispatch => useReduxDispatch<Dispatch>();
