import { Select } from "antd";
import { SelectProps, SelectValue } from "antd/lib/select";
import {
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  Ref,
  RefAttributes,
} from "react";

interface CustomSelectSubComponents {
  Option: typeof Select.Option;
  OptGroup: typeof Select.OptGroup;
}

// https://stackoverflow.com/questions/63453536/jsx-dot-notation-with-forwardref-typescript
type CustomSelectComponent = ForwardRefExoticComponent<
  PropsWithoutRef<SelectProps<any>> & RefAttributes<any>
> &
  CustomSelectSubComponents;

export const CustomSelect = forwardRef(
  <T extends SelectValue>(props: SelectProps<T>, ref?: Ref<any>) => (
    <Select<T>
      {...props}
      ref={ref}
      getPopupContainer={(trigger) => trigger?.parentNode}
    />
  )
) as CustomSelectComponent;

CustomSelect.Option = Select.Option;
CustomSelect.OptGroup = Select.OptGroup;
