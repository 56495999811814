import {
  BuildOrderStatus,
  DesignOrderStatus,
  WildcardSearchType,
} from "api/generated/optimum";
import { useDispatch } from "app/store";
import useSearchInput from "hooks/useSearchInput";
import { useCallback } from "react";
import { isBuilder, isEngineer, isNgbs } from "utils/authUtils";
import TabPaneList from "../components/tabPaneList/TabPaneList";

const ClosedTab: React.FC = () => {
  const dispatch = useDispatch();

  const getClosedProjects = useCallback(
    (wildcard?: string) =>
      dispatch.projects.getProjects({
        wildcardSearchTerm: wildcard,
        wildcardSearchTypes: [WildcardSearchType.Customer],
        buildOrderStatuses: isBuilder()
          ? [BuildOrderStatus.Completed]
          : undefined,
        designOrderStatuses:
          isEngineer() || isNgbs() ? [DesignOrderStatus.Completed] : undefined,
      }),
    [dispatch.projects]
  );
  const setSearchTerm = useSearchInput(getClosedProjects);

  return <TabPaneList setSearchTerm={setSearchTerm} isActive={false} />;
};

export default ClosedTab;
