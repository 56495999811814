import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationHu from "./locales/hu.json";
import translationEn from "./locales/en.json";

enum LanguageCode {
  HU = "HU",
  EN = "EN",
}

const resources = {
  [LanguageCode.HU]: {
    translation: translationHu,
  },
  [LanguageCode.EN]: {
    translation: translationEn,
  },
};

const defaultLanguage = LanguageCode.HU;

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true,
  },
});

export default i18n;
