import { Col, Empty, List, Row } from "antd";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import Card from "components/card/Card";
import CustomTitle from "components/typography/title/CustomTitle";
import { GrapeAntdForm, requiredField, zipCodeRule } from "components/form";
import CustomInput from "components/form/components/CustomInput";
import CustomButton from "components/button/CustomButton";
import CustomCollapse from "components/collapse/CustomCollapse";
import { useDispatch, useSelector } from "app/store";
import EngineerDetailModal from "./components/engineerDetailModal/EngineerDetailModal";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import EngineerTimeLine from "./components/engineerTimeLine/EngineerTimeLine";
import styles from "./EngineersList.module.scss";
import ProvidersListItem from "components/providersListItem/ProvidersListItem";
import clsx from "clsx";
import { useBreakpoint } from "hooks/useBreakpoint";
import Loading from "components/loading/Loading";
import useQuery from "hooks/useQuery";
import numeral from "numeral";
import { EngineerDto } from "api/generated/optimum";

const EngineersList: FC = () => {
  const { t } = useTranslation();
  const { loading } = useSelector((state) => state.loading.models.engineer);
  const { engineers, zipCode } = useSelector((state) => state.engineer);
  const dispatch = useDispatch();
  const zipCodeCardElementRef = useRef<HTMLDivElement>(null);
  const engineerListElementRef = useRef<HTMLDivElement>(null);
  const mobileScreen = useBreakpoint({ max: "sm" });
  const zip = useQuery().get("zip");
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const getEngineers = useCallback(
    (zip: string): void => {
      dispatch.engineer.getEngineers(zip).then(() => {
        setSubmitDisabled(true);
        setIsCollapseOpen(false);
        setTimeout(scrollToEngineerList, 150);
      });

      dispatch.engineer.setWizardData({ zipCode: zip }, 1);
    },
    [dispatch.engineer]
  );

  const handleInputOnChange = () => {
    setSubmitDisabled(false);
  };

  useEffect(() => {
    if (zip && zip.length === 4) {
      getEngineers(zip);
    }
  }, [zip, dispatch, getEngineers]);

  const scrollToZipCodeForm = (): void => {
    zipCodeCardElementRef &&
      zipCodeCardElementRef.current &&
      window.scrollTo({
        left: 0,
        top: zipCodeCardElementRef.current.offsetTop,
        behavior: "smooth",
      });
  };

  const scrollToEngineerList = (): void => {
    engineerListElementRef &&
      engineerListElementRef.current &&
      window.scrollTo({
        left: 0,
        top: engineerListElementRef.current.offsetTop,
        behavior: "smooth",
      });
  };

  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(true);

  return (
    <>
      <CustomParagraph size="md" className={styles.descriptionText}>
        {t("ENGINEERS.ENGINEERS_LIST.DESCRIPTION")}
      </CustomParagraph>
      <CustomParagraph size="md" className={styles.descriptionText}>
        {t("ENGINEERS.ENGINEERS_LIST.DESICION")}
      </CustomParagraph>

      <CustomButton
        type="link"
        className={styles.linkBtn}
        href="https://opticonnect.mvm-optimum.hu/javasolt-ingatlan-parameterek/ "
      >
        {t("ENGINEERS.ENGINEERS_LIST.PARAMS_LINK")} <RightOutlined />
      </CustomButton>

      <CustomButton
        type="link"
        className={styles.linkBtn}
        href="https://opticonnect.mvm-optimum.hu/opti-comfort-rendszer/ "
      >
        {t("ENGINEERS.ENGINEERS_LIST.OPTI_LINK")} <RightOutlined />
      </CustomButton>

      <div ref={zipCodeCardElementRef}>
        <Card className={styles.formContainer}>
          <CustomTitle
            level={3}
            responsiveLevel={{ mobile: 5, tablet: 5 }}
            color="lightBlue"
            className={styles.zipTitle}
            align="center"
          >
            {t("ENGINEERS.ENGINEERS_LIST.ZIP_CODE")}
          </CustomTitle>
          <GrapeAntdForm
            layout="inline"
            requiredMark={false}
            onFinish={({ zip }) => getEngineers(zip)}
            initialValues={{
              zip: zip ?? zipCode,
            }}
          >
            <Row gutter={20}>
              <Col xs={24} xl={12}>
                <GrapeAntdForm.Item
                  name="zip"
                  rules={[requiredField(), zipCodeRule()]}
                  className={clsx(styles.zipFormItem, styles.zipCode)}
                >
                  <CustomInput
                    onChange={handleInputOnChange}
                    placeholder={t(
                      "ENGINEERS.ENGINEERS_LIST.ZIP_CODE_PLACEHOLDER"
                    )}
                  />
                </GrapeAntdForm.Item>
              </Col>
              <Col xs={24} xl={12}>
                <GrapeAntdForm.Item className={styles.zipFormItem}>
                  <CustomButton
                    disabled={submitDisabled}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className={styles.zipSubmitButton}
                  >
                    {t("ENGINEERS.ENGINEERS_LIST.LOAD_ENGINEERS_BUTTON")}
                  </CustomButton>
                </GrapeAntdForm.Item>
              </Col>
            </Row>
          </GrapeAntdForm>
        </Card>
      </div>
      <CustomCollapse
        title={
          <div className={styles.collapseTitle}>
            <InfoCircleOutlined className={styles.collapseIcon} />
            {t("ENGINEERS.ENGINEERS_LIST.COLLAPSE_TITLE")}
          </div>
        }
        className={styles.collapse}
        isExpanded={isCollapseOpen}
        onClick={() => setIsCollapseOpen((prevValue: boolean) => !prevValue)}
      >
        <EngineerTimeLine />
      </CustomCollapse>

      {loading && <Loading />}

      {engineers ? (
        <>
          <span ref={engineerListElementRef} />
          <CustomTitle
            level={3}
            responsiveLevel={{
              tablet: 4,
              mobile: 5,
            }}
            color="grey"
            align="center"
            className={styles.engineerBoldTitle}
          >
            {t("ENGINEERS.ENGINEERS_LIST.ENGINEER")}
          </CustomTitle>

          {engineers.length ? (
            <List
              itemLayout={mobileScreen ? "vertical" : "horizontal"}
              dataSource={engineers}
              loading={loading}
              renderItem={(provider: EngineerDto, index) => (
                <ProvidersListItem
                  provider={provider}
                  key={index}
                  onViewProfileButton={() =>
                    dispatch.engineer.selectEngineer(provider)
                  }
                >
                  <div className={styles.providerItemDesc}>
                    <CustomTitle
                      extraStrong
                      level={5}
                      color="lightBlue"
                      className={styles.providerItemText}
                    >
                      {t("ENGINEERS.PRICE_RANGE", {
                        minPrice: numeral(provider.minPriceQuote)
                          .format("0,0")
                          .replaceAll(",", "."),
                        maxPrice: numeral(provider.maxPriceQuote)
                          .format("0,0")
                          .replaceAll(",", "."),
                      })}
                    </CustomTitle>

                    <CustomTitle
                      extraStrong
                      level={5}
                      className={styles.providerItemText}
                    >
                      {t("ENGINEERS.SPECIALIZATIONS")}
                    </CustomTitle>

                    <CustomTitle level={5} className={styles.providerItemText}>
                      {provider.specializations?.join(", ")}
                    </CustomTitle>
                  </div>
                </ProvidersListItem>
              )}
            />
          ) : (
            <Empty description={t("COMMON.NO_DATA")} />
          )}
        </>
      ) : (
        <CustomButton
          type="primary"
          className={styles.startSearchButon}
          block
          size="large"
          onClick={scrollToZipCodeForm}
        >
          {t("ENGINEERS.ENGINEERS_LIST.START_SEARCH_BUTTON")}
        </CustomButton>
      )}

      <EngineerDetailModal />
    </>
  );
};

export default EngineersList;
