import CustomTitle from "components/typography/title/CustomTitle";
import { PaperClipOutlined } from "@ant-design/icons";
import styles from "./FileList.module.scss";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { useTranslation } from "react-i18next";

export const FileList: React.FC<{
  title?: string;
  fileList?: any[];
}> = ({ title, fileList }) => {
  const { t } = useTranslation();

  return (
    <>
      {title && (
        <CustomTitle level={3} extraStrong align="left" defaultFontSize>
          {title}
        </CustomTitle>
      )}
      {fileList?.length ? (
        <div className={styles.fileListContainer}>
          {fileList.map((file: any, i: number) => {
            return (
              <a
                key={i}
                className={styles.fileListElement}
                href={file.downloadUrl ?? URL.createObjectURL(file)}
                title={file.name || file.fileName}
                download={file.name || file.fileName}
              >
                <PaperClipOutlined /> {file.name || file.fileName}
              </a>
            );
          })}
        </div>
      ) : (
        <CustomParagraph italic>{t("COMMON.NO_FILE")}</CustomParagraph>
      )}
    </>
  );
};
export default FileList;
