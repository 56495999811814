import {
  DesignOrderStatus,
  MaterialDto,
  MaterialUnit,
} from "api/generated/optimum";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import { useDispatch, useSelector } from "app/store";
import { CustomInputNumber } from "components/form/components/CustomInput";
import { CustomTableProps } from "components/GrapeAntdTable/CustomTable";
import { useTranslation } from "react-i18next";
import { isNgbs } from "utils/authUtils";
import enumTranslate from "utils/enumTranslate";
import styles from "../../components/materialListModal/MaterialListModal.module.scss";

const useMaterialTableProps = () => {
  const { t } = useTranslation();
  const { materialList, currentProject } = useSelector(
    (state) => state.projects
  );
  const dispatch = useDispatch();

  const tableProps = {
    // table config
    rowKey: "id",
    name: "HeatGenerationList",
    columns: [
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.PRODUCT_CODE"),
        dataIndex: "code",
        width: 180,
      },
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.PRODUCT_NAME"),
        dataIndex: "name",
      },
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.QUANTITY"),
        dataIndex: "amount",
        align: "left",
        width: "13rem",
        className: styles.amountCell,
        render: (_, row: MaterialDto) => {
          if (
            !isNgbs() ||
            currentProject?.designOrderStatus === DesignOrderStatus.Completed
          ) {
            return row?.unit === MaterialUnit.SquareMeter ? (
              <span>
                {row.amount} m<sup>2</sup>
              </span>
            ) : (
              <span>
                {`${row.amount} ${enumTranslate(
                  row?.unit?.toString() || "",
                  EnumContext.MATERIALUNIT
                )?.toLocaleLowerCase()}`}
              </span>
            );
          }

          const getValue = (): number => {
            const defaultValue =
              materialList.find((material) => material.materialId === row.id)
                ?.amount || 0;

            return defaultValue;
          };

          return (
            <div className={styles.inputContainer}>
              <CustomInputNumber
                min={0}
                value={getValue()}
                precision={0}
                onChange={(value) => {
                  dispatch.projects.setMaterialList({
                    materialList: [
                      ...materialList.filter(
                        (material) => material.materialId !== row.id
                      ),
                      ...(value
                        ? [{ materialId: row.id!, amount: value as number }]
                        : []),
                    ],
                    isMaterialUpdated: true,
                  });

                  dispatch.projects.setNgbsUpdatedorder(true);
                }}
              />
              {row?.unit === MaterialUnit.SquareMeter ? (
                <span>
                  m<sup>2</sup>
                </span>
              ) : (
                <span>
                  {enumTranslate(
                    row?.unit?.toString() || "",
                    EnumContext.MATERIALUNIT
                  )?.toLocaleLowerCase()}
                </span>
              )}
            </div>
          );
        },
      },
    ],
  } as CustomTableProps;

  return {
    tableProps,
  };
};

export default useMaterialTableProps;
