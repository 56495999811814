import { Col, Row } from "antd";
import { useSelector } from "app/store";
import CustomButton from "components/button/CustomButton";
import CustomModal from "components/customModal/CustomModal";
import { GrapeAntdForm, useGrapeAntdForm } from "components/form";
import { CustomTextArea } from "components/form/components/CustomInput";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SendPlanModal.module.scss";

interface SendPlanModalProps {
  title: string;
  description: string;
  note: string;
  submitBtnLabel: string;
  onClose: () => void;
  onSubmit: (note: string) => void;
  isVisible: boolean;
}

const SendPlanModal: React.FC<SendPlanModalProps> = ({
  title,
  description,
  note,
  onClose,
  onSubmit,
  isVisible,
  submitBtnLabel,
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm();
  const { loading } = useSelector(
    (state) => state.loading.effects.projects.manageOptiComfortPlan
  );

  const finishHandler = useCallback(({ note }) => onSubmit(note), [onSubmit]);

  return (
    <CustomModal
      closable={false}
      width={"58rem"}
      visible={isVisible}
      className={styles.modal}
    >
      <Row justify="center" className={styles.modalTitle}>
        <CustomTitle level={3} color="darkBlue" extraStrong>
          {title}
        </CustomTitle>
      </Row>
      <CustomParagraph color="black">{description}</CustomParagraph>

      <CustomParagraph className={styles.noteTite} strong>
        {note}
      </CustomParagraph>

      <GrapeAntdForm formUtils={formUtils} onFinish={finishHandler}>
        <GrapeAntdForm.Item name="note">
          <CustomTextArea
            className={styles.textArea}
            placeholder={t("EDIT_DESIGN_DEMAND_MODAL.TEXT_AREA_PLACEHOLDER")}
          />
        </GrapeAntdForm.Item>
      </GrapeAntdForm>

      <Row className={styles.modalButtonContainer} gutter={50} justify="center">
        <Col>
          <CustomButton
            className={styles.modalCancelButton}
            onClick={onClose}
            variant="default"
            size="large"
          >
            {t("COMMON.CANCEL")}
          </CustomButton>
        </Col>
        <Col>
          <CustomButton
            type="primary"
            className={styles.modalButton}
            onClick={formUtils.form.submit}
            variant="default"
            size="large"
            loading={loading}
          >
            {submitBtnLabel}
          </CustomButton>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default SendPlanModal;
