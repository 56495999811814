import { Col, Popover, Row } from "antd";
import { useSelector } from "app/store";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import useFileInput, { FileExtensions } from "hooks/useFileInput";
import {
  PaperClipOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Trans, useTranslation } from "react-i18next";
import UploadButton from "components/button/UploadButton";
import {
  DesignFileDto,
  DesignFileType,
  DesignOrderStatus,
} from "api/generated/optimum";
import styles from "./DesignFileInput.module.scss";
import { isEngineer } from "utils/authUtils";

interface DesignFileInputProps {
  layoutFiles?: DesignFileDto[];
  thermalCalculationFiles?: DesignFileDto[];
  disabled?: boolean;
  isNgbs?: boolean;
  designFiles: DesignFileDto[];

  beforeUploadHandler: (
    file: File,
    designFileType: DesignFileType
  ) => Promise<void> | void;

  removeFileHandler: (fileName: string) => Promise<void> | void;
}

const DesignFileInput: React.FC<DesignFileInputProps> = ({
  layoutFiles,
  thermalCalculationFiles,
  beforeUploadHandler,
  removeFileHandler,
  disabled,
  isNgbs,
  designFiles,
}) => {
  const { acceptedExtensions, isFileValid } = useFileInput({
    validExtensions: [
      FileExtensions.JPG,
      FileExtensions.JPEG,
      FileExtensions.PDF,
      FileExtensions.PNG,
      FileExtensions.XLS,
      FileExtensions.XLSX,
      FileExtensions.DWG,
    ],
  });

  const { t } = useTranslation();

  const { currentProject } = useSelector((state) => state.projects);

  return (
    <>
      <Row justify="space-between">
        <Col>
          <CustomTitle defaultFontSize extraStrong>
            {t("PROJECTS.DETAIL.DESIGN.LAYOUT")}:
            {!isNgbs && (
              <Popover
                title={
                  <CustomTitle
                    extraStrong
                    className={styles.fileInputTooltipTitle}
                  >
                    {t("PROJECTS.DETAIL.DESIGN.LAYOUT")}
                  </CustomTitle>
                }
                content={
                  <div className={styles.fileInputTooltipContent}>
                    {t("PROJECTS.DETAIL.DESIGN.LAYOUT_TOOLTIP_INFO")}
                    <ul className={styles.floorPlanTooltipList}>
                      <Trans i18nKey="PROJECTS.DETAIL.DESIGN.LAYOUT_TOOLTIP">
                        <li />
                        <li />
                        <li />
                        <li />
                      </Trans>
                    </ul>
                  </div>
                }
                placement="right"
              >
                <InfoCircleOutlined className={styles.popoverIcon} />
              </Popover>
            )}
          </CustomTitle>
        </Col>
        <Col>
          {currentProject?.designOrderStatus !== DesignOrderStatus.Completed &&
            isEngineer() && (
              <UploadButton
                accept={acceptedExtensions}
                showUploadList={false}
                disabled={disabled}
                beforeUpload={(file: File) => {
                  if (isFileValid(file, designFiles)) {
                    beforeUploadHandler(file, DesignFileType.FloorPlan);
                  }

                  return false;
                }}
                multiple
                children={t("COMMON.FILE_UPLOAD")}
              />
            )}
        </Col>
      </Row>
      <Row>
        {layoutFiles?.length ? (
          <div className={styles.layoutFileList}>
            {layoutFiles?.map((layoutFile: DesignFileDto, i: number) => (
              <a
                className={styles.layoutFileListElement}
                key={i}
                download={layoutFile.fileName}
                href={layoutFile.downloadUrl || undefined}
              >
                <PaperClipOutlined className={styles.layoutIcon} />
                <span>{layoutFile.fileName}</span>
                {!disabled && (
                  <DeleteOutlined
                    onClick={(e) => {
                      e.preventDefault();
                      layoutFile.fileName &&
                        removeFileHandler(layoutFile.fileName);
                    }}
                  />
                )}
              </a>
            ))}
          </div>
        ) : (
          <CustomParagraph size="xs" italic>
            {t("PROJECTS.DETAIL.DESIGN.NO_FILE")}
          </CustomParagraph>
        )}
      </Row>

      <Row justify="space-between">
        <Col>
          <CustomTitle defaultFontSize extraStrong>
            {t("PROJECTS.DETAIL.DESIGN.THERMAL_CALCULATION")}
          </CustomTitle>
        </Col>
        <Col>
          {currentProject?.designOrderStatus !== DesignOrderStatus.Completed &&
            isEngineer() && (
              <UploadButton
                accept={acceptedExtensions}
                disabled={disabled}
                beforeUpload={(file: File) => {
                  if (
                    isFileValid(
                      file,
                      designFiles?.filter(
                        (file: DesignFileDto) =>
                          file.type !== DesignFileType.ThermalCalculation
                      )
                    )
                  ) {
                    beforeUploadHandler(
                      file,
                      DesignFileType.ThermalCalculation
                    );
                  }

                  return false;
                }}
                showUploadList={false}
                multiple={false}
                children={
                  thermalCalculationFiles?.length
                    ? t("COMMON.FILE_SWAP")
                    : t("COMMON.FILE_UPLOAD")
                }
              />
            )}
        </Col>
      </Row>
      <Row>
        {thermalCalculationFiles?.length ? (
          thermalCalculationFiles.map(
            (thermalFile: DesignFileDto, i: number) => (
              <a
                download={thermalFile.fileName}
                key={i}
                href={thermalFile.downloadUrl || undefined}
                className={styles.fileElementEllipsis}
              >
                <PaperClipOutlined /> {thermalFile.fileName}
              </a>
            )
          )
        ) : (
          <CustomParagraph size="xs" italic>
            {t("PROJECTS.DETAIL.DESIGN.NO_FILE")}
          </CustomParagraph>
        )}
      </Row>
    </>
  );
};

export default DesignFileInput;
