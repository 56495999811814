import { notification } from "antd";
import { DesignFileDto } from "api/generated/optimum";
import { uniqBy } from "lodash";
import { useTranslation } from "react-i18next";

export const MAX_FILE_SIZE_IN_MB = 5;
export const MAX_PROFILE_PICTURE_FILE_SIZE_IN_MB = 2;

export enum FileExtensions {
  JPG = ".jpg",
  JPEG = ".jpeg",
  PNG = ".png",
  TXT = ".txt",
  PDF = ".pdf",
  CSV = ".csv",
  XLS = ".xls",
  XLSX = ".xlsx",
  DWG = ".dwg",
  DOC = ".doc",
  DOCX = ".docx",
}

export const FILE_TYPES = [
  { mimeTypes: ["image/jpg"], extension: FileExtensions.JPG },
  { mimeTypes: ["image/jpeg"], extension: FileExtensions.JPEG },
  { mimeTypes: ["image/png"], extension: FileExtensions.PNG },
  { mimeTypes: ["text/plain"], extension: FileExtensions.TXT },
  { mimeTypes: ["application/pdf"], extension: FileExtensions.PDF },
  {
    mimeTypes: ["application/vnd.ms-excel", "text/csv"],
    extension: FileExtensions.CSV,
  },
  { mimeTypes: ["application/vnd.ms-excel"], extension: FileExtensions.XLS },
  {
    mimeTypes: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    extension: FileExtensions.XLSX,
  },
  {
    mimeTypes: ["dwg"],
    extension: FileExtensions.DWG,
  },
  {
    mimeTypes: ["application/msword"],
    extension: FileExtensions.DOC,
  },
  {
    mimeTypes: [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    extension: FileExtensions.DOCX,
  },
];

export interface UploadedFile {
  file: File;
  fileList: any[];
}

interface useFileInputProps {
  validExtensions: FileExtensions[];
}

const useFileInput = ({ validExtensions }: useFileInputProps) => {
  const { t } = useTranslation();
  const acceptedExtensions = Object.values<string>(validExtensions).join(", ");

  /**
   * This function can ONLY be used with FormItem!!!!
   * @param e The newly uploaded file and the fileList containing all the uploaded files INCLUDING! the newly uploaded file as well! {@link UploadedFile}
   * @param multiple If true then only the newly uploaded file remains, all other files get removed from the array.
   * @returns The validated fileList. Removing duplicates, invalid size and extension.
   */
  const normFile = (e: UploadedFile, { multiple = true } = {}) => {
    // At this point the fileList already contains the added file.
    // If a file is NOT valid we have to remove it from the array.

    // If we want to check that the user wants to add the same file, we have to check that the list contains the same file more than once.
    if (e.fileList?.filter((file) => file.name === e.file.name).length > 1) {
      notification.error({
        message: t("error.common.file_exists"),
        description: e.file.name,
      });
    }

    isFileExtensionValid(e.file);
    isFileSizeValid(e.file);

    if (!multiple && e.fileList.length > 1) {
      e.fileList = e.fileList.filter((file) => file.name === e.file.name);
    }

    const validFiles = uniqBy(e.fileList, "name").filter(
      (uniqFile) =>
        isFileExtensionValid(uniqFile, false, false) &&
        isFileSizeValid(uniqFile, MAX_FILE_SIZE_IN_MB, false)
    );

    return e && validFiles;
  };

  const isFileValid = (
    file: File,
    files?: DesignFileDto[] | null,
    byName?: boolean
  ) => {
    return (
      !isFileAlreadyExist(file, files) &&
      isFileExtensionValid(file, byName) &&
      isFileSizeValid(file)
    );
  };

  const isFileAlreadyExist = (
    newlyAddedfile: File,
    files?: any[] | null,
    filesNameProp = "fileName"
  ) => {
    if (files?.some((file) => file[filesNameProp] === newlyAddedfile.name)) {
      notification.error({
        message: t("error.common.file_exists"),
        description: newlyAddedfile.name,
      });

      return true;
    }

    return false;
  };

  const isFileExtensionValid = (
    file: File,
    byName = false,
    showNotification = true
  ): boolean => {
    let fileExtension: string;

    // When type is not filled we have to validate byName
    if (byName || !file.type) {
      const splittedFileName = file.name.split(".");
      fileExtension = splittedFileName[splittedFileName.length - 1];
    } else {
      fileExtension = file.type;
    }

    const isFileExtensionValid = FILE_TYPES.some(
      (fileType) =>
        validExtensions?.some((x) => x === fileType.extension) &&
        fileType.mimeTypes?.some((x) => x === fileExtension)
    );

    if (!isFileExtensionValid && showNotification) {
      notification.error({
        message: t("error.common.file_ext"),
        description: t("error.common.file_formats", {
          formats: acceptedExtensions,
        }),
      });
    }

    return isFileExtensionValid;
  };

  const isFileSizeValid = (
    file: File,
    allowedSize = MAX_FILE_SIZE_IN_MB,
    showNotification = true
  ): boolean => {
    const isSizeValid = file.size / 1024 / 1024 <= allowedSize;

    if (!isSizeValid && showNotification) {
      notification.error({
        message: t("error.common.file_size"),
        description: t("error.common.file_allowed_size", {
          allowedSize,
        }),
      });
    }

    return isSizeValid;
  };

  const isImgWidthHeightValid = ({
    file,
    width,
    height,
  }: {
    file: File;
    width: number;
    height: number;
  }) =>
    new Promise((resolve, reject) => {
      const img = new Image();

      img.src = URL.createObjectURL(file);

      img.onload = () => {
        // Natural size is the actual image size regardless of rendering.
        // The 'normal' `width`/`height` are for the **rendered** size.
        const imgActualWidth = img.naturalWidth;
        const imgActualHeight = img.naturalHeight;

        if (imgActualWidth <= width && imgActualHeight <= height) {
          resolve(true);
        } else {
          notification.error({
            message: t("error.common.img_dimension", { width, height }),
          });
          resolve(false);
        }
      };

      img.onerror = reject;
    });

  return {
    normFile,
    isImgWidthHeightValid,
    isFileExtensionValid,
    isFileSizeValid,
    isFileValid,
    isFileAlreadyExist,
    acceptedExtensions,
  };
};

export default useFileInput;
