import { message } from "antd";
import { ReactElement } from "react";
import i18n from "../../../app/i18n";

export declare type StoreValue = any;
declare type RuleType =
  | "string"
  | "number"
  | "boolean"
  | "method"
  | "regexp"
  | "integer"
  | "float"
  | "object"
  | "enum"
  | "date"
  | "url"
  | "hex"
  | "email"
  | "array";

export type BaseRule = {
  enum?: StoreValue[];
  len?: number;
  max?: number;
  message?: string | ReactElement;
  min?: number;
  pattern?: RegExp;
  required?: boolean;
  transform?: (value: StoreValue) => StoreValue;
  type?: RuleType;
  whitespace?: boolean;
  validateTrigger?: string | string[];
};

export const requiredField = (
  field?: string,
  type?: RuleType,
  rest?: BaseRule
): BaseRule => {
  const rule = {
    ...rest,
    ...(type ? { type } : {}),
    required: true,
    message: field
      ? i18n.t("error.common.namedFieldRequired")
      : i18n.t("error.common.fieldRequired"),
  };
  return type === "string" ? { ...rule, whitespace: true } : rule;
};

export const email = (message?: string): BaseRule => ({
  pattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: message || i18n.t("error.common.emailInvalidFormat"),
});

export const checkBoxChecked = (message: string): BaseRule => ({
  required: true,
  transform: (value) => value || undefined,
  type: "boolean",
  message: message,
});

export const max = (
  max: number,
  type: "string" | "number" | "array",
  message?: string
): BaseRule => {
  const defaultMessage = {
    string: i18n.t("error.common.maxLength", { max }),
    number: i18n.t("error.common.maxValue", { max }),
    array: i18n.t("error.common.maxItems"),
  }[type];

  return {
    max,
    type,
    message: message || defaultMessage,
  };
};

export const min = (
  min: number,
  type: "string" | "number" | "array",
  message?: string
): BaseRule => {
  const defaultMessage = {
    string: i18n.t("error.common.minLength"),
    number: i18n.t("error.common.minValue"),
    array: i18n.t("error.common.minItems"),
  }[type];

  const rule = {
    min,
    type,
    message: message || defaultMessage,
  };

  return type === "string"
    ? { ...rule, transform: (value: any) => `${value}` }
    : rule;
};

export const phoneNumber = (message?: string): BaseRule =>
  pattern(/^\+\d{10,11}$/, message || i18n.t("error.common.phoneNumber"));

export const positiveNumber = (message?: string): BaseRule =>
  pattern(/^[1-9]+[0-9]*$/, message || i18n.t("error.common.positiveNumber"));

export const zipCodeRule = (message?: string): BaseRule =>
  pattern(/^[1-9][0-9]{3}$/, message || i18n.t("error.common.zipcode"));

export const pattern = (pattern: RegExp, message?: string): BaseRule => {
  return {
    pattern,
    message: message || i18n.t("error.common.pattern"),
  };
};
