import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import TagManager from "react-gtm-module";
import huHU from "antd/es/locale/hu_HU";
import { ConfigProvider } from "antd";
import ReactPixel from "react-facebook-pixel";

const tagManagerArgs = {
  gtmId: "GTM-P2HLCDM",
};
TagManager.initialize(tagManagerArgs);

ReactPixel.init("1027623460778111");
ReactPixel.pageView();

function App() {
  return (
    <BrowserRouter>
      <ConfigProvider locale={huHU}>
        <Router />
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
