import { Col, Row } from "antd";
import CustomButton from "components/button/CustomButton";
import CustomModal from "components/customModal/CustomModal";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ResetBuildMaterials.module.scss";

interface ResetBuildMaterialsProps {
  handleReset: () => void;
}

const ResetBuildMaterials: FC<ResetBuildMaterialsProps> = ({ handleReset }) => {
  const { t } = useTranslation();
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

  return (
    <Row justify="center" className={styles.resetButtonContainer}>
      <CustomButton
        className={styles.resetButton}
        type="link"
        size="large"
        onClick={() => setModalIsVisible(true)}
      >
        {t("PROJECTS.DETAIL.BUILD.MATERIAL_LIST.RESET_BUTTON_LABEL")}
      </CustomButton>

      <CustomModal
        closable={false}
        width={"58rem"}
        visible={modalIsVisible}
        className={styles.modal}
      >
        <Row justify="center" className={styles.modalTitle}>
          <CustomTitle level={3} color="darkBlue" extraStrong>
            {t("PROJECTS.DETAIL.BUILD.MATERIAL_LIST.RESET_MODAL_TITLE")}
          </CustomTitle>
        </Row>

        <CustomParagraph color="black" className={styles.modalDescription}>
          {t("PROJECTS.DETAIL.BUILD.MATERIAL_LIST.RESET_DESCRIPTION")}
        </CustomParagraph>

        <Row className={styles.modalButtonContainer} justify="space-around">
          <Col>
            <CustomButton
              className={styles.cancelButton}
              size="large"
              variant="default"
              onClick={() => setModalIsVisible(false)}
            >
              {t("COMMON.CANCEL")}
            </CustomButton>
          </Col>

          <Col>
            <CustomButton
              type="primary"
              className={styles.okButton}
              size="large"
              variant="default"
              onClick={() => {
                handleReset();
                setModalIsVisible(false);
              }}
            >
              {t("PROJECTS.DETAIL.BUILD.MATERIAL_LIST.RESET_CONFIRM")}
            </CustomButton>
          </Col>
        </Row>
      </CustomModal>
    </Row>
  );
};

export default ResetBuildMaterials;
