import { ReactComponent as RefusedBuildOrder } from "assets/images/refusedBuildOrder.svg";
import CustomButton from "components/button/CustomButton";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./BuildNotAvailable.module.scss";

export const BuildNotAvailable: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <RefusedBuildOrder />

      <CustomTitle className={styles.title} color="grey">
        {t("PROJECTS.DETAIL.BUILD.NOT_AVAILABLE.TITLE")}
      </CustomTitle>

      <CustomParagraph size="md" className={styles.description} strong>
        {t("PROJECTS.DETAIL.BUILD.NOT_AVAILABLE.DESC")}
      </CustomParagraph>

      <CustomButton type="primary" onClick={() => navigate("/projects")}>
        {t("PROJECTS.DETAIL.BUILD.NOT_AVAILABLE.CTA")}
      </CustomButton>
    </div>
  );
};

export default BuildNotAvailable;
