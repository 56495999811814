import { FC } from "react";
import CustomButton, { CustomButtonProps } from "../CustomButton";
import styles from "./LinkButton.module.scss";
import { RightOutlined } from "@ant-design/icons";
import clsx from "clsx";

const LinkButton: FC<CustomButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <CustomButton
      {...rest}
      type="link"
      className={clsx(styles.linkBtn, className)}
    >
      {children} <RightOutlined />
    </CustomButton>
  );
};

export default LinkButton;
