/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DesignOrderStatus,
    DesignOrderStatusFromJSON,
    DesignOrderStatusFromJSONTyped,
    DesignOrderStatusToJSON,
    DesignRefusalReason,
    DesignRefusalReasonFromJSON,
    DesignRefusalReasonFromJSONTyped,
    DesignRefusalReasonToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectListDto
 */
export interface ProjectListDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    projectCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    projectStatus?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectListDto
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectListDto
     */
    lastOrderRequestDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    projectStatusChangedByName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    responsibleName?: string | null;
    /**
     * 
     * @type {DesignOrderStatus}
     * @memberof ProjectListDto
     */
    designOrderStatus?: DesignOrderStatus;
    /**
     * 
     * @type {DesignRefusalReason}
     * @memberof ProjectListDto
     */
    designRefusalReason?: DesignRefusalReason;
    /**
     * 
     * @type {Date}
     * @memberof ProjectListDto
     */
    designOrderDeadline?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectListDto
     */
    designOrderCreationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectListDto
     */
    designOrderCompletionDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    customerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    customerEmailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    customerPhoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    realEstateZipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    realEstateCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    realEstateAddress?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectListDto
     */
    realEstateIsNew?: boolean;
}

export function ProjectListDtoFromJSON(json: any): ProjectListDto {
    return ProjectListDtoFromJSONTyped(json, false);
}

export function ProjectListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectCode': !exists(json, 'projectCode') ? undefined : json['projectCode'],
        'projectStatus': !exists(json, 'projectStatus') ? undefined : json['projectStatus'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'lastOrderRequestDate': !exists(json, 'lastOrderRequestDate') ? undefined : (json['lastOrderRequestDate'] === null ? null : new Date(json['lastOrderRequestDate'])),
        'projectStatusChangedByName': !exists(json, 'projectStatusChangedByName') ? undefined : json['projectStatusChangedByName'],
        'responsibleName': !exists(json, 'responsibleName') ? undefined : json['responsibleName'],
        'designOrderStatus': !exists(json, 'designOrderStatus') ? undefined : DesignOrderStatusFromJSON(json['designOrderStatus']),
        'designRefusalReason': !exists(json, 'designRefusalReason') ? undefined : DesignRefusalReasonFromJSON(json['designRefusalReason']),
        'designOrderDeadline': !exists(json, 'designOrderDeadline') ? undefined : (json['designOrderDeadline'] === null ? null : new Date(json['designOrderDeadline'])),
        'designOrderCreationDate': !exists(json, 'designOrderCreationDate') ? undefined : (json['designOrderCreationDate'] === null ? null : new Date(json['designOrderCreationDate'])),
        'designOrderCompletionDate': !exists(json, 'designOrderCompletionDate') ? undefined : (json['designOrderCompletionDate'] === null ? null : new Date(json['designOrderCompletionDate'])),
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerEmailAddress': !exists(json, 'customerEmailAddress') ? undefined : json['customerEmailAddress'],
        'customerPhoneNumber': !exists(json, 'customerPhoneNumber') ? undefined : json['customerPhoneNumber'],
        'realEstateZipCode': !exists(json, 'realEstateZipCode') ? undefined : json['realEstateZipCode'],
        'realEstateCity': !exists(json, 'realEstateCity') ? undefined : json['realEstateCity'],
        'realEstateAddress': !exists(json, 'realEstateAddress') ? undefined : json['realEstateAddress'],
        'realEstateIsNew': !exists(json, 'realEstateIsNew') ? undefined : json['realEstateIsNew'],
    };
}

export function ProjectListDtoToJSON(value?: ProjectListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectCode': value.projectCode,
        'projectStatus': value.projectStatus,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'lastOrderRequestDate': value.lastOrderRequestDate === undefined ? undefined : (value.lastOrderRequestDate === null ? null : value.lastOrderRequestDate.toISOString()),
        'projectStatusChangedByName': value.projectStatusChangedByName,
        'responsibleName': value.responsibleName,
        'designOrderStatus': DesignOrderStatusToJSON(value.designOrderStatus),
        'designRefusalReason': DesignRefusalReasonToJSON(value.designRefusalReason),
        'designOrderDeadline': value.designOrderDeadline === undefined ? undefined : (value.designOrderDeadline === null ? null : value.designOrderDeadline.toISOString()),
        'designOrderCreationDate': value.designOrderCreationDate === undefined ? undefined : (value.designOrderCreationDate === null ? null : value.designOrderCreationDate.toISOString()),
        'designOrderCompletionDate': value.designOrderCompletionDate === undefined ? undefined : (value.designOrderCompletionDate === null ? null : value.designOrderCompletionDate.toISOString()),
        'customerName': value.customerName,
        'customerEmailAddress': value.customerEmailAddress,
        'customerPhoneNumber': value.customerPhoneNumber,
        'realEstateZipCode': value.realEstateZipCode,
        'realEstateCity': value.realEstateCity,
        'realEstateAddress': value.realEstateAddress,
        'realEstateIsNew': value.realEstateIsNew,
    };
}


