import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./EditDesignDemandModal.module.scss";
import { Col, Row } from "antd";
import CustomModal from "components/customModal/CustomModal";
import CustomTitle from "components/typography/title/CustomTitle";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { CustomTextArea } from "components/form/components/CustomInput";
import CustomButton from "components/button/CustomButton";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "app/store";
import {
  DesignFileDto,
  DesignFileType,
  OptiComfortPlanActions,
} from "api/generated/optimum";
import { GrapeAntdForm, useGrapeAntdForm } from "components/form";
import { AlertModalType } from "components/alertModal/AlertModal.store";
import DesignFileInput from "./designFileInput/DesignFileInput";

export interface EditDesignDemandModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const EditDesignDemandModal: FC<EditDesignDemandModalProps> = ({
  isVisible,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formUtils = useGrapeAntdForm();

  const [removedFiles, setRemovedFiles] = useState<string[]>([]);
  const [newlyAddedFiles, setNewlyAddedFiles] = useState<
    { file: File; type: DesignFileType }[]
  >([]);
  const [thermalCalculationFile, setThermalCalculationFile] = useState<
    DesignFileDto[]
  >([]);

  const [floorPlanFiles, setFloorPlanFiles] = useState<DesignFileDto[]>([]);

  const { loading } = useSelector(
    (state) => state.loading.effects.projects.manageOptiComfortPlan
  );

  const { currentProject } = useSelector((state) => state.projects);

  const originalThermalCalculationFiles = useMemo(
    () =>
      currentProject?.designFiles?.filter(
        (file: DesignFileDto) => file.type === DesignFileType.ThermalCalculation
      ) || [],
    [currentProject?.designFiles]
  );

  const originalFloorPlanFiles = useMemo(
    () =>
      currentProject?.designFiles?.filter(
        (file: DesignFileDto) => file.type === DesignFileType.FloorPlan
      ) || [],
    [currentProject?.designFiles]
  );

  const resetFiles = useCallback(() => {
    if (originalThermalCalculationFiles.length) {
      setThermalCalculationFile(originalThermalCalculationFiles);
    }

    if (originalFloorPlanFiles.length) {
      setFloorPlanFiles(originalFloorPlanFiles);
    }

    setRemovedFiles([]);
    setNewlyAddedFiles([]);
  }, [originalThermalCalculationFiles, originalFloorPlanFiles]);

  useEffect(
    () => resetFiles(),
    [originalThermalCalculationFiles, originalFloorPlanFiles, resetFiles]
  );

  const removeFileHandler = useCallback(
    (fileName: string) => {
      if (!floorPlanFiles?.length) {
        return;
      }

      setFloorPlanFiles((prevFloorPlanFiles) =>
        prevFloorPlanFiles?.filter((file) => file.fileName !== fileName)
      );

      setNewlyAddedFiles((prevNewlyAddedFiles) =>
        prevNewlyAddedFiles?.filter((file) => file.file.name !== fileName)
      );

      // DELETE API NEEDS TO BE CALLED ONLY ON PREVIOUSLY POSTED FILES
      if (
        originalFloorPlanFiles.some(
          (originalFile: DesignFileDto) => originalFile.fileName === fileName
        )
      ) {
        setRemovedFiles((prevRemovedFiles) => [...prevRemovedFiles, fileName]);
      }
    },
    [floorPlanFiles, originalFloorPlanFiles]
  );

  const beforeUploadHandler = useCallback(
    (file: File, designFileType: DesignFileType) => {
      setNewlyAddedFiles((prevValues) => {
        let modifiedFiles = prevValues;

        if (designFileType === DesignFileType.ThermalCalculation) {
          modifiedFiles = prevValues.filter(
            (value) => value.type !== DesignFileType.ThermalCalculation
          );
        }

        return [...modifiedFiles, { file, type: designFileType }];
      });

      if (designFileType === DesignFileType.FloorPlan) {
        setFloorPlanFiles((prevFloorPlanFiles) => [
          ...prevFloorPlanFiles!,
          {
            fileName: file.name,
            type: designFileType,
            downloadUrl: URL.createObjectURL(file),
          },
        ]);

        return;
      }

      setThermalCalculationFile([
        {
          fileName: file.name,
          type: designFileType,
          downloadUrl: URL.createObjectURL(file),
        },
      ]);
    },
    []
  );

  const sendFormHandler = useCallback(
    async ({ note }) => {
      if (currentProject?.designOrderId != null) {
        try {
          await dispatch.projects.manageOptiComfortPlan({
            action: OptiComfortPlanActions.Modify,
            designOrderId: currentProject?.designOrderId,
            note: note,
          });

          await Promise.all(
            removedFiles.map((removedFileName) =>
              dispatch.projects.removeDesignFile({
                fileName: removedFileName,
                id: currentProject.designOrderId!,
              })
            )
          );

          setRemovedFiles([]);

          await Promise.all(
            newlyAddedFiles.map((newFile) =>
              dispatch.projects.uploadDesignFile({
                id: currentProject.designOrderId!,
                file: newFile.file,
                type: newFile.type,
              })
            )
          );

          setNewlyAddedFiles([]);

          await dispatch.projects.getProject({
            projectCode: currentProject.projectCode!,
          });

          onClose();

          await dispatch.alertModal.openModal({
            title: t("EDIT_DESIGN_DEMAND_MODAL.UPDATE_SUCCESS"),
            cardTitle: t("EDIT_DESIGN_DEMAND_MODAL.TITLE"),
            type: AlertModalType.Success,
            buttonLabel: t("COMMON.ALRIGHT"),
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
    [
      t,
      onClose,
      dispatch.alertModal,
      removedFiles,
      newlyAddedFiles,
      currentProject?.designOrderId,
      dispatch.projects,
      currentProject?.projectCode,
    ]
  );

  return (
    <CustomModal
      visible={isVisible}
      className={styles.modal}
      closable={false}
      width={"58rem"}
    >
      <Row justify="center">
        <CustomTitle
          level={3}
          color="darkBlue"
          className={styles.title}
          extraStrong
        >
          {t("EDIT_DESIGN_DEMAND_MODAL.TITLE")}
        </CustomTitle>
      </Row>

      <CustomParagraph className={styles.description}>
        <Trans i18nKey="EDIT_DESIGN_DEMAND_MODAL.DESCRIPTION">
          <span className={styles.bold}>asd</span>
        </Trans>
      </CustomParagraph>

      <CustomParagraph strong>
        {t("EDIT_DESIGN_DEMAND_MODAL.UPLOAD_TITLE")}
      </CustomParagraph>

      <DesignFileInput
        layoutFiles={floorPlanFiles}
        thermalCalculationFiles={thermalCalculationFile}
        removeFileHandler={removeFileHandler}
        beforeUploadHandler={beforeUploadHandler}
        designFiles={[...thermalCalculationFile, ...floorPlanFiles]}
      />

      <CustomParagraph className={styles.noteTite} strong>
        {t("EDIT_DESIGN_DEMAND_MODAL.NOTE")}
      </CustomParagraph>

      <GrapeAntdForm formUtils={formUtils} onFinish={sendFormHandler}>
        <GrapeAntdForm.Item name="note">
          <CustomTextArea
            className={styles.textArea}
            placeholder={t("EDIT_DESIGN_DEMAND_MODAL.TEXT_AREA_PLACEHOLDER")}
          />
        </GrapeAntdForm.Item>
      </GrapeAntdForm>

      <Row justify="space-between" className={styles.buttonContainer}>
        <Col>
          <CustomButton
            variant="default"
            className={styles.cancelButton}
            onClick={() => {
              onClose();
              resetFiles();
            }}
          >
            {t("EDIT_DESIGN_DEMAND_MODAL.CANCEL")}
          </CustomButton>
        </Col>
        <Col>
          <CustomButton
            className={styles.sendButton}
            onClick={formUtils.form.submit}
            disabled={
              (!newlyAddedFiles.length && !removedFiles.length) ||
              !floorPlanFiles.length
            }
            loading={loading}
          >
            {t("EDIT_DESIGN_DEMAND_MODAL.SEND")}
          </CustomButton>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default EditDesignDemandModal;
