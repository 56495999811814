import {
  BuildMaterialDto,
  MaterialDto,
  MaterialUnit,
} from "api/generated/optimum";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import { useDispatch, useSelector } from "app/store";
import clsx from "clsx";
import { CustomInputNumber } from "components/form/components/CustomInput";
import { CustomTableProps } from "components/GrapeAntdTable/CustomTable";
import { useTranslation } from "react-i18next";
import { isBuilder } from "utils/authUtils";
import enumTranslate from "utils/enumTranslate";
import { decimalSeparator } from "utils/format";
import styles from "../../components/materialListModal/MaterialListModal.module.scss";
import useBuild from "../useBuild";

const useBuildMaterialTableProps = () => {
  const { t } = useTranslation();
  const { updatedMaterialList } = useSelector((state) => state.buildOrder);
  const dispatch = useDispatch();

  const { isApprovedBuildOrder, isNewBuildOrder } = useBuild();

  const getCurrentAmount = (row: BuildMaterialDto): number => {
    const defaultValue =
      updatedMaterialList.find(
        (material) => material.materialId === row.materialId
      )?.amount ?? row.amount;

    return defaultValue ?? 0;
  };

  const tableProps = {
    // table config
    rowKey: "id",
    name: "HeatGenerationList",
    columns: [
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.PRODUCT_CODE"),
        dataIndex: "code",
        width: 180,
        className: styles.codeCell,
      },
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.PRODUCT_NAME"),
        dataIndex: "name",
      },
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.UNIT_PRICE"),
        dataIndex: "price",
        width: "14rem",
        className: styles.priceCell,
        render: (value) => decimalSeparator(value),
      },
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.QUANTITY"),
        dataIndex: "amount",
        align: "center",
        width: "13rem",
        className: styles.amountCell,
        render: (_, row: BuildMaterialDto) => {
          if (isBuilder() && (isApprovedBuildOrder || isNewBuildOrder)) {
            return (
              <div
                className={clsx(
                  styles.inputContainer,
                  styles.buildInputContainer
                )}
              >
                <CustomInputNumber
                  min={0}
                  value={getCurrentAmount(row)}
                  precision={0}
                  onChange={(value) => {
                    dispatch.buildOrder.setUpdatedMaterialList([
                      ...updatedMaterialList?.filter(
                        (material) => material.materialId !== row.materialId
                      ),
                      {
                        materialId: row.materialId!,
                        amount: (value as number) ?? 0,
                      },
                    ]);
                  }}
                />
                {row?.unit === MaterialUnit.SquareMeter ? (
                  <span>
                    m<sup>2</sup>
                  </span>
                ) : (
                  <span>
                    {enumTranslate(
                      row?.unit?.toString() || "",
                      EnumContext.MATERIALUNIT
                    )?.toLocaleLowerCase()}
                  </span>
                )}
              </div>
            );
          }

          return row?.unit === MaterialUnit.SquareMeter ? (
            <span>
              {row.amount} m<sup>2</sup>
            </span>
          ) : (
            <span>
              {`${row.amount} ${enumTranslate(
                row?.unit?.toString() || "",
                EnumContext.MATERIALUNIT
              )?.toLocaleLowerCase()}`}
            </span>
          );
        },
      },
      {
        title: t("MATERIAL_LIST_MODAL.LISTS.TOTAL_PRICE"),
        className: styles.priceCell,
        render: (_, row) => {
          const totalPrice = row.price * getCurrentAmount(row);

          return decimalSeparator(totalPrice);
        },
        width: "14rem",
      },
    ],
  } as CustomTableProps;

  return tableProps;
};

export default useBuildMaterialTableProps;
