/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EngineerProfession {
    Invalid = 'Invalid',
    ConstructionEngineer = 'ConstructionEngineer',
    MScConstructionEngineer = 'MScConstructionEngineer',
    MScEnergyEngineer = 'MScEnergyEngineer',
    Other = 'Other'
}

export function EngineerProfessionFromJSON(json: any): EngineerProfession {
    return EngineerProfessionFromJSONTyped(json, false);
}

export function EngineerProfessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineerProfession {
    return json as EngineerProfession;
}

export function EngineerProfessionToJSON(value?: EngineerProfession | null): any {
    return value as any;
}

