import { createModel } from "@rematch/core";
import { api } from "api";
import { BuilderDto, RealEstateFileType } from "api/generated/optimum";
import { CreateBuildOrder } from "models/CreateBuildOrder";
import { RootModel, store } from "../../app/store";

export interface Step3Data {
  note: string;
  start: string;
}

export interface Step4Data {
  name: string;
  email: string;
  phone: string;
  note: string;
}

interface BuilderState {
  builders?: BuilderDto[];
  selectedBuilders: BuilderDto[];
  zipCode?: string;
  selectedBuilder?: BuilderDto;
  currentStep: number;
  address: string | null | undefined;
  projectCode: string;
  wizardData: CreateBuildOrder[];
  designOrderId?: number;
}

export const INITIAL_CURRENT_STEP = -1;

const initialState: BuilderState = {
  currentStep: INITIAL_CURRENT_STEP,
  builders: undefined,
  selectedBuilders: [],
  zipCode: undefined,
  selectedBuilder: undefined,
  wizardData: [],
  projectCode: "",
  address: "",
  designOrderId: undefined,
};

export const builder = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSelectedBuilders(state, payload: { builders: BuilderDto[] }) {
      state.selectedBuilders = payload.builders;
    },
    setBuilders(state, payload: { builders: BuilderDto[] }) {
      state.builders = payload.builders;
    },
    setZipCode(state, zipCode: string) {
      state.zipCode = zipCode;
    },
    setAddress(state, addresss: string | null | undefined) {
      state.address = addresss;
    },
    selectBuilder(state, builder) {
      state.selectedBuilder = builder;
    },
    unSelectBuilder(state) {
      state.selectedBuilder = undefined;
    },
    setWizardData(state, data, step?: number) {
      state.wizardData[step ?? state.currentStep] = data;
    },
    setProjectCode(state, payload) {
      state.projectCode = payload;
    },
    setDesignOrderId(state, orderId: number) {
      state.designOrderId = orderId;
    },
    nextStep(state, steps = 1) {
      state.currentStep = state.currentStep + steps;
    },
    prevStep(state) {
      state.currentStep = state.currentStep - 1;
    },
    resetWizardData: (state) => {
      state.currentStep = initialState.currentStep;
      state.selectedBuilders = initialState.selectedBuilders;
      state.wizardData = initialState.wizardData;
    },
    resetSelectedBuilders: (state) => {
      state.selectedBuilders = initialState.selectedBuilders;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    return {
      getBuilders: async (zip: string): Promise<void> => {
        const builders = await api.builders.getBuilder({ zip });

        dispatch.builder.setBuilders({ builders });
        dispatch.builder.setZipCode(zip);
      },

      getBuildOrder: async (projectCode: string): Promise<void> => {
        const buildOrder = await api.projects.getProjectDesignByCode({
          projectCode,
        });

        dispatch.builder.setWizardData(
          {
            name: buildOrder?.customerName,
            emailAddress: buildOrder?.customerEmailAddress,
            phoneNumber: buildOrder?.customerPhoneNumber,
          },
          2
        );

        dispatch.builder.setDesignOrderId(Number(buildOrder.designOrderId));

        dispatch.builder.setAddress(
          `${buildOrder?.realEstateZipCode}, ${buildOrder?.realEstateCity}, ${buildOrder?.realEstateAddress}`
        );
      },

      createBuildOrder: async () => {
        const { wizardData, selectedBuilders, projectCode, designOrderId } =
          store.getState().builder;

        const { files } = wizardData[1];

        const id = await api.buildOrders.createBuildOrder({
          createBuildOrderCommand: {
            builderIds: selectedBuilders.map((x) => x.id) as string[],
            projectCode: projectCode,
            taskDescription: wizardData[1]?.taskDescription,
            preferredStart: wizardData[1]?.preferredStart,
            name: wizardData[2]?.name,
            emailAddress: wizardData[2]?.emailAddress,
            phoneNumber: wizardData[2]?.phoneNumber,
            note: wizardData[2]?.note,
          },
        });

        if (files?.length) {
          await Promise.all(
            files.map((file) =>
              api.realEstateFiles.uploadRealEstateFile({
                designOrderId,
                type: RealEstateFileType.Build,
                file: file.originFileObj,
              })
            )
          );
        }
      },
    };
  },
});
