import { Col, Row } from "antd";
import Content from "components/layout/content/Content";
import CustomTitle from "components/typography/title/CustomTitle";
import { useTranslation } from "react-i18next";
import styles from "./AdminProviderList.module.scss";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { useDispatch, useSelector } from "app/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { onGoingColumns } from "../components/providerTable/providerTableColumns";
import RadioButtonGroup, {
  RadioButton,
} from "../components/radioButton/RadioButtonGroup";
import CustomButton from "components/button/CustomButton";
import { PlusOutlined } from "@ant-design/icons";
import ProviderTable from "../components/providerTable/ProviderTable";
import ProviderModal from "./components/providerModal/ProviderModal";
import { ProviderType } from "api/generated/optimum";
import {
  getSessionStorageValue,
  SessionStorageKeys,
  setSessionStorageValue,
} from "utils/storageHandler";

export enum AdminProviderStatus {
  Active = "Active",
  NotPublished = "NotPublished",
  Deleted = "Deleted",
}

export enum RadioProviderType {
  Engineer = "Engineer",
  Builder = "Builder",
  All = "All",
}

const AdminProviderList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { providerList } = useSelector((state) => state.provider);
  const { loading } = useSelector(
    (state) => state.loading.effects.provider.getAllProvider
  );
  const [addProviderModalVisible, setAddProviderModalVisible] =
    useState<boolean>(false);

  const currentAdminProviderStatus = getSessionStorageValue(
    SessionStorageKeys.AdminProviderActiveStatus
  );

  const currentAdminProviderType = getSessionStorageValue(
    SessionStorageKeys.AdminProviderActiveType
  );

  const [statusRadioValue, setStatusRadioValue] = useState<AdminProviderStatus>(
    AdminProviderStatus[
      (currentAdminProviderStatus as keyof typeof AdminProviderStatus) ??
        "Active"
    ]
  );

  const [typeRadioValue, setTypeRadioValue] = useState<RadioProviderType>(
    RadioProviderType[
      (currentAdminProviderType as keyof typeof RadioProviderType) ?? "Engineer"
    ]
  );

  const getProviders = useCallback(
    (wildcard?: string) =>
      dispatch.provider.getAllProvider({
        isEnabled:
          statusRadioValue === AdminProviderStatus.Active ||
          statusRadioValue === AdminProviderStatus.NotPublished,
        isPublished:
          statusRadioValue !== AdminProviderStatus.NotPublished &&
          statusRadioValue !== AdminProviderStatus.Deleted,
        type:
          typeRadioValue === RadioProviderType.Builder
            ? ProviderType.Builder
            : typeRadioValue === RadioProviderType.Engineer
            ? ProviderType.Engineer
            : undefined,
        wildcard: wildcard,
      }),
    [dispatch.provider, statusRadioValue, typeRadioValue]
  );

  const statusRadioButtons = useMemo<RadioButton<AdminProviderStatus>[]>(
    () => [
      {
        label: t("ADMIN.PROVIDERS.STATUS.ACTIVE"),
        value: AdminProviderStatus.Active,
      },
      {
        label: t("ADMIN.PROVIDERS.STATUS.NOT_PUBLISHED"),
        value: AdminProviderStatus.NotPublished,
      },
      {
        label: t("ADMIN.PROVIDERS.STATUS.DELETED"),
        value: AdminProviderStatus.Deleted,
      },
    ],
    [t]
  );

  const typeRadioButtons = useMemo<RadioButton<RadioProviderType>[]>(
    () => [
      {
        label: t("ADMIN.PROVIDERS.TYPE.ENGINEER"),
        value: RadioProviderType.Engineer,
      },
      {
        label: t("ADMIN.PROVIDERS.TYPE.BUILDER"),
        value: RadioProviderType.Builder,
      },
      {
        label: t("ADMIN.PROVIDERS.TYPE.ALL"),
        value: RadioProviderType.All,
      },
    ],
    [t]
  );

  useEffect(() => {
    getProviders();
  }, [getProviders]);

  return (
    <Content>
      <Row>
        <Col flex="auto">
          <CustomTitle extraStrong level={1} className={styles.pageTitle}>
            {t("ADMIN.PROVIDERS.PAGE_TITLE")}
          </CustomTitle>
        </Col>

        <Col>
          <CustomButton
            variant="provider"
            type="primary"
            onClick={() => setAddProviderModalVisible(true)}
          >
            <PlusOutlined /> {t("ADMIN.PROVIDERS.ADD_PROVIDER")}
          </CustomButton>
        </Col>
      </Row>

      <Row align="middle">
        <Col>
          <RadioButtonGroup
            onChange={(e) => {
              setStatusRadioValue(e);
              setSessionStorageValue(
                SessionStorageKeys.AdminProviderActiveStatus,
                e
              );
            }}
            value={statusRadioValue}
            radioButtons={statusRadioButtons}
            size="large"
          />
        </Col>

        {/* <Col>
          <SearchInput getResource={getProviders} />
        </Col> */}

        <Col>
          <CustomButton
            type="link"
            onClick={dispatch.provider.exportProviderList}
          >
            {t("ADMIN.PROVIDERS.EXPORT_BTN")}
          </CustomButton>
        </Col>
      </Row>

      <Row>
        <RadioButtonGroup
          onChange={(e) => {
            setTypeRadioValue(e);
            setSessionStorageValue(
              SessionStorageKeys.AdminProviderActiveType,
              e
            );
          }}
          value={typeRadioValue}
          className={styles.typeRadioGroup}
          radioButtons={typeRadioButtons}
        />
      </Row>

      <CustomParagraph italic size="xs" className={styles.searchCount}>
        {t("ADMIN.PROVIDERS.SEARCH_COUNT", {
          recordCount: providerList?.length,
        })}
      </CustomParagraph>

      <ProviderTable
        providers={providerList}
        loading={loading}
        columns={onGoingColumns}
      />

      <ProviderModal
        isVisible={addProviderModalVisible}
        isCreateMode={true}
        onClose={() => setAddProviderModalVisible(false)}
        onSubmit={() => setAddProviderModalVisible(false)}
        getProviders={getProviders}
      />
    </Content>
  );
};

export default AdminProviderList;
