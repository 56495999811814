import { useDispatch, useSelector } from "app/store";
import CustomModal from "components/customModal/CustomModal";
import CustomTitle from "components/typography/title/CustomTitle";
import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AuthStep } from "./Auth.store";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import Login from "./login/Login";
import NewPassword from "./newPassword/NewPassword";
import styles from "./Auth.module.scss";
import clsx from "clsx";
import useQuery from "hooks/useQuery";

const Auth: FC = () => {
  const { authStep, navigate, user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.loading.models.auth.loading);
  const token = useQuery().get("token");
  const homepageLoginUrl = useQuery().get("login");

  useEffect(() => {
    if (token) {
      dispatch.auth.setAuthStep({ authStep: AuthStep.NEWPASS });
      return;
    }

    if (homepageLoginUrl !== null && !user) {
      dispatch.auth.setAuthStep({ authStep: AuthStep.LOGIN });
    }
  }, [token, dispatch.auth, homepageLoginUrl, user]);

  const cancelHandler = useCallback(() => {
    dispatch.auth.resetAuthStep();

    if (homepageLoginUrl) {
      window.location.href = homepageLoginUrl;
    }
  }, [dispatch.auth, homepageLoginUrl]);

  const getAuthContent = () => {
    if (!authStep) return null;

    return {
      [AuthStep.LOGIN]: {
        content: <Login />,
        title: t("AUTH.LOGIN.TITLE"),
        style: styles.loginModal,
      },
      [AuthStep.FORGOTTENPASS]: {
        content: <ForgotPassword />,
        title: t("AUTH.FORGOTTEN_PASSWORD.TITLE"),
        style: styles.forgotPasswordModal,
      },
      [AuthStep.NEWPASS]: {
        content: <NewPassword />,
        title: t("AUTH.NEW_PASSWORD.TITLE"),
        style: styles.newPasswordModal,
      },
    }[authStep];
  };

  const authObj = getAuthContent();

  return authObj ? (
    <CustomModal
      title={
        <CustomTitle color="darkBlue" align="center" level={3}>
          {authObj.title}
        </CustomTitle>
      }
      width={"50rem"}
      className={clsx(styles.modal, authObj.style)}
      visible
      onCancel={cancelHandler}
      loading={loading}
      closable={!!navigate}
      maskStyle={{
        backgroundColor: `rgba(0, 0, 0, 0.85)`,
      }}
    >
      {authObj.content}
    </CustomModal>
  ) : null;
};

export default Auth;
