/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BuildMaterialListStatus {
    Invalid = 'Invalid',
    New = 'New',
    Ordered = 'Ordered',
    Confirmed = 'Confirmed',
    Archived = 'Archived'
}

export function BuildMaterialListStatusFromJSON(json: any): BuildMaterialListStatus {
    return BuildMaterialListStatusFromJSONTyped(json, false);
}

export function BuildMaterialListStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildMaterialListStatus {
    return json as BuildMaterialListStatus;
}

export function BuildMaterialListStatusToJSON(value?: BuildMaterialListStatus | null): any {
    return value as any;
}

