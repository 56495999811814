import { Col, Row, Tabs } from "antd";
import Content from "components/layout/content/Content";
import CustomTitle from "components/typography/title/CustomTitle";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ProjectsList.module.scss";
import { useDispatch, useSelector } from "app/store";
import ActiveTab from "./tabs/ActiveTab";
import ClosedTab from "./tabs/ClosedTab";
import {
  getSessionStorageValue,
  SessionStorageKeys,
  setSessionStorageValue,
} from "utils/storageHandler";
import { isBuilder, isEngineer, isNgbs } from "utils/authUtils";
import OffersTab from "./tabs/OffersTab";
import { AdminProjectStatus } from "models/AdminProjectStatus";
import RadioButtonGroup, {
  RadioButton,
} from "features/admin/components/radioButton/RadioButtonGroup";
import SearchInput from "features/admin/components/searchInput/SearchInput";
import {
  BuildOrderStatus,
  DesignOrderStatus,
  ProjectStatusCount,
  ProviderType,
  WildcardSearchType,
} from "api/generated/optimum";
import {
  completedColumns,
  onGoingColumns,
  refusedColumns,
} from "features/admin/components/projectTable/projectTableColumns";
import ProjectTable from "features/admin/components/projectTable/ProjectTable";
import clsx from "clsx";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";

export enum ProjectListTabs {
  Offers = "Offers",
  Active = "Active",
  Closed = "Closed",
}

const ProjectsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { projectsStatics } = useSelector((state) => state.provider);
  const { projects } = useSelector((state) => state.projects);
  const { loading } = useSelector(
    (state) => state.loading.effects.projects.getProjects
  );

  const currentProjectListTab = getSessionStorageValue(
    SessionStorageKeys.ProjectActiveTab
  );

  const [statusRadioValue, setStatusRadioValue] = useState<AdminProjectStatus>(
    AdminProjectStatus[
      currentProjectListTab as keyof typeof AdminProjectStatus
    ] ?? AdminProjectStatus.onGoing
  );

  const statusRadioButtons = useMemo<RadioButton<AdminProjectStatus>[]>(
    () => [
      {
        label: t("ENGINEER.PROJECTS.STATUS.ONGOING"),
        value: AdminProjectStatus.onGoing,
      },
      {
        label: t("ENGINEER.PROJECTS.STATUS.REFUSED"),
        value: AdminProjectStatus.Refused,
      },
      {
        label: t("ENGINEER.PROJECTS.STATUS.COMPLETED"),
        value: AdminProjectStatus.Completed,
      },
    ],
    [t]
  );

  const ProjectStatusMatrix = useMemo(
    () => ({
      [AdminProjectStatus.onGoing]: {
        [ProviderType.Engineer]: [
          DesignOrderStatus.New,
          DesignOrderStatus.FileUpload,
          DesignOrderStatus.OptiComfortNew,
          DesignOrderStatus.OptiComfortWip,
          DesignOrderStatus.OptiComfort,
        ],
        [ProviderType.Invalid]: [
          DesignOrderStatus.OptiComfortNew,
          DesignOrderStatus.OptiComfortWip,
          DesignOrderStatus.OptiComfort,
        ],
      },

      [AdminProjectStatus.Refused]: {
        [ProviderType.Engineer]: [DesignOrderStatus.Refused],
        [ProviderType.Builder]: [BuildOrderStatus.Refused],
        [ProviderType.Invalid]: [],
      },

      [AdminProjectStatus.Completed]: {
        [ProviderType.Engineer]: [DesignOrderStatus.Completed],
        [ProviderType.Builder]: [BuildOrderStatus.Completed],
        [ProviderType.Invalid]: [DesignOrderStatus.Completed],
      },
    }),
    []
  );

  const getCount = (statuses: string[]): number =>
    projectsStatics
      ?.filter((stat) => stat?.status && statuses.includes(stat.status))
      .reduce<number>((acc: number, currentValue: ProjectStatusCount) => {
        if (currentValue.count) {
          return acc + currentValue.count;
        }

        return acc;
      }, 0);

  const getOnGoingCount = (): JSX.Element => {
    if (isEngineer()) {
      const newCount = getCount(["DesignOrderStatus.New"]);
      const fileUploadCount = getCount(["DesignOrderStatus.FileUpload"]);

      const underPlanningCount = getCount([
        "DesignOrderStatus.OptiComfortNew",
        "DesignOrderStatus.OptiComfortWip",
      ]);

      const donePlanningCount = getCount(["DesignOrderStatus.OptiComfort"]);

      return (
        <>
          <Col span={6}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {`${t("ADMIN.PROVIDERS.PROFILE.PROJECTS.NEW")} ${newCount}`}
            </CustomParagraph>
          </Col>

          <Col span={6}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {`${t(
                "ADMIN.PROVIDERS.PROFILE.PROJECTS.FILE_UPLOAD"
              )} ${fileUploadCount}`}
            </CustomParagraph>
          </Col>

          <Col span={6}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {`${t(
                "ADMIN.PROVIDERS.PROFILE.PROJECTS.UNDER_PLANNING"
              )} ${underPlanningCount}`}
            </CustomParagraph>
          </Col>

          <Col span={6}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {`${t(
                "ADMIN.PROVIDERS.PROFILE.PROJECTS.DONE_PLANNING"
              )} ${donePlanningCount}`}
            </CustomParagraph>
          </Col>
        </>
      );
    } else {
      const newCount = getCount(["DesignOrderStatus.OptiComfortNew"]);

      const underPlanningCount = getCount(["DesignOrderStatus.OptiComfortWip"]);

      const donePlanningCount = getCount(["DesignOrderStatus.OptiComfort"]);

      return (
        <>
          <Col span={6}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {`${t("NGBS.PROJECTS.NEW")} ${newCount}`}
            </CustomParagraph>
          </Col>

          <Col span={10}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {`${t(
                "ADMIN.PROVIDERS.PROFILE.PROJECTS.UNDER_PLANNING"
              )} ${underPlanningCount}`}
            </CustomParagraph>
          </Col>

          <Col span={6}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {`${t(
                "ADMIN.PROVIDERS.PROFILE.PROJECTS.DONE_PLANNING"
              )} ${donePlanningCount}`}
            </CustomParagraph>
          </Col>
        </>
      );
    }
  };

  const getRefusedCount = () => {
    return getCount(["DesignOrderStatus.Refused"]);
  };

  const getCompletedCount = () => {
    return getCount(["DesignOrderStatus.Completed"]);
  };

  const getEngineerProjects = useCallback(
    (wildcard?: string) =>
      dispatch.projects.getProjects({
        wildcardSearchTerm: wildcard,
        wildcardSearchTypes: [WildcardSearchType.Customer],
        designOrderStatuses:
          ProjectStatusMatrix[statusRadioValue][
            isEngineer() ? ProviderType.Engineer : ProviderType.Invalid
          ],
      }),
    [ProjectStatusMatrix, dispatch.projects, statusRadioValue]
  );

  const getTableColumns = useCallback(() => {
    let columns = {
      [AdminProjectStatus.onGoing]: onGoingColumns,
      [AdminProjectStatus.Refused]: refusedColumns,
      [AdminProjectStatus.Completed]: completedColumns,
    }[statusRadioValue];

    // Certain columns are NOT needed for this table.
    columns = columns?.filter(
      (column) =>
        column.dataIndex !== "responsibleName" &&
        column.dataIndex !== "lastOrderRequestDate" &&
        column.dataIndex !== "projectStatusChangedByName" &&
        column.dataIndex !== "projectStatus"
    );

    if (statusRadioValue !== AdminProjectStatus.Refused) {
      columns = columns.filter(
        (column) => column.dataIndex !== "designRefusalReason"
      );
    }

    if (statusRadioValue === AdminProjectStatus.Completed) {
      columns = columns.filter((column) => column.dataIndex !== "modifiedDate");
    }

    return columns;
  }, [statusRadioValue]);

  useEffect(() => {
    if (isBuilder()) {
      return;
    }

    dispatch.provider.getProjectStatistics({});
  }, [dispatch.provider]);

  const emptyMessage = useMemo(() => {
    switch (statusRadioValue) {
      case AdminProjectStatus.onGoing:
        return t("table.empty_note.projects.no_ongoing_projects");
      case AdminProjectStatus.Refused:
        return t("table.empty_note.projects.no_refused_projects");
      case AdminProjectStatus.Completed:
        return isEngineer() || isNgbs()
          ? t("table.empty_note.projects.no_finished_projects")
          : t("table.empty_note.projects.no_completed_projects");
      default:
        return "";
    }
  }, [statusRadioValue, t]);

  return (
    <Content
      title={`${t("COMMON.WELCOME")}, ${user?.displayName
        ?.split(" ")
        .slice(1)
        .join(" ")}`}
    >
      {isBuilder() ? (
        <Tabs
          destroyInactiveTabPane
          className={styles.tabs}
          defaultActiveKey={currentProjectListTab || ""}
          onChange={(activeKey: string) =>
            setSessionStorageValue(
              SessionStorageKeys.ProjectActiveTab,
              activeKey
            )
          }
        >
          <Tabs.TabPane
            tab={
              <CustomTitle level={3} color="darkBlue" extraStrong>
                {t("PROJECTS.LIST.OFFERS_TAB")}
              </CustomTitle>
            }
            key={ProjectListTabs.Offers}
          >
            <OffersTab />
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={
              <CustomTitle level={3} color="darkBlue" extraStrong>
                {t("PROJECTS.LIST.ACTIVE_TAB")}
              </CustomTitle>
            }
            key={ProjectListTabs.Active}
          >
            <ActiveTab />
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={
              <CustomTitle level={3} color="darkBlue" extraStrong>
                {t("PROJECTS.LIST.CLOSED_TAB")}
              </CustomTitle>
            }
            key={ProjectListTabs.Closed}
          >
            <ClosedTab />
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <>
          <CustomTitle extraStrong level={1} className={styles.pageTitle}>
            {t("ENGINEER.PROJECTS.PAGE_TITLE")}
          </CustomTitle>

          <Row>
            <div
              className={clsx(
                styles.container,
                isEngineer() ? styles.onGoing : styles.ngbsOnGoing
              )}
            >
              <Row className={styles.titleRow}>
                <CustomParagraph extraStrong size="xs" color="grey">
                  {t("ADMIN.PROVIDERS.PROFILE.PROJECTS.ONGOING")}
                </CustomParagraph>
              </Row>
              <Row className={styles.onGoingCountRow} justify="space-between">
                {getOnGoingCount()}
              </Row>
            </div>
            {isEngineer() && (
              <div className={styles.container}>
                <Row className={styles.titleRow}>
                  <CustomParagraph extraStrong size="xs" color="grey">
                    {t("ADMIN.PROVIDERS.PROFILE.PROJECTS.REFUSED")}
                  </CustomParagraph>
                </Row>

                <Row justify="end">
                  <CustomParagraph
                    extraStrong
                    size="xs"
                    color="grey"
                    className={styles.singleDigitCount}
                  >
                    {getRefusedCount()}
                  </CustomParagraph>
                </Row>
              </div>
            )}

            <div className={styles.container}>
              <Row className={styles.titleRow}>
                <CustomParagraph extraStrong size="xs" color="grey">
                  {t("ENGINEER.PROJECTS.COMPLETED")}
                </CustomParagraph>
              </Row>

              <Row justify="end">
                <CustomParagraph
                  extraStrong
                  size="xs"
                  color="grey"
                  className={styles.singleDigitCount}
                >
                  {getCompletedCount()}
                </CustomParagraph>
              </Row>
            </div>
          </Row>

          <Row align="middle" className={styles.radioButtonGroupWrapper}>
            <Col>
              <RadioButtonGroup
                onChange={(e) => {
                  setStatusRadioValue(e);
                  setSessionStorageValue(
                    SessionStorageKeys.ProjectActiveTab,
                    e
                  );
                }}
                value={statusRadioValue}
                radioButtons={
                  isEngineer()
                    ? statusRadioButtons
                    : statusRadioButtons.filter(
                        (item) => item.value !== AdminProjectStatus.Refused
                      )
                }
                size="large"
              />
            </Col>

            <Col>
              <SearchInput getResource={getEngineerProjects} />
            </Col>
          </Row>

          <ProjectTable
            projects={projects}
            loading={loading}
            isAdmin={false}
            columns={getTableColumns()}
            emptyMessage={emptyMessage}
          />
        </>
      )}
    </Content>
  );
};

export default ProjectsList;
