import CustomInput from "components/form/components/CustomInput";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import useSearchInput from "hooks/useSearchInput";
import styles from "./SearchInput.module.scss";

const SearchInput: React.FC<{
  getResource: (payload?: string) => Promise<void>;
}> = ({ getResource }) => {
  const { t } = useTranslation();

  const setSearchTerm = useSearchInput(getResource);

  return (
    <div>
      <span className={styles.searchtext}>{t("COMMON.SEARCH")}:</span>
      <CustomInput
        placeholder={t("ADMIN.SEARCH.PLACEHOLDER")}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        className={styles.searchInput}
        suffix={<SearchOutlined />}
        allowClear
      />
    </div>
  );
};

export default SearchInput;
