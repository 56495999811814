/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EngineerDto,
    EngineerDtoFromJSON,
    EngineerDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface GetEngineerRequest {
    zip?: string;
}

/**
 * 
 */
export class EngineersApi extends runtime.BaseAPI {

    /**
     * Searches Engineers in the near of zip
     */
    async getEngineerRaw(requestParameters: GetEngineerRequest): Promise<runtime.ApiResponse<Array<EngineerDto>>> {
        const queryParameters: any = {};

        if (requestParameters.zip !== undefined) {
            queryParameters['zip'] = requestParameters.zip;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Engineers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EngineerDtoFromJSON));
    }

    /**
     * Searches Engineers in the near of zip
     */
    async getEngineer(requestParameters: GetEngineerRequest): Promise<Array<EngineerDto>> {
        const response = await this.getEngineerRaw(requestParameters);
        return await response.value();
    }

}
