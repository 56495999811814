import { ReactElement, useEffect, useState } from "react";
import { Button, Dropdown } from "antd";
import { TableSettingsUtils } from "./useTableSettingsUtils";
import { mdiCog } from "@mdi/js";
import TableSettingsLayout from "./TableSettingsLayout";
import { MaterialIcon } from "../components/MaterialIcon";

export const TableSettingsDropdown: <T>(
  p: TableSettingsUtils<T>
) => ReactElement<TableSettingsUtils<T>> = (props) => {
  const [canRender, setCanRender] = useState(false);

  // TODO: ez egy hotfix arra, hogy ne rinyáljon a dnd, hogy nincs drag handle. Valami dom render issue van valamelyik komponensben.
  useEffect(() => {
    setTimeout(() => {
      setCanRender(true);
    }, 0);
  }, []);

  return (
    <Dropdown
      overlay={<>{canRender && <TableSettingsLayout {...props} />}</>}
      trigger={["click"]}
      visible={props.visible}
      onVisibleChange={props.handleChangeVisibility}
      placement="bottomRight"
      arrow
      forceRender
    >
      <Button icon={<MaterialIcon path={mdiCog} />} />
    </Dropdown>
  );
};
