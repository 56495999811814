/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompleteBuildOrderCommand
 */
export interface CompleteBuildOrderCommand {
    /**
     * 
     * @type {number}
     * @memberof CompleteBuildOrderCommand
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompleteBuildOrderCommand
     */
    heatPumpIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteBuildOrderCommand
     */
    heatPumpSerialNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof CompleteBuildOrderCommand
     */
    installationDate: Date;
}

export function CompleteBuildOrderCommandFromJSON(json: any): CompleteBuildOrderCommand {
    return CompleteBuildOrderCommandFromJSONTyped(json, false);
}

export function CompleteBuildOrderCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompleteBuildOrderCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'heatPumpIdentifier': json['heatPumpIdentifier'],
        'heatPumpSerialNumber': json['heatPumpSerialNumber'],
        'installationDate': (new Date(json['installationDate'])),
    };
}

export function CompleteBuildOrderCommandToJSON(value?: CompleteBuildOrderCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'heatPumpIdentifier': value.heatPumpIdentifier,
        'heatPumpSerialNumber': value.heatPumpSerialNumber,
        'installationDate': (value.installationDate.toISOString()),
    };
}


