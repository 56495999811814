/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcceptDesignOrderCommand,
    AcceptDesignOrderCommandFromJSON,
    AcceptDesignOrderCommandToJSON,
    CompleteDesignOrderCommand,
    CompleteDesignOrderCommandFromJSON,
    CompleteDesignOrderCommandToJSON,
    CreateDesignOrderCommand,
    CreateDesignOrderCommandFromJSON,
    CreateDesignOrderCommandToJSON,
    DesignFileType,
    DesignFileTypeFromJSON,
    DesignFileTypeToJSON,
    DesignMaterialDto,
    DesignMaterialDtoFromJSON,
    DesignMaterialDtoToJSON,
    DesignServiceChargeSummaryDto,
    DesignServiceChargeSummaryDtoFromJSON,
    DesignServiceChargeSummaryDtoToJSON,
    ManageOptiComfortPlanCommand,
    ManageOptiComfortPlanCommandFromJSON,
    ManageOptiComfortPlanCommandToJSON,
    PricedDesignMaterialDto,
    PricedDesignMaterialDtoFromJSON,
    PricedDesignMaterialDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RefuseDesignOrderCommand,
    RefuseDesignOrderCommandFromJSON,
    RefuseDesignOrderCommandToJSON,
    UpdateDesignOrderCommand,
    UpdateDesignOrderCommandFromJSON,
    UpdateDesignOrderCommandToJSON,
    UpdateDesignOrderMaterialsCommand,
    UpdateDesignOrderMaterialsCommandFromJSON,
    UpdateDesignOrderMaterialsCommandToJSON,
} from '../models';

export interface AcceptDesignOrderRequest {
    acceptDesignOrderCommand?: AcceptDesignOrderCommand;
}

export interface CompleteDesignOrderRequest {
    completeDesignOrderCommand?: CompleteDesignOrderCommand;
}

export interface CreateDesignOrderRequest {
    createDesignOrderCommand?: CreateDesignOrderCommand;
}

export interface DeleteDesignFileRequest {
    id: number;
    fileName: string;
}

export interface ExportDesignOrderMaterialsRequest {
    id: number;
}

export interface ExportDesignOrderPricedMaterialsRequest {
    id: number;
}

export interface GetDesignAutomationChargesRequest {
    designOrderId: number;
}

export interface GetDesignOrderMaterialsRequest {
    id: number;
}

export interface GetDesignOrderPricedMaterialsRequest {
    id: number;
}

export interface GetDesignPanelDeliveryChargesRequest {
    designOrderId: number;
}

export interface ManageOptiComfortPlanRequest {
    manageOptiComfortPlanCommand?: ManageOptiComfortPlanCommand;
}

export interface RefuseDesignOrderRequest {
    refuseDesignOrderCommand?: RefuseDesignOrderCommand;
}

export interface UpdateDesignOrderRequest {
    updateDesignOrderCommand?: UpdateDesignOrderCommand;
}

export interface UpdateDesignOrderMaterialRequest {
    updateDesignOrderMaterialsCommand?: UpdateDesignOrderMaterialsCommand;
}

export interface UploadDesignFileRequest {
    id: number;
    type?: DesignFileType;
    file?: Blob;
}

/**
 * 
 */
export class DesignOrdersApi extends runtime.BaseAPI {

    /**
     * Accept the design order
     */
    async acceptDesignOrderRaw(requestParameters: AcceptDesignOrderRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/Accept`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptDesignOrderCommandToJSON(requestParameters.acceptDesignOrderCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept the design order
     */
    async acceptDesignOrder(requestParameters: AcceptDesignOrderRequest): Promise<void> {
        await this.acceptDesignOrderRaw(requestParameters);
    }

    /**
     * Complete the design order
     */
    async completeDesignOrderRaw(requestParameters: CompleteDesignOrderRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/Complete`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteDesignOrderCommandToJSON(requestParameters.completeDesignOrderCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Complete the design order
     */
    async completeDesignOrder(requestParameters: CompleteDesignOrderRequest): Promise<void> {
        await this.completeDesignOrderRaw(requestParameters);
    }

    /**
     * Creates new entity
     */
    async createDesignOrderRaw(requestParameters: CreateDesignOrderRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDesignOrderCommandToJSON(requestParameters.createDesignOrderCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates new entity
     */
    async createDesignOrder(requestParameters: CreateDesignOrderRequest): Promise<number> {
        const response = await this.createDesignOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete the specified file
     */
    async deleteDesignFileRaw(requestParameters: DeleteDesignFileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDesignFile.');
        }

        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling deleteDesignFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/{id}/DesignFiles/{fileName}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"fileName"}}`, encodeURIComponent(String(requestParameters.fileName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the specified file
     */
    async deleteDesignFile(requestParameters: DeleteDesignFileRequest): Promise<void> {
        await this.deleteDesignFileRaw(requestParameters);
    }

    /**
     * Export the design order materials.
     */
    async exportDesignOrderMaterialsRaw(requestParameters: ExportDesignOrderMaterialsRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling exportDesignOrderMaterials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/{id}/Materials/Export`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export the design order materials.
     */
    async exportDesignOrderMaterials(requestParameters: ExportDesignOrderMaterialsRequest): Promise<Blob> {
        const response = await this.exportDesignOrderMaterialsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Export the priced design order materials.
     */
    async exportDesignOrderPricedMaterialsRaw(requestParameters: ExportDesignOrderPricedMaterialsRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling exportDesignOrderPricedMaterials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/{id}/PricedMaterials/Export`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export the priced design order materials.
     */
    async exportDesignOrderPricedMaterials(requestParameters: ExportDesignOrderPricedMaterialsRequest): Promise<Blob> {
        const response = await this.exportDesignOrderPricedMaterialsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the design order\'s automation charges
     */
    async getDesignAutomationChargesRaw(requestParameters: GetDesignAutomationChargesRequest): Promise<runtime.ApiResponse<DesignServiceChargeSummaryDto>> {
        if (requestParameters.designOrderId === null || requestParameters.designOrderId === undefined) {
            throw new runtime.RequiredError('designOrderId','Required parameter requestParameters.designOrderId was null or undefined when calling getDesignAutomationCharges.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/{designOrderId}/Automation`.replace(`{${"designOrderId"}}`, encodeURIComponent(String(requestParameters.designOrderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DesignServiceChargeSummaryDtoFromJSON(jsonValue));
    }

    /**
     * Returns the design order\'s automation charges
     */
    async getDesignAutomationCharges(requestParameters: GetDesignAutomationChargesRequest): Promise<DesignServiceChargeSummaryDto> {
        const response = await this.getDesignAutomationChargesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the design order materials.
     */
    async getDesignOrderMaterialsRaw(requestParameters: GetDesignOrderMaterialsRequest): Promise<runtime.ApiResponse<Array<DesignMaterialDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDesignOrderMaterials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/{id}/Materials`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DesignMaterialDtoFromJSON));
    }

    /**
     * Returns the design order materials.
     */
    async getDesignOrderMaterials(requestParameters: GetDesignOrderMaterialsRequest): Promise<Array<DesignMaterialDto>> {
        const response = await this.getDesignOrderMaterialsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the priced design order materials.
     */
    async getDesignOrderPricedMaterialsRaw(requestParameters: GetDesignOrderPricedMaterialsRequest): Promise<runtime.ApiResponse<Array<PricedDesignMaterialDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDesignOrderPricedMaterials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/{id}/PricedMaterials`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PricedDesignMaterialDtoFromJSON));
    }

    /**
     * Returns the priced design order materials.
     */
    async getDesignOrderPricedMaterials(requestParameters: GetDesignOrderPricedMaterialsRequest): Promise<Array<PricedDesignMaterialDto>> {
        const response = await this.getDesignOrderPricedMaterialsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the design order\'s panel delivery charges
     */
    async getDesignPanelDeliveryChargesRaw(requestParameters: GetDesignPanelDeliveryChargesRequest): Promise<runtime.ApiResponse<DesignServiceChargeSummaryDto>> {
        if (requestParameters.designOrderId === null || requestParameters.designOrderId === undefined) {
            throw new runtime.RequiredError('designOrderId','Required parameter requestParameters.designOrderId was null or undefined when calling getDesignPanelDeliveryCharges.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/{designOrderId}/PanelDelivery`.replace(`{${"designOrderId"}}`, encodeURIComponent(String(requestParameters.designOrderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DesignServiceChargeSummaryDtoFromJSON(jsonValue));
    }

    /**
     * Returns the design order\'s panel delivery charges
     */
    async getDesignPanelDeliveryCharges(requestParameters: GetDesignPanelDeliveryChargesRequest): Promise<DesignServiceChargeSummaryDto> {
        const response = await this.getDesignPanelDeliveryChargesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Start/Modify/Finalize OptiComfort planning phase
     */
    async manageOptiComfortPlanRaw(requestParameters: ManageOptiComfortPlanRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/ManageOptiComfortPlan`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManageOptiComfortPlanCommandToJSON(requestParameters.manageOptiComfortPlanCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Start/Modify/Finalize OptiComfort planning phase
     */
    async manageOptiComfortPlan(requestParameters: ManageOptiComfortPlanRequest): Promise<void> {
        await this.manageOptiComfortPlanRaw(requestParameters);
    }

    /**
     * Refuse the design order
     */
    async refuseDesignOrderRaw(requestParameters: RefuseDesignOrderRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/Refuse`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RefuseDesignOrderCommandToJSON(requestParameters.refuseDesignOrderCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Refuse the design order
     */
    async refuseDesignOrder(requestParameters: RefuseDesignOrderRequest): Promise<void> {
        await this.refuseDesignOrderRaw(requestParameters);
    }

    /**
     * Update the design order
     */
    async updateDesignOrderRaw(requestParameters: UpdateDesignOrderRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDesignOrderCommandToJSON(requestParameters.updateDesignOrderCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the design order
     */
    async updateDesignOrder(requestParameters: UpdateDesignOrderRequest): Promise<void> {
        await this.updateDesignOrderRaw(requestParameters);
    }

    /**
     * Update design order materials. Deletes the existing ones and creates new ones from the specified material list
     */
    async updateDesignOrderMaterialRaw(requestParameters: UpdateDesignOrderMaterialRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DesignOrders/Materials`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDesignOrderMaterialsCommandToJSON(requestParameters.updateDesignOrderMaterialsCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update design order materials. Deletes the existing ones and creates new ones from the specified material list
     */
    async updateDesignOrderMaterial(requestParameters: UpdateDesignOrderMaterialRequest): Promise<void> {
        await this.updateDesignOrderMaterialRaw(requestParameters);
    }

    /**
     * Uploads the specified files
     */
    async uploadDesignFileRaw(requestParameters: UploadDesignFileRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadDesignFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.type !== undefined) {
            formParams.append('type', requestParameters.type as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/DesignOrders/{id}/DesignFiles`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Uploads the specified files
     */
    async uploadDesignFile(requestParameters: UploadDesignFileRequest): Promise<string> {
        const response = await this.uploadDesignFileRaw(requestParameters);
        return await response.value();
    }

}
