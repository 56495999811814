/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MaterialCategories,
    MaterialCategoriesFromJSON,
    MaterialCategoriesFromJSONTyped,
    MaterialCategoriesToJSON,
    MaterialUnit,
    MaterialUnitFromJSON,
    MaterialUnitFromJSONTyped,
    MaterialUnitToJSON,
} from './';

/**
 * 
 * @export
 * @interface DesignMaterialDto
 */
export interface DesignMaterialDto {
    /**
     * 
     * @type {number}
     * @memberof DesignMaterialDto
     */
    designOrderId?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignMaterialDto
     */
    materialId?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignMaterialDto
     */
    id?: number;
    /**
     * 
     * @type {MaterialCategories}
     * @memberof DesignMaterialDto
     */
    category?: MaterialCategories;
    /**
     * 
     * @type {string}
     * @memberof DesignMaterialDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesignMaterialDto
     */
    name?: string | null;
    /**
     * 
     * @type {MaterialUnit}
     * @memberof DesignMaterialDto
     */
    unit?: MaterialUnit;
    /**
     * 
     * @type {number}
     * @memberof DesignMaterialDto
     */
    amount?: number;
}

export function DesignMaterialDtoFromJSON(json: any): DesignMaterialDto {
    return DesignMaterialDtoFromJSONTyped(json, false);
}

export function DesignMaterialDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DesignMaterialDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'designOrderId': !exists(json, 'designOrderId') ? undefined : json['designOrderId'],
        'materialId': !exists(json, 'materialId') ? undefined : json['materialId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'category': !exists(json, 'category') ? undefined : MaterialCategoriesFromJSON(json['category']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'unit': !exists(json, 'unit') ? undefined : MaterialUnitFromJSON(json['unit']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function DesignMaterialDtoToJSON(value?: DesignMaterialDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'designOrderId': value.designOrderId,
        'materialId': value.materialId,
        'id': value.id,
        'category': MaterialCategoriesToJSON(value.category),
        'code': value.code,
        'name': value.name,
        'unit': MaterialUnitToJSON(value.unit),
        'amount': value.amount,
    };
}


