/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BuildMaterialListDto,
    BuildMaterialListDtoFromJSON,
    BuildMaterialListDtoToJSON,
    BuildMaterialListStatus,
    BuildMaterialListStatusFromJSON,
    BuildMaterialListStatusToJSON,
    ConfirmBuildMaterialListCommand,
    ConfirmBuildMaterialListCommandFromJSON,
    ConfirmBuildMaterialListCommandToJSON,
    OrderBuildMaterialListCommand,
    OrderBuildMaterialListCommandFromJSON,
    OrderBuildMaterialListCommandToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ResetBuildOrderMaterialsCommand,
    ResetBuildOrderMaterialsCommandFromJSON,
    ResetBuildOrderMaterialsCommandToJSON,
    UpdateBuildOrderMaterialsCommand,
    UpdateBuildOrderMaterialsCommandFromJSON,
    UpdateBuildOrderMaterialsCommandToJSON,
} from '../models';

export interface ConfirmBuildOrderMaterialsRequest {
    confirmBuildMaterialListCommand?: ConfirmBuildMaterialListCommand;
}

export interface ExportBuildOrderMaterialsRequest {
    buildOrderId: number;
}

export interface GetBuildOrderMaterialsRequest {
    buildOrderId: number;
}

export interface OrderBuildOrderMaterialsRequest {
    orderBuildMaterialListCommand?: OrderBuildMaterialListCommand;
}

export interface ResetBuildOrderMaterialsRequest {
    resetBuildOrderMaterialsCommand?: ResetBuildOrderMaterialsCommand;
}

export interface SearchBuildOrderMaterialsRequest {
    status?: BuildMaterialListStatus;
}

export interface UpdateBuildOrderMaterialsRequest {
    updateBuildOrderMaterialsCommand?: UpdateBuildOrderMaterialsCommand;
}

/**
 * 
 */
export class BuildOrderMaterialsApi extends runtime.BaseAPI {

    /**
     * Confirm build materials
     */
    async confirmBuildOrderMaterialsRaw(requestParameters: ConfirmBuildOrderMaterialsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/Materials/Confirm`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmBuildMaterialListCommandToJSON(requestParameters.confirmBuildMaterialListCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirm build materials
     */
    async confirmBuildOrderMaterials(requestParameters: ConfirmBuildOrderMaterialsRequest): Promise<void> {
        await this.confirmBuildOrderMaterialsRaw(requestParameters);
    }

    /**
     * Export the build order material list
     */
    async exportBuildOrderMaterialsRaw(requestParameters: ExportBuildOrderMaterialsRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.buildOrderId === null || requestParameters.buildOrderId === undefined) {
            throw new runtime.RequiredError('buildOrderId','Required parameter requestParameters.buildOrderId was null or undefined when calling exportBuildOrderMaterials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/{buildOrderId}/Materials/Export`.replace(`{${"buildOrderId"}}`, encodeURIComponent(String(requestParameters.buildOrderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export the build order material list
     */
    async exportBuildOrderMaterials(requestParameters: ExportBuildOrderMaterialsRequest): Promise<Blob> {
        const response = await this.exportBuildOrderMaterialsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the build order materials
     */
    async getBuildOrderMaterialsRaw(requestParameters: GetBuildOrderMaterialsRequest): Promise<runtime.ApiResponse<BuildMaterialListDto>> {
        if (requestParameters.buildOrderId === null || requestParameters.buildOrderId === undefined) {
            throw new runtime.RequiredError('buildOrderId','Required parameter requestParameters.buildOrderId was null or undefined when calling getBuildOrderMaterials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/{buildOrderId}/Materials`.replace(`{${"buildOrderId"}}`, encodeURIComponent(String(requestParameters.buildOrderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildMaterialListDtoFromJSON(jsonValue));
    }

    /**
     * Returns the build order materials
     */
    async getBuildOrderMaterials(requestParameters: GetBuildOrderMaterialsRequest): Promise<BuildMaterialListDto> {
        const response = await this.getBuildOrderMaterialsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set build order materials list as Ordered
     */
    async orderBuildOrderMaterialsRaw(requestParameters: OrderBuildOrderMaterialsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/Materials/Order`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderBuildMaterialListCommandToJSON(requestParameters.orderBuildMaterialListCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set build order materials list as Ordered
     */
    async orderBuildOrderMaterials(requestParameters: OrderBuildOrderMaterialsRequest): Promise<void> {
        await this.orderBuildOrderMaterialsRaw(requestParameters);
    }

    /**
     * Reset build order materials
     */
    async resetBuildOrderMaterialsRaw(requestParameters: ResetBuildOrderMaterialsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/Materials/Reset`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResetBuildOrderMaterialsCommandToJSON(requestParameters.resetBuildOrderMaterialsCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reset build order materials
     */
    async resetBuildOrderMaterials(requestParameters: ResetBuildOrderMaterialsRequest): Promise<void> {
        await this.resetBuildOrderMaterialsRaw(requestParameters);
    }

    /**
     * Get build orders materials
     */
    async searchBuildOrderMaterialsRaw(requestParameters: SearchBuildOrderMaterialsRequest): Promise<runtime.ApiResponse<Array<BuildMaterialListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/Materials/Search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BuildMaterialListDtoFromJSON));
    }

    /**
     * Get build orders materials
     */
    async searchBuildOrderMaterials(requestParameters: SearchBuildOrderMaterialsRequest): Promise<Array<BuildMaterialListDto>> {
        const response = await this.searchBuildOrderMaterialsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update build order materials
     */
    async updateBuildOrderMaterialsRaw(requestParameters: UpdateBuildOrderMaterialsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/BuildOrders/Materials`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBuildOrderMaterialsCommandToJSON(requestParameters.updateBuildOrderMaterialsCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update build order materials
     */
    async updateBuildOrderMaterials(requestParameters: UpdateBuildOrderMaterialsRequest): Promise<void> {
        await this.updateBuildOrderMaterialsRaw(requestParameters);
    }

}
