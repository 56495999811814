/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BuilderDto,
    BuilderDtoFromJSON,
    BuilderDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface GetBuilderRequest {
    zip?: string;
}

/**
 * 
 */
export class BuildersApi extends runtime.BaseAPI {

    /**
     * Searches the Builders in the near of zip
     */
    async getBuilderRaw(requestParameters: GetBuilderRequest): Promise<runtime.ApiResponse<Array<BuilderDto>>> {
        const queryParameters: any = {};

        if (requestParameters.zip !== undefined) {
            queryParameters['zip'] = requestParameters.zip;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Builders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BuilderDtoFromJSON));
    }

    /**
     * Searches the Builders in the near of zip
     */
    async getBuilder(requestParameters: GetBuilderRequest): Promise<Array<BuilderDto>> {
        const response = await this.getBuilderRaw(requestParameters);
        return await response.value();
    }

}
