import { Role } from "api/generated/optimum";
import { ScrollToTop } from "components/ScrollToTop";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import { RootState, useSelector } from "../../app/store";
import Content from "../../components/layout/content/Content";
import { INITIAL_CURRENT_STEP } from "./Engineer.store";
import EngineerContactSuccess from "./engineerContactSuccess/EngineerContactSuccess";
import EngineerContactWizard from "./engineerContactWizard/EngineerContactWizard";
import {
  EngineerStepRouteForNew,
  EngineerStepRouteForRenewal,
} from "./engineerContactWizard/models";
import EngineersList from "./engineersList/EngineersList";

type DirectlyNotAccessiblePathname =
  | EngineerStepRouteForNew
  | EngineerStepRouteForRenewal
  | "/design"
  | "/new"
  | "/renewal";

const directlyNotAccessiblePathnames: DirectlyNotAccessiblePathname[] = [
  "/design",
  "/new",
  "/renewal",
  "/design/real-estate",
  "/design/new/details",
  "/design/new/contact",
  "/design/new/confirmation",
  "/design/new/confirmed",
  "/design/renewal/details",
  "/design/renewal/contact",
  "/design/renewal/confirmation",
  "/design/renewal/confirmed",
];

const Engineer: FC = () => {
  const { t } = useTranslation();
  const { currentStep } = useSelector((state: RootState) => state.engineer);
  const location = useLocation();

  if (
    directlyNotAccessiblePathnames.includes(
      location.pathname as DirectlyNotAccessiblePathname
    ) &&
    currentStep <= INITIAL_CURRENT_STEP
  ) {
    return <Navigate replace to="/" />;
  }

  return (
    <ScrollToTop scrollIndicator={currentStep}>
      <Content title={t("ENGINEERS.PAGE_TITLE")} colorVariant={Role.Engineer}>
        {location.pathname === "/design/new/confirmed" ||
        location.pathname === "/design/renewal/confirmed" ? (
          <EngineerContactSuccess />
        ) : currentStep > INITIAL_CURRENT_STEP ? (
          <EngineerContactWizard />
        ) : (
          <EngineersList />
        )}
      </Content>
    </ScrollToTop>
  );
};

export default Engineer;
