import { EmptyContent } from "./EmptyContent";
import { ReactComponent as EmptyListIcon } from "assets/images/empty_list.svg";
import styles from "./EmptyContent.module.scss";
import clsx from "clsx";
import { isAdmin, isBuilder, isEngineer, isNgbs } from "utils/authUtils";

export const CustomEmptyNote: React.FC<{ message?: string }> = ({
  message,
}) => {
  return message ? (
    <div
      className={clsx(styles.customEmptyContent, {
        [styles.ngbs]: isNgbs(),
        [styles.engineer]: isEngineer(),
        [styles.builder]: isBuilder(),
        [styles.admin]: isAdmin(),
      })}
    >
      <div className={styles.emptyNote}>{message}</div>
      <EmptyListIcon className={styles.customEmptyIcon} />
    </div>
  ) : (
    <EmptyContent />
  );
};
