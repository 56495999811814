import { Row } from "antd";

import CustomTitle from "components/typography/title/CustomTitle";
import { useTranslation } from "react-i18next";
import styles from "./FinalizeDesignModal.module.scss";
import CustomButton from "components/button/CustomButton";

import CustomParagraph from "components/typography/paragraph/CustomParagraph";

import CustomModal from "components/customModal/CustomModal";
import { useDispatch } from "react-redux";
import { AlertModalType } from "components/alertModal/AlertModal.store";
import { useSelector } from "app/store";

const FinalizeDesignModal: React.FC<{
  isVisible: boolean;
  closeModal: () => void;
  designOrderId?: number | null;
  projectCode?: string | null;
}> = ({ isVisible, closeModal, designOrderId, projectCode }) => {
  const { t } = useTranslation();
  const { loading } = useSelector(
    (state) => state.loading.effects.projects.finalizeDesignOrder
  );

  const dispatch = useDispatch();

  const finalizeDesignOrderHandler = async () => {
    if (designOrderId) {
      try {
        await dispatch.projects.finalizeDesignOrder(designOrderId);
        closeModal();

        dispatch.alertModal.openModal({
          cardTitle: t("PROJECTS.DETAIL.DESIGN.FINALIZE_MODAL.TITLE"),
          title: t("PROJECTS.DETAIL.DESIGN.FINALIZE_MODAL.SUCCESS_MSG"),
          buttonLabel: t("COMMON.ALRIGHT"),
          type: AlertModalType.Success,
          width: "58rem",
        });

        dispatch.projects.getProject({ projectCode });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <CustomModal
      closable={false}
      width={"58rem"}
      visible={isVisible}
      className={styles.modal}
    >
      <Row justify="center">
        <CustomTitle
          level={3}
          color="darkBlue"
          extraStrong
          className={styles.modalTitle}
        >
          {t("PROJECTS.DETAIL.DESIGN.FINALIZE_MODAL.TITLE")}
        </CustomTitle>
      </Row>

      <CustomParagraph color="black" className={styles.description}>
        {t("PROJECTS.DETAIL.DESIGN.FINALIZE_MODAL.DESC")}
      </CustomParagraph>

      <ul className={styles.list}>
        <li> {t("PROJECTS.DETAIL.DESIGN.FINALIZE_MODAL.LIST_1")}</li>
        <li> {t("PROJECTS.DETAIL.DESIGN.FINALIZE_MODAL.LIST_2")}</li>
        <li> {t("PROJECTS.DETAIL.DESIGN.FINALIZE_MODAL.LIST_3")}</li>
      </ul>

      <CustomParagraph color="black" className={styles.sureMsg}>
        {t("PROJECTS.DETAIL.DESIGN.FINALIZE_MODAL.SURE_MSG")}
      </CustomParagraph>

      <Row justify="center">
        <CustomButton
          className={styles.cancelBtn}
          onClick={closeModal}
          variant="default"
        >
          {t("COMMON.CANCEL")}
        </CustomButton>

        <CustomButton
          type="primary"
          onClick={finalizeDesignOrderHandler}
          variant="default"
          loading={loading}
        >
          {t("PROJECTS.DETAIL.DESIGN.FINALIZE_MODAL.SEND_DEMAND")}
        </CustomButton>
      </Row>
    </CustomModal>
  );
};

export default FinalizeDesignModal;
