import { FC } from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { useDispatch, useSelector } from "app/store";
import { Role } from "api/generated/optimum/models";
import { notification, Skeleton } from "antd";
import { AuthStep } from "features/auth/Auth.store";
import { useTranslation } from "react-i18next";

export interface PrivateRouteProps extends RouteProps {
  roles?: Role[];
  shouldStayOnRoute?: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  roles,
  children,
  shouldStayOnRoute,
}) => {
  const { user, authStep } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!user) {
    if (!shouldStayOnRoute) {
      return <Navigate to={"/"} replace />;
    }

    if (!authStep) {
      dispatch.auth.setAuthStep({
        authStep: AuthStep.LOGIN,
        navigate: null,
      });
    }

    return <Skeleton />;
  }

  if (roles === undefined || roles.some((x) => x === user.role)) {
    return <>{children}</>;
  }

  notification.error({ message: t("COMMON.ERROR.NO_PERMISSION") });
  return <Navigate to={"/projects"} replace />;
};

export default PrivateRoute;
