/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DesignRefusalReason {
    Invalid = 'Invalid',
    NotResidentalRequest = 'NotResidentalRequest',
    NotAdequateBuildingCondition = 'NotAdequateBuildingCondition',
    DuplicatedRequest = 'DuplicatedRequest',
    HeatPumpOnlyRequest = 'HeatPumpOnlyRequest',
    NotRequestJustInquiry = 'NotRequestJustInquiry',
    PriceMismatch = 'PriceMismatch',
    DeadlineMismatch = 'DeadlineMismatch',
    TestRequest = 'TestRequest',
    OtherReason = 'OtherReason'
}

export function DesignRefusalReasonFromJSON(json: any): DesignRefusalReason {
    return DesignRefusalReasonFromJSONTyped(json, false);
}

export function DesignRefusalReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): DesignRefusalReason {
    return json as DesignRefusalReason;
}

export function DesignRefusalReasonToJSON(value?: DesignRefusalReason | null): any {
    return value as any;
}

