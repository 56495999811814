import { Avatar, AvatarProps } from "antd";
import { FC } from "react";
import noProfilePicture from "../../assets/images/noprofile.png";

export interface CustomAvatarProps extends AvatarProps {
  className?: string;
}
const CustomAvatar: FC<CustomAvatarProps> = ({ icon, className, ...rest }) => {
  return (
    <Avatar {...rest} icon={icon || noProfilePicture} className={className} />
  );
};

export default CustomAvatar;
