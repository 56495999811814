/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BuildRefusalReason {
    Invalid = 'Invalid',
    NotResidentalRequest = 'NotResidentalRequest',
    AnotherBuildOrderWasAccepted = 'AnotherBuildOrderWasAccepted',
    NotRequestJustInquiry = 'NotRequestJustInquiry',
    PriceOfMaterials = 'PriceOfMaterials',
    PriceOfService = 'PriceOfService',
    DeadlineMismatch = 'DeadlineMismatch',
    CustomerUnreachable = 'CustomerUnreachable',
    TestRequest = 'TestRequest',
    OtherReason = 'OtherReason'
}

export function BuildRefusalReasonFromJSON(json: any): BuildRefusalReason {
    return BuildRefusalReasonFromJSONTyped(json, false);
}

export function BuildRefusalReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildRefusalReason {
    return json as BuildRefusalReason;
}

export function BuildRefusalReasonToJSON(value?: BuildRefusalReason | null): any {
    return value as any;
}

