import { useSelector } from "app/store";
import Card from "components/card/Card";
import { Descriptions, Empty, List } from "antd";
import CustomTitle from "components/typography/title/CustomTitle";
import CustomButton from "components/button/CustomButton";
import { useTranslation } from "react-i18next";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./TabPaneList.module.scss";
import OrderStatus from "../orderStatus/OrderStatus";
import CustomInput from "components/form/components/CustomInput";
import { useNavigate } from "react-router-dom";
import { DesignOrderStatus } from "api/generated/optimum";
import { phoneNumberDisplayFormat } from "utils/format";

interface TabPaneListProps {
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  isActive?: boolean;
}

const TabPaneList: React.FC<TabPaneListProps> = ({
  setSearchTerm,
  isActive = true,
}) => {
  const { projects } = useSelector((state) => state.projects);
  const { loading } = useSelector(
    (state) => state.loading.effects.projects.getProjects
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <CustomInput
        placeholder={t("PROJECTS.LIST.SEARCH_INPUT_PLACEHOLDER")}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.searchInput}
        suffix={<SearchOutlined />}
        allowClear
      />
      {projects.length ? (
        <>
          <List
            itemLayout="vertical"
            dataSource={projects}
            className={styles.list}
            loading={loading}
            renderItem={(project, index) => (
              <Card key={index}>
                <List.Item
                  className={styles.item}
                  actions={[
                    isActive && (
                      <OrderStatus projectStatus={project.projectStatus} />
                    ),
                    <CustomButton
                      type="primary"
                      size="large"
                      className={styles.detailButton}
                      onClick={() => {
                        if (!project.projectCode) {
                          return;
                        }

                        if (
                          project.designOrderStatus === DesignOrderStatus.New
                        ) {
                          navigate(`${project.projectCode}/design`);
                          return;
                        }

                        navigate(project.projectCode);
                      }}
                    >
                      {t("PROJECTS.LIST.DETAIL_BUTTON")} <RightOutlined />
                    </CustomButton>,
                  ]}
                >
                  <List.Item.Meta
                    title={`${
                      project.realEstateZipCode
                    } ${project?.realEstateCity?.toUpperCase()}, ${project?.realEstateAddress?.toUpperCase()}`}
                    description={
                      <Descriptions
                        className={styles.descriptions}
                        layout="horizontal"
                        colon={true}
                        column={{ xs: 1, sm: 1, md: 2 }}
                      >
                        <Descriptions.Item
                          label={
                            <CustomTitle level={5} color="grey">
                              {t("PROJECTS.LIST.CUSTOMER")}
                            </CustomTitle>
                          }
                        >
                          {project.customerName}
                        </Descriptions.Item>

                        <Descriptions.Item
                          label={
                            <CustomTitle level={5} color="grey">
                              {t("PROJECTS.LIST.PROJECT_CODE")}
                            </CustomTitle>
                          }
                        >
                          {project.projectCode}
                        </Descriptions.Item>

                        <Descriptions.Item
                          label={
                            <CustomTitle level={5} color="grey">
                              {t("PROJECTS.LIST.PHONE")}
                            </CustomTitle>
                          }
                        >
                          {project.customerPhoneNumber &&
                            phoneNumberDisplayFormat(
                              project.customerPhoneNumber
                            )}
                        </Descriptions.Item>

                        <Descriptions.Item
                          label={
                            <CustomTitle level={5} color="grey">
                              {t("PROJECTS.LIST.PROJECT_TYPE")}
                            </CustomTitle>
                          }
                        >
                          <span className={styles.projectType}>
                            {project.realEstateIsNew
                              ? t(
                                  "ENGINEERS.ENGINEER_WIZARD.STEP_2.NEWLY_BUILT"
                                )
                              : t("PROJECT_DETAILS.HEADER.AWAITING_RENOVATION")}
                          </span>
                        </Descriptions.Item>

                        <Descriptions.Item
                          label={
                            <CustomTitle level={5} color="grey">
                              {t("PROJECTS.LIST.EMAIL")}
                            </CustomTitle>
                          }
                        >
                          {project.customerEmailAddress}
                        </Descriptions.Item>

                        {/* <Descriptions.Item
                      label={
                        <CustomTitle level={5} color="grey">
                          {t("PROJECTS.LIST.DEAD_LINE")}
                        </CustomTitle>
                      }
                    >
                      {project.designOrderDeadline?.toLocaleDateString()}
                    </Descriptions.Item> */}
                      </Descriptions>
                    }
                  />
                </List.Item>
              </Card>
            )}
          />
        </>
      ) : (
        <div className={styles.noData}>
          <CustomTitle level={1} extraStrong align="center" color="grey">
            {t("PROJECTS.LIST.NO_DATA")}
          </CustomTitle>
          <Empty description={false} />
        </div>
      )}
    </>
  );
};

export default TabPaneList;
