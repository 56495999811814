import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Content from "components/layout/content/Content";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { MaterialIcon } from "components/GrapeAntdTable/components/MaterialIcon";
import { mdiChevronLeft } from "@mdi/js";
import GrapeAntdForm from "components/form/components/GrapeAntdForm";
import useGrapeAntdForm from "components/form/hooks/useGrapeAntdForm";
import Card from "components/card/Card";
import { Col, Row } from "antd";
import styles from "./AdminProviderProfile.module.scss";
import CustomButton from "components/button/CustomButton";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import Checkbox from "antd/lib/checkbox/Checkbox";
import ProviderAvatar from "components/providerAvatar/ProviderAvatar";
import { useDispatch, useSelector } from "app/store";
import {
  SetProviderActiveFlagCommand,
  SetProviderIsEnabledFlagCommand,
  SetProviderPublishedFlagCommand,
  UpdateProviderCommand,
} from "api/generated/optimum";
import { phoneNumberDisplayFormat } from "utils/format";
import ConfirmModal from "components/confirmModal/ConfirmModal";
import CustomTitle from "components/typography/title/CustomTitle";
import ProviderModal from "../providerModal/ProviderModal";
import { LocationProps } from "models/LocationProps";
import AdminProviderProjectsList from "./components/adminProviderProjectsList/AdminProviderProjectsList";

const AdminProviderProfile: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formUtils = useGrapeAntdForm("UpdateProviderCommand");
  const { user } = useSelector((state) => state.provider);
  const { id } = useParams();
  const [providerModalVisible, setProviderModalVisible] =
    useState<boolean>(false);

  const [isDisableConfirmModalVisible, setIsDisableConfirmModalVisible] =
    useState(false);

  const [isPublishedConfirmModalVisible, setIsPublishedConfirmModalVisible] =
    useState(false);

  const [rejectModalVisible, setRejectModalVisible] = useState(false);

  const location = useLocation() as LocationProps;

  const handleOnDisableButtonClicked = () => {
    setIsDisableConfirmModalVisible(true);
  };

  const changeUserIsPublishedState = () => {
    dispatch.provider
      .updateProviderPublishedFlag({
        id: user?.id,
        isPublished: !user?.isPublished,
      } as SetProviderPublishedFlagCommand)
      .finally(() => dispatch.provider.getProvider(String(user?.id)));
  };

  const changeUserIsActiveState = () => {
    dispatch.provider
      .updateProviderActiveFlag({
        id: user?.id,
        isActive: !user?.isActive,
      } as SetProviderActiveFlagCommand)
      .then(() => dispatch.provider.getProvider(String(user?.id)));
  };

  const changeUserIsEnabledState = () => {
    dispatch.provider
      .updateProviderEnabledFlag({
        id: user?.id,
        isEnabled: !user?.isEnabled,
      } as SetProviderIsEnabledFlagCommand)
      .then(() => dispatch.provider.getProvider(String(user?.id)));
  };

  useEffect(() => {
    if (id) {
      dispatch.provider.getProvider(String(id));
    }

    return () => {
      dispatch.provider.reset();
    };
  }, [dispatch.provider, id]);

  useEffect(() => {
    if (id) {
      formUtils.form.setFieldsValue({
        ...user,
      } as UpdateProviderCommand);
    }
  }, [formUtils.form, id, user]);

  return (
    <Content>
      <Row justify="space-between">
        <div className={styles.navigationLink}>
          <NavLink to={location?.state?.from ?? "/admin/providers"}>
            <MaterialIcon path={mdiChevronLeft} />
            {t("ADMIN.PROVIDERS.PROFILE.BACK")}
          </NavLink>
        </div>
      </Row>
      <Row gutter={20}>
        <Col>
          <ProviderAvatar url={user?.profilePictureUrl} size="10rem" />
        </Col>
        <Col flex="auto">
          <Row align="middle">
            <Col>
              <CustomTitle>
                {user?.lastName} {user?.firstName}
              </CustomTitle>
            </Col>
            {!user?.isEnabled && (
              <Col>
                <div className={styles.badge}>
                  {t("ADMIN.PROVIDERS.PROFILE.INACTIVE")}
                </div>
              </Col>
            )}
            {user?.isEnabled && !user?.isActive && (
              <Col>
                <div className={styles.badgeYellow}>
                  {t("ADMIN.PROVIDERS.PROFILE.DOESNOTTAKEAJOB")}
                </div>
              </Col>
            )}
          </Row>

          <CustomParagraph color="grey" className={styles.dataLabel}>
            {`${user?.position}, ${user?.companyName}`}
          </CustomParagraph>
          <CustomParagraph color="grey" className={styles.dataLabel}>
            <span className={styles.email}>{user?.emailAddress}</span>
            {(user?.phoneNumber &&
              phoneNumberDisplayFormat(user?.phoneNumber)) ||
              ""}
          </CustomParagraph>
        </Col>
        <Col>
          <CustomButton
            className={styles.detailsButton}
            onClick={() => setProviderModalVisible(true)}
          >
            {t("ADMIN.PROVIDERS.PROFILE.DETAILS")}
          </CustomButton>
        </Col>
      </Row>
      <Card className={styles.card}>
        <GrapeAntdForm
          formUtils={formUtils}
          layout="horizontal"
          className={styles.form}
        >
          <Row align="middle">
            <Col xs={8}>
              <GrapeAntdForm.Item name="isPublished" valuePropName="checked">
                <Checkbox
                  onChange={() => setIsPublishedConfirmModalVisible(true)}
                  disabled={!user?.isEnabled}
                >
                  {t("ADMIN.PROVIDERS.PROFILE.IS_PUBLISHED")}
                </Checkbox>
              </GrapeAntdForm.Item>
            </Col>
            <Col flex="auto">
              <GrapeAntdForm.Item name="isActive" valuePropName="checked">
                <Checkbox
                  onChange={() => changeUserIsActiveState()}
                  disabled={!user?.isEnabled}
                >
                  {t("ADMIN.PROVIDERS.PROFILE.IS_ACTIVE")}
                </Checkbox>
              </GrapeAntdForm.Item>
            </Col>
            <Col>
              <CustomButton
                className={styles.deleteButton}
                disabled={!user?.isEmailAddressConfirmed}
                onClick={() => handleOnDisableButtonClicked()}
              >
                {user?.isEnabled
                  ? t("ADMIN.PROVIDERS.PROFILE.DELETE_PROVIDER")
                  : t("ADMIN.PROVIDERS.PROFILE.ACTIVATE_PROVIDER")}
              </CustomButton>
            </Col>
          </Row>
        </GrapeAntdForm>
      </Card>

      <AdminProviderProjectsList />

      <ProviderModal
        isVisible={providerModalVisible}
        isCreateMode={false}
        id={user?.id || ""}
        setRejectModalVisible={setRejectModalVisible}
        onClose={() => setProviderModalVisible(false)}
        onSubmit={() => setProviderModalVisible(false)}
      />
      <ConfirmModal
        isVisible={isDisableConfirmModalVisible}
        submitBtnClassName={user?.isEnabled ? styles.modalDisableButton : ""}
        onClose={() => {
          setIsDisableConfirmModalVisible(false);
        }}
        onSubmit={async () => {
          await setIsDisableConfirmModalVisible(false);
          changeUserIsEnabledState();
        }}
        title={
          user?.isEnabled
            ? t("ADMIN.PROVIDERS.PROFILE.DELETE_PROVIDER_TITLE")
            : t("ADMIN.PROVIDERS.PROFILE.ACTIVATE_PROVIDER_TITLE")
        }
        description={
          user?.isEnabled
            ? t("ADMIN.PROVIDERS.PROFILE.DELETE_PROVIDER_DESCRIPTION")
            : t("ADMIN.PROVIDERS.PROFILE.ACTIVATE_PROVIDER_DESCRIPTION")
        }
        submitBtnLabel={
          user?.isEnabled
            ? t("ADMIN.PROVIDERS.PROFILE.DELETE_PROVIDER_OK_BUTTON")
            : t("ADMIN.PROVIDERS.PROFILE.ACTIVATE_PROVIDER_OK_BUTTON")
        }
      />

      <ConfirmModal
        isVisible={isPublishedConfirmModalVisible}
        onClose={() => {
          setIsPublishedConfirmModalVisible(false);
          formUtils.form.setFieldsValue({
            isPublished: user?.isPublished,
          });
        }}
        onSubmit={async () => {
          await setIsPublishedConfirmModalVisible(false);
          changeUserIsPublishedState();
        }}
        title={
          user?.isPublished
            ? t("ADMIN.PROVIDERS.PROFILE.UNPUBLISH_PROVIDER_TITLE")
            : t("ADMIN.PROVIDERS.PROFILE.PUBLISH_PROVIDER_TITLE")
        }
        description={
          user?.isPublished
            ? t("ADMIN.PROVIDERS.PROFILE.UNPUBLISH_PROVIDER_DESCRIPTION")
            : t("ADMIN.PROVIDERS.PROFILE.PUBLISH_PROVIDER_DESCRIPTION")
        }
        submitBtnLabel={
          user?.isPublished
            ? t("ADMIN.PROVIDERS.PROFILE.UNPUBLISH_PROVIDER_OK_BUTTON")
            : t("ADMIN.PROVIDERS.PROFILE.PUBLISH_PROVIDER_OK_BUTTON")
        }
      />

      <ConfirmModal
        isVisible={rejectModalVisible}
        onClose={() => {
          setRejectModalVisible(false);
        }}
        onSubmit={async () => {
          setRejectModalVisible(false);
          setProviderModalVisible(false);
        }}
        title={t("ADMIN.PROVIDERS.PROFILE.REJECT_MODAL_TITLE")}
        description={t("ADMIN.PROVIDERS.PROFILE.REJECT_MODAL_DESCRIPTION")}
        submitBtnLabel={t("ADMIN.PROVIDERS.PROFILE.REJECT_MODAL_OK_BUTTON")}
      />
    </Content>
  );
};

export default AdminProviderProfile;
