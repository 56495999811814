import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollToTopProps {
  scrollIndicator?: any;
}

export const ScrollToTop: React.FC<ScrollToTopProps> = ({
  scrollIndicator,
  children,
}) => {
  const { pathname } = useLocation();

  const dependancy = scrollIndicator ?? pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dependancy]);

  return <>{children}</>;
};
