import { Timeline } from "antd";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./BuilderTimeLine.module.scss";

interface TimeLineItem {
  subTitle?: string;
  description?: string[];
}
interface TimeLine {
  title: string;
  items?: TimeLineItem[];
}

const BuilderTimeLine: FC = () => {
  const { t } = useTranslation();
  const timeLineList = useMemo<TimeLine[]>(
    () => [
      {
        title: t("BUILDERS.BUILDERS_LIST.TIMELINE.AVAILABLE_BUILDERS.TITLE"),
        items: [
          {
            subTitle: t(
              "BUILDERS.BUILDERS_LIST.TIMELINE.AVAILABLE_BUILDERS.SUB_TITLE"
            ),
            description: [
              t("BUILDERS.BUILDERS_LIST.TIMELINE.AVAILABLE_BUILDERS.ZIP_CODE"),
              t(
                "BUILDERS.BUILDERS_LIST.TIMELINE.AVAILABLE_BUILDERS.LIST_BUILDERS"
              ),
            ],
          },
          {
            subTitle: t("BUILDERS.BUILDERS_LIST.TIMELINE.CONTACT.SUB_TITLE"),
            description: [
              t("BUILDERS.BUILDERS_LIST.TIMELINE.CONTACT.SELECT_BUILDERS"),
              t("BUILDERS.BUILDERS_LIST.TIMELINE.CONTACT.FILL_FORM"),
              t("BUILDERS.BUILDERS_LIST.TIMELINE.CONTACT.POST_DATA"),
            ],
          },
        ],
      },
      {
        title: t("BUILDERS.BUILDERS_LIST.TIMELINE.CONTRACTING.TITLE"),
        items: [
          {
            subTitle: t(
              "BUILDERS.BUILDERS_LIST.TIMELINE.CONTRACTING.SUB_TITLE"
            ),
            description: [
              t("BUILDERS.BUILDERS_LIST.TIMELINE.CONTRACTING.REPLY"),
              t("BUILDERS.BUILDERS_LIST.TIMELINE.CONTRACTING.CONTRACT"),
            ],
          },
          {
            subTitle: t(
              "BUILDERS.BUILDERS_LIST.TIMELINE.PREPARATION.SUB_TITLE"
            ),
            description: [
              t("BUILDERS.BUILDERS_LIST.TIMELINE.PREPARATION.CREATE_DESIGN"),
              t("BUILDERS.BUILDERS_LIST.TIMELINE.PREPARATION.SEND_DESIGN"),
            ],
          },
        ],
      },
      {
        title: t("BUILDERS.BUILDERS_LIST.TIMELINE.BUILDER.TITLE"),
      },
    ],
    [t]
  );

  return (
    <Timeline className={styles.timeLine}>
      {timeLineList.map((timeLine: TimeLine, i: number) => (
        <Timeline.Item key={i}>
          <CustomTitle
            level={4}
            color="darkBlue"
            className={styles.timeLineTitle}
          >
            {timeLine.title}
          </CustomTitle>

          <div className={styles.timeLineDetails}>
            {timeLine.items?.map((timeLineItem: TimeLineItem, i: number) => (
              <div key={i}>
                <CustomTitle
                  level={4}
                  color="lightBlue"
                  className={styles.timeLineSubTitle}
                >
                  {timeLineItem.subTitle}
                </CustomTitle>

                <ol>
                  {timeLineItem.description?.map(
                    (desc: string, index: number) => (
                      <li className={styles.listElement} key={index}>
                        {desc}
                      </li>
                    )
                  )}
                </ol>
              </div>
            ))}
          </div>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default React.memo(BuilderTimeLine);
