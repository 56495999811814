import CustomButton, { CustomButtonProps, variant } from "./CustomButton";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Upload, UploadProps } from "antd";

export interface UploadButtonProps extends UploadProps {
  variant?: variant;
  buttonVisible?: boolean;
  buttonClassName?: string;
}

const UploadButton: React.FC<UploadButtonProps> = ({
  children,
  disabled,
  buttonClassName,
  buttonVisible = true,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Upload {...rest} disabled={disabled}>
      {buttonVisible && (
        <CustomButton
          className={buttonClassName}
          icon={<UploadOutlined />}
          disabled={disabled}
        >
          {children || t("COMMON.FILE_UPLOAD")}
        </CustomButton>
      )}
    </Upload>
  );
};

export default UploadButton;
