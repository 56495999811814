import { createModel } from "@rematch/core";
import { api } from "api";
import {
  AuthenticationDto,
  ConfirmEmailAndSetPasswordCommand,
  ResetPasswordCommand,
  Role,
} from "api/generated/optimum";
import { RootModel, store } from "app/store";
import { LoginCredentials } from "models/LoginCredentials";
import {
  getLocalStorageValue,
  LocalStorageKeys,
  removeLocalStorageValue,
  setLocalStorageValue,
} from "utils/storageHandler";

export enum AuthStep {
  LOGIN = "LOGIN",
  FORGOTTENPASS = "FORGOTTENPASS",
  NEWPASS = "NEWPASS",
}

interface AuthState {
  user?: AuthenticationDto;
  authStep?: AuthStep;
  navigate?: string | null;
}

const getInitialUserValue = (): AuthenticationDto | undefined => {
  const userValueInStorage = getLocalStorageValue(LocalStorageKeys.User);

  const initialUserValue =
    userValueInStorage && userValueInStorage !== ""
      ? (JSON.parse(userValueInStorage) as AuthenticationDto)
      : undefined;

  return initialUserValue;
};

const getInitialState = (): AuthState => ({
  user: getInitialUserValue(),
  authStep: undefined,
  navigate: "/projects",
});

export const auth = createModel<RootModel>()({
  state: getInitialState(),
  reducers: {
    setUser(state, user: AuthenticationDto) {
      state.user = user;
    },
    setAuthStep(
      state,
      payload: { authStep: AuthStep; navigate?: string | null }
    ) {
      state.authStep = payload.authStep;

      if (payload.navigate !== undefined) {
        state.navigate = payload.navigate;
      }
    },
    resetAuthStep(state) {
      state.authStep = undefined;
      state.navigate = getInitialState().navigate;
    },
    reset: () => getInitialState(),
  },
  effects: (dispatch) => ({
    login: async (credentials: LoginCredentials) => {
      const user = await api.auth.login({ loginCommand: credentials });

      setLocalStorageValue(
        LocalStorageKeys.AccessToken,
        user.accessToken || ""
      );

      // Whend user clicks on "Emlekezz ram" during login we store refreshToken - Temporary disabled
      // if (credentials.remember) {
      setLocalStorageValue(
        LocalStorageKeys.RefreshToken,
        user.refreshToken || ""
      );
      // } else {
      //   removeLocalStorageValue(LocalStorageKeys.RefreshToken);
      // }

      if (user.role === Role.Admin) {
        dispatch.auth.setUser(user);

        setLocalStorageValue(LocalStorageKeys.User, JSON.stringify(user) || "");
        return user;
      }

      if (user.role !== Role.Ngbs) {
        await dispatch.provider.getMyProfile();
      }

      const profilePictureUrl =
        store.getState().provider.user?.profilePictureUrl;

      const userWithProfilePicture = { ...user, profilePictureUrl };

      setLocalStorageValue(
        LocalStorageKeys.User,
        JSON.stringify(userWithProfilePicture) || ""
      );

      dispatch.auth.setUser(userWithProfilePicture);

      return user;
    },
    logout: (shouldCallRemoveTokenApi = true) => {
      const refreshToken = getLocalStorageValue(LocalStorageKeys.RefreshToken);

      if (refreshToken && shouldCallRemoveTokenApi) {
        api.auth.logout({
          logoutCommand: {
            refreshToken: refreshToken,
          },
        });
      }

      removeLocalStorageValue(LocalStorageKeys.AccessToken);
      removeLocalStorageValue(LocalStorageKeys.RefreshToken);
      removeLocalStorageValue(LocalStorageKeys.User);

      dispatch({ type: "RESET" });

      dispatch.auth.reset();
    },
    forgotPassword: async (email: string) => {
      await api.accounts.forgotPassword({
        forgotPasswordCommand: { email },
      });
    },
    resetPassword: async (resetPassword: ResetPasswordCommand) => {
      await api.accounts.resetPassword({
        resetPasswordCommand: resetPassword,
      });
    },
    confirmEmailResetPassword: async (
      confirmEmailAndSetPasswordCommand: ConfirmEmailAndSetPasswordCommand
    ) => {
      await api.accounts.confirmEmailAndSetPassword({
        confirmEmailAndSetPasswordCommand,
      });
    },
    refreshAccessToken: async () => {
      const refreshToken = getLocalStorageValue(LocalStorageKeys.RefreshToken);

      if (!refreshToken) {
        dispatch.auth.logout();

        dispatch.auth.setAuthStep({
          authStep: AuthStep.LOGIN,
        });

        throw new Error("No refresh token"); // Important! Without is if refresh call fails it enters into endless loop
      }

      return api.auth
        .refreshToken({
          refreshTokenCommand: { refreshToken },
        })
        .then((responseDTO) => {
          setLocalStorageValue(
            LocalStorageKeys.AccessToken,
            responseDTO.accessToken || ""
          );
          setLocalStorageValue(
            LocalStorageKeys.RefreshToken,
            responseDTO.refreshToken || ""
          );
        })
        .catch((error) => {
          dispatch.auth.logout(false);

          throw error; // Important! Without is if refresh call fails it enters into endless loop
        });
    },
  }),
});
