import { Col, Row } from "antd";
import { ProjectStatusCount, ProviderType } from "api/generated/optimum";
import { useDispatch, useSelector } from "app/store";
import clsx from "clsx";
import Card from "components/card/Card";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import ProjectTable from "features/admin/components/projectTable/ProjectTable";
import {
  completedColumns,
  onGoingColumns,
  refusedColumns,
} from "features/admin/components/projectTable/projectTableColumns";
import RadioButtonGroup, {
  RadioButton,
} from "features/admin/components/radioButton/RadioButtonGroup";
import { AdminProjectStatus } from "models/AdminProjectStatus";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getSessionStorageValue,
  SessionStorageKeys,
  setSessionStorageValue,
} from "utils/storageHandler";
import styles from "./AdminProviderProjectsList.module.scss";

export const AdminProviderProjectsList: React.FC = () => {
  const { t } = useTranslation();
  const { user, projectsStatics } = useSelector((state) => state.provider);
  const { projects } = useSelector((state) => state.projects);
  const { loading } = useSelector(
    (state) => state.loading.effects.projects.getProjects
  );
  const dispatch = useDispatch();

  const currentAdmintProjectStatus = getSessionStorageValue(
    SessionStorageKeys.AdminProjectActiveStatus
  );

  const [statusRadioValue, setStatusRadioValue] = useState<AdminProjectStatus>(
    AdminProjectStatus[
      (currentAdmintProjectStatus as keyof typeof AdminProjectStatus) ??
        "onGoing"
    ]
  );

  const isEngineer = user?.type === ProviderType.Engineer;

  const providerType = useMemo(
    () => (isEngineer ? "engineerId" : "builderId"),
    [isEngineer]
  );

  const ProjectStatusMatrix = useMemo(
    () => ({
      [AdminProjectStatus.onGoing]: {
        [ProviderType.Engineer]: [
          "DesignOrderStatus.New",
          "DesignOrderStatus.FileUpload",
          "DesignOrderStatus.OptiComfortNew",
          "DesignOrderStatus.OptiComfortWip",
          "DesignOrderStatus.OptiComfort",
        ],
        [ProviderType.Builder]: [
          "BuildOrderStatus.New",
          "BuildOrderStatus.Approved",
          "BuildOrderStatus.MaterialsOrdered",
        ],
        [ProviderType.Invalid]: [],
      },

      [AdminProjectStatus.Refused]: {
        [ProviderType.Engineer]: ["DesignOrderStatus.Refused"],
        [ProviderType.Builder]: ["BuildOrderStatus.Refused"],
        [ProviderType.Invalid]: [],
      },

      [AdminProjectStatus.Completed]: {
        [ProviderType.Engineer]: ["DesignOrderStatus.Completed"],
        [ProviderType.Builder]: ["BuildOrderStatus.Completed"],
        [ProviderType.Invalid]: [],
      },
    }),
    []
  );

  const getTableColumns = useCallback(() => {
    let columns = {
      [AdminProjectStatus.onGoing]: onGoingColumns,
      [AdminProjectStatus.Refused]: refusedColumns,
      [AdminProjectStatus.Completed]: completedColumns,
    }[statusRadioValue];

    // Certain columns are NOT needed for this table.
    columns = columns.filter(
      (column) =>
        column.dataIndex !== "responsibleName" &&
        column.dataIndex !== "projectStatusChangedByName" &&
        column.dataIndex !== "designOrderStatus" &&
        column.dataIndex !== "designOrderCreationDate" &&
        column.dataIndex !== "designOrderCompletionDate"
    );

    if (!isEngineer && statusRadioValue !== AdminProjectStatus.onGoing) {
      columns = columns.filter(
        (column) => column.dataIndex !== "lastOrderRequestDate"
      );
    }

    if (!isEngineer && statusRadioValue === AdminProjectStatus.Refused) {
      columns = columns.filter(
        (column) => column.dataIndex !== "designRefusalReason"
      );
    }

    return columns;
  }, [isEngineer, statusRadioValue]);

  useEffect(() => {
    if (user?.id == null) {
      return;
    }

    dispatch.provider.getProjectStatistics({
      [providerType]: user.id,
    });
  }, [providerType, dispatch.provider, user?.id]);

  useEffect(() => {
    if (user?.id == null || user?.type == null) {
      return;
    }

    dispatch.projects.getProjects({
      [providerType]: user.id,
      projectStatuses: ProjectStatusMatrix[statusRadioValue][user.type],
    });
  }, [
    dispatch.projects,
    user?.id,
    user?.type,
    ProjectStatusMatrix,
    providerType,
    statusRadioValue,
  ]);

  const getCount = (statuses: string[]): number =>
    projectsStatics
      ?.filter((stat) => stat?.status && statuses.includes(stat.status))
      .reduce<number>((acc: number, currentValue: ProjectStatusCount) => {
        if (currentValue.count) {
          return acc + currentValue.count;
        }

        return acc;
      }, 0);

  const getOnGoingCount = (): JSX.Element => {
    if (user?.type === ProviderType.Engineer) {
      const newCount = getCount(["DesignOrderStatus.New"]);

      const fileUploadCount = getCount(["DesignOrderStatus.FileUpload"]);

      const underPlanningCount = getCount([
        "DesignOrderStatus.OptiComfortNew",
        "DesignOrderStatus.OptiComfortWip",
      ]);

      const donePlanningCount = getCount(["DesignOrderStatus.OptiComfort"]);

      return (
        <>
          <Col span={6}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {`${t("ADMIN.PROVIDERS.PROFILE.PROJECTS.NEW")} ${newCount}`}
            </CustomParagraph>
          </Col>

          <Col span={6}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {`${t(
                "ADMIN.PROVIDERS.PROFILE.PROJECTS.FILE_UPLOAD"
              )} ${fileUploadCount}`}
            </CustomParagraph>
          </Col>

          <Col span={6}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {`${t(
                "ADMIN.PROVIDERS.PROFILE.PROJECTS.UNDER_PLANNING"
              )} ${underPlanningCount}`}
            </CustomParagraph>
          </Col>

          <Col span={6}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {`${t(
                "ADMIN.PROVIDERS.PROFILE.PROJECTS.DONE_PLANNING"
              )} ${donePlanningCount}`}
            </CustomParagraph>
          </Col>
        </>
      );
    }

    const buildNewCount = getCount(["BuildOrderStatus.New"]);

    const buildApprovedCount = getCount(["BuildOrderStatus.Approved"]);

    const buildMaterialsOrderedCount = getCount([
      "BuildOrderStatus.MaterialsOrdered",
    ]);

    return (
      <>
        <Col span={8}>
          <CustomParagraph extraStrong size="xs" color="grey">
            {`${t(
              "ADMIN.PROVIDERS.PROFILE.PROJECTS.BUILD.NEW"
            )} ${buildNewCount}`}
          </CustomParagraph>
        </Col>

        <Col span={8}>
          <CustomParagraph extraStrong size="xs" color="grey">
            {`${t(
              "ADMIN.PROVIDERS.PROFILE.PROJECTS.BUILD.APPROVED"
            )} ${buildApprovedCount}`}
          </CustomParagraph>
        </Col>

        <Col span={8}>
          <CustomParagraph extraStrong size="xs" color="grey">
            {`${t(
              "ADMIN.PROVIDERS.PROFILE.PROJECTS.BUILD.MATERIALSORDERED"
            )} ${buildMaterialsOrderedCount}`}
          </CustomParagraph>
        </Col>
      </>
    );
  };

  const getRefusedCount = () => {
    if (isEngineer) {
      return getCount(["DesignOrderStatus.Refused"]);
    }

    return getCount(["BuildOrderStatus.Refused"]);
  };

  const getCompletedCount = () => {
    if (isEngineer) {
      return getCount(["DesignOrderStatus.Completed"]);
    }

    return getCount(["BuildOrderStatus.Completed"]);
  };

  const statusRadioButtons = useMemo<RadioButton<AdminProjectStatus>[]>(
    () => [
      {
        label: t("ADMIN.PROJECTS.STATUS.ONGOING"),
        value: AdminProjectStatus.onGoing,
      },
      {
        label: t("ADMIN.PROJECTS.STATUS.REFUSED"),
        value: AdminProjectStatus.Refused,
      },
      {
        label: t("ADMIN.PROJECTS.STATUS.COMPLETED"),
        value: AdminProjectStatus.Completed,
      },
    ],
    [t]
  );

  const emptyMessage = (() => {
    switch (statusRadioValue) {
      case AdminProjectStatus.onGoing:
        return t("table.empty_note.projects.no_ongoing_projects_provider");
      case AdminProjectStatus.Refused:
        return t("table.empty_note.projects.no_refused_projects_provider");
      case AdminProjectStatus.Completed:
        return t("table.empty_note.projects.no_completed_projects_provider");
      default:
        return "";
    }
  })();

  return (
    <Card className={styles.card}>
      <CustomTitle
        level={3}
        extraStrong
        color="darkBlue"
        align="center"
        className={styles.cardTitle}
      >
        {t("ADMIN.PROVIDERS.PROFILE.PROJECTS.TITLE")}
      </CustomTitle>

      <Row>
        <div className={clsx(styles.container, styles.onGoing)}>
          <Row className={styles.titleRow}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {t("ADMIN.PROVIDERS.PROFILE.PROJECTS.ONGOING")}
            </CustomParagraph>
          </Row>
          <Row className={styles.onGoingCountRow}>{getOnGoingCount()}</Row>
        </div>

        <div className={styles.container}>
          <Row className={styles.titleRow}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {t("ADMIN.PROVIDERS.PROFILE.PROJECTS.REFUSED")}
            </CustomParagraph>
          </Row>

          <Row justify="end">
            <CustomParagraph
              extraStrong
              size="xs"
              color="grey"
              className={styles.singleDigitCount}
            >
              {getRefusedCount()}
            </CustomParagraph>
          </Row>
        </div>

        <div className={styles.container}>
          <Row className={styles.titleRow}>
            <CustomParagraph extraStrong size="xs" color="grey">
              {t("ADMIN.PROVIDERS.PROFILE.PROJECTS.COMPLETED")}
            </CustomParagraph>
          </Row>

          <Row justify="end">
            <CustomParagraph
              extraStrong
              size="xs"
              color="grey"
              className={styles.singleDigitCount}
            >
              {getCompletedCount()}
            </CustomParagraph>
          </Row>
        </div>
      </Row>

      <Row>
        <RadioButtonGroup
          className={styles.radioButtonGroup}
          onChange={(e) => {
            setStatusRadioValue(e);
            setSessionStorageValue(
              SessionStorageKeys.AdminProjectActiveStatus,
              e
            );
          }}
          value={statusRadioValue}
          radioButtons={statusRadioButtons}
          size="large"
        />
      </Row>

      <ProjectTable
        projects={projects}
        loading={loading}
        columns={getTableColumns()}
        emptyMessage={emptyMessage}
      />
    </Card>
  );
};
export default AdminProviderProjectsList;
