import { Descriptions, Divider, Row } from "antd";
import { useTranslation } from "react-i18next";
import Card from "components/card/Card";
import CustomTitle from "components/typography/title/CustomTitle";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { RealEstateFileDto } from "api/generated/optimum";
import { phoneNumberDisplayFormat } from "utils/format";

import styles from "./OfferDetails.module.scss";
import { useSelector } from "app/store";
import useBuild from "../useBuild";
import enumTranslate from "utils/enumTranslate";
import { EnumContext } from "app/i18n/manualLocaleKeys";

const OfferDetails: React.FC = () => {
  const { t } = useTranslation();
  const { currentProject } = useSelector((state) => state.projects);
  const { buildOrder } = useBuild();

  return (
    <Card className={styles.dataCard}>
      <Row justify="center" className={styles.cardTitle}>
        <CustomTitle level={3} color="darkBlue" extraStrong>
          {t("ORDER_DETAILS.BUILD_TITLE")}
        </CustomTitle>
      </Row>

      <Descriptions className={styles.descriptions}>
        <Descriptions.Item
          label={
            <CustomParagraph className={styles.descriptionLabel} extraStrong>
              {t("ORDER_DETAILS.CUSTOMER")}
            </CustomParagraph>
          }
          span={24}
        >
          {currentProject?.customerName}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <CustomParagraph className={styles.descriptionLabel} extraStrong>
              {t("ORDER_DETAILS.PHONENUMBER")}
            </CustomParagraph>
          }
          span={24}
        >
          {phoneNumberDisplayFormat(currentProject?.customerPhoneNumber || "")}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <CustomParagraph className={styles.descriptionLabel} extraStrong>
              {t("ORDER_DETAILS.EMAIL")}
            </CustomParagraph>
          }
          span={24}
        >
          {currentProject?.customerEmailAddress}
        </Descriptions.Item>
      </Descriptions>

      <Divider className={styles.divider} />

      <CustomParagraph className={styles.descriptionLabel} extraStrong>
        {t("ORDER_DETAILS.BUILD_TASK")}
      </CustomParagraph>
      <CustomParagraph className={styles.message}>
        {buildOrder?.taskDescription}
      </CustomParagraph>

      <Divider className={styles.divider} />

      <Descriptions className={styles.descriptions}>
        <Descriptions.Item
          label={
            <CustomParagraph className={styles.descriptionLabel} extraStrong>
              {t("ORDER_DETAILS.BUILD_ADDRESS")}
            </CustomParagraph>
          }
          span={24}
        >
          {`${currentProject?.realEstateZipCode} ${currentProject?.realEstateCity}, ${currentProject?.realEstateAddress}`}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <CustomParagraph className={styles.descriptionLabel} extraStrong>
              {t("ORDER_DETAILS.START_DATE")}
            </CustomParagraph>
          }
          span={24}
        >
          {buildOrder?.preferredStart &&
            enumTranslate(
              buildOrder?.preferredStart,
              EnumContext.PREFERREDSTART
            )}
        </Descriptions.Item>
      </Descriptions>

      <Divider className={styles.divider} />

      <CustomParagraph className={styles.descriptionLabel} extraStrong>
        {t("ORDER_DETAILS.MESSAGE")}
      </CustomParagraph>

      <CustomParagraph className={styles.message}>
        {buildOrder?.message}
      </CustomParagraph>

      <Divider className={styles.divider} />

      <CustomParagraph className={styles.descriptionLabel} extraStrong>
        {t("ORDER_DETAILS.BUILD_FILES")}
      </CustomParagraph>

      {currentProject?.realEstateFiles?.map(
        (file: RealEstateFileDto, i: number) => (
          <a key={i} href={file.downloadUrl || ""} className={styles.files}>
            {(i ? ", " : "") + file.fileName}
          </a>
        )
      )}
    </Card>
  );
};

export default OfferDetails;
