import { mdiChevronDown } from "@mdi/js";
import { Checkbox, Dropdown, Menu, Space } from "antd";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  arrayElementsContainedInAnother,
  someArrayElementsContainedInAnother,
} from "./utils";
import styles from "./useSelectableRowUtils.module.scss";
import { MaterialIcon } from "./components/MaterialIcon";

export enum THREE_STATE_CHECKBOX {
  ALL = "all",
  MINUS = "minus",
  NONE = "none",
}

export interface SelectableRowUtils {
  selectedItems: any[];
  setCurrentPageIds: (
    source: any[],
    currentPage?: number,
    pageSize?: number
  ) => void;
  resetSelection: () => void;
  selectColumnConfig: any;
}

export interface SelectableRowUtilsParams {
  isBackendPagination: boolean;
  defaultPageSize?: number;
}

interface SelectedState {
  selectedItems: any[];
  currentPageIds: any[];
  headerState: {
    status: THREE_STATE_CHECKBOX;
    className: string;
  };
}

interface SelectedStateAction {
  type: string;
  payload: any;
}

const initialSelectedState: SelectedState = {
  selectedItems: [],
  currentPageIds: [],
  headerState: {
    status: THREE_STATE_CHECKBOX.NONE,
    className: "",
  },
};

function reducer(state: SelectedState, action: SelectedStateAction): any {
  switch (action.type) {
    case "add_selection":
      return {
        ...state,
        selectedItems: [...state.selectedItems, ...action.payload],
      };

    case "remove_selection":
      return {
        ...state,
        selectedItems: state.selectedItems.filter(
          (el: any) => !action.payload.includes(el)
        ),
      };

    case "reset_selection":
      return { ...initialSelectedState };

    case "set_current_page_ids":
      return {
        ...state,
        currentPageIds: [...action.payload],
      };

    case "set_header_state":
      return { ...state, headerState: action.payload };

    default:
      throw new Error();
  }
}

export const useSelectableRowUtils = (
  props: SelectableRowUtilsParams
): SelectableRowUtils => {
  const { isBackendPagination, defaultPageSize } = props;
  const { t } = useTranslation();
  const [selectedState, dispatch] = useReducer(reducer, initialSelectedState);
  const { selectedItems, currentPageIds, headerState } = selectedState;

  const [fePaginationPageSize, setFePaginationPageSize] = useState<number>(
    defaultPageSize || 10
  );
  const [fePaginationCurrentPage, setFePaginationCurrentPage] =
    useState<number>(1);

  const resetSelection = useCallback(() => {
    dispatch({ type: "reset_selection", payload: null });
  }, []);

  const removeFromSelection = (ids: any[]): void =>
    dispatch({ type: "remove_selection", payload: ids });

  const addToSelection = (ids: any[]): void =>
    dispatch({ type: "add_selection", payload: ids });

  const setCurrentPageIds = (
    source: any[],
    currentPage?: number,
    pageSize?: number
  ): void => {
    const mappedIds = source.map((el) => el.id);

    if (isBackendPagination) {
      dispatch({ type: "set_current_page_ids", payload: mappedIds });
    } else {
      let take = fePaginationPageSize;
      let skip = take * (fePaginationCurrentPage - 1);

      if (currentPage && pageSize) {
        take = pageSize;
        setFePaginationPageSize(pageSize);
        skip = take * (currentPage - 1);
        setFePaginationCurrentPage(currentPage);
      } else if (
        mappedIds.length <=
        fePaginationPageSize * (fePaginationCurrentPage - 1)
      ) {
        skip = 0;
      }
      const filtered = mappedIds.filter(
        (el, i) => i >= skip && i < skip + take
      );

      dispatch({ type: "set_current_page_ids", payload: filtered });
    }
  };

  const reverseSelection = (): void => {
    const currentPageSelectedIds = currentPageIds.filter((id: any) =>
      selectedItems.includes(id)
    );
    const currentPageNotSelectedIds = currentPageIds.filter(
      (id: any) => !selectedItems.includes(id)
    );
    removeFromSelection(currentPageSelectedIds);
    addToSelection(currentPageNotSelectedIds);
  };

  const setHeaderState = useCallback(() => {
    const isAllCurrentPageIdsSelected = arrayElementsContainedInAnother(
      currentPageIds,
      selectedItems
    );
    const someCurrentPageIdsAreSelected = someArrayElementsContainedInAnother(
      currentPageIds,
      selectedItems
    );

    if (
      selectedItems.length === 0 ||
      (!someCurrentPageIdsAreSelected && !isAllCurrentPageIdsSelected)
    ) {
      dispatch({
        type: "set_header_state",
        payload: {
          status: THREE_STATE_CHECKBOX.NONE,
          className: "",
        },
      });
    } else if (someCurrentPageIdsAreSelected && !isAllCurrentPageIdsSelected) {
      dispatch({
        type: "set_header_state",
        payload: {
          status: THREE_STATE_CHECKBOX.MINUS,
          className: styles.checkboxMinus,
        },
      });
    } else if (isAllCurrentPageIdsSelected) {
      dispatch({
        type: "set_header_state",
        payload: {
          status: THREE_STATE_CHECKBOX.ALL,
          className: "",
        },
      });
    }
  }, [selectedItems, currentPageIds]);

  const headerCheckboxClicked = (): void => {
    if (headerState.status === THREE_STATE_CHECKBOX.NONE) {
      addToSelection(currentPageIds);
    } else {
      removeFromSelection(currentPageIds);
    }
  };

  const headerContainer = (
    <Space>
      <Checkbox
        checked={headerState.status !== THREE_STATE_CHECKBOX.NONE}
        className={headerState.className}
        onChange={headerCheckboxClicked}
      />
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="table_select_reverse" onClick={reverseSelection}>
              {t("common.selection.reverse")}
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <MaterialIcon path={mdiChevronDown} />
      </Dropdown>
    </Space>
  );

  const selectColumnConfig = {
    key: "selectCheckboxes",
    title: headerContainer,
    width: 60,
    render(record: any) {
      return (
        <Checkbox
          checked={selectedItems.includes(record.id)}
          onChange={(e) => {
            if (e.target.checked) {
              addToSelection([record.id]);
            } else {
              removeFromSelection([record.id]);
            }
          }}
        />
      );
    },
  };

  useEffect(() => {
    setHeaderState();
  }, [setHeaderState]);

  return {
    selectedItems,
    selectColumnConfig,
    setCurrentPageIds,
    resetSelection,
  };
};
