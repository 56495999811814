/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UpdateBuildMaterialDto,
    UpdateBuildMaterialDtoFromJSON,
    UpdateBuildMaterialDtoFromJSONTyped,
    UpdateBuildMaterialDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateBuildOrderMaterialsCommand
 */
export interface UpdateBuildOrderMaterialsCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateBuildOrderMaterialsCommand
     */
    buildOrderId: number;
    /**
     * 
     * @type {Date}
     * @memberof UpdateBuildOrderMaterialsCommand
     */
    paymentDueDate?: Date | null;
    /**
     * 
     * @type {Array<UpdateBuildMaterialDto>}
     * @memberof UpdateBuildOrderMaterialsCommand
     */
    materials: Array<UpdateBuildMaterialDto>;
}

export function UpdateBuildOrderMaterialsCommandFromJSON(json: any): UpdateBuildOrderMaterialsCommand {
    return UpdateBuildOrderMaterialsCommandFromJSONTyped(json, false);
}

export function UpdateBuildOrderMaterialsCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBuildOrderMaterialsCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buildOrderId': json['buildOrderId'],
        'paymentDueDate': !exists(json, 'paymentDueDate') ? undefined : (json['paymentDueDate'] === null ? null : new Date(json['paymentDueDate'])),
        'materials': ((json['materials'] as Array<any>).map(UpdateBuildMaterialDtoFromJSON)),
    };
}

export function UpdateBuildOrderMaterialsCommandToJSON(value?: UpdateBuildOrderMaterialsCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buildOrderId': value.buildOrderId,
        'paymentDueDate': value.paymentDueDate === undefined ? undefined : (value.paymentDueDate === null ? null : value.paymentDueDate.toISOString()),
        'materials': ((value.materials as Array<any>).map(UpdateBuildMaterialDtoToJSON)),
    };
}


