import { Row } from "antd";
import {
  DesignMaterialDto,
  DesignOrderStatus,
  MaterialCategories,
} from "api/generated/optimum";
import { useDispatch, useSelector } from "app/store";
import CustomButton from "components/button/CustomButton";
import ConfirmModal from "components/confirmModal/ConfirmModal";

import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isNgbs, isProvider } from "utils/authUtils";
import MaterialListModal from "../../components/materialListModal/MaterialListModal";
import styles from "../../components/materialListModal/MaterialListModal.module.scss";
import useMaterialTableProps from "./useMaterialTableProps";

const DesignMaterialListModal: FC<{
  visible: boolean;
  orderId: number;
  onClose: () => void;
}> = ({ visible, orderId, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    materialList,
    availableMaterials,
    currentProject,
    isMaterialUpdated,
  } = useSelector((state) => state.projects);

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const actualList =
    !isNgbs() ||
    currentProject?.designOrderStatus === DesignOrderStatus.Completed
      ? materialList
      : availableMaterials;

  const heatGenerationData = (actualList as DesignMaterialDto[]).filter(
    (x) => x.category === MaterialCategories.HeatGeneration
  );

  const heatReleaseData = (actualList as DesignMaterialDto[]).filter(
    (x) => x.category === MaterialCategories.HeatRelease
  );

  const controlData = (actualList as DesignMaterialDto[]).filter(
    (x) => x.category === MaterialCategories.Control
  );

  const toolsData = (actualList as DesignMaterialDto[]).filter(
    (x) => x.category === MaterialCategories.Tools
  );

  useEffect(() => {
    orderId && dispatch.projects.getMaterials(orderId);
    isProvider() && dispatch.projects.getAvailableMaterials();
  }, [orderId, dispatch]);

  const { tableProps } = useMaterialTableProps();

  return (
    <MaterialListModal
      visible={visible}
      heatGenerationData={heatGenerationData}
      heatReleaseData={heatReleaseData}
      controlData={controlData}
      toolsData={toolsData}
      tableProps={tableProps}
    >
      <>
        <Row justify="center" className={styles.buttonContainerRow}>
          {!isNgbs() ||
          currentProject?.designOrderStatus === DesignOrderStatus.Completed ? (
            <CustomButton
              type="primary"
              onClick={onClose}
              className={styles.closeButton}
              variant="default"
            >
              {t("COMMON.CLOSE")}
            </CustomButton>
          ) : (
            <div className={styles.buttonContainer}>
              <CustomButton
                onClick={() => {
                  if (isMaterialUpdated) {
                    setIsConfirmModalVisible(true);
                    return;
                  }

                  onClose();
                }}
                className={styles.cancelButton}
                variant="default"
              >
                {t("COMMON.CANCEL")}
              </CustomButton>
              <CustomButton
                type="primary"
                onClick={async () => {
                  await dispatch.projects.updateMaterials({
                    designOrderId: orderId,
                    designOrderMaterials: materialList.map((material) => ({
                      materialId: material.materialId!,
                      amount: material.amount!,
                    })),
                  });

                  await Promise.all([
                    dispatch.projects.getMaterials(orderId),
                    currentProject?.projectCode &&
                      dispatch.projects.getProject({
                        projectCode: currentProject?.projectCode,
                      }),
                  ]);

                  onClose();
                }}
                className={styles.saveButton}
                variant="default"
              >
                {t("COMMON.SAVE")}
              </CustomButton>
            </div>
          )}
        </Row>

        <ConfirmModal
          isVisible={isConfirmModalVisible}
          onClose={() => setIsConfirmModalVisible(false)}
          onSubmit={async () => {
            await setIsConfirmModalVisible(false);
            onClose();
          }}
          title={t(
            "PROJECTS.DETAIL.DESIGN.MATERIAL.CONFIRM_MODAL.UNSAVED_CHANGES_TITLE"
          )}
          description={t("PROJECTS.DETAIL.DESIGN.MATERIAL.CONFIRM_MODAL.DESC")}
          submitBtnLabel={t(
            "PROJECTS.DETAIL.DESIGN.MATERIAL.CONFIRM_MODAL.REJECT"
          )}
        />
      </>
    </MaterialListModal>
  );
};

export default DesignMaterialListModal;
