import { DesignOrderStatus, ProjectListDto } from "api/generated/optimum";
import i18n from "app/i18n";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import clsx from "clsx";
import moment from "moment";
import enumTranslate from "utils/enumTranslate";
import { formatDate } from "utils/format";
import styles from "./ProjectTable.module.scss";
import { SortOrder } from "antd/lib/table/interface";
import { compareStrings } from "components/GrapeAntdTable/utils";
import { isAdmin, isEngineer } from "utils/authUtils";

const realEstateAddress = {
  title: i18n.t("ADMIN.PROJECTS.TABLE.ADDRESS"),
  dataIndex: "realEstateAddress",
  sorter: {
    compare: (a: ProjectListDto, b: ProjectListDto) =>
      compareStrings(a.realEstateZipCode, b.realEstateZipCode),
  },
  width: "20%",
  className: styles.firstColumn,
  render: (value: string, row: ProjectListDto) => {
    return (
      <div
        className={clsx(
          styles.order,
          isAdmin() && {
            [styles.designOrder]:
              row.projectStatus?.includes("DesignOrderStatus"),
            [styles.builderOrder]:
              row.projectStatus?.includes("BuildOrderStatus"),
          }
        )}
      >
        <div>
          {row.realEstateZipCode} {row.realEstateCity}
        </div>
        <div>{value}</div>
      </div>
    );
  },
};

const modifiedDate = {
  title: i18n.t("ADMIN.PROJECTS.TABLE.MODIFIED_DATE"),
  dataIndex: "modifiedDate",
  render: (value: Date) => formatDate.dateTime(value),
  defaultSortOrder: "descend" as SortOrder,
  sorter: {
    compare: (a: ProjectListDto, b: ProjectListDto) =>
      moment(a.modifiedDate).unix() - moment(b.modifiedDate).unix(),
  },
  width: "20%",
};

const projectCode = {
  title: i18n.t("ADMIN.PROJECTS.TABLE.PROJECT_CODE"),
  dataIndex: "projectCode",
  sorter: {
    compare: (a: ProjectListDto, b: ProjectListDto) =>
      compareStrings(a.projectCode, b.projectCode),
  },
  width: "20%",
};

const responsible = {
  title: i18n.t("ADMIN.PROJECTS.TABLE.RESPONSIBLE"),
  dataIndex: "responsibleName",
  sorter: {
    compare: (a: ProjectListDto, b: ProjectListDto) =>
      compareStrings(a.responsibleName, b.responsibleName),
  },
  render: (value: string, row: ProjectListDto) =>
    row.projectStatus === "BuildOrderStatus.New" ? "-" : value,
  width: "20%",
};

export const onGoingColumns = [
  realEstateAddress,
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.STATUS"),
    dataIndex: "projectStatus",
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        compareStrings(
          enumTranslate(a.projectStatus!, EnumContext.PROJECT_STATUS),
          enumTranslate(b.projectStatus!, EnumContext.PROJECT_STATUS)
        ),
    },
    render: (value: string) => enumTranslate(value, EnumContext.PROJECT_STATUS),
    width: "20%",
  },
  // for Engineer and NGBS users only
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.STATUS"),
    dataIndex: "designOrderStatus",
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        compareStrings(
          enumTranslate(a.designOrderStatus!, EnumContext.DESIGN_ORDER_STATUS),
          enumTranslate(b.designOrderStatus!, EnumContext.DESIGN_ORDER_STATUS)
        ),
    },
    render: (value: string) => {
      if (isEngineer() && value === DesignOrderStatus.OptiComfortNew) {
        return enumTranslate(
          DesignOrderStatus.OptiComfortWip,
          EnumContext.DESIGN_ORDER_STATUS
        );
      }
      return enumTranslate(value, EnumContext.DESIGN_ORDER_STATUS);
    },
    width: "20%",
  },
  projectCode,
  responsible,
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.ORDER_REQUEST_DATE"),
    dataIndex: "lastOrderRequestDate",
    render: (value: Date) => formatDate.date(value),
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        moment(a.lastOrderRequestDate).unix() -
        moment(b.lastOrderRequestDate).unix(),
    },
    width: "10%",
  },
  // for Engineer and NGBS users only
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.ORDER_REQUEST_DATE"),
    dataIndex: "designOrderCreationDate",
    render: (value: Date) => formatDate.date(value),
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        moment(a.designOrderCreationDate).unix() -
        moment(b.designOrderCreationDate).unix(),
    },
    width: "10%",
  },
  modifiedDate,
];

export const refusedColumns = [
  realEstateAddress,
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.REFUSEAL_REASON"),
    dataIndex: "designRefusalReason",
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        compareStrings(a.designRefusalReason, b.designRefusalReason),
    },
    width: "20%",
    render: (value: string) =>
      enumTranslate(value, EnumContext.DESIGN_REFUSAL_REASON),
  },
  projectCode,
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.REFUSED_BY"),
    dataIndex: "projectStatusChangedByName",
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        compareStrings(
          a.projectStatusChangedByName,
          b.projectStatusChangedByName
        ),
    },
    width: "20%",
    render: (value: string) => value || "-",
  },
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.ORDER_REQUEST_DATE"),
    dataIndex: "lastOrderRequestDate",
    render: (value: Date) => formatDate.date(value),
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        moment(a.lastOrderRequestDate).unix() -
        moment(b.lastOrderRequestDate).unix(),
    },
    width: "10%",
  },
  // for Engineer and NGBS users only
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.ORDER_REQUEST_DATE"),
    dataIndex: "designOrderCreationDate",
    render: (value: Date) => formatDate.date(value),
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        moment(a.designOrderCreationDate).unix() -
        moment(b.designOrderCreationDate).unix(),
    },
    width: "10%",
  },
  modifiedDate,
];

export const completedColumns = [
  realEstateAddress,
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.STATUS"),
    dataIndex: "projectStatus",
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        compareStrings(
          enumTranslate(a.projectStatus!, EnumContext.PROJECT_STATUS),
          enumTranslate(b.projectStatus!, EnumContext.PROJECT_STATUS)
        ),
    },
    render: (value: string) => enumTranslate(value, EnumContext.PROJECT_STATUS),
    width: "20%",
  },
  projectCode,
  responsible,
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.ORDER_REQUEST_DATE"),
    dataIndex: "lastOrderRequestDate",
    render: (value: Date) => formatDate.date(value),
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        moment(a.lastOrderRequestDate).unix() -
        moment(b.lastOrderRequestDate).unix(),
    },
    width: "10%",
  },
  // for Engineer and NGBS users only
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.ORDER_REQUEST_DATE"),
    dataIndex: "designOrderCreationDate",
    render: (value: Date) => formatDate.date(value),
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        moment(a.designOrderCreationDate).unix() -
        moment(b.designOrderCreationDate).unix(),
    },
    width: "10%",
  },
  {
    title: i18n.t("ADMIN.PROJECTS.TABLE.CLOSE_DATE"),
    dataIndex: "modifiedDate",
    render: (value: Date) => formatDate.dateTime(value),
    defaultSortOrder: "descend" as SortOrder,
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        moment(a.modifiedDate).unix() - moment(b.modifiedDate).unix(),
    },
    width: "20%",
  },
  // for Engineer and NGBS users only
  {
    title: i18n.t("PROJECTS.TABLE.CLOSE_DATE"),
    dataIndex: "designOrderCompletionDate",
    render: (value: Date) => formatDate.dateTime(value),
    defaultSortOrder: "descend" as SortOrder,
    sorter: {
      compare: (a: ProjectListDto, b: ProjectListDto) =>
        moment(a.designOrderCompletionDate).unix() -
        moment(b.designOrderCompletionDate).unix(),
    },
    width: "20%",
  },
];
