import { createModel } from "@rematch/core";
import { EngineerDto, RealEstateFileType } from "api/generated/optimum";
import { RealEstateType } from "models/RealEstateType";
import { RootModel, store } from "../../app/store";
import { api } from "api";
import { CreateDesign } from "models/CreateDesign";

interface EngineerState {
  engineers?: EngineerDto[];
  zipCode?: string;
  selectedEngineer?: EngineerDto;
  currentStep: number;
  wizardData: CreateDesign[];
  realEstateType?: RealEstateType;
  designOrderId?: number;
}

export const INITIAL_CURRENT_STEP = -1;

const initialState: EngineerState = {
  currentStep: INITIAL_CURRENT_STEP,
  engineers: undefined,
  zipCode: undefined,
  selectedEngineer: undefined,
  realEstateType: undefined,
  wizardData: [],
  designOrderId: undefined,
};

export const engineer = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEngineersList(state, engineerList: EngineerDto[]) {
      state.engineers = engineerList;
    },
    selectEngineer(state, engineer: EngineerDto) {
      state.selectedEngineer = engineer;
    },
    unSelectEngineer(state) {
      state.selectedEngineer = undefined;
    },
    setZipCode(state, zipCode: string) {
      state.zipCode = zipCode;
    },
    setRealEstateType(state, realEstateType: RealEstateType) {
      state.realEstateType = realEstateType;
      state.currentStep = state.currentStep + 1;
    },
    setWizardData(state, data, step?: number) {
      state.wizardData[step || state.currentStep] = data;
    },
    nextStep(state) {
      state.currentStep = state.currentStep + 1;
    },
    prevStep(state) {
      state.currentStep = state.currentStep - 1;
    },
    resetWizardData: (state) => {
      state.currentStep = initialState.currentStep;
      state.realEstateType = initialState.realEstateType;
      state.wizardData = initialState.wizardData;
      state.selectedEngineer = initialState.selectedEngineer;
    },
    setDesignOrderId(state, orderId: number) {
      state.designOrderId = orderId;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    return {
      getEngineers: async (zip: string): Promise<void> => {
        const engineers = await api.engineers.getEngineer({ zip });

        dispatch.engineer.setEngineersList(engineers);
        dispatch.engineer.setZipCode(zip);
      },
      createDesignOder: async () => {
        const { wizardData, selectedEngineer, zipCode, realEstateType } =
          store.getState().engineer;

        if (!selectedEngineer?.id || !zipCode) return;

        const contactDetail = wizardData[2];
        const { files, ...realEstateDetail } = wizardData[1];

        const designOrderId = await api.designOrders.createDesignOrder({
          createDesignOrderCommand: {
            ...contactDetail,
            ...realEstateDetail,
            engineerId: selectedEngineer.id,
            heatEmitterOptions: realEstateDetail.heatEmitterOptions || [],
            isNew: realEstateType === RealEstateType.NewlyBuilt,
          },
        });

        dispatch.engineer.setDesignOrderId(designOrderId);

        if (files?.length) {
          await Promise.all(
            files.map((file) =>
              api.realEstateFiles.uploadRealEstateFile({
                designOrderId,
                type: RealEstateFileType.Design,
                file: file.originFileObj,
              })
            )
          );
        }
      },
    };
  },
});
