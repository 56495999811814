import { Modal, ModalProps } from "antd";
import { FC } from "react";

interface ModalPropsExtended extends ModalProps {
  loading?: boolean;
  className?: string;
  closable?: boolean;
}

const CustomModal: FC<ModalPropsExtended> = ({
  children,
  loading,
  className,
  closable = true,
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      maskClosable={false}
      footer={null}
      className={className}
      confirmLoading={loading}
      closable={closable}
      keyboard={closable}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
