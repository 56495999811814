import { Col, Row } from "antd";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ProjectDetailsHeader.module.scss";
import CustomTitle from "components/typography/title/CustomTitle";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { ProjectDto } from "api/generated/optimum";
import { formatDate, phoneNumberDisplayFormat } from "utils/format";
import { useNavigate } from "react-router-dom";
import { MaterialIcon } from "components/GrapeAntdTable/components/MaterialIcon";
import { mdiChevronLeft } from "@mdi/js";
import { isAdmin, isEngineer, isNgbs } from "utils/authUtils";
import clsx from "clsx";
import useBuild from "../../build/useBuild";

export interface ProjectDetailsHeaderProps {
  logo: JSX.Element;
  project?: ProjectDto;
}

const ProjectDetailsHeader: FC<ProjectDetailsHeaderProps> = ({
  logo,
  project,
}) => {
  const { t } = useTranslation();
  const { buildOrder } = useBuild();
  const navigate = useNavigate();

  const CustomerDetail = useCallback(
    () => (
      <>
        <Col>
          <CustomParagraph size="sm" className={styles.headerTitle} extraStrong>
            {isNgbs()
              ? t("PROJECT_DETAILS.HEADER.PROVIDER")
              : t("PROJECT_DETAILS.HEADER.CUSTOMER")}
          </CustomParagraph>
          <CustomParagraph size="sm">
            {isNgbs()
              ? `${project?.engineer?.lastName} ${project?.engineer?.firstName}`
              : project?.customerName}
          </CustomParagraph>
        </Col>
        <Col>
          <CustomParagraph size="sm" className={styles.headerTitle} extraStrong>
            {isNgbs()
              ? t("PROJECT_DETAILS.HEADER.PROVIDER_PHONE")
              : t("PROJECT_DETAILS.HEADER.PHONE_NUMBER")}
          </CustomParagraph>
          <CustomParagraph size="sm">
            {phoneNumberDisplayFormat(
              (isNgbs()
                ? project?.engineer?.phoneNumber
                : project?.customerPhoneNumber) || ""
            )}
          </CustomParagraph>
        </Col>
      </>
    ),
    [
      t,
      project?.customerName,
      project?.engineer?.lastName,
      project?.engineer?.firstName,
      project?.engineer?.phoneNumber,
      project?.customerPhoneNumber,
    ]
  );

  const projectStatusLabel = useMemo(() => {
    switch (project?.projectStatus) {
      case "DesignOrderStatus.Refused":
        return isAdmin() || isEngineer() ? (
          <Row justify="center">
            <div className={clsx(styles.projectStatus, styles.refused)}>
              {t("PROJECT_DETAILS.HEADER.REFUSED_PROJECT")}
            </div>
          </Row>
        ) : null;
      case "DesignOrderStatus.Completed":
        return isAdmin() ? (
          <Row justify="center">
            <div className={clsx(styles.projectStatus, styles.completed)}>
              {t("PROJECT_DETAILS.HEADER.CLOSED_PROJECT")}
            </div>
          </Row>
        ) : null;
      default:
        return null;
    }
  }, [project?.projectStatus, t]);

  return (
    <>
      <div
        className={styles.navigationLink}
        onClick={() => {
          if (isAdmin()) {
            navigate(-1);
          } else {
            navigate("/projects");
          }
        }}
      >
        <MaterialIcon path={mdiChevronLeft} />
        {t("PROJECT_DETAILS.BACK")}
      </div>

      <Row justify="center">{logo}</Row>
      <Row justify="center">
        <CustomTitle level={1} color="grey" className={styles.addressTitle}>
          {`${project?.realEstateZipCode} ${project?.realEstateCity}, ${project?.realEstateAddress}`}
        </CustomTitle>
      </Row>

      {projectStatusLabel}

      <Row justify="space-between" className={styles.customerDetails}>
        <Col>
          <CustomParagraph size="sm" className={styles.headerTitle} extraStrong>
            {t("PROJECT_DETAILS.HEADER.PROJECTCODE")}
          </CustomParagraph>
          <CustomParagraph size="sm">{project?.projectCode}</CustomParagraph>
        </Col>

        <CustomerDetail />

        <Col>
          <CustomParagraph size="sm" className={styles.headerTitle} extraStrong>
            {t("PROJECT_DETAILS.HEADER.TYPE")}
          </CustomParagraph>
          <CustomParagraph size="sm">
            {project?.realEstateIsNew
              ? t("PROJECT_DETAILS.HEADER.NEWLY_BUILT")
              : t("PROJECT_DETAILS.HEADER.AWAITING_RENOVATION")}
          </CustomParagraph>
        </Col>
        <Col>
          <CustomParagraph size="sm" className={styles.headerTitle} extraStrong>
            {t("PROJECT_DETAILS.HEADER.CREATION_DATE")}
          </CustomParagraph>
          <CustomParagraph size="sm">
            {buildOrder
              ? buildOrder.createdAt && formatDate.date(buildOrder.createdAt)
              : project?.designOrderCreatedAt &&
                formatDate.date(project.designOrderCreatedAt)}
          </CustomParagraph>
        </Col>
      </Row>
    </>
  );
};

export default ProjectDetailsHeader;
