/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PreferredStart,
    PreferredStartFromJSON,
    PreferredStartFromJSONTyped,
    PreferredStartToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateBuildOrderCommand
 */
export interface CreateBuildOrderCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBuildOrderCommand
     */
    builderIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateBuildOrderCommand
     */
    projectCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBuildOrderCommand
     */
    taskDescription: string;
    /**
     * 
     * @type {PreferredStart}
     * @memberof CreateBuildOrderCommand
     */
    preferredStart: PreferredStart;
    /**
     * 
     * @type {string}
     * @memberof CreateBuildOrderCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBuildOrderCommand
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBuildOrderCommand
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBuildOrderCommand
     */
    note?: string | null;
}

export function CreateBuildOrderCommandFromJSON(json: any): CreateBuildOrderCommand {
    return CreateBuildOrderCommandFromJSONTyped(json, false);
}

export function CreateBuildOrderCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBuildOrderCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'builderIds': json['builderIds'],
        'projectCode': json['projectCode'],
        'taskDescription': json['taskDescription'],
        'preferredStart': PreferredStartFromJSON(json['preferredStart']),
        'name': json['name'],
        'emailAddress': json['emailAddress'],
        'phoneNumber': json['phoneNumber'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function CreateBuildOrderCommandToJSON(value?: CreateBuildOrderCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'builderIds': value.builderIds,
        'projectCode': value.projectCode,
        'taskDescription': value.taskDescription,
        'preferredStart': PreferredStartToJSON(value.preferredStart),
        'name': value.name,
        'emailAddress': value.emailAddress,
        'phoneNumber': value.phoneNumber,
        'note': value.note,
    };
}


