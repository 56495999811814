import { Radio } from "antd";
import clsx from "clsx";
import { isAdmin, isBuilder, isNgbs } from "utils/authUtils";
import styles from "./RadioButton.module.scss";

export interface RadioButton<T> {
  label: string;
  value: T;
  disabled?: boolean;
}

interface RadioButtonGroupProps {
  radioButtons: RadioButton<any>[];
  onChange: (value: React.SetStateAction<any>) => void;
  value: any;
  className?: string;
  size?: FontSize;
  theme?: Theme;
}

type FontSize = "small" | "large";

const fontSizeClasses: { [size in FontSize]: string } = {
  small: styles.small,
  large: styles.large,
};

type Theme = "default" | "admin" | "ngbs" | "builder";

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  radioButtons,
  value,
  onChange,
  className,
  size = "small",
}) => {
  return (
    <Radio.Group
      onChange={(e) => onChange(e.target.value)}
      value={value}
      buttonStyle="solid"
      className={clsx(
        styles.radioGroup,
        fontSizeClasses[size],
        {
          [styles.admin]: isAdmin(),
          [styles.ngbs]: isNgbs(),
          [styles.builder]: isBuilder(),
        },
        className
      )}
    >
      {radioButtons.map((button, index: number) => (
        <Radio.Button
          key={index}
          value={button.value}
          disabled={button.disabled}
        >
          {button.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default RadioButtonGroup;
