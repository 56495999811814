import { Descriptions } from "antd";
import Card from "components/card/Card";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { phoneNumberDisplayFormat } from "utils/format";
import styles from "./AdministrationCard.module.scss";

export const AdministrationCard: React.FC<{
  displayName?: string;
  phoneNumber?: string | null;
  email?: string | null;
  providerUrl?: string;
}> = ({ displayName, phoneNumber, email, providerUrl }) => {
  const { t } = useTranslation();

  const location = useLocation();

  return (
    <Card className={styles.card}>
      <CustomTitle
        level={3}
        extraStrong
        color="darkBlue"
        align="center"
        className={styles.cardTitle}
      >
        {t("PROJECTS.DETAIL.ADMIN.ADMINISTRATION.TITLE")}
      </CustomTitle>

      <Descriptions layout="vertical">
        <Descriptions.Item
          label={
            <CustomParagraph color="grey" extraStrong className={styles.label}>
              {t("PROJECTS.DETAIL.ADMIN.ADMINISTRATION.PROVIDER")}
            </CustomParagraph>
          }
          span={24}
          className={styles.description}
        >
          {providerUrl && (
            <NavLink
              to={providerUrl}
              state={{
                from: location.pathname,
              }}
            >
              {displayName}
            </NavLink>
          )}

          <CustomParagraph color="grey">
            {phoneNumber && phoneNumberDisplayFormat(phoneNumber)}
          </CustomParagraph>

          <CustomParagraph color="grey">{email}</CustomParagraph>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
export default AdministrationCard;
