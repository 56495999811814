import { Middleware, RequestContext } from "api/generated/optimum";
import { getLocalStorageValue, LocalStorageKeys } from "utils/storageHandler";

export const getAuthorizationHeader = (): string =>
  `Bearer ${getLocalStorageValue(LocalStorageKeys.AccessToken)}`;

export const requestHeadersMiddleware: Middleware[] = [
  {
    pre: async (ctx: RequestContext): Promise<void> => {
      ctx.init.headers = {
        ...ctx.init.headers,
        Authorization: getAuthorizationHeader(),
      };

      return Promise.resolve();
    },
  },
];
