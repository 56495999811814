import { Timeline } from "antd";
import CustomTitle from "components/typography/title/CustomTitle";
import { BUILDER_SEARCH_URL } from "constants/urls";
import React, { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./EngineerTimeLine.module.scss";

interface TimeLineItem {
  subTitle?: string;
  description?: string[];
}
interface TimeLine {
  title: string | JSX.Element;
  items?: TimeLineItem[];
}

const EngineerTimeLine: FC = () => {
  const { t } = useTranslation();
  const timeLineList = useMemo<TimeLine[]>(
    () => [
      {
        title: t("ENGINEERS.ENGINEERS_LIST.TIMELINE.AVAILABLE_ENGINEERS.TITLE"),
        items: [
          {
            subTitle: t(
              "ENGINEERS.ENGINEERS_LIST.TIMELINE.AVAILABLE_ENGINEERS.SUB_TITLE"
            ),
            description: [
              t(
                "ENGINEERS.ENGINEERS_LIST.TIMELINE.AVAILABLE_ENGINEERS.ZIP_CODE"
              ),
              t(
                "ENGINEERS.ENGINEERS_LIST.TIMELINE.AVAILABLE_ENGINEERS.LIST_ENGINEERS"
              ),
            ],
          },
          {
            subTitle: t("ENGINEERS.ENGINEERS_LIST.TIMELINE.CONTACT.SUB_TITLE"),
            description: [
              t("ENGINEERS.ENGINEERS_LIST.TIMELINE.CONTACT.FILL_FORM"),
              t("ENGINEERS.ENGINEERS_LIST.TIMELINE.CONTACT.POST_DATA"),
            ],
          },
        ],
      },
      {
        title: t("ENGINEERS.ENGINEERS_LIST.TIMELINE.CONTRACTING.TITLE"),
        items: [
          {
            subTitle: t(
              "ENGINEERS.ENGINEERS_LIST.TIMELINE.CONTRACTING.SUB_TITLE"
            ),
            description: [
              t("ENGINEERS.ENGINEERS_LIST.TIMELINE.CONTRACTING.REPLY"),
              t("ENGINEERS.ENGINEERS_LIST.TIMELINE.CONTRACTING.CONTRACT"),
            ],
          },
          {
            subTitle: t(
              "ENGINEERS.ENGINEERS_LIST.TIMELINE.PREPARATION.SUB_TITLE"
            ),
            description: [
              t("ENGINEERS.ENGINEERS_LIST.TIMELINE.PREPARATION.CREATE_DESIGN"),
              t("ENGINEERS.ENGINEERS_LIST.TIMELINE.PREPARATION.SEND_DESIGN"),
            ],
          },
        ],
      },
      {
        title: (
          <Trans i18nKey="ENGINEERS.ENGINEERS_LIST.TIMELINE.BUILDER.TITLE">
            <a href={BUILDER_SEARCH_URL} />
          </Trans>
        ),
      },
    ],
    [t]
  );

  return (
    <Timeline className={styles.timeLine}>
      {timeLineList.map((timeLine: TimeLine, i: number) => (
        <Timeline.Item key={i}>
          <CustomTitle
            level={4}
            color="darkBlue"
            className={styles.timeLineTitle}
            extraStrong
          >
            {timeLine.title}
          </CustomTitle>

          <div className={styles.timeLineDetails}>
            {timeLine.items?.map((timeLineItem: TimeLineItem, i: number) => (
              <div key={i}>
                <CustomTitle
                  level={4}
                  color="lightBlue"
                  className={styles.timeLineSubTitle}
                >
                  {timeLineItem.subTitle}
                </CustomTitle>
                <ol>
                  {timeLineItem.description?.map(
                    (desc: string, index: number) => (
                      <li className={styles.listElement} key={index}>
                        {desc}
                      </li>
                    )
                  )}
                </ol>
              </div>
            ))}
          </div>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default React.memo(EngineerTimeLine);
