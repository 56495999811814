/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountyDto,
    CountyDtoFromJSON,
    CountyDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SpecializationCategory,
    SpecializationCategoryFromJSON,
    SpecializationCategoryToJSON,
    SpecializationDto,
    SpecializationDtoFromJSON,
    SpecializationDtoToJSON,
    TagDto,
    TagDtoFromJSON,
    TagDtoToJSON,
} from '../models';

export interface GetSpecializationsRequest {
    categories?: Array<SpecializationCategory>;
}

/**
 * 
 */
export class ReferenceDataApi extends runtime.BaseAPI {

    /**
     * Returns Counties of the application
     */
    async getCountiesRaw(): Promise<runtime.ApiResponse<Array<CountyDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReferenceData/Counties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CountyDtoFromJSON));
    }

    /**
     * Returns Counties of the application
     */
    async getCounties(): Promise<Array<CountyDto>> {
        const response = await this.getCountiesRaw();
        return await response.value();
    }

    /**
     * Returns the list of the valid Heat pump identifiers
     */
    async getHeatPumpIdentifiersRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReferenceData/HeatPumpIdentifiers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns the list of the valid Heat pump identifiers
     */
    async getHeatPumpIdentifiers(): Promise<Array<string>> {
        const response = await this.getHeatPumpIdentifiersRaw();
        return await response.value();
    }

    /**
     * Returns Specializations of the application
     */
    async getSpecializationsRaw(requestParameters: GetSpecializationsRequest): Promise<runtime.ApiResponse<Array<SpecializationDto>>> {
        const queryParameters: any = {};

        if (requestParameters.categories) {
            queryParameters['categories'] = requestParameters.categories;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReferenceData/Specializations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecializationDtoFromJSON));
    }

    /**
     * Returns Specializations of the application
     */
    async getSpecializations(requestParameters: GetSpecializationsRequest): Promise<Array<SpecializationDto>> {
        const response = await this.getSpecializationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns Tags of the application
     */
    async getTagsRaw(): Promise<runtime.ApiResponse<Array<TagDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReferenceData/Tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagDtoFromJSON));
    }

    /**
     * Returns Tags of the application
     */
    async getTags(): Promise<Array<TagDto>> {
        const response = await this.getTagsRaw();
        return await response.value();
    }

}
