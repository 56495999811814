/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BoilerOptions,
    BoilerOptionsFromJSON,
    BoilerOptionsFromJSONTyped,
    BoilerOptionsToJSON,
    HeatEmitterOptions,
    HeatEmitterOptionsFromJSON,
    HeatEmitterOptionsFromJSONTyped,
    HeatEmitterOptionsToJSON,
    HomeBuildingPhaseOptions,
    HomeBuildingPhaseOptionsFromJSON,
    HomeBuildingPhaseOptionsFromJSONTyped,
    HomeBuildingPhaseOptionsToJSON,
    InsulationOptions,
    InsulationOptionsFromJSON,
    InsulationOptionsFromJSONTyped,
    InsulationOptionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateDesignOrderCommand
 */
export interface CreateDesignOrderCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateDesignOrderCommand
     */
    engineerId: string;
    /**
     * 
     * @type {InsulationOptions}
     * @memberof CreateDesignOrderCommand
     */
    insulationOptions: InsulationOptions;
    /**
     * 
     * @type {HomeBuildingPhaseOptions}
     * @memberof CreateDesignOrderCommand
     */
    homeBuildingPhaseOptions?: HomeBuildingPhaseOptions;
    /**
     * 
     * @type {BoilerOptions}
     * @memberof CreateDesignOrderCommand
     */
    boilerOptions?: BoilerOptions;
    /**
     * 
     * @type {Array<HeatEmitterOptions>}
     * @memberof CreateDesignOrderCommand
     */
    heatEmitterOptions?: Array<HeatEmitterOptions> | null;
    /**
     * 
     * @type {number}
     * @memberof CreateDesignOrderCommand
     */
    heatedSquareMs: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDesignOrderCommand
     */
    headCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDesignOrderCommand
     */
    isNew?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDesignOrderCommand
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDesignOrderCommand
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDesignOrderCommand
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDesignOrderCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDesignOrderCommand
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDesignOrderCommand
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDesignOrderCommand
     */
    note?: string | null;
}

export function CreateDesignOrderCommandFromJSON(json: any): CreateDesignOrderCommand {
    return CreateDesignOrderCommandFromJSONTyped(json, false);
}

export function CreateDesignOrderCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDesignOrderCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'engineerId': json['engineerId'],
        'insulationOptions': InsulationOptionsFromJSON(json['insulationOptions']),
        'homeBuildingPhaseOptions': !exists(json, 'homeBuildingPhaseOptions') ? undefined : HomeBuildingPhaseOptionsFromJSON(json['homeBuildingPhaseOptions']),
        'boilerOptions': !exists(json, 'boilerOptions') ? undefined : BoilerOptionsFromJSON(json['boilerOptions']),
        'heatEmitterOptions': !exists(json, 'heatEmitterOptions') ? undefined : (json['heatEmitterOptions'] === null ? null : (json['heatEmitterOptions'] as Array<any>).map(HeatEmitterOptionsFromJSON)),
        'heatedSquareMs': json['heatedSquareMs'],
        'headCount': json['headCount'],
        'isNew': !exists(json, 'isNew') ? undefined : json['isNew'],
        'zipCode': json['zipCode'],
        'city': json['city'],
        'address': json['address'],
        'name': json['name'],
        'emailAddress': json['emailAddress'],
        'phoneNumber': json['phoneNumber'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function CreateDesignOrderCommandToJSON(value?: CreateDesignOrderCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'engineerId': value.engineerId,
        'insulationOptions': InsulationOptionsToJSON(value.insulationOptions),
        'homeBuildingPhaseOptions': HomeBuildingPhaseOptionsToJSON(value.homeBuildingPhaseOptions),
        'boilerOptions': BoilerOptionsToJSON(value.boilerOptions),
        'heatEmitterOptions': value.heatEmitterOptions === undefined ? undefined : (value.heatEmitterOptions === null ? null : (value.heatEmitterOptions as Array<any>).map(HeatEmitterOptionsToJSON)),
        'heatedSquareMs': value.heatedSquareMs,
        'headCount': value.headCount,
        'isNew': value.isNew,
        'zipCode': value.zipCode,
        'city': value.city,
        'address': value.address,
        'name': value.name,
        'emailAddress': value.emailAddress,
        'phoneNumber': value.phoneNumber,
        'note': value.note,
    };
}


