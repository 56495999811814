import {
  Button,
  Drawer,
  Input,
  Menu,
  Popover,
  Tooltip,
  Typography,
} from "antd";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "../NavBar.module.scss";
import { ReactComponent as Logo } from "assets/images/Logo.svg";
import { useDispatch } from "app/store";
import { AuthStep } from "features/auth/Auth.store";
import CustomButton from "components/button/CustomButton";
import clsx from "clsx";
import { KNOWLEDGE_BASE_URL } from "constants/urls";
import { MenuItemProps } from "models/MenuItemProps";
import { ReactComponent as LoginIcon } from "assets/images/login.svg";
import { ReactComponent as KnowledgeBaseIcon } from "assets/images/question-circle-green.svg";

const PublicNavBar: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDrawerVisible, setisDrawerVisible] = useState<boolean>(false);

  const canLogin = process.env.REACT_APP_LOGIN_IS_ENABLED === "true";

  const menuItems = useMemo<MenuItemProps[]>(
    () => [
      {
        label: t("NAVBAR.REAL_ESTATE_OWNERS"),
        onClick: (e) => {
          e.preventDefault();
          window.location.href =
            "https://opticonnect.mvm-optimum.hu/ingatlantulajdonosoknak/";
        },
      },
      {
        label: t("NAVBAR.DESIGNER"),
        onClick: (e) => {
          e.preventDefault();
          window.location.href =
            "https://opticonnect.mvm-optimum.hu/tervezoknek/";
        },
      },
      {
        label: t("NAVBAR.BUILDER"),
        onClick: (e) => {
          e.preventDefault();
          window.location.href =
            "https://opticonnect.mvm-optimum.hu/kivitelezoknek/";
        },
      },
      {
        label: (
          <span className={styles.knowledgeBaseMenuItemContent}>
            <Tooltip title="prompt text">
              <KnowledgeBaseIcon className={styles.knowledgeBaseItemIcon} />
            </Tooltip>
            <span className={styles.knowledgeBaseMenuItemText}>
              {t("NAVBAR.KNOWLEDGE_BASE")}
            </span>
          </span>
        ),
        onClick: (e) => {
          e.preventDefault();
          window.open(KNOWLEDGE_BASE_URL, "_blank");
        },
        className: styles.knowledgeBaseMenuItem,
        tooltipText: t("NAVBAR.KNOWLEDGE_BASE_TOOLTIP"),
        tooltipClassname: styles.knowledgeBaseMenuItemTooltip,
      },
      {
        label: (
          <span className={styles.loginMenuItemContent}>
            <LoginIcon className={styles.loginMenuItemIcon} />
            <span className={styles.loginMenuItemText}>
              {t("NAVBAR.LOGIN")}
            </span>
          </span>
        ),
        onClick: (e) => {
          e.preventDefault();
          dispatch.auth.setAuthStep({
            authStep: AuthStep.LOGIN,
          });
        },
        className: styles.loginMenuItem,
        tooltipText: t("NAVBAR.LOGIN_TOOLTIP"),
        tooltipClassname: styles.loginMenuItemTooltip,
      },
    ],

    [t, dispatch.auth]
  );

  const menu = useCallback(
    () => (
      <Menu
        mode={isDrawerVisible ? "vertical" : "horizontal"}
        className={styles.menu}
        onClick={() => isDrawerVisible && setisDrawerVisible(false)}
      >
        {menuItems.map((menuItem, index) => {
          if (menuItem.label === t("NAVBAR.LOGIN") && !canLogin) {
            return [];
          }
          return menuItem.external ? (
            <Menu.Item key={index} className={clsx(menuItem.className)}>
              <a href={menuItem.link} target="_blank" rel="noopener noreferrer">
                {menuItem.label}
              </a>
            </Menu.Item>
          ) : (
            <Menu.Item key={index} className={clsx(menuItem.className)}>
              {/* Tooltip inside <Menu.Item><Link></Link></Menu.Item> doesn't work for some reason */}
              <Tooltip
                title={menuItem.tooltipText}
                overlayClassName={clsx(
                  styles.tooltipOverlay,
                  menuItem.tooltipClassname
                )}
              >
                <Link to={menuItem.link || ""} onClick={menuItem.onClick}>
                  {menuItem.label}
                </Link>
              </Tooltip>
            </Menu.Item>
          );
        })}
      </Menu>
    ),
    [canLogin, isDrawerVisible, menuItems, t]
  );

  const HamburgerMenu = useCallback(
    () => (
      <>
        <div className={styles.mobileMenu}>
          <CustomButton type="text" onClick={() => setisDrawerVisible(true)}>
            <span className={styles.barsBtn} />
          </CustomButton>
        </div>
        <Drawer
          placement="right"
          closable={true}
          onClose={() => setisDrawerVisible(false)}
          visible={isDrawerVisible}
        >
          {menu()}
        </Drawer>
      </>
    ),
    [isDrawerVisible, menu]
  );

  return (
    <nav className={styles.navBar}>
      <a className={styles.logo} href="https://opticonnect.mvm-optimum.hu/">
        <Logo />
      </a>
      <div className={styles.desktopMenu}>{menu()}</div>
      <HamburgerMenu />
    </nav>
  );
};

export default PublicNavBar;
