/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DesignRefusalReason,
    DesignRefusalReasonFromJSON,
    DesignRefusalReasonFromJSONTyped,
    DesignRefusalReasonToJSON,
} from './';

/**
 * 
 * @export
 * @interface RefuseDesignOrderCommand
 */
export interface RefuseDesignOrderCommand {
    /**
     * 
     * @type {number}
     * @memberof RefuseDesignOrderCommand
     */
    id?: number;
    /**
     * 
     * @type {DesignRefusalReason}
     * @memberof RefuseDesignOrderCommand
     */
    reason: DesignRefusalReason;
    /**
     * 
     * @type {string}
     * @memberof RefuseDesignOrderCommand
     */
    note: string;
}

export function RefuseDesignOrderCommandFromJSON(json: any): RefuseDesignOrderCommand {
    return RefuseDesignOrderCommandFromJSONTyped(json, false);
}

export function RefuseDesignOrderCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefuseDesignOrderCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reason': DesignRefusalReasonFromJSON(json['reason']),
        'note': json['note'],
    };
}

export function RefuseDesignOrderCommandToJSON(value?: RefuseDesignOrderCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reason': DesignRefusalReasonToJSON(value.reason),
        'note': value.note,
    };
}


