import React from "react";
import {
  FlagOutlined,
  ClockCircleOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import styles from "./OrderStatus.module.scss";
import clsx from "clsx";
import enumTranslate from "utils/enumTranslate";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import { isNgbs } from "utils/authUtils";

const OrderStatus: React.FC<{ projectStatus?: string | null }> = ({
  projectStatus,
}) => {
  const getLabel = (overrideStatus?: string) => {
    return (
      projectStatus &&
      enumTranslate(overrideStatus ?? projectStatus, EnumContext.ORDER_STATUS)
    );
  };

  return projectStatus
    ? {
        "DesignOrderStatus.New": (
          <div className={clsx(styles.orderStatus, styles.new)}>
            <FlagOutlined /> {getLabel()}
          </div>
        ),
        "DesignOrderStatus.FileUpload": (
          <div className={clsx(styles.orderStatus, styles.fileUpload)}>
            <FileTextOutlined />
            {getLabel()}
          </div>
        ),
        "DesignOrderStatus.OptiComfortNew": isNgbs() ? (
          <div className={clsx(styles.orderStatus, styles.new)}>
            <FlagOutlined />
            {getLabel()}
          </div>
        ) : (
          <div className={clsx(styles.orderStatus, styles.optiComfortWip)}>
            <ClockCircleOutlined />
            {getLabel("DesignOrderStatus.OptiComfortWip")}
          </div>
        ),
        "DesignOrderStatus.OptiComfortWip": (
          <div className={clsx(styles.orderStatus, styles.optiComfortWip)}>
            <ClockCircleOutlined />
            {getLabel()}
          </div>
        ),
        "DesignOrderStatus.OptiComfort": (
          <div className={clsx(styles.orderStatus, styles.completed)}>
            <CheckCircleOutlined />
            {getLabel()}
          </div>
        ),
        "BuildOrderStatus.Approved": (
          <div className={clsx(styles.orderStatus, styles.optiComfortWip)}>
            <ClockCircleOutlined />
            {getLabel()}
          </div>
        ),
        "BuildOrderStatus.MaterialsOrdered": (
          <div className={clsx(styles.orderStatus, styles.fileUpload)}>
            <FileTextOutlined />
            {getLabel()}
          </div>
        ),
      }[projectStatus] || null
    : null;
};

export default OrderStatus;
