import { Button as AntdButton, ButtonProps } from "antd";
import clsx from "clsx";
import { FC } from "react";
import { isBuilder, isLoggedIn, isNgbs } from "utils/authUtils";
import styles from "./CustomButton.module.scss";

export type variant = "default" | "customer" | "provider" | "ngbs" | "builder";
export interface CustomButtonProps extends ButtonProps {
  className?: string;
  borderLess?: boolean;
  variant?: variant;
}

const variantClassName: { [selectedVariant in variant]: string } = {
  default: "",
  customer: styles.customerBtn,
  provider: styles.providerBtn,
  builder: styles.builderBtn,
  ngbs: styles.ngbsBtn,
};

const CustomButton: FC<CustomButtonProps> = ({
  children,
  className,
  borderLess,
  variant,
  ...rest
}) => {
  const getCurrentVariant = () => {
    if (variant) {
      return variant;
    }

    if (!isLoggedIn()) {
      return "default";
    }

    if (isNgbs()) {
      return "ngbs";
    }

    if (isBuilder()) {
      return "builder";
    }

    return "provider";
  };

  return (
    <AntdButton
      {...rest}
      className={clsx(
        className,
        variantClassName[getCurrentVariant()],
        { [styles.borderLess]: borderLess },
        styles.button
      )}
    >
      {children}
    </AntdButton>
  );
};

export default CustomButton;
