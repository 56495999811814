/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BoilerOptions,
    BoilerOptionsFromJSON,
    BoilerOptionsFromJSONTyped,
    BoilerOptionsToJSON,
    BuildOrderDto,
    BuildOrderDtoFromJSON,
    BuildOrderDtoFromJSONTyped,
    BuildOrderDtoToJSON,
    DesignFileDto,
    DesignFileDtoFromJSON,
    DesignFileDtoFromJSONTyped,
    DesignFileDtoToJSON,
    DesignOrderStatus,
    DesignOrderStatusFromJSON,
    DesignOrderStatusFromJSONTyped,
    DesignOrderStatusToJSON,
    DesignRefusalReason,
    DesignRefusalReasonFromJSON,
    DesignRefusalReasonFromJSONTyped,
    DesignRefusalReasonToJSON,
    HeatEmitterOptions,
    HeatEmitterOptionsFromJSON,
    HeatEmitterOptionsFromJSONTyped,
    HeatEmitterOptionsToJSON,
    HomeBuildingPhaseOptions,
    HomeBuildingPhaseOptionsFromJSON,
    HomeBuildingPhaseOptionsFromJSONTyped,
    HomeBuildingPhaseOptionsToJSON,
    InsulationOptions,
    InsulationOptionsFromJSON,
    InsulationOptionsFromJSONTyped,
    InsulationOptionsToJSON,
    LatestNotes,
    LatestNotesFromJSON,
    LatestNotesFromJSONTyped,
    LatestNotesToJSON,
    ProviderDto,
    ProviderDtoFromJSON,
    ProviderDtoFromJSONTyped,
    ProviderDtoToJSON,
    RealEstateFileDto,
    RealEstateFileDtoFromJSON,
    RealEstateFileDtoFromJSONTyped,
    RealEstateFileDtoToJSON,
    SpecializationDto,
    SpecializationDtoFromJSON,
    SpecializationDtoFromJSONTyped,
    SpecializationDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectDto
 */
export interface ProjectDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    projectCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    projectStatus?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDto
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDto
     */
    lastOrderRequestDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    projectStatusChangedByName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    responsibleName?: string | null;
    /**
     * 
     * @type {DesignOrderStatus}
     * @memberof ProjectDto
     */
    designOrderStatus?: DesignOrderStatus;
    /**
     * 
     * @type {DesignRefusalReason}
     * @memberof ProjectDto
     */
    designRefusalReason?: DesignRefusalReason;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDto
     */
    designOrderDeadline?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDto
     */
    designOrderCreationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDto
     */
    designOrderCompletionDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    customerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    customerEmailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    customerPhoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    realEstateZipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    realEstateCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    realEstateAddress?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDto
     */
    realEstateIsNew?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    realEstateHeatedSquareMs?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    realEstateHeadCount?: number | null;
    /**
     * 
     * @type {InsulationOptions}
     * @memberof ProjectDto
     */
    realEstateInsulationOptions?: InsulationOptions;
    /**
     * 
     * @type {BoilerOptions}
     * @memberof ProjectDto
     */
    realEstateBoilerOptions?: BoilerOptions;
    /**
     * 
     * @type {HomeBuildingPhaseOptions}
     * @memberof ProjectDto
     */
    realEstateHomeBuildingPhaseOptions?: HomeBuildingPhaseOptions;
    /**
     * 
     * @type {Array<HeatEmitterOptions>}
     * @memberof ProjectDto
     */
    realEstateHeatEmitterOptions?: Array<HeatEmitterOptions> | null;
    /**
     * 
     * @type {Array<RealEstateFileDto>}
     * @memberof ProjectDto
     */
    realEstateFiles?: Array<RealEstateFileDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    customerDesignOrderMessage?: string | null;
    /**
     * 
     * @type {ProviderDto}
     * @memberof ProjectDto
     */
    engineer?: ProviderDto;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    designOrderId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDto
     */
    designOrderCreatedAt?: Date;
    /**
     * 
     * @type {Array<DesignFileDto>}
     * @memberof ProjectDto
     */
    readonly designFiles?: Array<DesignFileDto> | null;
    /**
     * 
     * @type {Array<SpecializationDto>}
     * @memberof ProjectDto
     */
    requiredSpecializations?: Array<SpecializationDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDto
     */
    latestOptiComfortModificationDate?: Date | null;
    /**
     * 
     * @type {Array<LatestNotes>}
     * @memberof ProjectDto
     */
    latestNotes?: Array<LatestNotes> | null;
    /**
     * 
     * @type {Array<BuildOrderDto>}
     * @memberof ProjectDto
     */
    buildOrders?: Array<BuildOrderDto> | null;
}

export function ProjectDtoFromJSON(json: any): ProjectDto {
    return ProjectDtoFromJSONTyped(json, false);
}

export function ProjectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectCode': !exists(json, 'projectCode') ? undefined : json['projectCode'],
        'projectStatus': !exists(json, 'projectStatus') ? undefined : json['projectStatus'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'lastOrderRequestDate': !exists(json, 'lastOrderRequestDate') ? undefined : (json['lastOrderRequestDate'] === null ? null : new Date(json['lastOrderRequestDate'])),
        'projectStatusChangedByName': !exists(json, 'projectStatusChangedByName') ? undefined : json['projectStatusChangedByName'],
        'responsibleName': !exists(json, 'responsibleName') ? undefined : json['responsibleName'],
        'designOrderStatus': !exists(json, 'designOrderStatus') ? undefined : DesignOrderStatusFromJSON(json['designOrderStatus']),
        'designRefusalReason': !exists(json, 'designRefusalReason') ? undefined : DesignRefusalReasonFromJSON(json['designRefusalReason']),
        'designOrderDeadline': !exists(json, 'designOrderDeadline') ? undefined : (json['designOrderDeadline'] === null ? null : new Date(json['designOrderDeadline'])),
        'designOrderCreationDate': !exists(json, 'designOrderCreationDate') ? undefined : (json['designOrderCreationDate'] === null ? null : new Date(json['designOrderCreationDate'])),
        'designOrderCompletionDate': !exists(json, 'designOrderCompletionDate') ? undefined : (json['designOrderCompletionDate'] === null ? null : new Date(json['designOrderCompletionDate'])),
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerEmailAddress': !exists(json, 'customerEmailAddress') ? undefined : json['customerEmailAddress'],
        'customerPhoneNumber': !exists(json, 'customerPhoneNumber') ? undefined : json['customerPhoneNumber'],
        'realEstateZipCode': !exists(json, 'realEstateZipCode') ? undefined : json['realEstateZipCode'],
        'realEstateCity': !exists(json, 'realEstateCity') ? undefined : json['realEstateCity'],
        'realEstateAddress': !exists(json, 'realEstateAddress') ? undefined : json['realEstateAddress'],
        'realEstateIsNew': !exists(json, 'realEstateIsNew') ? undefined : json['realEstateIsNew'],
        'realEstateHeatedSquareMs': !exists(json, 'realEstateHeatedSquareMs') ? undefined : json['realEstateHeatedSquareMs'],
        'realEstateHeadCount': !exists(json, 'realEstateHeadCount') ? undefined : json['realEstateHeadCount'],
        'realEstateInsulationOptions': !exists(json, 'realEstateInsulationOptions') ? undefined : InsulationOptionsFromJSON(json['realEstateInsulationOptions']),
        'realEstateBoilerOptions': !exists(json, 'realEstateBoilerOptions') ? undefined : BoilerOptionsFromJSON(json['realEstateBoilerOptions']),
        'realEstateHomeBuildingPhaseOptions': !exists(json, 'realEstateHomeBuildingPhaseOptions') ? undefined : HomeBuildingPhaseOptionsFromJSON(json['realEstateHomeBuildingPhaseOptions']),
        'realEstateHeatEmitterOptions': !exists(json, 'realEstateHeatEmitterOptions') ? undefined : (json['realEstateHeatEmitterOptions'] === null ? null : (json['realEstateHeatEmitterOptions'] as Array<any>).map(HeatEmitterOptionsFromJSON)),
        'realEstateFiles': !exists(json, 'realEstateFiles') ? undefined : (json['realEstateFiles'] === null ? null : (json['realEstateFiles'] as Array<any>).map(RealEstateFileDtoFromJSON)),
        'customerDesignOrderMessage': !exists(json, 'customerDesignOrderMessage') ? undefined : json['customerDesignOrderMessage'],
        'engineer': !exists(json, 'engineer') ? undefined : ProviderDtoFromJSON(json['engineer']),
        'designOrderId': !exists(json, 'designOrderId') ? undefined : json['designOrderId'],
        'designOrderCreatedAt': !exists(json, 'designOrderCreatedAt') ? undefined : (new Date(json['designOrderCreatedAt'])),
        'designFiles': !exists(json, 'designFiles') ? undefined : (json['designFiles'] === null ? null : (json['designFiles'] as Array<any>).map(DesignFileDtoFromJSON)),
        'requiredSpecializations': !exists(json, 'requiredSpecializations') ? undefined : (json['requiredSpecializations'] === null ? null : (json['requiredSpecializations'] as Array<any>).map(SpecializationDtoFromJSON)),
        'latestOptiComfortModificationDate': !exists(json, 'latestOptiComfortModificationDate') ? undefined : (json['latestOptiComfortModificationDate'] === null ? null : new Date(json['latestOptiComfortModificationDate'])),
        'latestNotes': !exists(json, 'latestNotes') ? undefined : (json['latestNotes'] === null ? null : (json['latestNotes'] as Array<any>).map(LatestNotesFromJSON)),
        'buildOrders': !exists(json, 'buildOrders') ? undefined : (json['buildOrders'] === null ? null : (json['buildOrders'] as Array<any>).map(BuildOrderDtoFromJSON)),
    };
}

export function ProjectDtoToJSON(value?: ProjectDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectCode': value.projectCode,
        'projectStatus': value.projectStatus,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'lastOrderRequestDate': value.lastOrderRequestDate === undefined ? undefined : (value.lastOrderRequestDate === null ? null : value.lastOrderRequestDate.toISOString()),
        'projectStatusChangedByName': value.projectStatusChangedByName,
        'responsibleName': value.responsibleName,
        'designOrderStatus': DesignOrderStatusToJSON(value.designOrderStatus),
        'designRefusalReason': DesignRefusalReasonToJSON(value.designRefusalReason),
        'designOrderDeadline': value.designOrderDeadline === undefined ? undefined : (value.designOrderDeadline === null ? null : value.designOrderDeadline.toISOString()),
        'designOrderCreationDate': value.designOrderCreationDate === undefined ? undefined : (value.designOrderCreationDate === null ? null : value.designOrderCreationDate.toISOString()),
        'designOrderCompletionDate': value.designOrderCompletionDate === undefined ? undefined : (value.designOrderCompletionDate === null ? null : value.designOrderCompletionDate.toISOString()),
        'customerName': value.customerName,
        'customerEmailAddress': value.customerEmailAddress,
        'customerPhoneNumber': value.customerPhoneNumber,
        'realEstateZipCode': value.realEstateZipCode,
        'realEstateCity': value.realEstateCity,
        'realEstateAddress': value.realEstateAddress,
        'realEstateIsNew': value.realEstateIsNew,
        'realEstateHeatedSquareMs': value.realEstateHeatedSquareMs,
        'realEstateHeadCount': value.realEstateHeadCount,
        'realEstateInsulationOptions': InsulationOptionsToJSON(value.realEstateInsulationOptions),
        'realEstateBoilerOptions': BoilerOptionsToJSON(value.realEstateBoilerOptions),
        'realEstateHomeBuildingPhaseOptions': HomeBuildingPhaseOptionsToJSON(value.realEstateHomeBuildingPhaseOptions),
        'realEstateHeatEmitterOptions': value.realEstateHeatEmitterOptions === undefined ? undefined : (value.realEstateHeatEmitterOptions === null ? null : (value.realEstateHeatEmitterOptions as Array<any>).map(HeatEmitterOptionsToJSON)),
        'realEstateFiles': value.realEstateFiles === undefined ? undefined : (value.realEstateFiles === null ? null : (value.realEstateFiles as Array<any>).map(RealEstateFileDtoToJSON)),
        'customerDesignOrderMessage': value.customerDesignOrderMessage,
        'engineer': ProviderDtoToJSON(value.engineer),
        'designOrderId': value.designOrderId,
        'designOrderCreatedAt': value.designOrderCreatedAt === undefined ? undefined : (value.designOrderCreatedAt.toISOString()),
        'requiredSpecializations': value.requiredSpecializations === undefined ? undefined : (value.requiredSpecializations === null ? null : (value.requiredSpecializations as Array<any>).map(SpecializationDtoToJSON)),
        'latestOptiComfortModificationDate': value.latestOptiComfortModificationDate === undefined ? undefined : (value.latestOptiComfortModificationDate === null ? null : value.latestOptiComfortModificationDate.toISOString()),
        'latestNotes': value.latestNotes === undefined ? undefined : (value.latestNotes === null ? null : (value.latestNotes as Array<any>).map(LatestNotesToJSON)),
        'buildOrders': value.buildOrders === undefined ? undefined : (value.buildOrders === null ? null : (value.buildOrders as Array<any>).map(BuildOrderDtoToJSON)),
    };
}


