import {
  AccountsApi,
  AuthApi,
  Configuration,
  DesignOrdersApi,
  EngineersApi,
  ProjectsApi,
  ReferenceDataApi,
  RealEstateFilesApi,
  BuildersApi,
  BuildOrdersApi,
  MaterialsApi,
  BuildOrderMaterialsApi,
  StatsApi,
} from "./generated/optimum";
import { Middleware } from "./generated/optimum/runtime";
import { errorHandlingMiddleware } from "./middleware/errorHandlingMiddleware";
import { requestHeadersMiddleware } from "./middleware/requestHeaderMiddleware";

const apiMiddleware: Middleware[] = [
  ...requestHeadersMiddleware,
  ...errorHandlingMiddleware,
];

export const API_URL = process.env.REACT_APP_API_URL;

const apiConfig: Configuration = new Configuration({
  basePath: API_URL,
  middleware: apiMiddleware,
});

export const api = {
  designOrders: new DesignOrdersApi(apiConfig),
  engineers: new EngineersApi(apiConfig),
  auth: new AuthApi(apiConfig),
  accounts: new AccountsApi(apiConfig),
  projects: new ProjectsApi(apiConfig),
  referenceData: new ReferenceDataApi(apiConfig),
  realEstateFiles: new RealEstateFilesApi(apiConfig),
  builders: new BuildersApi(apiConfig),
  buildOrders: new BuildOrdersApi(apiConfig),
  buildOrderMaterials: new BuildOrderMaterialsApi(apiConfig),
  materials: new MaterialsApi(apiConfig),
  stats: new StatsApi(apiConfig),
};
