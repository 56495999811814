/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UpdateDesignOrderMaterial,
    UpdateDesignOrderMaterialFromJSON,
    UpdateDesignOrderMaterialFromJSONTyped,
    UpdateDesignOrderMaterialToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateDesignOrderMaterialsCommand
 */
export interface UpdateDesignOrderMaterialsCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateDesignOrderMaterialsCommand
     */
    designOrderId: number;
    /**
     * 
     * @type {Array<UpdateDesignOrderMaterial>}
     * @memberof UpdateDesignOrderMaterialsCommand
     */
    designOrderMaterials: Array<UpdateDesignOrderMaterial>;
}

export function UpdateDesignOrderMaterialsCommandFromJSON(json: any): UpdateDesignOrderMaterialsCommand {
    return UpdateDesignOrderMaterialsCommandFromJSONTyped(json, false);
}

export function UpdateDesignOrderMaterialsCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDesignOrderMaterialsCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'designOrderId': json['designOrderId'],
        'designOrderMaterials': ((json['designOrderMaterials'] as Array<any>).map(UpdateDesignOrderMaterialFromJSON)),
    };
}

export function UpdateDesignOrderMaterialsCommandToJSON(value?: UpdateDesignOrderMaterialsCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'designOrderId': value.designOrderId,
        'designOrderMaterials': ((value.designOrderMaterials as Array<any>).map(UpdateDesignOrderMaterialToJSON)),
    };
}


