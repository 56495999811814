import { Checkbox, Descriptions, Row } from "antd";
import { CreateDesignOrderCommand } from "api/generated/optimum";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import { useDispatch, useSelector } from "app/store";
import CustomButton from "components/button/CustomButton";
import Card from "components/card/Card";
import {
  checkBoxChecked,
  GrapeAntdForm,
  useGrapeAntdForm,
} from "components/form";
import ProviderAvatar from "components/providerAvatar/ProviderAvatar";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import { FC, useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import enumTranslate from "utils/enumTranslate";
import styles from "./Step5.module.scss";
import { useNavigate } from "react-router-dom";
import { useBreakpoint } from "hooks/useBreakpoint";
import { phoneNumberDisplayFormat } from "utils/format";
import { ASZF_URL, PRIVACY_URL } from "constants/urls";
import useEngineerContactWizard from "../../useEngineerContactWizard";

const Step5: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const navigate = useNavigate();
  const formUtils = useGrapeAntdForm();
  const { selectedEngineer, wizardData } = useSelector(
    (state) => state.engineer
  );
  const { prevRoute, nextRoute } = useEngineerContactWizard();

  const { loading } = useSelector(
    (state) => state.loading.effects.engineer.createDesignOder
  );

  const mobileScreen = useBreakpoint({ max: "sm" });

  const contactDetail: CreateDesignOrderCommand = wizardData[2];
  const realEstateDetail = wizardData[1];

  const handleFormFinish = useCallback(async () => {
    try {
      await dispatch.engineer.createDesignOder();
      setIsSubmitBtnDisabled(true);

      if (nextRoute) {
        navigate(nextRoute);
      }
    } catch (e) {
      console.error(e);
      setIsSubmitBtnDisabled(false);
    }
  }, [dispatch.engineer, nextRoute, navigate]);

  const RealEstateDetails = useCallback(
    () => (
      <>
        {realEstateDetail.homeBuildingPhaseOptions && (
          <>
            <CustomTitle
              level={3}
              align="left"
              color="darkBlue"
              className={styles.title}
              extraStrong
              responsiveLevel={{
                mobile: 5,
                tablet: 5,
              }}
            >
              {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.HOME_PHASE_TITLE")}
            </CustomTitle>

            <CustomTitle level={4}>
              {enumTranslate(
                realEstateDetail.homeBuildingPhaseOptions,
                EnumContext.HOMEPHASE
              )}
            </CustomTitle>
          </>
        )}
        <CustomTitle
          level={3}
          align="left"
          color="darkBlue"
          className={styles.title}
          extraStrong
          responsiveLevel={{
            mobile: 4,
            tablet: 5,
          }}
        >
          {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.REAL_ESTATE_TITLE")}
        </CustomTitle>

        <Descriptions
          className={styles.descriptions}
          layout={mobileScreen ? "vertical" : "horizontal"}
          colon={true}
          column={1}
          bordered
        >
          <Descriptions.Item
            label={
              <CustomTitle level={4}>
                {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.FLOOR_ARE")}
              </CustomTitle>
            }
          >
            {realEstateDetail.heatedSquareMs ? (
              <span>
                {realEstateDetail.heatedSquareMs} m<sup>2</sup>
              </span>
            ) : (
              ""
            )}
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <CustomTitle level={4}>
                {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.INSULATION")}
              </CustomTitle>
            }
          >
            {realEstateDetail.insulationOptions &&
              enumTranslate(
                realEstateDetail.insulationOptions,
                EnumContext.INSULATION
              )}
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <CustomTitle level={4}>
                {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.PEOPLE_COUNT")}
              </CustomTitle>
            }
          >
            {realEstateDetail.headCount}
          </Descriptions.Item>

          {realEstateDetail.boilerOptions && (
            <Descriptions.Item
              label={
                <CustomTitle level={4}>
                  {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.BOILER")}
                </CustomTitle>
              }
            >
              {enumTranslate(
                realEstateDetail.boilerOptions,
                EnumContext.BOILER
              )?.toLocaleLowerCase()}
            </Descriptions.Item>
          )}

          {realEstateDetail.heatEmitterOptions?.length && (
            <Descriptions.Item
              label={
                <CustomTitle level={4}>
                  {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.HEAT_EMITTER")}
                </CustomTitle>
              }
            >
              {realEstateDetail.heatEmitterOptions
                .map((heatOption) =>
                  enumTranslate(
                    heatOption,
                    EnumContext.HEATEMITTER
                  )?.toLocaleLowerCase()
                )
                .join(", ")}
            </Descriptions.Item>
          )}

          <Descriptions.Item
            label={
              <CustomTitle level={4}>
                {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.FULL_ADDRESS")}
              </CustomTitle>
            }
          >
            {`${realEstateDetail.zipCode} ${realEstateDetail.city}, ${realEstateDetail.address}`}
          </Descriptions.Item>
        </Descriptions>
      </>
    ),
    [t, realEstateDetail, mobileScreen]
  );

  const ContactDetails = useCallback(
    () => (
      <>
        <CustomTitle
          level={3}
          align="left"
          color="darkBlue"
          className={styles.title}
          extraStrong
          responsiveLevel={{
            mobile: 5,
            tablet: 5,
          }}
        >
          {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.CONTACT_TITLE")}
        </CustomTitle>

        <Descriptions
          className={styles.descriptions}
          layout={mobileScreen ? "vertical" : "horizontal"}
          colon={true}
          column={1}
          bordered
        >
          <Descriptions.Item
            label={
              <CustomTitle level={4}>
                {t("ENGINEERS.ENGINEER_WIZARD.STEP_4.NAME_LABEL")}
              </CustomTitle>
            }
          >
            {contactDetail.name}
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <CustomTitle level={4}>
                {t("ENGINEERS.ENGINEER_WIZARD.STEP_4.EMAIL_LABEL")}
              </CustomTitle>
            }
          >
            {contactDetail.emailAddress}
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <CustomTitle level={4}>
                {t("ENGINEERS.ENGINEER_WIZARD.STEP_4.PHONE_LABEL")}
              </CustomTitle>
            }
          >
            {phoneNumberDisplayFormat(contactDetail.phoneNumber)}
          </Descriptions.Item>
        </Descriptions>
      </>
    ),
    [
      t,
      contactDetail.name,
      contactDetail.phoneNumber,
      contactDetail.emailAddress,
      mobileScreen,
    ]
  );

  const handleBackButton = () => {
    dispatch.engineer.prevStep();

    if (prevRoute) {
      navigate(prevRoute);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <CustomTitle
          level={3}
          align="center"
          color="darkBlue"
          responsiveLevel={{ mobile: 5, tablet: 5 }}
          extraStrong
        >
          {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.CARD_TITLE")}
        </CustomTitle>

        <CustomTitle
          level={3}
          align="left"
          color="lightBlue"
          className={styles.selectedEngineerTitle}
          responsiveLevel={{ mobile: 5, tablet: 5 }}
          extraStrong
        >
          {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.SELECTED_ENGINEER")}
        </CustomTitle>

        <div className={styles.selectedEngineer}>
          <ProviderAvatar size={60} url={selectedEngineer?.profilePictureUrl} />
          <div className={styles.selectedEngineerData}>
            <CustomTitle level={5} extraStrong>
              {`${selectedEngineer?.lastName} ${selectedEngineer?.firstName}`}
            </CustomTitle>

            <CustomParagraph size="xs" strong>
              {enumTranslate(
                selectedEngineer?.profession || "",
                EnumContext.PROFESSION
              )}
            </CustomParagraph>
            <CustomParagraph size="xs" strong>
              {selectedEngineer?.companyName}
            </CustomParagraph>
          </div>
        </div>
        <RealEstateDetails />
        <ContactDetails />
        <CustomTitle
          level={3}
          align="left"
          color="darkBlue"
          className={styles.title}
          extraStrong
          responsiveLevel={{
            mobile: 5,
            tablet: 5,
          }}
        >
          {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.FILES_TITLE")}
        </CustomTitle>

        <CustomParagraph size="md" className={styles.fileText}>
          {realEstateDetail.files?.map((file) => file.name).join("; ")}
        </CustomParagraph>

        <CustomTitle
          level={3}
          align="left"
          color="darkBlue"
          className={styles.title}
          extraStrong
          responsiveLevel={{
            mobile: 5,
            tablet: 5,
          }}
        >
          {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.NOTE_TITLE")}
        </CustomTitle>
        <CustomParagraph size="md" className={styles.noteText}>
          {contactDetail.note}
        </CustomParagraph>
      </Card>

      <GrapeAntdForm
        requiredMark={false}
        onFinish={handleFormFinish}
        formUtils={formUtils}
        className={styles.checkBoxGroup}
      >
        <GrapeAntdForm.Item
          name="aszf"
          rules={[
            checkBoxChecked(
              t("ENGINEERS.ENGINEER_WIZARD.STEP_5.ASZF_CHECKBOX_ERROR")
            ),
          ]}
          valuePropName="checked"
        >
          <Checkbox>
            <CustomParagraph>
              <Trans i18nKey="ENGINEERS.ENGINEER_WIZARD.STEP_5.ASZF">
                <a
                  onClick={() => window.open(ASZF_URL, "_blank")}
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </Trans>
            </CustomParagraph>
          </Checkbox>
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item
          name="dataHandling"
          rules={[
            checkBoxChecked(
              t("ENGINEERS.ENGINEER_WIZARD.STEP_5.DATA_CHECKBOX_ERROR")
            ),
          ]}
          valuePropName="checked"
        >
          <Checkbox>
            <CustomParagraph>
              <Trans i18nKey="ENGINEERS.ENGINEER_WIZARD.STEP_5.DATA_HANDLING">
                <a
                  onClick={() => window.open(PRIVACY_URL, "_blank")}
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </Trans>
            </CustomParagraph>
          </Checkbox>
        </GrapeAntdForm.Item>
      </GrapeAntdForm>

      <Row justify="center">
        <CustomParagraph color="green" className={styles.info}>
          {`${t("ENGINEERS.ENGINEER_WIZARD.STEP_5.INFO")}`}
          <br />
          {`${t("ENGINEERS.ENGINEER_WIZARD.STEP_5.DESIGNER_CONTACT")}`}
        </CustomParagraph>
      </Row>

      <Row justify="center">
        <CustomButton
          type="primary"
          onClick={() => formUtils.form.submit()}
          size="large"
          className={styles.submitButton}
          loading={loading}
          disabled={isSubmitBtnDisabled}
        >
          {t("ENGINEERS.ENGINEER_WIZARD.STEP_5.SUBMIT")}
        </CustomButton>
      </Row>

      <Row justify="center">
        <CustomButton type="text" onClick={handleBackButton}>
          {t("COMMON.BACK")}
        </CustomButton>
      </Row>
    </div>
  );
};

export default Step5;
