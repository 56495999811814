import { Col, Divider, Row } from "antd";
import { useDispatch, useSelector } from "app/store";
import CustomButton from "components/button/CustomButton";
import Card from "components/card/Card";
import Jumbotron from "components/jumbotron/Jumbotron";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./BuildImplementation.module.scss";
import { CheckCircleOutlined } from "@ant-design/icons";
import FileList from "../components/fileList/FileList";
import ReadyReportModal from "./readyReportModal/ReadyReportModal";
import useBuild from "../useBuild";
import BuildMaterialListModal from "../buildMaterialListModal/BuildMaterialListModal";
import ConfirmModal from "components/confirmModal/ConfirmModal";
import { formatDate } from "utils/format";
import { isAdmin, isBuilder } from "utils/authUtils";
import ProviderInfo from "../components/fileList/providerInfo/ProviderInfo";
import { BuildFileType } from "api/generated/optimum";
import BuildMaterialTable from "./assemblyModal/BuildMaterialTable";
import MaterialListCSVLink from "./materialListCSVLink/MaterialListCSVLink";

const BuildImplementation: React.FC = () => {
  const { t } = useTranslation();
  const { currentProject } = useSelector((state) => state.projects);
  const { automation, delivery } = useSelector((state) => state.buildOrder);
  const { getAutomationData, getPanelDeliveryData } = useBuild();
  const { loading: downloadProposalTemplateIsLoading } = useSelector(
    (state) => state.loading.effects.buildOrder.downloadProposalTemplate
  );

  const dispatch = useDispatch();
  const [isMaterialListModalVisible, setIsMaterialListModalVisible] =
    useState(false);

  const [isReadyReportModalVisible, setIsReadyReportModalVisible] =
    useState(false);
  const [isPanelDeliveryModalVisible, setIsPanelDeliveryModalVisible] =
    useState(false);

  const [
    isMaterialOrderConfirmModalVisible,
    setIsMaterialOrderConfirmModalVisible,
  ] = useState(false);

  const [assemblyModalVisible, setAssemblyModalVisible] = useState(false);

  const {
    isApprovedBuildOrder,
    isMaterialOrderedBuildOrder,
    buildOrder,
    isCompletedBuildOrder,
    isNewBuildOrder,
    isBuildOrderRefused,
  } = useBuild();

  useEffect(() => {
    getAutomationData();
  }, [currentProject, dispatch.buildOrder, getAutomationData]);

  useEffect(() => {
    getPanelDeliveryData();
  }, [currentProject, getPanelDeliveryData]);

  const getAutomationTitle = () => {
    if (isNewBuildOrder) {
      return t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.NEW_AUTOMATION_TITLE");
    }
    if (isCompletedBuildOrder || isMaterialOrderedBuildOrder) {
      return t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.ORDERED_AUTOMATION_TITLE");
    }

    return t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.AUTOMATION_TITLE");
  };

  const ReadySection = useCallback(
    () => (
      <div className={styles.readySection}>
        <Row justify="center" gutter={35}>
          <Col>
            <CheckCircleOutlined className={styles.icon} />
          </Col>
          <Col>
            <CustomTitle color="green" level={4} extraStrong align="center">
              {t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.READY_SUCCESS")}
            </CustomTitle>
            <CustomParagraph className={styles.readyParag}>
              {t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.READY_DATE")}
            </CustomParagraph>
            <CustomParagraph className={styles.readyParag}>
              {buildOrder?.completionDate &&
                formatDate.dateTime(buildOrder?.completionDate)}
            </CustomParagraph>
          </Col>
        </Row>

        {isAdmin() && (
          <ProviderInfo
            title={t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.BUILDER_TITLE")}
            provider={buildOrder?.builder}
          />
        )}

        <Jumbotron className={styles.jumbotron}>
          <CustomTitle
            level={4}
            extraStrong
            align="left"
            className={styles.subTitle}
          >
            {t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.READY_DETAILS_TITLE")}
          </CustomTitle>

          <Row>
            <Col span={12}>
              <FileList
                title={t(
                  "PROJECTS.DETAIL.BUILD.IMPLEMENTATION.STATEMENT_LABEL"
                )}
                fileList={buildOrder?.buildFiles?.filter(
                  (file) => file.type === BuildFileType.StatementOfBuilder
                )}
              />

              <FileList
                title={t(
                  "PROJECTS.DETAIL.BUILD.IMPLEMENTATION.PURCHASE_PROTOCOL_LABEL"
                )}
                fileList={buildOrder?.buildFiles?.filter(
                  (file) => file.type === BuildFileType.RecordOfDelivery
                )}
              />

              <FileList
                title={t(
                  "PROJECTS.DETAIL.BUILD.IMPLEMENTATION.EDUCATION_PROTOCOLT_LABEL"
                )}
                fileList={buildOrder?.buildFiles?.filter(
                  (file) => file.type === BuildFileType.RecordOfTraining
                )}
              />

              <FileList
                title={t(
                  "PROJECTS.DETAIL.BUILD.IMPLEMENTATION.DAIKIN_PROTOCOL_LABEL"
                )}
                fileList={buildOrder?.buildFiles?.filter(
                  (file) =>
                    file.type === BuildFileType.RecordOfDaikinInstallation
                )}
              />
            </Col>

            <Col span={12}>
              <CustomTitle level={3} extraStrong align="left" defaultFontSize>
                {t("PROJECTS.DETAIL.BUILD.READY.DATE_LABLE")}
              </CustomTitle>
              {buildOrder?.installationDate &&
                formatDate.date(buildOrder?.installationDate)}

              <CustomTitle level={3} extraStrong align="left" defaultFontSize>
                {t("PROJECTS.DETAIL.BUILD.READY.CODE_LABLE")}
              </CustomTitle>
              {buildOrder?.heatPumpIdentifier}

              <CustomTitle level={3} extraStrong align="left" defaultFontSize>
                {t("PROJECTS.DETAIL.BUILD.READY.PUMP_SN_LABLE")}
              </CustomTitle>
              {buildOrder?.heatPumpSerialNumber}
            </Col>
          </Row>
        </Jumbotron>
      </div>
    ),
    [
      t,
      buildOrder?.builder,
      buildOrder?.completionDate,
      buildOrder?.heatPumpIdentifier,
      buildOrder?.installationDate,
      buildOrder?.buildFiles,
      buildOrder?.heatPumpSerialNumber,
    ]
  );

  return (
    <Card className={styles.card}>
      <CustomTitle
        level={3}
        extraStrong
        align="center"
        color="darkBlue"
        className={styles.cardTitle}
      >
        {isNewBuildOrder
          ? t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.NEW_TITLE")
          : t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.TITLE")}
      </CustomTitle>

      {isCompletedBuildOrder && <ReadySection />}

      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Jumbotron className={styles.jumbotron}>
            <CustomTitle
              level={4}
              extraStrong
              align="left"
              className={styles.subTitle}
            >
              {isCompletedBuildOrder || isMaterialOrderedBuildOrder
                ? t(
                    "PROJECTS.DETAIL.BUILD.IMPLEMENTATION.ORDERED_MATERIALLIST_TITLE"
                  )
                : t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.MATERIALLIST_TITLE")}
            </CustomTitle>

            <Row>
              <CustomButton
                onClick={() => setIsMaterialListModalVisible(true)}
                size="large"
              >
                {isBuilder() && (isApprovedBuildOrder || isNewBuildOrder)
                  ? t("COMMON.MODIFY")
                  : t("COMMON.VIEW")}
              </CustomButton>

              <CustomButton
                onClick={() => {
                  if (isAdmin() && (isBuildOrderRefused || isNewBuildOrder)) {
                    dispatch.buildOrder.exportPricedMaterialList(
                      Number(currentProject?.designOrderId)
                    );
                  } else {
                    buildOrder?.id &&
                      dispatch.buildOrder.exportMaterialList(buildOrder?.id);
                  }
                }}
                size="large"
              >
                {t("COMMON.EXPORT_CSV")}
              </CustomButton>
            </Row>
          </Jumbotron>
        </Col>

        <Col span={12}>
          <Jumbotron className={styles.jumbotron}>
            <CustomTitle
              level={4}
              extraStrong
              align="left"
              className={styles.subTitle}
            >
              {getAutomationTitle()}
            </CustomTitle>

            <Row>
              <CustomButton
                size="large"
                onClick={() => setAssemblyModalVisible(true)}
              >
                {t("COMMON.VIEW")}
              </CustomButton>

              <CustomButton size="large">
                <MaterialListCSVLink data={automation} />
              </CustomButton>
            </Row>
          </Jumbotron>
        </Col>

        {isBuilder() && (
          <Col span={12}>
            <Jumbotron className={styles.jumbotron}>
              <CustomTitle
                level={4}
                extraStrong
                align="left"
                className={styles.subTitle}
              >
                {t("PROJECTS.DETAIL.BUILD.PROPOSAL_TEMPLATE.TITLE")}
              </CustomTitle>

              <CustomButton
                size="large"
                onClick={() =>
                  buildOrder?.id &&
                  dispatch.buildOrder.downloadProposalTemplate(buildOrder.id)
                }
                loading={downloadProposalTemplateIsLoading}
              >
                {t("COMMON.DOWNLOAD")}
              </CustomButton>
            </Jumbotron>
          </Col>
        )}

        <Col span={12} offset={!isBuilder() ? 12 : 0}>
          <Jumbotron className={styles.jumbotron}>
            <CustomTitle
              level={4}
              extraStrong
              align="left"
              className={styles.subTitle}
            >
              {t("PROJECTS.DETAIL.BUILD.PANEL_DELIVERY.TITLE")}
            </CustomTitle>

            <Row>
              <CustomButton
                size="large"
                onClick={() => setIsPanelDeliveryModalVisible(true)}
              >
                {t("COMMON.VIEW")}
              </CustomButton>

              <CustomButton size="large">
                <MaterialListCSVLink data={delivery} />
              </CustomButton>
            </Row>
          </Jumbotron>
        </Col>
      </Row>

      {isApprovedBuildOrder && !isAdmin() && (
        <Row justify="center">
          <CustomButton
            variant="default"
            type="primary"
            size="large"
            className={styles.orderBtn}
            onClick={() => setIsMaterialOrderConfirmModalVisible(true)}
          >
            {t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.ORDER_BTN")}
          </CustomButton>
        </Row>
      )}

      {isMaterialOrderedBuildOrder && (
        <Row justify="center">
          <CustomParagraph size="xs" italic className={styles.orderDate}>
            {` ${t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.ORDER_DATE")}
                ${
                  buildOrder?.buildMaterialListOrderDate &&
                  formatDate.dateTime(buildOrder?.buildMaterialListOrderDate)
                }`}
          </CustomParagraph>
        </Row>
      )}

      {(isApprovedBuildOrder || isMaterialOrderedBuildOrder) && !isAdmin() && (
        <>
          <Divider className={styles.divider} />

          <Row justify="center">
            <CustomButton
              variant="default"
              type="primary"
              size="large"
              className={styles.readyBtn}
              onClick={() => setIsReadyReportModalVisible(true)}
              disabled={isApprovedBuildOrder}
            >
              {t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.READY_BTN")}
            </CustomButton>
          </Row>
        </>
      )}

      {buildOrder?.id && (
        <BuildMaterialListModal
          visible={isMaterialListModalVisible}
          buildOrder={buildOrder}
          project={currentProject}
          onClose={() => {
            dispatch.buildOrder.setUpdatedMaterialList([]);
            setIsMaterialListModalVisible(false);
          }}
        />
      )}

      <ReadyReportModal
        isVisible={isReadyReportModalVisible}
        onClose={() => setIsReadyReportModalVisible(false)}
      />

      <ConfirmModal
        width={"58rem"}
        isVisible={isMaterialOrderConfirmModalVisible}
        className={styles.materialOrderConfirmModal}
        onClose={() => setIsMaterialOrderConfirmModalVisible(false)}
        onSubmit={async () => {
          try {
            buildOrder?.id &&
              (await dispatch.buildOrder.orderBuildOrderMaterials(
                buildOrder?.id
              ));

            setIsMaterialOrderConfirmModalVisible(false);

            currentProject?.projectCode &&
              dispatch.projects.getProject({
                projectCode: currentProject?.projectCode,
              });
          } catch (error) {
            console.error(error);
          }
        }}
        title={t("PROJECTS.DETAIL.BUILD.IMPLEMENTATION.CONFIRM_ORDER.TITLE")}
        description={t(
          "PROJECTS.DETAIL.BUILD.IMPLEMENTATION.CONFIRM_ORDER.DESC"
        )}
        subDescription={t(
          "PROJECTS.DETAIL.BUILD.IMPLEMENTATION.CONFIRM_ORDER.SUB_DESC"
        )}
        submitBtnLabel={t(
          "PROJECTS.DETAIL.BUILD.IMPLEMENTATION.CONFIRM_ORDER.SUBMIT"
        )}
      />
      <BuildMaterialTable
        visible={assemblyModalVisible}
        onClose={() => setAssemblyModalVisible(false)}
        data={automation}
        title={t("ASSEMBLY_MODAL.TITLE")}
      />
      <BuildMaterialTable
        visible={isPanelDeliveryModalVisible}
        onClose={() => setIsPanelDeliveryModalVisible(false)}
        data={delivery}
        title={t("PROJECTS.DETAIL.BUILD.PANEL_DELIVERY.TITLE")}
      />
    </Card>
  );
};

export default BuildImplementation;
