import { Result } from "antd";
import { useDispatch } from "app/store";
import CustomButton from "components/button/CustomButton";
import LinkButton from "components/button/linkButton/LinkButton";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./EngineerContactSuccess.module.scss";
import { CheckCircleOutlined } from "@ant-design/icons";

const NAVIGATION_URL = process.env.REACT_APP_SUCCESSFUL_ORDER_URL;

const EngineerContactSuccess: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePrimaryButtonClick = useCallback(() => {
    dispatch.engineer.reset();

    navigate("/");
  }, [dispatch.engineer, navigate]);

  const handleSecondaryButtonClick = useCallback(() => {
    if (NAVIGATION_URL === undefined) {
      return;
    }

    if (process.env.NODE_ENV === "development") {
      navigate(`${NAVIGATION_URL}`);
    } else {
      window.location.href = NAVIGATION_URL;
    }
  }, [navigate]);

  return (
    <Result
      status="success"
      title={t("ENGINEERS.ENGINEER_WIZARD.SUCCESS.SUCCESS_MSG")}
      subTitle={t("ENGINEERS.ENGINEER_WIZARD.SUCCESS.EMAIL_COPY")}
      icon={<CheckCircleOutlined className={styles.icon} />}
      extra={[
        <CustomButton
          onClick={handlePrimaryButtonClick}
          type="primary"
          variant="default"
          size="large"
          key="primary"
        >
          {t("ENGINEERS.ENGINEER_WIZARD.SUCCESS.GOTO_HOMEPAGE_BUTTON")}
        </CustomButton>,
        <LinkButton onClick={handleSecondaryButtonClick} key="secondary">
          {t("ENGINEERS.ENGINEER_WIZARD.SUCCESS.VIEW_BUILDER_BUTTON")}
        </LinkButton>,
      ]}
      className={styles.result}
    />
  );
};

export default EngineerContactSuccess;
