import { useGrapeAntdForm } from "components/form";
import { DesignRefusalReason } from "api/generated/optimum";
import enumTranslate from "utils/enumTranslate";
import { EnumContext } from "app/i18n/manualLocaleKeys";

import RefuseOrder, { RefuseOrderProps } from "./RefuseOrder";

const RefuseDesignOrder: React.FC<
  Omit<RefuseOrderProps, "formUtils" | "refuseOptions">
> = (props) => {
  const formUtils = useGrapeAntdForm("RefuseDesignOrderCommand");

  const designRefusalReasonOptions = Object.values(DesignRefusalReason)
    .filter((option) => option !== DesignRefusalReason.Invalid)
    .map((value) => {
      return {
        value,
        label: enumTranslate(value, EnumContext.DESIGN_REFUSAL_REASON) ?? value,
      };
    });

  return (
    <RefuseOrder
      formUtils={formUtils}
      refuseOptions={designRefusalReasonOptions}
      {...props}
    />
  );
};

export default RefuseDesignOrder;
