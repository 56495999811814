import { FC } from "react";
import Card from "components/card/Card";
import styles from "./BuilderSelected.module.scss";
import { UserOutlined, CloseOutlined, StopOutlined } from "@ant-design/icons";
import { Trans, useTranslation } from "react-i18next";
import CustomTitle from "components/typography/title/CustomTitle";
import { Col, Row } from "antd";
import CustomButton from "components/button/CustomButton";
import Avatar from "components/providerAvatar/ProviderAvatar";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import { useDispatch } from "app/store";
import CustomAvatar from "components/customAvatar/CustomAvatar";
import { BuilderDto, BuildOrderStatus } from "api/generated/optimum";
import clsx from "clsx";
import { useBreakpoint } from "hooks/useBreakpoint";

export interface SelectedBuilder extends BuilderDto {
  status?: BuildOrderStatus;
}

export interface BuilderListProps {
  title?: string;
  wizardTitle?: string;
  wizardTitlePosition?:
    | "center"
    | "start"
    | "end"
    | "space-around"
    | "space-between"
    | undefined;
  hideRemove?: boolean;
  selectedBuilders: SelectedBuilder[];
  handleModifyBuilderSelection?: (builder: SelectedBuilder) => void;
}

const BuilderSelected: FC<BuilderListProps> = ({
  title,
  wizardTitle,
  hideRemove = false,
  wizardTitlePosition,
  selectedBuilders,
  handleModifyBuilderSelection,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobileScreen = useBreakpoint({ max: "lg" });

  const emptyCards = (): JSX.Element[] => {
    let i = selectedBuilders?.length;

    const result: JSX.Element[] = [];

    while (i < 3) {
      result.push(
        <Col xs={24} lg={8} key={i}>
          <Card className={styles.emptyCard}>
            <Row gutter={10}>
              <Col>
                <Avatar size={40} preview={false} />
              </Col>
              <Col flex="auto">
                <div className={styles.emptyCardInnerCard} />
              </Col>
            </Row>
          </Card>
        </Col>
      );
      i++;
    }

    return result;
  };

  return (
    <>
      <Card className={title ? styles.container : styles.wizardContainer}>
        {title && (
          <>
            <Row className={styles.title}>
              <Col xs={4} sm={2} lg={1}>
                <CustomAvatar
                  src={<UserOutlined />}
                  className={styles.titleIcon}
                />
              </Col>
              <Col xs={20} sm={22} lg={23}>
                <CustomTitle
                  level={3}
                  responsiveLevel={{ mobile: 4, tablet: 5 }}
                  color="lightBlue"
                  className={styles.titleText}
                >
                  {title}
                </CustomTitle>
              </Col>
            </Row>
          </>
        )}

        {wizardTitle && (
          <Row className={styles.title} justify={wizardTitlePosition}>
            <Col>
              <CustomTitle className={styles.wizardTitle} color="lightBlue">
                {wizardTitle}
              </CustomTitle>
            </Col>
          </Row>
        )}

        <Row gutter={[32, 16]} justify="center">
          {selectedBuilders?.map((builder, index) => (
            <Col xs={24} lg={8} key={index}>
              <Card
                className={clsx(styles.selectedCardContainer, {
                  [styles.refusedCardContainer]:
                    builder.status === BuildOrderStatus.Refused ||
                    builder.status === BuildOrderStatus.Archived,
                })}
              >
                <Row gutter={10}>
                  <Col xs={3.5}>
                    <Avatar
                      url={builder.profilePictureUrl}
                      size={40}
                      preview={false}
                    />
                  </Col>
                  <Col xs={17}>
                    <CustomTitle
                      level={4}
                      className={styles.name}
                      extraStrong
                      ellipsis
                    >
                      {`${builder.lastName} ${builder.firstName}`}
                    </CustomTitle>

                    <CustomParagraph
                      strong
                      color="grey"
                      className={styles.details}
                    >
                      {builder?.position || ""}
                    </CustomParagraph>

                    <CustomParagraph
                      strong
                      color="grey"
                      className={styles.details}
                    >
                      {builder.companyName}
                    </CustomParagraph>
                  </Col>
                  {!hideRemove && (
                    <CustomButton
                      icon={<CloseOutlined />}
                      className={styles.closeIcon}
                      type="ghost"
                      onClick={() =>
                        handleModifyBuilderSelection &&
                        handleModifyBuilderSelection(builder)
                      }
                    />
                  )}
                </Row>

                {(builder.status === BuildOrderStatus.Refused ||
                  builder.status === BuildOrderStatus.Archived) && (
                  <Row
                    justify="center"
                    align="middle"
                    className={styles.declined}
                  >
                    <div>
                      <StopOutlined />
                      {t("BUILDERSELECTED.DECLINE")}
                    </div>
                  </Row>
                )}
              </Card>
            </Col>
          ))}

          {emptyCards()}
          <Row>
            <Col>
              {selectedBuilders?.length === 0 && (
                <CustomParagraph className={styles.emptyNote}>
                  {!mobileScreen
                    ? t("BUILDERSELECTED.EMPTY_NOTE")
                    : t("BUILDERSELECTED.EMPTY_NOTE_MOBILE")}
                </CustomParagraph>
              )}
            </Col>
          </Row>
        </Row>
      </Card>
      {title && (
        <Row justify="center">
          <CustomButton
            type="primary"
            className={styles.nextButton}
            size="large"
            disabled={selectedBuilders.length === 0}
            onClick={() => dispatch.builder.nextStep()}
          >
            <Row gutter={8}>
              <Col xs={24} md={12}>
                {t("BUILDERSELECTED.CONTACT_FOR_OFFER_1")}
              </Col>
              <Col xs={24} md={12}>
                {t("BUILDERSELECTED.CONTACT_FOR_OFFER_2")}
              </Col>
            </Row>
          </CustomButton>
        </Row>
      )}
    </>
  );
};

export default BuilderSelected;
