import { Descriptions, Divider } from "antd";
import { EnumContext } from "app/i18n/manualLocaleKeys";
import { useSelector } from "app/store";
import clsx from "clsx";
import Card from "components/card/Card";
import CustomParagraph from "components/typography/paragraph/CustomParagraph";
import CustomTitle from "components/typography/title/CustomTitle";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { isAdmin } from "utils/authUtils";
import enumTranslate from "utils/enumTranslate";
import { phoneNumberDisplayFormat } from "utils/format";
import styles from "./RejectionDetails.module.scss";

const RejectionDetails: React.FC = () => {
  const { t } = useTranslation();
  const { currentProject } = useSelector((state) => state.projects);
  const location = useLocation();

  return (
    <Card className={styles.card}>
      <CustomTitle
        level={3}
        extraStrong
        color="darkBlue"
        align="center"
        className={styles.cardTitle}
      >
        {t("PROJECTS.DETAIL.DESIGN.ADMIN.REJECTION.TITLE")}
      </CustomTitle>

      {isAdmin() && (
        <>
          <Descriptions layout="vertical">
            <Descriptions.Item
              label={
                <CustomParagraph
                  color="grey"
                  extraStrong
                  className={styles.label}
                >
                  {t("PROJECTS.DETAIL.DESIGN.ADMIN.REJECTION.REFUSED_BY")}
                </CustomParagraph>
              }
              span={24}
              className={styles.description}
            >
              <NavLink
                to={`/admin/providers/${currentProject?.latestNotes?.[0]?.actor?.id}`}
                state={{
                  from: location.pathname,
                }}
              >
                {currentProject?.latestNotes?.[0]?.actor?.displayName}
              </NavLink>

              <CustomParagraph color="grey">
                {currentProject?.latestNotes?.[0]?.actor?.phoneNumber &&
                  phoneNumberDisplayFormat(
                    currentProject?.latestNotes?.[0]?.actor?.phoneNumber
                  )}
              </CustomParagraph>

              <CustomParagraph color="grey">
                {currentProject?.latestNotes?.[0]?.actor?.email}
              </CustomParagraph>
            </Descriptions.Item>
          </Descriptions>

          <Divider className={styles.divider} />
        </>
      )}
      <Descriptions layout="vertical">
        <Descriptions.Item
          label={
            <CustomParagraph color="grey" extraStrong className={styles.label}>
              {t("PROJECTS.DETAIL.DESIGN.ADMIN.REJECTION.REFUSED_REASON")}
            </CustomParagraph>
          }
          span={24}
          className={styles.description}
        >
          {currentProject?.latestNotes?.[0]?.reason
            ? enumTranslate(
                currentProject?.latestNotes?.[0]?.reason,
                EnumContext.DESIGN_REFUSAL_REASON
              )
            : "-"}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <CustomParagraph
              color="grey"
              extraStrong
              className={clsx(styles.label, styles.labelRefusedNote)}
            >
              {t("PROJECTS.DETAIL.DESIGN.ADMIN.REJECTION.REFUSED_NOTE")}
            </CustomParagraph>
          }
          span={24}
          className={styles.description}
        >
          {currentProject?.latestNotes?.[0]?.note}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default RejectionDetails;
