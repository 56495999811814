import { useSelector } from "app/store";
import { useMemo } from "react";
import { EngineerStepRouteForNew, EngineerStepRouteForRenewal } from "./models";
import { RealEstateType } from "models/RealEstateType";

type EngineerRouteForNewStepMap = {
  [key in EngineerStepRouteForNew]: number;
};

type EngineerRouteForRenewalStepMap = {
  [key in EngineerStepRouteForRenewal]: number;
};

const engineerRouteStepMapForNew: EngineerRouteForNewStepMap = {
  "/design/real-estate": 0,
  "/design/new/details": 1,
  "/design/new/contact": 2,
  "/design/new/confirmation": 3,
  "/design/new/confirmed": 4,
};

const engineerRouteStepMapForRenewal: EngineerRouteForRenewalStepMap = {
  "/design/real-estate": 0,
  "/design/renewal/details": 1,
  "/design/renewal/contact": 2,
  "/design/renewal/confirmation": 3,
  "/design/renewal/confirmed": 4,
};

const realEstateTypeStepMap: {
  [key in RealEstateType]:
    | EngineerRouteForNewStepMap
    | EngineerRouteForRenewalStepMap;
} = {
  AwaitingRenovation: engineerRouteStepMapForRenewal,
  NewlyBuilt: engineerRouteStepMapForNew,
};

interface UseEngineerContactWizard {
  prevRoute: string | undefined;
  nextRoute: string | undefined;
  nextRouteForNew: string | undefined; // Only use if RealEstateType isn't set yet
  nextRouteForRenewal: string | undefined; // Only use if RealEstateType isn't set yet
}

const useEngineerContactWizard = (): UseEngineerContactWizard => {
  const { realEstateType, currentStep } = useSelector(
    (state) => state.engineer
  );

  const routeStepMap = realEstateType && realEstateTypeStepMap[realEstateType];

  const prevRoute = useMemo(
    () =>
      routeStepMap &&
      Object.entries(routeStepMap).find(
        ([key, value]) => value === currentStep - 1
      )?.[0],
    [currentStep, routeStepMap]
  );

  const nextRoute = useMemo(
    () =>
      routeStepMap &&
      Object.entries(routeStepMap).find(
        ([key, value]) => value === currentStep + 1
      )?.[0],
    [currentStep, routeStepMap]
  );

  // Only use if RealEstateType isn't set yet
  const nextRouteForNew = useMemo(
    () =>
      Object.entries(engineerRouteStepMapForNew).find(
        ([key, value]) => value === currentStep + 1
      )?.[0],
    [currentStep]
  );

  // Only use if RealEstateType isn't set yet
  const nextRouteForRenewal = useMemo(
    () =>
      Object.entries(engineerRouteStepMapForRenewal).find(
        ([key, value]) => value === currentStep + 1
      )?.[0],
    [currentStep]
  );

  return {
    prevRoute,
    nextRoute,
    nextRouteForNew,
    nextRouteForRenewal,
  };
};

export default useEngineerContactWizard;
