import { Col, Row } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Card from "components/card/Card";
import {
  email,
  GrapeAntdForm,
  phoneNumber,
  useGrapeAntdForm,
} from "components/form";
import CustomTitle from "components/typography/title/CustomTitle";
import StepNavigator from "components/stepNavigator/StepNavigator";
import CustomButton from "components/button/CustomButton";
import { useDispatch, useSelector } from "app/store";

import styles from "./Step4.module.scss";
import CustomInput, {
  CustomTextArea,
} from "components/form/components/CustomInput";
import { CreateBuildOrder } from "models/CreateBuildOrder";
import { CreateDesignOrderCommand } from "api/generated/optimum";

const Step4: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formUtils = useGrapeAntdForm<CreateBuildOrder>(
    "CreateBuildOrderCommand"
  );
  const { currentStep, wizardData } = useSelector((state) => state.builder);

  const handleFinish = (values: CreateDesignOrderCommand) => {
    dispatch.builder.setWizardData(values);

    dispatch.builder.nextStep();
  };

  const handleBackButton = () => {
    dispatch.builder.setWizardData(formUtils.form.getFieldsValue());
    dispatch.builder.prevStep();
  };

  return (
    <>
      <Card className={styles.cardContainer}>
        <CustomTitle level={3} align="center" color="darkBlue">
          {t("BUILDERS.BUILDERS_WIZARD.STEP_4.CARD_TITLE")}
        </CustomTitle>

        <GrapeAntdForm
          formUtils={formUtils}
          onFinish={handleFinish}
          className={styles.form}
          requiredMark={true}
          initialValues={wizardData[currentStep]}
        >
          <Row gutter={90}>
            <Col xs={24}>
              <GrapeAntdForm.Item<CreateBuildOrder>
                label={t("BUILDERS.BUILDERS_WIZARD.STEP_4.NAME_LABEL")}
                name="name"
              >
                <CustomInput placeholder={t("COMMON.FORM.INPUT_PLACEHOLDER")} />
              </GrapeAntdForm.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <GrapeAntdForm.Item<CreateBuildOrder>
                label={t("BUILDERS.BUILDERS_WIZARD.STEP_4.EMAIL_LABEL")}
                name="emailAddress"
                rules={[email()]}
              >
                <CustomInput placeholder={t("COMMON.FORM.INPUT_PLACEHOLDER")} />
              </GrapeAntdForm.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <GrapeAntdForm.Item<CreateBuildOrder>
                label={t("BUILDERS.BUILDERS_WIZARD.STEP_4.PHONE_LABEL")}
                name="phoneNumber"
                rules={[
                  phoneNumber(
                    t("BUILDERS.BUILDERS_WIZARD.STEP_4.PHONE_INVALID_FORMAT")
                  ),
                ]}
              >
                <CustomInput
                  placeholder={t(
                    "BUILDERS.BUILDERS_WIZARD.STEP_4.PHONE_PLACEHOLDER"
                  )}
                />
              </GrapeAntdForm.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <GrapeAntdForm.Item<CreateBuildOrder>
                label={t("BUILDERS.BUILDERS_WIZARD.STEP_4.NOTE_LABEL")}
                name="note"
              >
                <CustomTextArea
                  placeholder={t(
                    "BUILDERS.BUILDERS_WIZARD.STEP_4.NOTE_PLACEHOLDER"
                  )}
                  className={styles.textArea}
                />
              </GrapeAntdForm.Item>
            </Col>
          </Row>
        </GrapeAntdForm>
      </Card>

      <StepNavigator
        firstButton={
          <CustomButton
            onClick={handleBackButton}
            className={styles.navigatorButton}
          >
            {t("COMMON.BACK")}
          </CustomButton>
        }
        secondButton={
          <CustomButton
            type="primary"
            block
            onClick={() => formUtils.form.submit()}
            className={styles.navigatorButton}
          >
            {t("COMMON.NEXT")}
          </CustomButton>
        }
      />
    </>
  );
};

export default Step4;
