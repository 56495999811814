import moment from "moment";

enum DateFormat {
  date = "YYYY.MM.DD.",
  dateTime = "YYYY-MM-DD HH:mm",
  long = "YYYY.MM.DD HH:mm:ss",
  period = "HH:mm:ss",
  shortPeriod = "HH:mm",
  weekday = "dddd",
  monthYear = "MM/YYYY",
}

export const formatDate = {
  seconds: (value: number) => value && moment.duration(value).asSeconds(),
  date: (value: Date) => value && moment(value).format(DateFormat.date),
  dateTime: (value: Date) => value && moment(value).format(DateFormat.dateTime),
  long: (value: Date) => value && moment(value).format(DateFormat.long),
  period: (value: Date) => value && moment(value).format(DateFormat.period),
  shortPeriod: (value: Date) =>
    value && moment(value).format(DateFormat.shortPeriod),
  weekday: (value: Date) => value && moment(value).format(DateFormat.weekday),
  unixTimestampToDate: (value: number, dateFormat: DateFormat) =>
    value && moment.unix(value).format(dateFormat),
  toUnixTimestamp: (value: Date) => value && moment(value).unix(),
  dateToUnixTimestamp: (value: number) =>
    value && moment.duration(value).asSeconds(),
  dateToUtcString: (value: Date) => value && moment(value).toJSON(),
  dateToString: (value: string, dateFormat: DateFormat) =>
    value && moment(value).format(dateFormat),
  secondsToPeriod: (value: number) =>
    value && moment().startOf("day").seconds(value).format(DateFormat.period),
};

export const decimalSeparator = (value: number) =>
  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");

export const phoneNumberDisplayFormat = (phoneNumber: string): string => {
  let areaCode: string;
  let firstPartOfNumber: string;
  let endPartOfNumber: string;

  const countryCode = phoneNumber.slice(0, 3); // +36
  const isBudapestAreaCode = phoneNumber[3] === "1"; // Bp area code: 1 = +361

  if (isBudapestAreaCode) {
    areaCode = phoneNumber.slice(3, 4);
    firstPartOfNumber = phoneNumber.slice(4, 7);
    endPartOfNumber = phoneNumber.slice(7);
  } else {
    areaCode = phoneNumber.slice(3, 5);
    firstPartOfNumber = phoneNumber.slice(5, 8);
    endPartOfNumber = phoneNumber.slice(8);
  }

  return `${countryCode}-${areaCode}-${firstPartOfNumber}-${endPartOfNumber}`;
};
