/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DesignServiceChargeDto,
    DesignServiceChargeDtoFromJSON,
    DesignServiceChargeDtoFromJSONTyped,
    DesignServiceChargeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface DesignServiceChargeSummaryDto
 */
export interface DesignServiceChargeSummaryDto {
    /**
     * 
     * @type {Array<DesignServiceChargeDto>}
     * @memberof DesignServiceChargeSummaryDto
     */
    serviceCharges?: Array<DesignServiceChargeDto> | null;
    /**
     * 
     * @type {number}
     * @memberof DesignServiceChargeSummaryDto
     */
    readonly totalPrice?: number;
}

export function DesignServiceChargeSummaryDtoFromJSON(json: any): DesignServiceChargeSummaryDto {
    return DesignServiceChargeSummaryDtoFromJSONTyped(json, false);
}

export function DesignServiceChargeSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DesignServiceChargeSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceCharges': !exists(json, 'serviceCharges') ? undefined : (json['serviceCharges'] === null ? null : (json['serviceCharges'] as Array<any>).map(DesignServiceChargeDtoFromJSON)),
        'totalPrice': !exists(json, 'totalPrice') ? undefined : json['totalPrice'],
    };
}

export function DesignServiceChargeSummaryDtoToJSON(value?: DesignServiceChargeSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceCharges': value.serviceCharges === undefined ? undefined : (value.serviceCharges === null ? null : (value.serviceCharges as Array<any>).map(DesignServiceChargeDtoToJSON)),
    };
}


